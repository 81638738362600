/* eslint-disable no-unused-vars */
import axios from "axios";
import axiosautomacao from "../apigestao"
const NETWORK_ERROR = "Network Error"
const {
  ADD_CASHIER,
  UPDATE_CASHIER,
  GET_ALL_CASHIER,
  DESTROY_CASHIER,
  GET_AUTOCOMPLETE_CASHIER,
} = require("./actions/cashier");

const { SPLICE_SUCESSO, SPLICE_ERRO } = require("./actions/alert_api");
const moduleCashier = {
  state: () => ({
    cashiers: Object(),
  }),

  mutations: {
    GET_ALL_CASHIER: (state, cashiers) => {
      state.cashiers = cashiers;
    },
    GET_AUTOCOMPLETE_CASHIER: (state, cashiers) => {
      state.cashiers = cashiers;
    },
    ADD_CASHIER: (state, newCashiers) => {
      state.cashiers.data.push(newCashiers.data);
    },
    UPDATE_CASHIER(state, editCashier) {
      let result = state.cashiers.data.find(
        (r) => r.id === editCashier.data.id
      );
      for (let key in editCashier.data) {
        result[key] = editCashier.data[key];
      }
    },

    DESTROY_CASHIER: (state, id) => {
      let auxState = [...state.cashiers.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.cashiers.data = [...auxState];
    },
  },
  actions: {
    GET_ALL_CASHIER({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axiosautomacao.post("cashier/index", {
        page: payload.page,
        limit: payload.limit,
        paginate: true
      }, {
        headers: {
          'Authorization': 'Bearer ' + token,

        }
      }).then((response) => {
        commit(GET_ALL_CASHIER, response.data[0]);
      }).catch((error) => {
        commit(SPLICE_ERRO, {
          alert: "Erro ao carregar os Caixas!",
          type: "E",
        });
        if (NETWORK_ERROR == error.message) {
          axios.post("cashier/index", { page: payload.page, limit: payload.limit }).then((response) => {
            commit(GET_ALL_CASHIER, response.data.data);
          });
        }
      })

    },
    GET_AUTOCOMPLETE_CASHIER({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axiosautomacao.post("cashier/autocomplete", {
        search: payload.search,
        page: payload.page,
        limit: payload.limit,
        paginate: true
      }, {
        headers: {
          'Authorization': 'Bearer ' + token,

        }
      }).then((response) => {
        commit(GET_AUTOCOMPLETE_CASHIER, response.data[0]);
      })
    },

    ADD_CASHIER({ commit }, payload) {
      axios
        .post("cashier/create", payload)
        .then((response) => {
          commit(ADD_CASHIER, response.data);
          commit(SPLICE_SUCESSO, {
            alert: "Cadastrado com Sucesso!",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    UPDATE_CASHIER({ commit }, payload) {
      axios
        .post("cashier/update", payload)
        .then((response) => {
          commit(UPDATE_CASHIER, response.data);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
          });
        })
        .catch((e) => {
          e.response.data.errors.forEach((el) => {
            commit(SPLICE_ERRO, {
              alert: el,
              type: 'E'
            })
          })
        });
    },

    DESTROY_CASHIER({ commit }, payload) {
      console.log("ger", payload);
      axios
        .post("cashier/delete", { id: payload.id })
        .then((response) => {
          commit(DESTROY_CASHIER, payload.id);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((e) => {
          e.response.data.errors.forEach((el) => {
            commit(SPLICE_ERRO, {
              alert: el,
              type: 'E'
            })
          })
        });
    },
  },

  getters: {
    getCashier: (state) => ({ ...state.cashiers }),
  },
};



export default moduleCashier;
