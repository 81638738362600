<template>
  <div>
    <v-app-bar app color="success" dark>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">
        <span class="font-weight-light">{{ title }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon @click="logout" large>mdi-logout</v-icon>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      width="300px"
      temporary
      app
      color="success"
      class="SideNavbar"
    >
      <NavAvatar />
      <NavList />
    </v-navigation-drawer>
  </div>
</template>

<script>
import NavList from "./NavList/";
import NavAvatar from "./NavAvatar/";
import "./styles.css";
import { GET_ALL_ENTERPRISE } from "../../store/actions/enterprise";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      title: String(),
    };
  },
  components: {
    NavList,
    NavAvatar,
  },
  methods: {
    async GetEnterprise() {
      await this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: 1, limit: 10, paginate:true 
      });
    },
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "Login" }));
    },
  },
  async created() {
    await this.GetEnterprise();
  },
  computed: {
    ...mapGetters(["getEnterprises"]),
  },
  watch: {
    getEnterprises: function (val) {
      this.title = val.data[0].fantasyName;
    },
  },
};
</script>
