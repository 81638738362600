import axios from "axios";
import axiosautomacao from "../apigestao"
const {
  ADD_SELLER,
  UPDATE_SELLER,
  GET_ALL_SELLER_OFFLINE,
  GET_ALL_SELLER_ONLINE,
  DESTROY_SELLER,
  GET_BY_ID_SELLER,
  GET_BY_ID_NOT_IDENTIFY_SELLER,
  AUTOCOMPLETE_SELLER,
} = require("./actions/seller");
const NETWORK_ERROR = "Network Error"
const { SPLICE_ERRO } = require("./actions/alert_api");
const { SET_SELLER_IN_MEMORY } = require('./actions/sales')

const moduleSeller = {
  state: () => ({
    sellers: Object(),
    resultSeller: Object()
  }),

  mutations: {
    GET_ALL_SELLER_OFFLINE: (state, payload) => {
      state.sellers = payload;
    },
    GET_BY_ID_NOT_IDENTIFY_SELLER: (state, payload) => {
      state.resultSeller = {}
    },
    GET_ALL_SELLER_ONLINE: (state, payload) => {
      let sellersObject = {};
      let sellersArray = []
      let contacts = {};
      let address = {};
      let banks = {};
      let physicalpeople = {};
      let auxPayload = { ...payload };

      // Estruturar os dados
      payload.data.forEach((e) => {
        sellersObject = {};
        physicalpeople = {}
        contacts = {};
        address = {};
        banks = {};

        physicalpeople = { ...e.people.physicalpeople };
        contacts = e.people.contact;
        address = e.people.address;
        banks = e.people.bank_data;

        Object.assign(sellersObject, { contacts });
        Object.assign(sellersObject, { address });
        Object.assign(sellersObject, { banks });
        Object.assign(sellersObject, { physicalpeople });
        sellersObject.type_people = e.type_people;
        sellersObject.enterprise_id = e.enterprise_id;
        sellersObject.page = { ...auxPayload };
        sellersObject.id = e.id;
        sellersArray.push(sellersObject);
      });
      delete auxPayload.data;
      while (state.sellers.length) {
        state.sellers.pop();
      }
      auxPayload.data = sellersArray
      state.sellers = auxPayload

    },

    AUTOCOMPLETE_SELLER: (state, payload) => {
      let sellersObject = {};
      let sellersArray = []
      let contacts = {};
      let address = {};
      let banks = {};
      let physicalpeople = {};
      let auxPayload = { ...payload };

      // Estruturar os dados
      payload.data.forEach((e) => {
        sellersObject = {};
        physicalpeople = {}
        contacts = {};
        address = {};
        banks = {};

        physicalpeople = { ...e.people.physicalpeople };
        contacts = e.people.contact;
        address = e.people.address;
        banks = e.people.bank_data;

        Object.assign(sellersObject, { contacts });
        Object.assign(sellersObject, { address });
        Object.assign(sellersObject, { banks });
        Object.assign(sellersObject, { physicalpeople });
        sellersObject.type_people = e.type_people;
        sellersObject.enterprise_id = e.enterprise_id;
        sellersObject.page = { ...auxPayload };
        sellersObject.id = e.id;
        sellersArray.push(sellersObject);
      });
      delete auxPayload.data;
      while (state.sellers.length) {
        state.sellers.pop();
      }
      auxPayload.data = sellersArray
      state.sellers = auxPayload
    },

    ADD_SELLER: (state, payload) => {
      let AuxState = { ...state.sellers }
      AuxState.data.push(payload)
      state.sellers = AuxState;
    },
    GET_BY_ID_SELLER: (state, payload) => {
      let sellersObject = {};
      let physicalpeople = {}
      let contacts = {};
      let address = {};
      physicalpeople = { ...payload.people.physicalpeople };
      contacts = payload.people.contact;
      address = payload.people.address;
      Object.assign(sellersObject, { contacts });
      Object.assign(sellersObject, { address });
      Object.assign(sellersObject, { physicalpeople });
      sellersObject.type_people = payload.type_people;
      sellersObject.id = payload.id;
      state.resultSeller = sellersObject

    },
    UPDATE_SELLER(state, payload) {
      state.sellers = payload
    },

    DESTROY_SELLER: (state, id) => {
      let auxState = [...state.sellers.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      );
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.sellers = [...auxState];
    },

  },

  actions: {
    async GET_ALL_SELLER({ commit }, payload) {

      let token = localStorage.getItem("token_gestao");
      axiosautomacao.post("seller/index", {
        page: payload.page,
        limit: payload.limit,
        paginate: true
      }, {
        headers: {
          'Authorization': 'Bearer ' + token,

        }
      }).then((response) => {
        commit(GET_ALL_SELLER_ONLINE, response.data[0]);
      }).catch((error) => {
        commit(SPLICE_ERRO, {
          alert: "Erro ao carregar os Vendedor!",
          type: "E",
        });
        if (NETWORK_ERROR == error.message) {
          let data = { ...payload }
          axios.post("seller/index", {
            page: data.page,
            limit: data.limit
          }).then((response) => {
            commit(GET_ALL_SELLER_OFFLINE, response.data.data);
          });

        }

      })


    },

    async AUTOCOMPLETE_SELLER({ commit }, payload) {

      let token = localStorage.getItem("token_gestao");
      axiosautomacao.post("seller/autocomplete", {
        search: payload.search,
        page: payload.page,
        limit: payload.limit,
        paginate: true,
        enterprise_id: payload.enterprise_id,
        type_people: "PhysicalPeople"
      }, {
        headers: {
          'Authorization': 'Bearer ' + token,

        }
      }).then((response) => {
        commit(AUTOCOMPLETE_SELLER, response.data[0]);
      })


    },
    GET_BY_ID_SELLER({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axios.post("seller/show", { id: payload.id }, {
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      })
        .then((response) => {
          commit(GET_BY_ID_SELLER, response.data[0])
        }).catch((error) => {
          commit(GET_BY_ID_NOT_IDENTIFY_SELLER, {})
          commit(SPLICE_ERRO, {
            alert: 'Vendedor não encontrado!',
            type: 'E'
          })

        })
    },

    ADD_SELLER({
      commit
    }, payload) {
      axios.post("seller/create", payload).then((response) => {
        commit(ADD_SELLER, response.data.data);
        commit(SPLICE_ERRO, {
          alert: response.data.msg,
          type: 'S'
        })
      }).catch(r => {
        let erros = r.response.data.errors
        for (let erro in erros) {
          erros[erro].forEach(err => commit(SPLICE_ERRO, {
            alert: err,
            type: 'E'
          }))
        }
      });
    },

    UPDATE_SELLER({
      commit
    }, payload) {
      axios.post("seller/update", payload)
        .then((response) => {
          commit(UPDATE_SELLER, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.msg,
            type: 'S'
          })
        }).catch(r => {
          r.response.data.forEach((e) => {
            commit(SPLICE_ERRO, {
              alert: e,
              type: 'E'
            })
          })

        });
    },

    DESTROY_SELLER({
      commit
    }, payload) {
      axios
        .post("seller/delete", payload)
        .then((response) => {
          commit(DESTROY_SELLER, payload.id);
          commit(SPLICE_ERRO, {
            alert: response.data.msg,
            type: 'S'
          })
        }).catch(r => {
          let erros = r.response.data.errors
          for (let erro in erros) {
            erros[erro].forEach(err => commit(SPLICE_ERRO, {
              alert: err,
              type: 'E'
            }))
          }
        });
    },
  },

  getters: {
    getSeller: (state) => ({
      ...state.sellers
    }),
    getSellerOnline: (state) => ({
      ...state.sellers
    }),
    getResultSeller: (state) => ({ ...state.resultSeller })
  },
};

export default moduleSeller;