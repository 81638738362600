import axiosautomacao from "../apigestao"
const {
  ADD_CART,
  UPDATE_CART,
  GET_ALL_CART,
  DESTROY_CART,
  CLEAN_CART,
  DISCOUNT_PERMISSION,
  SET_DISCOUNT_PERMISSION,
} = require("./actions/cart");
const { SPLICE_ERRO } = require("./actions/alert_api");
const moduleCart = {
  state: () => ({
    cart: [],
    discountPermission: null,
  }),

  mutations: {
    ADD_CART: (state, newProduct) => {
      state.cart.push(newProduct);
    },
    CLEAN_CART: (state) => {
      state.cart = [];
    },
    GET_ALL_CART: (state, product) => {
      state.cart = product;
    },
    DESTROY_CART: (state, index) => {
      state.cart.splice(index, 1);
    },

    UPDATE_CART: (state, payload) => {
      let auxstate  = state.cart
      const index = auxstate.findIndex((item) => item.product_id === payload.product_id)
      auxstate.splice(index, 1, payload)
      state.cart = auxstate
    
      //   let result = state.cart.find((r) => r.product_id === payload.product_id);
      //   for (let key in payload) {
      //     result[key] = payload[key]
      //     if (key === 'amount' && key !== 'value_descont_product') result[key] = parseInt(payload[key]);
      //      else if (key !== 'value_descont_product') result[key] = payload[key];
      //   }
      //  result['value_subtotal_product'] = (result.amount * result.value_sale) - result.value_descont_product

    },
    CLEAR_CART: (state) => {
      state.cart = Array()
    },
    DISCOUNT_PERMISSION: (state, payload) => {
      state.discountPermission = payload
    },
    SET_DISCOUNT_PERMISSION: (state ) => {
      state.discountPermission = null
    }
  },

  actions: {
    ADD_CART({
      commit
    }, payload) {
      commit(ADD_CART, payload);
    },
    CLEAN_CART({
      commit
    }) {
      commit(CLEAN_CART);
    },
    GET_ALL_CART({
      commit
    }, payload) {
      commit(GET_ALL_CART, payload);
    },
    UPDATE_CART({
      commit
    }, payload) {
      commit(UPDATE_CART, payload);
    },
    DESTROY_CART({
      commit
    }, payload) {
      commit(DESTROY_CART, payload);
    },
    CLEAR_CART({
      commit
    }) {
      commit(CLEAN_CART)
    },
    DISCOUNT_PERMISSION({commit}, payload) {
      let token = localStorage.getItem("token_gestao");
        let response = axiosautomacao.post("/user/consult-discount-permission", payload, {
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        })
        .then((response) => {
          commit(DISCOUNT_PERMISSION, true);
          commit(SPLICE_ERRO, {
            alert: response.data.original,
            type: "S",
          });
        }).catch((e) => {
          commit(DISCOUNT_PERMISSION, false);
          commit(SPLICE_ERRO, {
            alert: e.response.data.error,
            type: "E",
          });
        })
    },
    SET_DISCOUNT_PERMISSION({commit}) {
      commit( SET_DISCOUNT_PERMISSION );
    }
  },
  getters: {
    getCart: (state) => state.cart,
    getDiscountPermission: (state) => state.discountPermission,
  },
};

export default moduleCart;