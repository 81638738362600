import jwt_decode from "jwt-decode"

export default (token) => {
    try {
        const now = Date.now() / 1000
        if (typeof jwt_decode(token).exp !== 'undefined' && jwt_decode(token).exp < now)
            return false

        return true
    } catch (error) {
        return false
    }
}
