const links = [
    { text: "Dashboard", icon: "mdi-monitor-dashboard", route: "/dashboard" },
    /*
    { text: "Vendas", icon: "mdi-shopping", route: "/vendas" },
    {
        text: "Produtos",
        icon: "mdi-package-variant-closed",
        route: "/produtos",
    },
    { text: "Clientes", icon: "mdi-account", route: "/clientes" },
    { text: "Fiscal", icon: "mdi-clipboard-list", route: "/fiscal" },
    */
];

export { links };