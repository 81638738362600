const headers = {
    isPhisical: [
        { text: "Código", value: "id" },
        { text: "Nome", value: "physicalpeople.name" },
        { text: "Ações", value: "actions" },
    ],
    isLegal: [
        { text: "Código", value: "id" },
        { text: "Nome", value: "legalpeople.socialName" },
        { text: "Ações", value: "actions" },
    ]

}

export {
    headers
};