<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this._props.addDialogCst" max-width="600">
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Cst</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="Codigo"
                type="number"
                v-model="code"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="2"
                :rules="[() => !!code || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
              <v-textarea
                label="Descrição"
                v-model="description"
                :rules="[() => !!description || 'Campo Obrigatorio']"
                outlined
              />
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    closeCst();
                    //runAlterDialog(this, alterAddDialog);
                  }
                "
                >Cancelar</v-btn
              >
              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiAccount } from "@mdi/js";
import { ADD_CST, UPDATE_CST } from "../../../store/actions/cst";
export default {
  props: {
    addDialogCst: Boolean,
    component: Object,
  },
  data() {
    return {
      attrs: false,
      on: false,
      valid: true,
      id: "",
      code: "",
      description: "",
      cfopToUpdate: "",
      svgPath: mdiAccount,
      search: "",
      formHasErrors: false,
    };
  },
  methods: {
    closeCst() {
      this.component.addDialogCst = false;
    },
    getComponent() {
      return this._props.component;
    },
    async create_cst() {
      const cst = {
        code: this.code,
        description: this.description,
      };
      try {
        await this.$store.dispatch(ADD_CST, cst);
        this.closeCst();
      } catch (error) {
        alert(error);
      }
    },
    submit() {
      this.id == undefined ? this.create_cst() : this.update_cst();
      this.component.addDialog = false;
    },
    async update_cst() {
      const cst = {
        id: this.id,
        code: this.code,
        description: this.description,
      };
      try {
        await this.$store.dispatch(UPDATE_CST, cst);
      } catch (error) {
        alert(error);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  async created() {
    this.object = { ...this.getComponent().objectToUpdate };
    this.id = this.object.id;
    this.code = this.object.code;
    this.description = this.object.description;
  },
  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
