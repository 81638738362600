/* eslint-disable no-unused-vars */
const {
    GET_BYBARCODE_BARCODE,

} = require("./actions/barcode");

// const {
//     getByEmailBaseActions

// } = require("./baseModules/baseActions/");

const moduleBarcode = {
    state: () => ({
        barcode: Object(),
    }),

    mutations: {
        GET_BYBARCODE_BARCODE: (state, newProduct) => {
            state.barcode = (newProduct.data);
        },
        CLEAR_BARCODE_CODE: (state) => {
            state.barcode = {}
        }

    },

    actions: {
        async GET_BYBARCODE_BARCODE({
            commit
        }, payload) {
            console.log(payload, 'payload barcode');

            const pay = Object()
            pay.condition = 'barcode'
            pay.value = payload.barcode
            commit
            console.log(GET_BYBARCODE_BARCODE)
            // try {
            //     await getByEmailBaseActions(commit, payload, GET_BYBARCODE_BARCODE)

            // } catch (error) {
            //     console.log(error, 'ere');
            // }
        },

    },
    getters: {
        getBarcode: (state) => JSON.parse(JSON.stringify(state.barcode)),
    },
};

export default moduleBarcode;