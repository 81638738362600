<template>
  <v-row justify="center">
    <v-dialog
      v-model="this._props.component.dialog_cancel"
      max-width="500"
      persistent
    >
      <v-card class="d-flex justify-center">
        <v-col>
          <v-card-title>Deseja cancelar esse cupom ?</v-card-title>
          <v-divider class="mb-5" />
          <v-form ref="form" v-model="form">
            <v-text-field
              label="CNPJ Emitente"
              v-model="getEnterprises.data[0].cnpj"
              filled
              disabled
              dense
              outlined
            />
            <v-text-field
              label="Chave de acesso"
              v-model="this.component.objectNfceCancel.access_key"
              filled
              disabled
              dense
              outlined
            />
            <v-text-field
              label="Protocolo"
              v-model="this.component.objectNfceCancel.authorization_protocol"
              filled
              disabled
              dense
              outlined
            />
            <v-textarea
              label="Justificativa"
              :rules="rules"
              v-model="justification"
              dense
              outlined
            />
            <v-textarea
              label="Modelo de Nota"
              v-model="modelNf"
              dense
              outlined
            />
            <v-divider class="mt-1" />
            <v-card-actions class="d-flex justify-end">
              <v-btn @click="cancel" class="error">Sair</v-btn>
              <v-btn
                @click="confirmationCancel"
                color="primary"
                :disabled="isConfirmationButtonDisabled"
                >Confirmar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-col>
        <AlertForComponents
          :active="alert"
          message="Preencha todos os campos"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { CANCEL_NFCE } from "../../../../store/actions/nfce";
import { CANCEL_SALES } from "../../../../store/actions/sales";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
  },
  data() {
    return {
      justification: String(),
      modelNf: "65",
    };
  },
  methods: {
    cancel() {
      this._props.component.dialog_cancel =
        !this._props.component.dialog_cancel;
    },
    async confirmationCancel() {
      const cnpj = this.getEnterprises.data[0].cnpj;
      const invoceCancel = {
        cnpjEmitter: cnpj.replace(/\D/g, ""),
        key: this.component.objectNfceCancel.access_key,
        justification: this.justification,
        protocol: this.component.objectNfceCancel.authorization_protocol,
        modelNf: this.modelNf,
      };
      const saleCancel = {
        sales_id: this.component.objectNfceCancel.id,
      };
      await this.$store
        .dispatch(CANCEL_NFCE, invoceCancel)
        .then(() => {
          this.$store.dispatch(CANCEL_SALES, saleCancel);
        })
        .catch((error) => {
          this.dialog_load = !this.dialog_load;
          this.dialog_alert = !this.dialog_alert;
          this.titleError = error.code + " - " + "Error ao cancelar o nfce";

          error.errors.forEach((e) => {
            let item = Object();
            item.text = e;
            this.listErrors.push(item);
          });
        });

      this.component.getAll()
      this._props.component.dialog_cancel =
        !this._props.component.dialog_cancel;
    },
  },
  computed: {
    ...mapGetters(["getEnterprises"]),

    isConfirmationButtonDisabled() {
      return this.justification.length < 15;
    },
  },
};
</script>
