<template>
  <div>
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="./avatar.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title white--text">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="white--text">{{
            user.email
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
  </div>
</template>
<script>
export default {
  data: () => ({
    user: Object(),
  }),
  created() {
    this.user = { ...this.$store.state.auth.user.user };
  },
};
</script>

<style>
p {
  text-align: center;
}
</style>