const GET_ALL_PERMISSION = 'GET_ALL_PERMISSION'
const GET_ALL_ROLES = 'GET_ALL_ROLES'
const CREATE_ROLES = 'CREATE_ROLES'
const UPDATE_ROLES = 'UPDATE_ROLES'
const DELETE_ROLES = 'DELETE_ROLES'

module.exports = {
    GET_ALL_PERMISSION,
    CREATE_ROLES,
    UPDATE_ROLES,
    DELETE_ROLES,
    GET_ALL_ROLES
};
