const CSOSN_101 = "101"
const CSOSN_102 = "102"
const CSOSN_103 = "103"
const CSOSN_300 = "300"
const CSOSN_400 = "400"
const CSOSN_201 = "201"
const CSOSN_202 = "202"
const CSOSN_203 = "203"
const CSOSN_500 = "500"
const CSOSN_900 = "900"


async function verifyTypeOperationEnterpriseTaxRegimeSimples(csosnOperation, product) {

    switch (csosnOperation) {
        case CSOSN_101:
   
            return calculeIcmsSimplesCsosn101(product)


        case CSOSN_102:
            return calculeIcmsSimplesCsosn102(product)


        case CSOSN_103:
            return calculeIcmsSimplesCsosn102(product)

        case CSOSN_300:
            return calculeIcmsSimplesCsosn102(product)


        case CSOSN_400:
            return calculeIcmsSimplesCsosn102(product)


        case CSOSN_201:
            return calculeIcmsSimplesCsosn201(product)

        case CSOSN_202:
            return calculeIcmsSimplesCsosn201(product)


        case CSOSN_203:
            return calculeIcmsSimplesCsosn201(product)


        case CSOSN_500:
            return calculeIcmsSimplesCsosn201(product)


        case CSOSN_900:
            return calculeIcmsSimplesCsosn201(product)


        default:
            break;
    }
}

async function calculeIcmsSimplesCsosn101(product) {

    product.value_subtotal_product = product.amount * product.value_sale
    product.value_base_icms_product = product.value_subtotal_product
    product.value_accounting_product = product.value_subtotal_product
    let calcIcms = product.value_base_icms_product * (product.aliquot_icms_product / 100)
    product.value_icms_product = calcIcms.toFixed(2)
    product.value_free_icms_product = 0
    product.value_icms_outher_product = 0
    product.value_base_icms_st_product = 0
    product.value_icms_st_product = 0
    return product
}


async function calculeIcmsSimplesCsosn102(product) {

    product.value_subtotal_product = product.amount * product.value_sale
    product.value_base_icms_product = 0
    product.aliquot_icms_product = 0
    product.value_accounting_product = product.value_subtotal_product
    product.value_icms_product = 0
    product.value_free_icms_product = 0
    product.value_icms_outher_product = product.amount * product.value_sale
    product.value_base_icms_st_product = 0
    product.value_icms_st_product = 0



    return product
}
async function calculeIcmsSimplesCsosn201(product) {

    product.value_subtotal_product = product.amount * product.value_sale
    product.value_base_icms_product = 0
    product.aliquot_icms_product = 0
    product.value_accounting_product = product.value_subtotal_product
    product.value_icms_product = 0
    product.value_free_icms_product = 0
    product.value_icms_outher_product = product.amount * product.value_sale
    product.value_base_icms_st_product = 0
    product.value_icms_st_product = 0

    return product
}


module.exports = {
    verifyTypeOperationEnterpriseTaxRegimeSimples
}


