var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"650","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-container',[_c('v-card-title',[_vm._v(_vm._s(_vm.title)+" Usuário")]),_c('v-divider'),_c('v-form',{ref:"form",model:{value:(_vm.valid_form),callback:function ($$v) {_vm.valid_form=$$v},expression:"valid_form"}},[_c('v-row',{staticClass:"ma-2"},[_c('v-checkbox',{attrs:{"label":"Ativo"},model:{value:(_vm.component.user.status),callback:function ($$v) {_vm.$set(_vm.component.user, "status", $$v)},expression:"component.user.status"}})],1),_c('v-row',{staticClass:"ma-2"},[_c('v-text-field',{staticClass:"mr-1",attrs:{"rules":[(val) => !!val || 'Campo obrigatório!'],"label":"*Nome","outlined":""},model:{value:(_vm.component.user.name),callback:function ($$v) {_vm.$set(_vm.component.user, "name", $$v)},expression:"component.user.name"}}),_c('v-text-field',{attrs:{"rules":[
                (val) => !!val || 'Campo obrigatório!',
                (v) => /.+@.+/.test(v) || 'Insira um e-mail válido!',
              ],"label":"*E-mail","outlined":""},model:{value:(_vm.component.user.email),callback:function ($$v) {_vm.$set(_vm.component.user, "email", $$v)},expression:"component.user.email"}})],1),(_vm.component.changePassword)?_c('div',[_c('v-row',{staticClass:"ma-2"},[_c('v-text-field',{staticClass:"mr-1",attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"rules":[_vm.rules.required, _vm.rules.min],"type":_vm.show1 ? 'text' : 'password',"hint":"Minimo 8 digitos","label":"*Senha","outlined":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.component.user.password),callback:function ($$v) {_vm.$set(_vm.component.user, "password", $$v)},expression:"component.user.password"}}),_c('v-text-field',{attrs:{"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"rules":[
                  (val) =>
                    val === _vm.component.user.password || 'Senhas não coicidem!',
                ],"label":"*Confirmar Senha","hint":"Minimo 8 digitos","outlined":""},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.component.confirm_password),callback:function ($$v) {_vm.$set(_vm.component, "confirm_password", $$v)},expression:"component.confirm_password"}})],1)],1):_vm._e(),_c('v-row',{staticClass:"ma-2"},[_c('v-select',{attrs:{"items":_vm.getRoles,"outlined":"","return-object":"","item-text":"label","label":"*Permissão","multiple":""},model:{value:(_vm.component.user.roles),callback:function ($$v) {_vm.$set(_vm.component.user, "roles", $$v)},expression:"component.user.roles"}})],1),_c('v-row',{staticClass:"ma-2"},[_c('v-text-field',{attrs:{"outlined":"","readonly":"","clearable":"","label":"*Permissões de Caixa","append-icon":"mdi-cash-register"},on:{"click:append":function($event){return _vm.activeCashier()}},model:{value:(_vm.cashier.name),callback:function ($$v) {_vm.$set(_vm.cashier, "name", $$v)},expression:"cashier.name"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":function($event){return _vm.actionDialog()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cancel")]),_vm._v("Cancelar")],1),_c('v-btn',{staticClass:"success",attrs:{"disabled":!_vm.valid_form},on:{"click":_vm.submit}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-content-save")]),_vm._v(" Confirmar")],1)],1)],1)],1)],1),(_vm.dialogCashier)?_c('cashierSetting',{attrs:{"component":this}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }