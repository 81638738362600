<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="component.destroyDialog"
      transition="fab-transition"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline"
          >Deseja Excluir esta Empresa ?</v-card-title
        >
        <v-card-text>Essa ação irá excluir a Empresa</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="
              () => {
                executeAlterDestroyDialog(this);
              }
            "
            >Cancelar</v-btn
          >
          <v-btn
            color="success ml-4"
            @click="
              () => {
                submit(this);
              }
            "
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: {
    component: Object,
    destroy_object: Function,
  },
  methods: {
    executeAlterDestroyDialog() {
      this.component.destroyDialog = false;
    },
    submit() {
      this.component.confirmDelete();
    },
  },
};
</script>

<style>
</style>