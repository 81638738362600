<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="searchInput"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
          @keyup.enter="autocompleteFop"
        >
        </v-text-field>
      </v-card-text>
      <DestroyDialog :component="this" :destroy_object="destroy_object" />
      <Table :component="this" :search="search" />
      <AddDialog v-if="addDialog" :component="this" />
    </v-card>
    <AlertApi />
    <loadScreen/>
  </Container>
</template>

<script>
import Container from "../components/FormOfPayment/Container";
import loadScreen from "../components/load/index.vue"
import CardTitle from "../components/FormOfPayment/CardTitle";
import Table from "../components/FormOfPayment/Table";
import AddDialog from "../components/FormOfPayment/AddDialog";
import DestroyDialog from "../components/FormOfPayment/DestroyDialog";
import { mapGetters } from "vuex";

import {
  GET_ALL_FORM_OF_PAYMENT,
  DESTROY_FORM_OF_PAYMENT,
  AUTOCOMPLETE_FORM_OF_PAYMENT,
} from "../store/actions/formofpayment";

import AlertApi from "../components/alert_api/";

export default {
  data: () => ({
    search: "",
    searchInput: "",
    code: "",
    addDialog: false,
    destroyDialog: false,
    idToDestroy: 0,
    objectToUpdate: {},
    objectDestroyItem: {},
    permission: []
  }),
  methods: {
    AlterAddDialog() {
      this.addDialog = !this.addDialog;
    },
    async destroy_object() {
      const formofpayment = {
        id: this.objectDestroyItem.id,
      };
      try {
        await this.$store.dispatch(DESTROY_FORM_OF_PAYMENT, formofpayment);
      } catch (error) {
        alert(error);
      } finally {
        this.destroyDialog = false;
      }
    },
    change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
    },
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_FORM_OF_PAYMENT, {
        page: page,
        limit: 10,
      });
    },
    async GetAutocompleteFop(page = 1) {
      await this.$store.dispatch(AUTOCOMPLETE_FORM_OF_PAYMENT, {
        search: this.search,
        page: page,
        limit: 10,
      });
    },
    autocompleteFop() {
      this.search = this.searchInput
    }
  },
  watch: {
    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
      if(val.length == 0) {
        this.GetAll();
      } else if(val.length >= 2){
        this.GetAutocompleteFop()
      }
    }
  },
  computed: {
    ...mapGetters(["getCadastrosLinks"]),
  },
  mounted() {
    let name = document.URL.split("/").pop()
    this.permission = this.getCadastrosLinks.getPermissions(name)
  },
  async created() {
    this.GetAll();
  },

  components: {
    Container,
    CardTitle,
    Table,
    AddDialog,
    DestroyDialog,
    AlertApi,
    loadScreen
  },
};
</script>

<style scoped></style>
