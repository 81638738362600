const nameRules = [
    v => !!v || 'Nome é obrigatório'
]

const LastNameRules = [
    v => !!v || 'Sobrenome é obrigatório'
]

const cpfRules = [
    v => !!v || 'CPF é obrigatório',
    v => v.length == 14 || 'O CPF deve possuir 11 caracteres'

]



const TransportRules = [
    v => !!v || 'Campo Obrigatório!'
]




export { nameRules, LastNameRules, cpfRules, TransportRules }