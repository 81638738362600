<template>
  <v-row justify-center>
    <v-dialog v-model="component.dialogDeadline" max-width="600">
      <v-card>
        <v-card-title>Prazo</v-card-title>
        <v-divider></v-divider>

        <v-col cols="12">
          <v-text-field
            outlined
            label="Dias"
            v-model="component.day"
            color="primary"
            append-icon="mdi-plus-circle"
            @click:append="addDays()"
          ></v-text-field>
        </v-col>

        <v-divider></v-divider>
        <v-data-table
          :items="itemsDeadline"
          :headers="header"
          no-data-text="Sem registro!"
          :footer-props="{
            'items-per-page-options': [5, -1],
            'items-per-page-text': 'Items por página',
          }"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="red darken-4"
              class="mr-2"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" @click="component.changeDeadline()"
            >Cancelar</v-btn
          >
          <v-btn class="success" @click="submit()" :disabled="!valid"
            >Confirmar</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    header: [
      { text: "Parcela", value: "id" },
      { text: "Prazo", value: "deadline" },
      { text: "Ações", value: "actions" },
    ],
    valid: Boolean(),
    disabledItems: Number(),
    itemsDeadline: Array(),
  }),
  methods: {
    addDays() {
      let day = Object();
      day.deadline = this.component.day;
      this.itemsDeadline.push(day);
      day.id = this.itemsDeadline.length;
      this.disabledItems = this.itemsDeadline.length;
    },
    deleteItem(item) {
      let indice = this.itemsDeadline.indexOf(item);
      this.itemsDeadline.splice(indice, 1);
      this.itemsDeadline.forEach((e, i) => {
        e.id = i + 1;
      });
      this.disabledItems = this.itemsDeadline.length;
    },
    submit() {
      let values = Array();
      this.itemsDeadline.forEach((e) => values.push(e.deadline));
      this.component.deadline = values.join("/");
      this.component.changeDeadline();
    },
  },
  watch: {
    disabledItems(val) {
      if (val == this.component.amount) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
  },
};
</script>

<style>
</style>