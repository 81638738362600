


const rules = [
    (v) => !!v || "Campo Obrigatório"
]


export {
    rules
}