import axios from "axios";
const {
    ADD_CST_PIS_CONFIS,
    GET_ALL_CST_PIS_CONFIS,
} = require("./actions/cstpisconfis");


const { SPLICE_ERRO, SPLICE_SUCESSO } = require("./actions/alert_api");

const modulePisConfis = {
    state: () => ({
        cstpisconfis: Object(),
    }),

    mutations: {
        GET_ALL_CST_PIS_CONFIS: (state, cstpisconfis) => {
            state.cstpisconfis = cstpisconfis.data;
        },
        ADD_CST_PIS_CONFIS: (state, newCstpisconfis) => {
            state.cstpisconfis.data.push(newCstpisconfis);
        },
    },
    actions: {
        GET_ALL_CST_PIS_CONFIS({ commit }, payload) {
            axios
                .post("piscofins/index", {
                    page: payload.page,
                })
                .then((response) => {
                    commit(GET_ALL_CST_PIS_CONFIS, response.data);
                });
        },

        ADD_CST_PIS_CONFIS({ commit }, payload) {
            axios
                .post("piscofins/create", payload)
                .then((response) => {
                    commit(ADD_CST_PIS_CONFIS, response.data.data);
                    commit(SPLICE_SUCESSO, {
                        alert: response.data.msg,
                    });
                })
                .catch((r) => {
                    let erros = r.response.data.errors;
                    for (let erro in erros) {
                        erros[erro].forEach((err) =>
                            commit(SPLICE_ERRO, {
                                alert: err,
                                type: "E",
                            })
                        );
                    }
                });
        },


    },

    getters: {
        getCstPisConfis: (state) => ({
            ...state.cstpisconfis,
        }),
    },
};

export default modulePisConfis;
