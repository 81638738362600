
import generateGtin from "gtin-generator"
import { isValid } from "gtin"

function generateGtinFunction() {

    const gtinCode14 = generateGtin.getGTIN()
    const gtinCode13 = gtinCode14.substring(1, 14);

    if (isValid(gtinCode13)) {
        return gtinCode13
    } else {
        alert("Código de Barras Inválido")
    }
}




export { generateGtinFunction }