<template>
  <div>
    <Edit v-if="dialog" :isUpdate="true" :component="this" :objectToUpdate="objectToUpdate"  title="Editar" />
    <Delete :component="this" />
    <v-data-table :headers="atual.header" :search="search" :items="atual.getter" hide-default-footer
      no-data-text="Nenhum Transportador Cadastrado" no-results-text="Nenhum Transportador Encontrado">
      <template v-slot:item.actions="{ item }">
        <v-icon color="yellow darken-4" class="mr-2" v-if="!component.permission.Editar" @click="
          () => {
            edit_item(item);
          }
        ">mdi-pencil</v-icon>
        <v-icon color="red darken-4" class="mr-2" v-if="!component.permission.Excluir" @click="
          () => {
            delete_item(item);
          }
        ">mdi-delete</v-icon>
        <v-icon color="green darken-4" class="mr-2" v-if="component.permission.Excluir" @click="
          () => {
            visibleItem(item);
          }
        ">mdi-eye</v-icon>
      </template>
    </v-data-table>
    <div>
      <v-divider></v-divider>
      <v-pagination v-model="current" :length="lastPage" color="success" :total-visible="10"></v-pagination>
    </div>
  </div>
</template>

<script>
import { headers } from "./script";
import Edit from "../AddDialog/";
import Delete from "./Delete_Item/";
import {
  GET_ALL_TRANSPORTER,
  DESTROY_TRANSPORTER,
  ITEM_TO_UPDATE
} from "../../../store/actions/transporter";
import { mapGetters } from "vuex";

export default {
  props: { component: Object(), search: String(),  },
  components: { Edit, Delete },
  data: () => ({
    dialog: false,
    dialog_delete: false,
    headers,
    ObjectToDestroy: Object(),
    objectToUpdate: Object(),
    pagIsLegal: Boolean(),
    isUpdate:Boolean(),
    total: Number(),
    lastPage: Number(),
    current: Number(),

    atual: {
      header: Array(),
      getter: Array(),
    },
  }),
  computed: {
    ...mapGetters([
      "getPhisycalTransporter",
      "getLegalTransporter",
      "getPagination",
    ]),
  },

  methods: {
    isLegal(isLegal = false) {
      const type = { isPhisical: "isPhisical", isLegal: "isLegal" };
      this.atual.header = this.headers[
        isLegal ? type.isLegal : type.isPhisical
      ];

      this.atual.getter = isLegal
        ? this.getLegalTransporter
        : this.getPhisycalTransporter;

      this.atualTotal = isLegal ? this.pagLegalTotal : this.pagPhysicalTotal;
    },
    visibleItem(item){
      this.$store.dispatch(ITEM_TO_UPDATE, {item: item, justVisible: true})
      this.dialog = !this.dialog;
    },
    edit_item(item) {
      this.objectToUpdate = { ...item };
      this.$store.dispatch(ITEM_TO_UPDATE, {item: item, justVisible: false})
      this.dialog = !this.dialog;
    },

    delete_item(item) {
      this.dialog_delete = !this.dialog_delete;
      this.objectToDestroy = { ...item };
      console.log(this.objectToDestroy);
    },
    async confirmDelete() {
      let objectDelete = { ...this.objectToDestroy };
      try {
        await this.$store.dispatch(DESTROY_TRANSPORTER, objectDelete);
      } catch (e) {
        alert(e);
      } finally {
        this.dialog_delete = !this.dialog_delete;
      }
    },
    async GetAllPhysical(page = 1) {
      await this.$store.dispatch(GET_ALL_TRANSPORTER, {
        page: page,
        limit: 10,
        type_people: "PhysicalPeople",
      });
    },
    async GetAllLegal(page = 1) {
      await this.$store.dispatch(GET_ALL_TRANSPORTER, {
        page: page,
        limit: 10,
        type_people: "LegalPeople",
      });
    },
  },

  async created() {
    await this.GetAllLegal();
    await this.GetAllPhysical();
  },
  watch: {
    getPagination: function (val) {
      this.current = val.currentPage;
      this.lastPage = val.lastPage;
    },
    getPhisycalTransporter: function () {
      if (this.getPhisycalTransporter.length === 11) {
        this.GetAllPhysical();
      }
    },
    getLegalTransporter: function () {
      if (this.getLegalTransporter.length === 11) {
        this.GetAllLegal();
      }
    },
    current: function (val) {
      if (this.pagIsLegal) {
        this.GetAllLegal(val);
      } else {
        this.GetAllPhysical(val);
      }
    },
  },
  mounted() {
    this.isLegal();
    this.$watch(
      () => this.component.isFormalPeople,
      function (val) {
        this.pagIsLegal = val;
        if (val) {
          this.GetAllLegal();
        } else {
          this.GetAllPhysical();
        }
        this.isLegal(val);
      }
    );
  },
};
</script>