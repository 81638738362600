<template>
  <v-container>
    <v-form ref="form" v-model="valid">
      <v-row class="mt-6">
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field :readonly="component.justVisible" v-model="component.address.cep" outlined v-mask="'#####-###'" label="CEP"
            :rules="[(v) => !!v || 'Campo Obrigatório']"></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field :readonly="component.justVisible" v-model="component.address.street" outlined label="Rua"
            :rules="[(v) => !!v || 'Campo Obrigatório']">
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field :readonly="component.justVisible" v-model="component.address.number" outlined label="Número"
            :rules="[(v) => !!v || 'Campo Obrigatório']"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field :readonly="component.justVisible" v-model="component.address.district" outlined label="Bairro"
            :rules="[(v) => !!v || 'Campo Obrigatório']"></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field :readonly="component.justVisible" v-model="component.address.complement" outlined label="Complemento"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-autocomplete :readonly="component.justVisible" v-model="component.address.state" :items="initials" item-text="text" item-value="" return-object outlined
            label="Estado" :rules="[(v) => !!v || 'Campo Obrigatório']"></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-autocomplete :readonly="component.justVisible" v-model="component.address.city" item-text="name" :items="listCities" outlined :return-object="true"
            label="Cidade" :rules="[(v) => !!v || 'Campo Obrigatório']"></v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    state: Object(),
    city: String(),
    listCities: Array(),
    initials: [
      { text: "Acre", value: "ac" },
      { text: "Alagoas", value: "al" },
      { text: "Amapá", value: "ap" },
      { text: "Amazonas", value: "am" },
      { text: "Bahia", value: "ba" },
      { text: "Ceará", value: "ce" },
      { text: "Espírito Santo", value: "es" },
      { text: "Goiás", value: "go" },
      { text: "Maranhão", value: "ma" },
      { text: "Mato Grosso", value: "mt" },
      { text: "Mato Grosso do Sul", value: "ms" },
      { text: "Minas Gerais", value: "mg" },
      { text: "Pará", value: "pa" },
      { text: "Paraíba", value: "pb" },
      { text: "Paraná", value: "pr" },
      { text: "Pernambuco", value: "pe" },
      { text: "Piauí", value: "pi" },
      { text: "Rio de Janeiro", value: "rj" },
      { text: "Rio Grande do Norte", value: "rn" },
      { text: "Rio Grande do Sul", value: "rs" },
      { text: "Rondônia", value: "ro" },
      { text: "Roraima", value: "rr" },
      { text: "Santa Catarina", value: "sc" },
      { text: "São Paulo", value: "sp" },
      { text: "Sergipe", value: "se" },
      { text: "Tocatins", value: "to" },
      { text: "Distrito Federal", value: "df" },
    ],
    valid: false,
  }),

  props: {
    component: Object(),
  },
  methods: {
    updateAddress(address, isUpdate){
       if(isUpdate){
          
         this.initials.forEach(element => {
             if(element.value == address.state){
               this.component.address.state = {value:element.value, text:element.text} 
               this.component.address.city = address.city
             }
         });
       }
     },
    // searchByCep(){

    // },
    // searchAddress(val) {
    //   axios
    //     .get(
    //       `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val}/municipios`
    //     )
    //     .then((response) => {
           
    //       response.data.map((element) => {
    //         this.arrayCity.push({name:element.nome, id:element.id})
    //       })
           
    //     });
    // },
  },
  created(){
    this.updateAddress(this.component.address, this.component.isUpdate)
  },
  watch: {
    "component.address.state":function(val){
       
       axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val.value}/municipios`
      ).then((response) => {
        response.data.forEach(element => {
          this.listCities.push({name:element.nome, city_id:element.id})
          
        });
         
      //  this.cities = {city:response.data.nome, city_id: response.data.id};
         
      });
  }
    // city: function(){
    //   this.component.address.city = this.city
    // },
    // "component.address.state": function (val) {
    //   this.searchAddress(val.value)
    // }
  },
};
</script>
