<template>
  <v-container>
    <v-form ref="form">
      <v-row class="mt-6">
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field outlined label="E-mail"  :readonly="component.justVisible" v-model="component.contact.primaryEmail" :rules="[(v) => !!v || 'Campo Obrigatório']">
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field outlined label="E-mail Secundário"  :readonly="component.justVisible" v-model="component.contact.secundaryEmail" :rules="[(v) => !!v || 'Campo Obrigatório']">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4" >
          <v-text-field label="Telefone Celular"  :readonly="component.justVisible" v-model="component.contact.cellPhone" v-mask="'(##) # ####-####'" outlined
            :rules="[(v) => !!v || 'Campo Obrigatório']"></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4" >
          <v-text-field v-mask="'(##) ####-####'"  :readonly="component.justVisible" v-model="component.contact.phone" label="Telefone Residencial" outlined
            :rules="[(v) => !!v || 'Campo Obrigatório']"></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4" >
          <v-text-field label="Telefone Referência"  :readonly="component.justVisible" v-model="component.contact.phoneReference" v-mask="'(##) # ####-####'" outlined
            :rules="[(v) => !!v || 'Campo Obrigatório']"></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  data: () => ({}),
};
</script>
