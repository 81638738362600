<template>
  <v-form ref="form" v-model="component.valid">
    <v-row class="ml-2">
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-text-field
          v-model="component.checkcashier.dt_movement_inicial"
          label="Data Inicial"
          type="date"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="2" lg="3">
        <v-text-field
          v-model="component.checkcashier.dt_movement_final"
          label="Data Final"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']"
          type="date"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="2">
        <v-select
          v-model="component.cashier"
          :items="this.getCashier.data"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']"
          item-text="description"
          return-object
          outlined
          label="Caixa"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="2" lg="2" class="mr-3">
        <v-text-field
          v-model="component.cashier_value"
          prefix="R$"
          readonly
          outlined
          label="Saldo do Caixa"
        >
        </v-text-field>
      </v-col>

      <v-col
        ><v-btn
          class="mt-2"
          :disabled="!component.valid"
          color="primary"
          rounded
          @click="consult_cashier()"
          ><v-icon>mdi-magnify</v-icon></v-btn
        ></v-col
      >
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object(),
  },

  data: () => ({
    data: String(),
  }),
  methods: {
    consult_cashier() {
      this.component.find();
      this.reset();
    },
    reset() {
      this.$refs.form.reset();
    },
  },

  computed: {
    ...mapGetters(["getCashier"]),
  },
};
</script>

<style>
</style>