var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(this._props.addDialogAliquot),callback:function ($$v) {_vm.$set(this._props, "addDialogAliquot", $$v)},expression:"this._props.addDialogAliquot"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("Aliquota")]),_c('v-container',[_c('v-divider'),_c('v-form',{ref:"form",staticClass:"mt-6",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Descrição","rules":[
                () => !!_vm.description || 'Campo Obrigatorio',
                () =>
                  (!!_vm.description && _vm.description.length >= 1) ||
                  'Campo tem que ser maior do que 1',
              ],"outlined":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Aliquota - Valor entre 0 e 100 %.","type":"number","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"5","rules":[
                () => !!_vm.aliquot || 'Campo Obrigatorio',
                () =>
                  (!!_vm.aliquot && _vm.aliquot.length >= 1 && _vm.aliquot <= 100) ||
                  'Campo tem que ser maior do que 1 e menor que 100',
              ],"outlined":""},model:{value:(_vm.aliquot),callback:function ($$v) {_vm.aliquot=$$v},expression:"aliquot"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":() => {
                  _vm.closeAliquotDialog();
                  //runAlterDialog(this, alterAddDialog);
                }}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","disabled":!_vm.valid},on:{"click":() => {
                  _vm.submit();
                }}},[_vm._v("Salvar")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }