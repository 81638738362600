const headers = [{
    text: 'Forma',
    value: 'form.description'
},
{
    text: 'Condição',
    value: 'condition.description'
},
{
    text: 'Valor',
    value: 'value_form_payment'
},
{
    text: 'Ações',
    value: 'actions'
},
];

export {
    headers
};