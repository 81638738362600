:value="Items."
<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          :value="Items.value_icms_substituicao_total"
          label="Valor de icms substituição"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_descount_total"
          label="Valor desconto total"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.base_cal_icms_total"
          label="Base calculo de icms"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_icms_total"
          label="Valor de Icms"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_icms_free_total"
          label="Valor de icms isento"
          outlined
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          :value="Items.value_outher_tax_total"
          label="Valor outras taxas"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_ipi_total"
          label="Valor do ipi total"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_ipi_outher_total"
          label="Valor outros Valores de ipi"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_ipi_free_total"
          label="Valor isento de ipi"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.base_calc_ipi_total"
          label="Valor base de calculo de ipi"
          outlined
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          :value="Items.value_base_pis_total"
          label="Valor base pis total"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_base_cofins_total"
          label="Valor base cofins total"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_base_cofins_st_total"
          label="Valor base cofins substituicao total"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_base_pis_st_total"
          label="Valor base pis substitução total"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_pis_st_total"
          label="Valor total de substituição pis"
          outlined
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          :value="Items.value_other_cofins_total"
          label="Valor outros cofins"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_cofins_st_total"
          label="Valor outros confis substiuição total"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_other_pis_total"
          label="Valor outros pis total"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_pis_total"
          label=" Valor pis total"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="Items.value_cofins_total"
          label="Valor confis total"
          outlined
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    Items: Object(),
  },
};
</script>

<style>
</style>

