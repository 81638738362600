const headers = {

    isPhisical: [
        { text: "Codigo", value: "id" },
        { text: "Nome", value: "PhysicalPeople.name" },
        { text: "CPF", value: "PhysicalPeople.cpf" },
        { text: "Telefone", value: "contacts[0].phone" },
        { text: "Cidade", value: "addresses[0].city.name" },
        { text: "Estado", value: "addresses[0].state" },
        { text: "Ações", value: "actions" },
    ],

    isLegal: [
        { text: "Codigo", value: "id" },
        { text: "Nome", value: "LegalPeople.socialName" },
        { text: "Nome Fantasia", value: "LegalPeople.fantasyName" },
        { text: "CNPJ", value: "LegalPeople.cnpj" },
        { text: "Telefone", value: "contacts[0].phone" },
        { text: "Cidade", value: "addresses[0].city.name" },
        { text: "Estado", value: "addresses[0].state" },
        { text: "Ações", value: "actions" },
    ]

}

    ;
export { headers };