<template>
    <v-container>
    <v-col  lg="12" xl="12">
      <v-card 
      elevation="15">
        <v-card-text>
            <CardTitle/>
            <AddDialog/>
        </v-card-text>  
      </v-card>
    </v-col>
    </v-container>
  </template>
  
  <script>
  import AddDialog from "../components/Contingency/AddDialog/";
  import CardTitle from "../components/Contingency/CardTitle/";
  
  export default {

  
    components: {
      AddDialog,
      CardTitle,
    },
  };
  </script>
  
  <style scoped></style>
  