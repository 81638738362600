import axios from "axios";

const {
  ADD_TAX_REGIME,
  UPDATE_TAX_REGIME,
  GET_ALL_TAX_REGIME,
  DESTROY_TAX_REGIME,
} = require("./actions/taxregime");

const { SPLICE_ERRO, SPLICE_SUCESSO } = require("./actions/alert_api");

const moduleTaxRegime = {
  state: () => ({
    taxregime: {},
  }),

  mutations: {
    GET_ALL_TAX_REGIME: (state, taxregimes) => {
      state.taxregime = taxregimes;
    },

    ADD_TAX_REGIME: (state, newTaxregimes) => {
      state.taxregime.data.push(newTaxregimes.data);
    },

    UPDATE_TAX_REGIME(state, editTaxRegimes) {
      let result = state.taxregime.data.find(
        (r) => r.id === editTaxRegimes.data.id
      );
      for (let key in editTaxRegimes.data) {
        result[key] = editTaxRegimes.data[key];
      }
    },

    DESTROY_TAX_REGIME: (state, id) => {
      let auxState = [...state.taxregime.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.aliquots.data = [...auxState];
    },
  },
  actions: {
    GET_ALL_TAX_REGIME({ commit }, payload) {
      axios
      .post("taxregime/index", {
        page: payload.page,
      })
      .then((response) => {
           
          commit(GET_ALL_TAX_REGIME, response.data);
        });
    },

    ADD_TAX_REGIME({ commit }, payload) {
      axios
        .post("taxregime/create", payload)
        .then((response) => {
           
          commit(ADD_TAX_REGIME, response.data);

          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    async UPDATE_TAX_REGIME({ commit }, payload) {
      try {
        let response = await axios.post("taxregime/update", payload);
        commit(UPDATE_TAX_REGIME, response.data[0]);
        commit(SPLICE_ERRO, {
          alert: "Cadastrado com Sucesso!",
        });
      } catch (r) {
        let erros = r.response.data.errors;
        for (let erro in erros) {
          erros[erro].forEach((err) =>
            commit(SPLICE_ERRO, {
              alert: err,
              type: "E",
            })
          );
        }
      }
    },

    async DESTROY_TAX_REGIME({ commit }, payload) {
      try {
        const response = await axios.post("taxregime/delete", {
          ...payload,
        });
        commit(DESTROY_TAX_REGIME, payload.id);
        commit(SPLICE_ERRO, {
          alert: response.data.sucess.message[0],
        });
      } catch (r) {
        let erros = r.response.data.errors;
        for (let erro in erros) {
          erros[erro].forEach((err) =>
            commit(SPLICE_ERRO, {
              alert: err,
              type: "E",
            })
          );
        }
      }
    },
  },

  getters: {
    getTaxRegime: (state) => ({
      ...state.taxregime,
    }),
  },
};

export default moduleTaxRegime;
