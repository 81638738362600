<template>
    <v-row>
      <v-dialog max-width="820" v-model="this.component.notesDialog" persistent>
        <v-card class="mx-auto" outlined>
          <v-container>
            <v-row style="justify-content: center;" v-if="component.certificateDaysLeft <= 30">
              <v-card-title style="font-size: 25px;"> Atenção, certificado digital tem  <span style="color: #FF5252;"> &nbsp;{{ component.certificateDaysLeft }}&nbsp; </span> dias de validade </v-card-title>
            </v-row>
            <v-row style="justify-content: center;">
              <v-card-title style="color: #FF5252; font-size: 25px;"> Existem notas com pendência de processamento! </v-card-title>
            </v-row>
            <v-container style="height: 50px;"></v-container>
            <v-row style="justify-content: center;">
              <v-text style="color: #666666; font-size: 20px;">Notas pendentes de {{ this.firstDate }} a {{ this.currentDate }}</v-text>
            </v-row>
            <v-container style="height: 20px;"></v-container>
            <v-data-table
              :headers="headers"
              :items="this.component.notes"
              no-data-text="Sem registros"
              hide-default-footer
            >
            </v-data-table>
            <v-container style="height: 20px;"></v-container>
            <v-divider></v-divider>
            <v-card-actions style="justify-content: flex-end;">
              <v-btn @click="closeDialog()" dark style="background-color: #264867;">Ok</v-btn>

            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    props: { component: Object() },
    data: () => ({
      currentDate: String(),
      firstDate: String(),
        headers: [
          { text: 'ID', value: 'id' },
          { text: 'Data', value: 'data_sale' },
          { text: 'Status', value: 'status' },
          { text: 'Chave de Acesso', value: 'access_key' },
          { text: 'Numero da nota', value: 'number_sale' },
          { text: 'Cliente', value: 'cliente.name' },
          { text: 'Vendedor', value: 'seller.people.physicalpeople.name' },
          { text: 'PDV', value: 'pdv.code' },
          { text: 'Valor da nota(R$)', value: 'value_total_note' },
        ]
    }),
    methods: {
     closeDialog() {
      this.component.notesDialog = false
     }
    },
    computed: {
      ...mapGetters([
      ]),
    },
    watch: {
     
    },
    created() {
      this.firstDate = new Date()
      this.firstDate.setDate(1);
      this.firstDate = this.firstDate.toISOString().split('T')[0];
      const parts = this.firstDate.split("-");
      this.firstDate =  `${parts[2]}/${parts[1]}/${parts[0].slice(2)}`;
      this.currentDate = new Date().toISOString().split('T')[0];
      const parts2 = this.currentDate.split("-");
      this.currentDate = `${parts2[2]}/${parts2[1]}/${parts2[0].slice(2)}`;
    },
    mounted() {
    },
  };
  </script>
  