const headers = [
    {
        text: 'Codigo',
        value: 'product_id'
    },
    {
        text: 'Descrição',
        value: 'description'
    },
    {
        text:'Unidade',
        value:'unitBusinessProduct'

    },
    {
        text: 'Quantidade',
        value: 'amount'
    },
    {
        text: 'Preço(R$)',
        value: 'value_sale'
    },
    {
        text: 'Descontos',
        value: 'value_descont_product'
    },
    {
        text: 'Valor Total',
        value: 'value_subtotal_product' //'total_price'
    },
    {
        text: 'Ações',
        value: 'actions'
    },
];

export {
    headers
};