import axios from "axios";
const {
  GET_ALL_BANK,
  ADD_BANK,
  UPDATE_BANK,
  DESTROY_BANK,
} = require("./actions/bank");

const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleBank = {
  state: () => ({
    banks: {},
  }),

  mutations: {
    GET_ALL_BANK: (state, banks) => {
      state.banks = banks;
      console.log("das")
    },
    ADD_BANK: (state, newBanks) => {
      state.banks = newBanks;
    },
    UPDATE_BANK(state, editBank) {
      state.banks.data.Update(editBank);
    },

    // DESTROY_BANK: (state, id) => {
    //   state.banks.data.splice(state.banks.data.indexOf(id), 1);
    // },
    DESTROY_BANK: (state, id) => {
      let auxState = [...state.banks.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.banks = [...auxState];
    },
  },

  actions: {
    GET_ALL_BANK({ commit }, payload) {
      axios.post("bank/index", { page: payload.page }).then((response) => {
        commit(GET_ALL_BANK, response.data[0]);
      });
    },

    async ADD_BANK({ commit }, payload) {
      try {
        let response = await axios.post("bank/store", payload);
        commit(ADD_BANK, response.data);
        commit(SPLICE_ERRO, {
          alert: "Cadastrado com Sucesso!",
        });
      } catch (r) {
        let erros = r.response.data.errors;
        for (let erro in erros) {
          erros[erro].forEach((err) =>
            commit(SPLICE_ERRO, {
              alert: err,
              type: "E",
            })
          );
        }
      }
    },

    UPDATE_BANK({ commit }, payload) {
      axios
        .post("bank/update", payload)
        .then((response) => {
          commit(UPDATE_BANK, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          console.log(r);
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    DESTROY_BANK({ commit }, payload) {
      axios
        .post("bank/delete", { id: payload })
        .then((response) => {
          commit(DESTROY_BANK, payload);
          console.log(response);
          commit(SPLICE_ERRO, {
            alert: response.data.sucess.message,
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },
  },

  getters: {
    getBank: (state) => ({ ...state.banks }),
  },
};
//namespaced: true,
//module.exports = moduleAliquot
export default moduleBank;
