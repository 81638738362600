<template>

    <v-form ref="form" v-model="component.validPhysical">
  
      <!-- <v-row v-if="component.out_of_register">
  
        <v-col cols="6">
  
          <v-select label="Empresa" :items="listEnterprise" v-model="selectedEnterprise" outlined item-text="fantasyName"
  
            item-value="id"></v-select>
  
        </v-col>
  
      </v-row> -->
  
      <v-row>
  
        <v-col cols="6">
  
          <v-text-field v-model="component.physicalClient.name" label="*Nome" :rules="nameRules" outlined required>
  
          </v-text-field>
  
        </v-col>
  
        <v-col cols="6">
  
          <v-text-field v-model="component.physicalClient.lastName" label="*Sobrenome" :rules="LastNameRules" outlined
  
            required>
  
          </v-text-field>
  
        </v-col>
  
      </v-row>
  
      <v-row>
  
        <v-col cols="4">
  
          <v-text-field v-model="component.physicalClient.cpf" label="*CPF" v-mask="'###.###.###-##'" :rules="[cpfRule]"
  
            outlined required>
  
          </v-text-field>
  
        </v-col>
  
         <v-col>
  
           <v-select
  
             label="*Gênero"
  
             outlined
  
             v-model="component.physicalClient.gender"
  
             :items="items_gender"
  
             :rules="[() => !!component.physicalClient.gender || 'Campo Obrigatorio']"
  
           >
  
           </v-select>
  
         </v-col>
  
         <v-col cols="3">
  
           <v-select
  
             v-model="component.physicalClient.civilStatus"
  
             label="*Estado Civil"
  
             :items="civilStatus"
  
             outlined
  
             @blur="checkCivilStatus()"
  
           >
  
           </v-select>
  
         </v-col>
  
       </v-row>
  
       <v-row>
  
         <v-col cols="4">
  
           <v-text-field
  
             v-model="component.contact.cellPhone"
  
             label="*Telefone Celular"
  
             :rules="textRules"
  
             hint="Exemplo: (12) 3 4567-8901"
  
             v-mask="'(##) # ####-####'"
  
             outlined
  
           ></v-text-field>
  
         </v-col>
  
       </v-row>
  
    </v-form>
  
  </template>
  
  <script>
  
  import { GET_ALL_ENTERPRISE, SELECTED_ENTERPRISE } from "../../../../../../store/actions/enterprise"
  
  import { nameRules, LastNameRules, cpfRules } from "./script";
  
  import { mapGetters } from "vuex";
  
  export default {
  
    computed: {
  
      ...mapGetters(["getShowDialogRegisterClient", "getSelectedEnterprise", "getEnterprises"]),
  
    },
  
    props: {
  
      component: Object(),
  
    },
  
    data: () => ({
  
      selectedEnterprise: Object(),
  
      listEnterprise: Array(),
  
      valid: false,
  
      nameRules,
  
      LastNameRules,
  
      cpfRules,
  
      items_gender: ["Masculino", "Feminino"],
  
      civilStatus: ["Casado(a)", "Divorciado(a)", "Solteiro(a)", "Viúvo(a)"],
  
    }),
  
    watch: {
  
      // selectedEnterprise: function () {
  
      //   this.$store.dispatch(SELECTED_ENTERPRISE, this.selectedEnterprise)
  
      // },
  
      // getEnterprises: function (value) {
  
      //   if(value){
  
      //     this.listEnterprise = Object.values(value)
  
      //     this.selectedEnterprise = this.listEnterprise[0]
  
      //   }
  
      // },
  
    },
  
    methods: {
  
      // getAllInformations(page = null) {
  
      //   var payload = {
  
      //     page: page, limit: null, paginate: false
  
      //   }
  
      //   this.$store.dispatch(GET_ALL_ENTERPRISE, payload)
  
      // },
  
      cpfRule(v) {
  
        if (!this.checkCpf(v)) {
  
          return 'CPF Inválido';
  
        }
  
        return true;
  
      },
  
      checkCpf(cpf) {
  
        // Remove caracteres não numéricos do CPF
  
        cpf = cpf.replace(/[^\d]/g, '');
  
        // Verifica se o CPF tem 11 dígitos
  
        if (cpf.length !== 11) {
  
          return false;
  
        }
  
        // Verifica se todos os dígitos são iguais (CPF inválido se for verdadeiro)
  
        if (/^(\d)\1+$/.test(cpf)) {
  
          return false;
  
        }
  
        // Calcula o primeiro dígito verificador
  
        let soma = 0;
  
        for (let i = 0; i < 9; i++) {
  
          soma += parseInt(cpf.charAt(i)) * (10 - i);
  
        }
  
        let primeiroDigito = 11 - (soma % 11);
  
        if (primeiroDigito === 10 || primeiroDigito === 11) {
  
          primeiroDigito = 0;
  
        }
  
        // Verifica se o primeiro dígito verificador está correto
  
        if (primeiroDigito !== parseInt(cpf.charAt(9))) {
  
          return false;
  
        }
  
        // Calcula o segundo dígito verificador
  
        soma = 0;
  
        for (let i = 0; i < 10; i++) {
  
          soma += parseInt(cpf.charAt(i)) * (11 - i);
  
        }
  
        let segundoDigito = 11 - (soma % 11);
  
        if (segundoDigito === 10 || segundoDigito === 11) {
  
          segundoDigito = 0;
  
        }
  
        // Verifica se o segundo dígito verificador está correto
  
        if (segundoDigito !== parseInt(cpf.charAt(10))) {
  
          return false;
  
        }
  
        // Se todas as verificações passaram, o CPF é válido
  
        return true;
  
      },
  
      
  
      reset() {
  
        this.$refs.form.resetValidation();
  
      },
  
      checkCivilStatus() {
  
        if(this.component.physicalClient.civilStatus == "") {
  
          this.component.physicalClient.civilStatus = "Solteiro(a)"
  
        }
  
      }
  
    },
  
    created() {
  
      // this.getAllInformations()
  
    },
  
  };
  
  </script>
  
  <style>
  
  </style>
  