let transportInfo = {
  shippingModalityFreight: "",
  // socialReasonTransporter: "",
  // addressesTransporter: "",
  // siglaUF: "",
  // amountVolumeTransported: "",
  // speciesVolumeTransported: "",
  // numberSeal: "",
  // netWeightVolumeTransported: "",
  // brandVolumeTransported: "",
};


async function execute() {

  transportInfo.shippingModalityFreight = "9"
  // transportInfo.socialReasonTransporter = "",
  // transportInfo.addressesTransporter= "",
  // transportInfo.siglaUF= "",
  // transportInfo.amountVolumeTransported= "",
  // transportInfo.speciesVolumeTransported= "",
  // transportInfo.numberSeal= "",
  // transportInfo.netWeightVolumeTransported= "",
  // transportInfo.brandVolumeTransported= ""

  return transportInfo

}

module.exports = {
  execute,
};