<template>
  <div>
    
  <v-row>
    <v-card-title> Empresas </v-card-title>
    <v-spacer></v-spacer>
    <v-btn
      class="success mr-2 mt-6"
      v-if="component.permission.Cadastrar"
      @click="
        () => {
          active_addDialog();
        }
      "
    >
      Adicionar</v-btn
    >
    

    
    
  </v-row>
    <div class="alert">

        <!-- <Alert/> -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
// import Alert from "../../alert_api/index.vue"
import {SPLICE_ERRO} from "../../../store/actions/alert_api"
export default {
  componets:{
    // Alert
  },
  props: {
    component: Object(),
  },

  data: () => ({
    
  }),
  computed:{
    ...mapGetters(["getEnterprises"])
  },
  methods: {
    active_addDialog() {
      if(this.getEnterprises.data.length >= 1){
        this.$store.commit(SPLICE_ERRO,  {
            alert: "Já existe empresa cadastrada",
            type: "E",
          })
      }else{
        this.component.addDialog = !this.component.addDialog;
      }
    },
  },
};
</script>
<style>
  .alert{
    margin: 50px;
  }
</style>