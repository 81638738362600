const ADD_CART = "ADD_CART";
const UPDATE_CART = "UPDATE_CART";
const GET_ALL_CART = "GET_ALL_CART";
const DESTROY_CART = "DESTROY_CART";
const CLEAN_CART = "CLEAN_CART";
const DISCOUNT_PERMISSION = "DISCOUNT_PERMISSION";
const SET_DISCOUNT_PERMISSION = "SET_DISCOUNT_PERMISSION";

module.exports = {
  ADD_CART,
  UPDATE_CART,
  GET_ALL_CART,
  DESTROY_CART,
  CLEAN_CART,
  DISCOUNT_PERMISSION,
  SET_DISCOUNT_PERMISSION,
};