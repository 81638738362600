<template>
  <v-dialog
    persistent
    :retain-focus="false"
    width="500"
    v-model="component.nsuConfigShow"
  >
    <div class="maiframe">
      <p>Informações do Cartão</p>
      <v-divider />
      <div class="form">
        <v-text-field
          v-model="component.infCard.nsu_number"
          label="NSU"
          class="mr-2"
          outlined
        />
        <v-text-field
          v-model="component.infCard.card_credit_number"
          hint="12345678"
          label="Código do cartão inicial e final"
          class="ml-2"
          outlined
        />
        <v-text-field
          v-model="component.infCard.autorization_code"
          label="Código de autorização"
          outlined
        />
      </div>
      <div class="actionsbuttons">
        <v-btn @click="closeDialog" class="error mr-2">Cancelar</v-btn>
        <v-btn @click="component.confirmInfCard" class="success ml-2">Confirmar</v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "nsu-inf",
  props: {
    component: Object(),
  },
  components: {},
  data: () => ({}),
  methods: {
    closeDialog(){
        this.component.nsuConfigShow = !this.component.nsuConfigShow
    }
  },
  computed: {},
  created() {},
};
</script>

<style>
.maiframe {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
}

.actionsbuttons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
</style>