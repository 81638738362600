const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const emailRules = [
    (v) => {
        if (v.length > 0) {
            return emailRegex.test(v) ? true : 'E-mail inválido'
        } else {
            return true
        }
    }
    ,
]

export { emailRules }