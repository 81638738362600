<template>
  <div>
    <v-spacer />
    <v-text-field label="Pesquisar" outlined append-icon="mdi-magnify" v-model="search" @keyup.enter="autocomplete_exchange()"/>
    <v-divider />
    <v-data-table
      hide-default-footer
      :items="itemsExchange"
      :headers="Header"
      no-data-text="Sem registro!"
    >
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="blue"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
            @click="
              () => {
                view_info(item);
              }
            "
            >mdi-information-outline</v-icon
          >
        </template>
        <span>Visualizar Troca</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
          <v-icon
          color="green darken-4"
          class="mr-2"
          v-bind="attrs"
          v-on="on"
          @click="
            () => {
              print_item(item);
            }
          "
          >mdi-printer</v-icon
        >
      </template>
        <span>Imprimir Troca</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="primary"
          class="mr-2"
          v-bind="attrs"
          v-on="on"
          @click="
            () => {
              cancelExchange(item);
            }
          "
          >mdi-note-edit</v-icon
        >
      </template>
        <span>Estorno fora do prazo</span>
        </v-tooltip>
        </template>
    </v-data-table>
    <v-divider></v-divider>
    
    <v-pagination
      class="mb-1 mt-1"
      :length="paginationInformation.last_page"
      v-model="paginationInformation.current_page"
    ></v-pagination>
    <div v-show="false">
      <svg id="barcode"></svg>
    </div>
    <AddDialogCancel v-if="dialogCancel" :component="this" />
    <TaxOperationTypeDialog v-if="taxOperationTypeErrorDialog" :component="this" />
  </div>
</template>

<script>
import Header from "./headers";
import AddDialogCancel from "../AddDialogCancel/index.vue"
import TaxOperationTypeDialog from "../AddDialogCancel/ErrorDialog/index.vue"
import { GET_ALL_EXCHANGE, VIEW_EXCHANGE, AUTOCOMPLETE_EXCHANGE, GET_ALL_TAX_OPERATION_TYPE } from "../../../store/actions/exchange_control";
import { mapGetters } from "vuex";
import JsBarcode from "jsbarcode"
import generatePdf from "../AddDialog/table/generatePDF";
import {generateGtinFunction} from "../../Utils/global_functions/generateGtin"
export default {
  components: {
    AddDialogCancel,
    TaxOperationTypeDialog,
  },
  data: () => ({
    Header,
    itemsExchange: Array(),
    paginationInformation: {
      current_page: 1,
      last_page: 0,
      total_page: 1,
    },
    search: String(),
    dialogCancel: false,
    cancelExchangeData: Object(),
    taxOperationTypeValidation: false,
    taxOperationTypeErrorDialog: false,
    taxOperationToCancel: Object(),
  }),
  computed: {
    ...mapGetters(["getExchangeItems", "getExchangeToAdd", "getShowDialogRegisterExchangeControl", "getSelectedEnterprise"]),
  },
  methods: {
    generatePdf,
    getAllExchange(page) {
      let payload = {}
      if(this.getSelectedEnterprise.id) {
        payload = {
          page: page,
          paginate: true,
          limit: 10,
          enterprise_id: this.getSelectedEnterprise.id,
        };
      } else {
        payload = {
          page: page,
          paginate: true,
          limit: 10,
          enterprise_id: 1,
        };
      }
      this.$store.dispatch(GET_ALL_EXCHANGE, payload).then((response) => {
        this.itemsExchange = response.data.data;
        this.paginationInformation.current_page = response.data.current_page;
        this.paginationInformation.last_page = response.data.last_page;
        this.paginationInformation.total_page = response.data.total;
      });
    },
    view_info(item) {
      this.$store.dispatch(VIEW_EXCHANGE, {item: item, justVisible: true});
      this.$store.dispatch("setShowDialogRegisterExchangeControl", true);
    },
    autocomplete_exchange() {
      if(this.search >=2) {
        const payload = {
            page: 1,
            paginate: true,
            limit: 10,
            enterprise_id: this.getSelectedEnterprise.id,
            search: this.search
        }
        this.$store.dispatch(AUTOCOMPLETE_EXCHANGE, payload).then((response) => {
          this.itemsExchange = response.data.data;
          this.paginationInformation.current_page = response.data.current_page;
          this.paginationInformation.last_page = response.data.last_page;
          this.paginationInformation.total_page = response.data.total;
        });
      } else {
        this.getAllExchange(1)
      }
    },
    print_item(item) {
      JsBarcode("#barcode", item.number_exchange );
      const getElementDOM = document.getElementById('barcode')
      const tagHTML = getElementDOM.outerHTML
      item.exchange = Object()
      item.exchange.access_key = item.access_key
      item.exchange.total_credit= item.value_credit_icms_total
      this.generatePdf( this.getSelectedEnterprise, item.sale, item, item, tagHTML );
    },

    cancelExchange(item) {
      if(this.taxOperationTypeValidation) {
        this.cancelExchangeData = item
        this.dialogCancel = true
      } else {
        this.taxOperationTypeErrorDialog = true
      }
    },
    getAllTaxOperationType() {
      const payload = {
        paginate: false,
        page: null,
        limit: null
      }
      this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, payload).then((response) => {
        for(let i=0; i<=response.length; i++) {
          if(response[i].code == 999) {
            this.taxOperationTypeValidation = true
            this.taxOperationToCancel = response[i]
          }
        }
      });
    }
  },
  watch: {
    getExchangeToAdd: function () {
      this.getAllExchange(1);
    },
    "paginationInformation.current_page": function (value) {
      this.getAllExchange(value);
    },
    search: function () {
      if(this.search == ""){
        this.getAllExchange(1)
      }
    }
  },
  created() {
  },
  mounted() {
    this.getAllExchange(1);
    this.getAllTaxOperationType()
  }
};
</script>

<style>

</style>
