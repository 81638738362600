<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
        <v-switch
          v-model="isFormalPeople"
          label="Pessoa Jurídica"
          color="primary"
        ></v-switch>
      </v-card-text>
      <v-card-text>
        <v-text-field
          outlined
          v-model="searchInput"
          label="Pesquisar"
          append-icon="mdi-magnify"
          @keyup.enter="searchClient()"
        ></v-text-field>
      </v-card-text>
      <Table v-if="permission.Visualizar" :component="this" :search="search" />
    </v-card>
    <AlertAPI />
    <loadScreen />
    <!-- <loadScreen /> -->
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/Client/CardTitle/";
import Table from "../components/Client/Table/";
import AlertAPI from "../components/alert_api";
import loadScreen from "../components/load/index.vue";
import { mapGetters } from "vuex";
import {SELECTED_ENTERPRISE } from "../store/actions/enterprise"
export default {
  data: () => ({
    search: "",
    searchInput: String(),
    isFormalPeople: false,
    enterpriseId: Number(),
    permission: Object(),
  }),
  components: {
    Container,
    CardTitle,
    Table,
    loadScreen,
    AlertAPI,
  },
  methods : {
    searchClient() {
      this.search = this.searchInput
    }
  },
  watch:{
    enterpriseId: function(value){
      this.$store.dispatch(SELECTED_ENTERPRISE,value)
    },
    searchInput: function(value){
      if(value.length == 0) {
        this.search = this.searchInput
      }
    }
  },
  computed: { ...mapGetters(["getCadastrosLinks"]) },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },
};
</script>

<style scoped></style>
