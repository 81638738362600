<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-layout>
        <InfoConsult :component="this" />
      </v-layout>
      <v-card-text>
        <v-text-field
          v-model="search"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-card-text>
      <InfoMovemet v-if="addDialog" :component="this" />
      <Table :component="this" :search="search" />
    </v-card>
    <AlertApi />
  </Container>
</template>

<script>
// /v-if="permission.Visualizar"
import Container from "../components/CheckCashier/Container";
import CardTitle from "../components/CheckCashier/CardTitle";
import Table from "../components/CheckCashier/Table";
import InfoConsult from "../components/CheckCashier/InfoConsult";
import InfoMovemet from "../components/CheckCashier/InfoMovement";

import {
  GET_ALL_CHECK_CASHIER,
  GET_ALL_WITH_DATE_END_CASHIER,
} from "../store/actions/checkcashier";

import { GET_ALL_CASHIER } from "../store/actions/cashier";

import AlertApi from "../components/alert_api/";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    search: "",
    code: "",
    cashier: Object(),
    cashier_value: Number(),
    checkcashier: {
      dt_movement_inicial: String(),
      dt_movement_final: String(),
      cashier_id: Number(),
      page: Number(),
      limit: Number(),
    },
    infocashier: {
      date_movement: String(),
      cashier_id: Number(),
      descritpion_movement: String(),
      type_movement: String(),
      value_movement: Number(),
      account_plans_id: Number(),
    },

    addDialog: false,
    objectToInfo: {},
    permission: [],
    valid: true,
  }),
  methods: {
    AlterAddDialog() {
      this.addDialog = !this.addDialog;
    },

    find() {
      try {
        this.checkcashier.dt_movement_inicial = this.convertDate(
          this.checkcashier.dt_movement_inicial
        );
        this.checkcashier.dt_movement_final = this.convertDate(
          this.checkcashier.dt_movement_final
        );
        (this.checkcashier.cashier_id = this.cashier.id),
          (this.checkcashier.page = 1);
        this.checkcashier.limit = 10;

        this.$store.dispatch(GET_ALL_WITH_DATE_END_CASHIER, this.checkcashier);
      } catch (error) {
        console.log(error);
      }
    },
    //Formato aceitável no método de pesquisa
    convertDate(item) {
      let year = item[0] + item[1] + item[2] + item[3];
      let mouth = item[5] + item[6];
      let day = item[8] + item[9];
      return year + mouth + day;

      // return day + mouth + year;
    },

    change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
    },
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_CASHIER, {
        page: page,
        limit: 10,
      });
      await this.$store.dispatch(GET_ALL_CHECK_CASHIER, {
        page: page,
        limit: 10,
      });
    },
  },

  // mounted() {
  //   // let name = document.URL.split("/").pop();
  //   // this.permission = this.getCadastrosLinks.getPermissions(name);
  // },
  async created() {
    this.GetAll();
  },
  watch: {
    cashier: function (val) {
      this.cashier_value = val.balance_current;
    },
  },

  components: {
    Container,
    CardTitle,
    Table,
    AlertApi,
    InfoConsult,
    InfoMovemet,
  },
  computed: {

    ...mapGetters(["getCheckCashier", "getCadastrosLinks"]),
  },
};
</script>

<style scoped></style>
