import axios from "axios";

const {
    GET_ALL_PERMISSION,
    CREATE_ROLES,
    UPDATE_ROLES,
    GET_ALL_ROLES
} = require("./actions/permission");
const {
    SPLICE_ERRO
} = require('./actions/alert_api')

const Permission = {
    state: () => ({
        roles: Array(),
        permissions: Array(),
    }),
    mutations: {
        GET_ALL_ROLES: (state, roles) => {
            state.roles = roles
        },
        // DELETE_ROLES: (state, id) => {
        //     let auxState = [...state.roles.data];
        //     let index = auxState.indexOf(
        //         auxState.find(value => value.id === id),
        //     );
        //     if (index >= 0) {
        //         state.roles.data.splice(index, 1);
        //     }

        // },
        UPDATE_ROLES: (state, permissions) => {
            let result = state.roles.data.find(
                (r) => r.id === permissions.id
            );
            for (let key in permissions) {
                result[key] = permissions[key]
            }
        },
        CREATE_ROLES: (state, permissions) => {
            let auxState = { ...state.roles }
            auxState.data.push(permissions)
            state.roles = auxState
        },
        GET_ALL_PERMISSION: (state, permissions) => {
            state.permissions = permissions.data;
        }
    },
    actions: {
        async GET_ALL_ROLES({ commit }, payload) {
            await axios.post("roles", {
                ...payload
            }).then((response) => {
                commit(GET_ALL_ROLES, response.data);
                SPLICE_ERRO
            }).catch(r => {
                console.log(r.response, 'rrr');
            });
        },

        async GET_ALL_PERMISSION({ commit }, payload) {
            await axios.post("permission/index", { ...payload }).then((response) => {
                commit(GET_ALL_PERMISSION, response.data);
                SPLICE_ERRO
            }).catch(r => {
                console.log(r.response, 'rrr');
            });
        },
        CREATE_ROLES({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post("role/create", payload)
                    .then((response) => {
                        let { data } = response.data
                        commit(CREATE_ROLES, data)
                        resolve(
                            commit(SPLICE_ERRO, {
                                alert: 'Permissão cadastrada com sucesso!',
                                type: 'S'
                            })
                        )

                    }).catch((error) => {
                        reject(
                            error.response
                        )
                    })
            })

        },
        async UPDATE_ROLES({ commit }, payload) {
            return new Promise((resolve, reject) => {
                console.log("Chegou aqui")
                axios.post("role/update", payload)
                    .then((response) => {
                        let { data } = response.data
                        commit(UPDATE_ROLES, data)
                        resolve(
                            commit(SPLICE_ERRO, {
                                alert: 'Permissão atualizada com sucesso!',
                                type: 'S'
                            })
                        )

                    }).catch((error) => {
                        reject(
                            error.response
                        )
                    })
            })
        },
        async DELETE_ROLES({ commit }, payload) {
            return new Promise((resolve) => {
                axios.post("role/delete",
                    payload
                ).then(() => {
                    commit(SPLICE_ERRO, {
                        alert: 'Permissão deletada com sucesso!',
                        type: 'S'
                    })
                    resolve()
                }).catch(() => {
                    commit(SPLICE_ERRO, {
                        alert: 'Error ao deletar esta permissão!',
                        type: 'E'
                    })

                })
            })

        },


    },

    getters: {
        getRoles: (state) => state.roles,
        getPermissions: (state) => state.permissions,
    },
};

export default Permission;