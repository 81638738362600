<template>
  <v-row justify="center">
    <v-dialog
      max-width="400"
      v-model="component.dialog_close"
      transition="fab-transition"
    >
      <v-card outlined>
        <v-card-title>Fechamento de Caixa</v-card-title>
        <v-card-subtitle>Preencha os dados.</v-card-subtitle>
        <v-divider></v-divider>
        <v-container>
          <v-form v-model="valid">
            <v-text-field
              outlined
              label="Tipo operação de caixa"
              v-model="cashier_operation_types_description"
              readonly
            ></v-text-field>
            <v-text-field
              outlined
              readonly
              dense
              tile
              label="Abertura de caixa "
              v-model="valueOpenCashierClose"
              placeholder="0.00"
              prefix="R$"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              readonly
              v-model="valueBleedCashierClose"
              tile
              label="Sangrias"
              placeholder="0.00"
              prefix="R$"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              readonly
              v-model="valueCloseCashier"
              tile
              label="Valor fechamento (Entrada  - Sangrias)"
              placeholder="0.00"
              prefix="R$"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              tile
              v-model="valueCashier"
              label="Valor no caixa"
              placeholder="0.00"
              prefix="R$"
            ></v-text-field>
          </v-form>
          <v-data-table
            :headers="header"
            :items="items"
            no-data-text="Sem registro"
            hide-default-footer
          >
            <template v-slot:item.value_form_payment="{ item }">
              <v-text-field
                class="mt-3"
                dense
                v-model="item.value_form_payment"
                readonly
                filled
                outlined
                prefix="R$"
                placeholder="0,00"
              >
              </v-text-field>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          {{ '&nbsp;&nbsp;&nbsp;&nbsp;TOTAL' }} {{`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${this.valueTotalFormOfPayments}` }}
          <v-divider></v-divider>
          <v-container style="height: 10px;"></v-container>
          <v-card-actions>
            <v-row justify="center">
              <v-btn
                class="error mr-1"
                @click="suggest_print ? confirm(false) : cancel()"
                >{{ words.cancel }}</v-btn
              >
              <v-btn
                class="success"
                :disabled="!validCloseCashier"
                @click="current_operation == 'CONFIRM' ? openConfirmDialog() : confirm(true)"
                >{{ words.confirm }}</v-btn
              >
            </v-row>
          </v-card-actions>
          <v-card-subtitle>Pressione ESC para sair</v-card-subtitle>
        </v-container>
      </v-card>
      
      
    </v-dialog>
    <v-dialog
      persistent
      max-width="350"
      v-model="confirmDialog"
      >
     <v-card outlined>
      <v-card-title>Deseja fechar o caixa?</v-card-title>
      <v-divider></v-divider>
      <v-container>
        <v-card-actions>
          <v-row style="display: flex; justify-content: center;">
              <v-btn
                class="error mr-1"
                @click="closeConfirmDialog()"
                >CANCELAR</v-btn
              >
              <v-btn
                class="success"
                :disabled="!validCloseCashier"
                @click="
                  () =>
                    current_operation == 'CONFIRM' ? suggestPrint() : confirm(1)
                "
                >CONFIRMAR</v-btn
              >
            </v-row>
        </v-card-actions>
      </v-container>
    </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  CONFIRM_CLOSE_CASHIER,
  GET_CLOSE_SALE,
} from "./../../../../store/actions/sales";
import { GET_ALL_CASHIER_OPERATION_TYPE } from "../../../../store/actions/cashier_operation_type";
import ENV from "../../../../env";
export default {
  props: {
    component: Object(),
  },

  data: () => ({
    validCloseCashier: false,
    valueTotalFormOfPayments: Number(),
    confirmDialog: false,
    idOpenCashier: Number(),
    valueOpenCashierClose: Number(),
    valueBleedCashierClose: Number(),
    valueCloseCashier: Number(),
    valueSupplyCashier: Number(),
    valueCashier: Number(),
    getArrayValuesCloseCashier: Array(),
    cashier_operation_types_description: Object(),
    cashier_operation_types_id: Number(),
    saleformOfPaymentObject: Object(),
    closeCashier: {
      dateMovement: Date(),
      numberMovement: String(),
      descriptionMovement: String(),
      typeMovement: String(),
      enterprise_id: Number(),
      value_close_cashier: Number(),
      cashier_operation_types_id: Number(),
    },
    items: [],
    keys: {},
    isDisablead: Boolean(),
    salesIdArray: Array(),
    words: {
      confirm: "Confirmar",
      cancel: "Cancelar",
    },
    header: [
      { text: "Forma de Pagamento", value: "form_of_description" },
      { text: "Valor (R$)", value: "value_form_of_payment" },
    ],
    operation_type: {
      SUGGEST: "SUGGEST",
      CONFIRM: "CONFIRM",
    },
    current_operation: "CONFIRM",
    isPritting: Boolean(),
    suggest_print: Boolean(),
  }),

  computed: {
    ...mapGetters([
      "getCloseCashier",
      "getAllCashierOperationType",
      "auth/getUser",
      "getEnterprises",
      "getSalesOpenCashier",
    ]),
  },
  methods: {
    closeConfirmDialog() {
      this.confirmDialog = false
    },
    openConfirmDialog() {
      this.confirmDialog = true
    },
    checkState() {
      switch (this.current_operation) {
        case this.operation_type.SUGGEST:
          this.words.confirm = "IMPRIMIR";
          this.words.cancel = "NÃO";

          break;

        case this.operation_type.CONFIRM:
          this.words.confirm = "Confirmar";
          this.words.cancel = "Cancelar";

          break;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    async getSalesChoserCashier() {
      const STATUS_CASHIER_IS_NOT_OPEN = false;
      let statusOpenCashier = this.getSalesOpenCashier[0];
      if (statusOpenCashier === STATUS_CASHIER_IS_NOT_OPEN) {
        alert(
          "E necessario abrir o caixa antes de Processar fechamento de caixa"
        );
        this.component.dialog_close = !this.component.dialog_close;
      } else {
        let user = { ...this["auth/getUser"].user };
        let payload = {
          date_close: this.mounterDateCloseCashier(),
          cashier_id: user.cashier_operation.cashier_id,
          pdv_id: ENV.getInstance().VUE_APP_PDV_COMPUTER_ID,
          user_id: user.cashier_operation.user_id,
          enterprise_id: this.getEnterprises.data[0].id,
          cashier_operation_types_id: this.cashier_operation_types_id,
        };
        await this.$store.dispatch(GET_CLOSE_SALE, payload).then((data) => {
          this.getArrayValuesCloseCashier = data.response.data;
          this.calculeOpenCashier(
            data.response.data.getOpenCashierFormClosePdv
          );
          this.calculeSupplyCashier(
            data.response.data.getSuppyCashierFormClosePdv
          );
          this.calculeBleedCashier(
            data.response.data.getBleedCashierForCloseCashier
          );
          this.calculeValueCloseCashier(
            data.response.data.getSalesWithCloseCashier
              .saleFormOfPaymentCalculeTypeForm
          );
          this.saleformOfPaymentObject =
            data.response.data.getSalesWithCloseCashier;
          this.items = Object.values(
            this.saleformOfPaymentObject.saleFormOfPaymentCalculeTypeForm
          );
          this.salesIdArray = this.saleformOfPaymentObject.salesIdsArray;
        });
      }
    },
    calculeValueCloseCashier(saleFormOfPaymentList) {
      const DINHEIRO = "DINHEIRO";
      if (saleFormOfPaymentList != []) {
        let valueCloseCashierMoney = 0;
        let saleformOfPaymenetArray = Object.values(saleFormOfPaymentList);
        saleformOfPaymenetArray.forEach((list) => {
          if (list.form_of_description.toLowerCase() == DINHEIRO.toLowerCase())
          valueCloseCashierMoney =
        parseFloat(valueCloseCashierMoney) + parseFloat(list.value_form_of_payment.replace(/,/g, ''));
        });
        let valueCloseCashierCalc = valueCloseCashierMoney + this.valueOpenCashierClose +
         this.valueSupplyCashier - this.valueBleedCashierClose;
        this.valueCloseCashier = valueCloseCashierCalc.toFixed(2);
      }
    },

    calculeOpenCashier(openCashierList) {
      if (openCashierList != []) {
        let valueOpenCashierCalcule = 0;
        openCashierList.forEach((list) => {
          valueOpenCashierCalcule =
            valueOpenCashierCalcule + list.value_movement;
        });
        this.valueOpenCashierClose = valueOpenCashierCalcule;
      }
    },

    calculeSupplyCashier(supplyCashierList) {
      if (supplyCashierList != []) {
        let valueSupplyCashierCalcule = 0;
        supplyCashierList.forEach((list) => {
          valueSupplyCashierCalcule =
            valueSupplyCashierCalcule + list.value_movement;
        });
        this.valueSupplyCashier = valueSupplyCashierCalcule;
      }
    },

    calculeBleedCashier(bleedCashierList) {
      if (bleedCashierList != []) {
        let valueBleedCashierCalcule = 0;
        bleedCashierList.forEach((list) => {
          valueBleedCashierCalcule =
            valueBleedCashierCalcule + list.value_movement;
        });
        this.valueBleedCashierClose = valueBleedCashierCalcule;
      }
    },

    mounterDateCloseCashier() {
      // Create a date object from a date string
      let date = new Date();
      // Get year, month, and day part from the date
      let year = date.toLocaleString("default", { year: "numeric" });
      let month = date.toLocaleString("default", { month: "2-digit" });
      let day = date.toLocaleString("default", { day: "2-digit" });
      let formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },
    suggestPrint(val) {
      this.closeConfirmDialog()
      if (val) this.isPritting = true;

      this.suggest_print = true;
    },
    async confirmationCloseCashier() {
      this.closeCashier.dateMovement = this.mounterDateCloseCashier();
      let max = 100000;
      let min = 1;
      this.closeCashier.numberMovement = String(
        Math.floor(Math.random() * (max - min) + min)
      );
      this.closeCashier.descriptionMovement = "Fechamento caixa";
      this.closeCashier.typeMovement = "Saída";
      (this.closeCashier.enterprise_id = this.getEnterprises.data[0].id),
        (this.closeCashier.cashier_operation_types_id =
          this.cashier_operation_types_id);
      this.closeCashier.value_close_cashier = this.valueCashier;

      let payloadCloseCashier = {
        valuesIdOpenCashierClosePdv:this.getArrayValuesCloseCashier.getOpenCashierFormClosePdv,
        valuesIdBleedCashierClosePdv: this.getArrayValuesCloseCashier.getBleedCashierForCloseCashier,
        valuesIdSuppyCashierFormClosePdv: this.getArrayValuesCloseCashier.getSuppyCashierFormClosePdv,
        operationCloseCashier: { ...this.closeCashier },
        salesIdProcesss: this.salesIdArray,
      };
      await this.$store.dispatch(CONFIRM_CLOSE_CASHIER, payloadCloseCashier);
    },

    async confirm(print) {
      this.closeConfirmDialog()
      this.confirmationCloseCashier();
      if(print) {
        this.component.valuePrintCloseCashier.value_open_cashier =  this.valueOpenCashierClose
        this.component.valuePrintCloseCashier.value_bleed_cashier =  this.valueBleedCashierClose
        this.component.valuePrintCloseCashier.value_close_cashier =  this.valueCloseCashier
        this.component.valuePrintCloseCashier.value_close_cashier_input =  this.valueCashier
        this.component.titlePDF = "Fechamento de Caixa";
        this.component.doc_operation =  this.closeCashier.numberMovement
        this.isPritting = true;
        this.isDisablead = true;
        this.component.hasItems = true;
        this.component.printCloseCashier = this.isPritting;
        this.component.dataToPrint = this.saleformOfPaymentObject.saleFormOfPaymentCalculeTypeForm
      }
      this.cancel();
      // this.$router.replace({ name: "Shortcuts" });
    },

    cancel() {
      this.isDisablead = false;
      this.component.dialog_close = !this.component.dialog_close;
    },
    forINData(e) {
      let arr = [];
      for (const key in e) {
        if (Object.hasOwnProperty.call(e, key)) {
          const element = e[key];

          arr.push(element);
        }
      }
      return arr;
    },
    processTypeOperationCashier(cashierOperationTypeList) {
      const CLOSE_CASHIER = "Fechamento caixa";
      cashierOperationTypeList.forEach((cashierOperationType) => {
        if (
          cashierOperationType.type.toLowerCase() ===
          CLOSE_CASHIER.toLowerCase()
        ) {
          this.cashier_operation_types_description =
            cashierOperationType.description;
          this.cashier_operation_types_id = cashierOperationType.id;
        }
      });
    },
  },
  async mounted() {
    await this.getSalesChoserCashier();
  },
  async created() {
    await this.$store.dispatch(GET_ALL_CASHIER_OPERATION_TYPE, {});
  },
  watch: {
    current_operation: function () {
      this.checkState();
    },
    getAllCashierOperationType: function (val) {
      this.processTypeOperationCashier(val[0]);
    },
    suggest_print: function (v) {
      if (v) {
        this.current_operation = this.operation_type.SUGGEST;
      } else {
        this.current_operation = this.operation_type.CONFIRM;
      }
    },

    valueOpenCashierClose: function(val) {
      if(val > 0) {
        this.validCloseCashier = true
      }
    },

    items: function(val) {
      if(val.length > 0) {
        for(let i=0; i<val.length; i++) {
          this.valueTotalFormOfPayments += parseFloat(val[i].value_form_of_payment.replace(/,/g, ''))
          this.valueTotalFormOfPayments.toFixed(2)
        }
      }
    },
  },
};
</script>



