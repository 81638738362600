import axios from "axios";

const {
  ADD_CFOP,
  UPDATE_CFOP,
  GET_ALL_CFOP,
  DESTROY_CFOP,
} = require("./actions/cfop");
const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const moduleCfop = {
  state: () => ({
    cfops: Object(),
    newCfopToAdd: Object(),
  }),

  mutations: {
    GET_ALL_CFOP: (state, cfops) => {
      state.cfops = cfops.data;
    },
    ADD_CFOP: (state, newCFOP) => {
      state.newCfopToAdd = newCFOP
      // state.cfops.data.push(newCFOP);
    },
    UPDATE_CFOP(state, editCFOP) {
      let result = state.cfops.data.find(
        (r) => r.id === editCFOP.data.id
      );
      for (let key in editCFOP.data) {
        result[key] = editCFOP.data[key];
      }
    },

    DESTROY_CFOP: (state, id) => {
      let auxState = [...state.cfops.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      )
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.cfops = [...auxState];
    },
  },
  actions: {
    async GET_ALL_CFOP({
      commit
    }, payload) {
      await axios.post("cfop/index", {
        page: payload.page
      }).then((response) => {
        commit(GET_ALL_CFOP, response.data);
      });
    },
    ADD_CFOP({
      commit
    }, payload) {
      axios.post("cfop/create", payload).then((response) => {

        commit(ADD_CFOP, response.data.data);
        commit(SPLICE_ERRO, {
          alert: response.data.msg,
          type: 'S'
        });

      }).catch(r => {
        let erros = r.response.data.errors
        for (let erro in erros) {
          erros[erro].forEach(err => commit(SPLICE_ERRO, {
            alert: err,
            type: 'E'
          }))
        }
      });
    },

    UPDATE_CFOP({
      commit
    }, payload) {
      axios.post("cfop/update", payload).then((response) => {
        commit(UPDATE_CFOP, response.data[0]);
        commit(SPLICE_ERRO, {
          alert: response.data.msg,
          type: 'S'
        });
      }).catch(r => {
        let erros = r.response.data.errors
        for (let erro in erros) {
          erros[erro].forEach(err => commit(SPLICE_ERRO, {
            alert: err,
            type: 'E'
          }))
        }
      });
    },

    DESTROY_CFOP({
      commit
    }, payload) {
      axios.post("cfop/delete", payload).then((response) => {
        commit(DESTROY_CFOP, response.data);
        commit(SPLICE_ERRO, {
          alert: response.data.sucess.message[0],
          type: 'S'
        });
      }).catch(r => {
        let erros = r.response.data.errors
        for (let erro in erros) {
          erros[erro].forEach(err => commit(SPLICE_ERRO, {
            alert: err,
            type: 'E'
          }))
        }
      });
    },
  },


  getters: {
    getNewCfopToAdd: (state) => state.newCfopToAdd,
    getCfop: (state) => ({ ...state.cfops }),

  },
};

export default moduleCfop;