import axiosfiscal from "../apifiscal";

import {
    GET_TAX_PRODUCT_IBPT
} from "./actions/ibpt"

const moduleIbpt = {

    state: () => ({
        productsIbpt: Object(),
    }),
    getters: {
        getProductIbpt: (state) => ({
            ...state.productsIbpt
        }),
    },
    mutations: {
        GET_TAX_PRODUCT_IBPT: (state, payload) => {
            state.productsIbpt = payload
        }
    },

    actions: {
        GET_TAX_PRODUCT_IBPT({ commit }, payload) {


            let token = localStorage.getItem("token_fiscal");
            return new Promise((resolve,reject) => {
                axiosfiscal.post("/ibpt/getTaxIbpt", payload, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    }
                }).then((response) => {
                    resolve({ response });
                    commit(GET_TAX_PRODUCT_IBPT, response.data);
                }).catch((error)=>{
                    reject(error)
                }) 
            })


        }

    }
}

export default moduleIbpt;

