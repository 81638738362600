const GET_ALL_PARAMETER = 'GET_ALL_PARAMETER';
const CREATE_PARAMETER = 'CREATE_PARAMETER';
const UPDATE_PARAMETER = 'UPDATE_PARAMETER';
const DELETE_PARAMETER = 'DELETE_PARAMETER';

module.exports = {
    GET_ALL_PARAMETER,
    CREATE_PARAMETER,
    UPDATE_PARAMETER,
    DELETE_PARAMETER
}