<template>
  <v-form ref="form" v-model="component.valid">
    <v-row>
      <v-col cols="2">
        <v-select
          label="*Habilitar?"
          :items="status"
          item-text="text"
          item-value="value"
          v-model="component.enterprise.status"
          :readonly="component.justVisible"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="*Razão Social"
          v-model="component.enterprise.name"
          :readonly="component.justVisible"
          :rules="[
            () => !!component.enterprise.name || 'Campo Obrigatorio',
            () =>
              (!!component.enterprise.name && component.enterprise.name.length >= 3) ||
              'Campo tem que ser maior ou igual a 3',
          ]"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="*Nome Fantasia"
          v-model="component.enterprise.fantasyName"
          :readonly="component.justVisible"
          :rules="[
            () => !!component.enterprise.fantasyName || 'Campo Obrigatorio',
            () =>
              (!!component.enterprise.fantasyName &&
                component.enterprise.fantasyName.length >= 3) ||
              'Campo tem que ser maior ou igual a 3',
          ]"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          label="*CNPJ"
          v-model="component.enterprise.cnpj"
          :readonly="component.justVisible"
          outlined
          v-mask="'##.###.###/####-##'"
          :rules="campoRules"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-select
          label="*--Selecione o Tipo da Empresa--"
          v-model="component.enterprise.enterprise_type"
          :readonly="component.justVisible"
          item-text="text"
          item-value="value"
          :items="enterprise_type"
          outlined
          :rules="campoRules"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          label="*--Selecione o Tipo da Tributário--"
          v-model="component.enterprise.tax_regime_id"
          :readonly="component.justVisible"
          :items="tax_regime"
          item-text="text"
          item-value="value"
          outlined
          :rules="campoRules"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="Inscrição Estadual"
          v-model="component.enterprise.registrationState"
          :readonly="component.justVisible"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Inscrição Municipal"
          v-model="component.enterprise.registrationMunicipal"
          :readonly="component.justVisible"
          outlined
        >
        </v-text-field>
      </v-col>

      <v-col>
        <v-autocomplete
          :items="company_group"
          item-text="name"
          item-value="id"
          v-model="component.enterprise.company_group_id"
          :readonly="component.justVisible"
          :rules="campoRules"
          outlined
          label="*Grupo de Empresas"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { campoRules } from "../script";
import { GET_ALL_COMPANY_GROUP } from "../../../../store/actions/companygroup";
import { GET_ALL_TAX_REGIME } from "../../../../store/actions/taxregime";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object(),
  },
  async created() {
    await this.GetAll();
  },

  computed: { ...mapGetters(["getCompanygroup", "getTaxRegime"]) },
  data: () => ({
    campoRules,
      tax_regime: [
      { text: "Lucro Presumido", value: "1" },
      { text: "Lucro Real", value: "2" },
      { text: "Simples Nacional", value: "3" },
      { text: "Micro Empreendedor", value: "4" }
    ],
    company_group: Array(),
    enterprise_type: [
      { text: "Atacadista", value: "A" },
      { text: "Indústria", value: "I" },
      { text: "Serviço", value: "S" },
      { text: "Varejo", value: "V" },
    ],

    status: [
      { text: "Ativo", value: "Ativo" },
      { text: "Inativo", value: "Inativo" },
    ],
  }),
  methods: {
    async GetAll() {
      await this.$store.dispatch(GET_ALL_COMPANY_GROUP, { page: 0 });
      await this.$store.dispatch(GET_ALL_TAX_REGIME, { page: 0 });
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  watch: {
    getTaxRegime: function (val) {
      this.tax_regime = val.data;
    },
    getCompanygroup: function (val) {
      this.company_group = val.data;
    },
  },
};
</script>
