const html = require('raw-loader!./../templatesPdfs/linx.html')


class TemplateMountParams {
    title_value = ''
    enterprise_name = ''
    info_address = ''
    info_address2 = ''
    cep_value = ''
    cnpj_value = ''
    ie_value = ''
    doc_value = ''
    date_value = ''
    moviment_value = ''
    sangria_in_value = ''
    sangria_pdv_value = ''
    sangria_current_cashier_value = ''
    sangria_out_type_value = ''
    sangria_deservacao_value = ''
    money_value = ''
    func_response = ''
    note_title_value = ''
    hasItems = false
    static instance(content, hasItems = false) {

        let i = new TemplateMountParams()
        i.hasItems = hasItems

        i.title_value = content.title_value ? content.title_value : null
        i.enterprise_name = content.enterprise_name ? content.enterprise_name : null
        i.info_address = content.info_address ? content.info_address : null
        i.info_address2 = content.info_address2 ? content.info_address2 : null
        i.cep_value = content.cep_value ? content.cep_value : null
        i.cnpj_value = content.cnpj_value ? content.cnpj_value : null
        i.ie_value = content.ie_value ? content.ie_value : null
        i.doc_value = content.doc_value ? content.doc_value : null
        i.date_value = content.date_value ? content.date_value : null
        i.moviment_value = content.moviment_value ? content.moviment_value : null
        i.sangria_in_value = content.sangria_in_value ? content.sangria_in_value : null
        i.sangria_pdv_value = content.sangria_pdv_value ? content.sangria_pdv_value : null
        i.sangria_current_cashier_value = content.sangria_current_cashier_value ? content.sangria_current_cashier_value : null
        i.sangria_out_type_value = content.sangria_out_type_value ? content.sangria_out_type_value : null
        i.sangria_deservacao_value = content.sangria_deservacao_value ? content.sangria_deservacao_value : null
        i.money_value = content.money_value ? content.money_value : null
        i.func_response = content.func_response ? content.func_response : null
        i.note_title_value = content.note_title_value ? content.note_title_value : null



        return i
    }
}

class TemplateMount {
    /** @type {TemplateMountParams} */
    params
    htmlString = ''
    /** @type {Document} */
    html
    hasItems
    items = null
    constructor(data, hasItems, items = null) {
        this.items = items
        this.htmlString = html.default
        this.html = new DOMParser().parseFromString(this.htmlString, 'text/html')

        this.setParams(data, hasItems)
        this.reMountHtml()
        this.valid()
    }
    setParams(param, hasItems) {
        this.params = TemplateMountParams.instance(param, hasItems)
    }

    byId(id = '', val = '') {
        id, val
        this.html.getElementById(id).innerHTML = val
        return this.html
    }
    hide(id = '') {
        let el = this.html.getElementById(id)
        if (el) el.hidden = true
    }
    setHtmlString(path = null) {
        if (!path) path = this.path
    }

    padString(str = '') {
        console.log(str, 'strsss');
        return str.padEnd(22, ".") + ':';
    }
    createEl(str) {
        let el = document.createElement('div')
        el.innerHTML = this.padString(str.title.toUpperCase())

        let subel = document.createElement('span')
        subel.innerHTML = str.value
        el.appendChild(subel)
        return el
    }
    /**
    * @param {HTMLDivElement} el
    * @param {HTMLDivElement} str
    */
    appendByTag(el, str) {
        el.appendChild(str)
    }

    /**
     * @param {[]} items 
     */
    makeItems(items) {
        let i = items.map((e) => this.createEl(e))
        let el = this.html.getElementById('items')

        i.forEach(e => this.appendByTag(el, e))
    }
    reMountHtml() {
        let boy = this.html
        if (this.params.hasItems) {
            this.hide('sangriaOK')
            this.makeItems(this.items)
        } else {
            this.hide('customSangria')
        }
        boy = this.byId('title_value', this.params.title_value)
        boy = this.byId('enterprise_name', this.params.enterprise_name)
        boy = this.byId('info_address', this.params.info_address)
        boy = this.byId('info_address2', this.params.info_address2)
        boy = this.byId('cep_value', this.params.cep_value)
        boy = this.byId('cnpj_value', this.params.cnpj_value)
        boy = this.byId('ie_value', this.params.ie_value)
        boy = this.byId('doc_value', this.params.doc_value)
        boy = this.byId('date_value', this.params.date_value)
        boy = this.byId('moviment_value', this.params.moviment_value)
        boy = this.byId('sangria_in_value', this.params.sangria_in_value)
        boy = this.byId('sangria_pdv_value', this.params.sangria_pdv_value)
        boy = this.byId('sangria_current_cashier_value', this.params.sangria_current_cashier_value)
        boy = this.byId('sangria_out_type_value', this.params.sangria_out_type_value)
        boy = this.byId('sangria_deservacao_value', this.params.sangria_deservacao_value)
        boy = this.byId('money_value', this.params.money_value)
        boy = this.byId('func_response', this.params.func_response)
        boy = this.byId('note_title_value', this.params.note_title_value)

        this.html = boy
    }
    valid() {
        let context = this
        let arr = ['title_value',
            'enterprise_name',
            'note_title_value',
            'info_address',
            'info_address2',
            'cep_value',
            'cnpj_value',
            'ie_value',
            'doc_value',
            'date_value',
            'moviment_value',
            'sangria_in_value',
            'sangria_pdv_value',
            'sangria_current_cashier_value',
            'sangria_out_type_value',
            'sangria_deservacao_value',
            'money_value',
            'func_response',]
        arr.forEach(function (key) {
            if (!context.params[key]) {
                context.hide(`${key}_`)
            }
        })
    }
    static instance(content = {}, hasItems, items) {

        return new TemplateMount(content, hasItems, items);
    }
}
module.exports = {
    TemplateMountParams,
    TemplateMount,
}
