import axios from "axios";

const {
  ADD_PIS_COFINS,
  UPDATE_PIS_COFINS,
  GET_ALL_PIS_COFINS,
  DESTROY_PIS_COFINS,
  GET_FILTER_PIS_COFINS
} = require("./actions/pis_cofins");

const {
  SPLICE_ERRO
} = require('./actions/alert_api')

const modulePisCofins = {
  state: () => ({
    pisCofins: Object(),
    newPisCofinsToAdd: Object(),
  }),
  mutations: {
    GET_ALL_PIS_COFINS: (state, pis) => {
      state.pisCofins = pis.data;
    },

    GET_FILTER_PIS_COFINS: (state, payload) => {
      if (payload !== undefined) {
        state.pisCofins = payload
      }

    },
    ADD_PIS_COFINS: (state, newPIS) => {
      if (state.pisCofins.data) {
        state.pisCofins.data.push(newPIS)
      } else {
        state.pisCofins.push(newPIS);
      }
    },
    UPDATE_PIS_COFINS(state, updatePIS) {
      state.pisCofins.data.Update(updatePIS)
    },

    DESTROY_PIS_COFINS: (state, id) => {
      let auxState = [...state.pisCofins.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      );
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.pisCofins = [...auxState];
    },
  },


  
  
  actions: {
    GET_ALL_PIS_COFINS({
      commit
    },
      payload
    ) {
      axios.post("piscofins/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
        commit(GET_ALL_PIS_COFINS, response.data);
        // commit(GET_ALL_PIS_COFINS, response.data[0]);
      });
    },

    GET_FILTER_PIS_COFINS({
      commit
    }, payload) {
      axios.get("piscofins/autocomplete", {
        params: {
          search: payload.search,
          limit: payload.limit,
          page: payload.page,
          paginate: payload.paginate
        }
      }).then((response) => {
        commit(GET_FILTER_PIS_COFINS, response.data[0]);
      })
    },

    ADD_PIS_COFINS({
      commit
    }, payload) {
      return new Promise((resolve, reject) => {

        axios.post("piscofins/create", payload).then((response) => {
          resolve()
          commit(ADD_PIS_COFINS, response.data.data);
          commit(SPLICE_ERRO, {
            alert: response.data.msg,
            type: 'S',

          })
        }).catch(r => {
          reject(r.response.data.errors)

        });
      })
    },

    UPDATE_PIS_COFINS({
      commit
    }, payload) {

      return new Promise((resolve, reject) => {

        axios.post("piscofins/update", payload).then((response) => {
          resolve()
          commit(UPDATE_PIS_COFINS, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.menssage,
            type: 'S'
          })
        }).catch(() => {
          reject()
        })

      })

    },

    DESTROY_PIS_COFINS({
      commit
    }, payload) {
      axios
        .post("piscofins/delete", payload)
        .then((response) => {
          commit(DESTROY_PIS_COFINS, response.id);
          commit(SPLICE_ERRO, {
            alert: "Deletado com sucesso!",
            type: 'S'
          })
        })
    },
  },

  getters: {
    getNewPisCofinsToAdd: (state) => state.newPisCofinsToAdd,
    getPisCofins: (state) => ({ ...state.pisCofins }),
  },
};

export default modulePisCofins;