
const ADD_PF_CLIENT = "ADD_PF_CLIENT";
const ADD_PJ_CLIENT = "ADD_PJ_CLIENT";
const UPDATE_PF_CLIENT = "UPDATE_PF_CLIENT";
const UPDATE_PJ_CLIENT = "UPDATE_PJ_CLIENT";
const GET_ALL_CLIENT = "GET_ALL_CLIENT";
const GET_ALL_CLIENT_OFFLINE = "GET_ALL_CLIENT_OFFLINE,"
const DESTROY_CLIENT = "DESTROY_CLIENT";
const GET_AUTOCOMPLETE_CLIENT = 'GET_AUTOCOMPLETE_CLIENT';
const ITEM_TO_UPDATE_CLIENT = "ITEM_TO_UPDATE_CLIENT";
const GET_BY_ID = "GET_BY_ID";
const GET_BY_ID_NOT_IDENTIFY_CLIENT  = "GET_BY_ID_NOT_IDENTIFY_CLIENT";
const OBJECT_TO_UPDATE = "OBJECT_TO_UPDATE"

module.exports = {
    ADD_PF_CLIENT,
    ADD_PJ_CLIENT,
    UPDATE_PF_CLIENT,
    UPDATE_PJ_CLIENT,
    GET_ALL_CLIENT,
    DESTROY_CLIENT,
    GET_AUTOCOMPLETE_CLIENT,
    ITEM_TO_UPDATE_CLIENT,
    GET_BY_ID,
    GET_BY_ID_NOT_IDENTIFY_CLIENT,
    GET_ALL_CLIENT,
    GET_ALL_CLIENT_OFFLINE,
    OBJECT_TO_UPDATE
}
