const SPLICE_SUCESSO = "SPLICE_SUCESSO"
const SPLICE_ERRO = "SPLICE_ERRO"
const REMOVE_ALL = 'REMOVE_ALL'




module.exports = {
    SPLICE_SUCESSO,
    SPLICE_ERRO,
    REMOVE_ALL,
}