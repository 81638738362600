import axiosautomacao from "../apigestao"

const { 
  GET_ALL_CASHIER_OPERATION_TYPE,
} = require("./actions/cashier_operation_type");

//const { SPLICE_ERRO, SPLICE_SUCESSO } = require("./actions/alert_api");

const moduleCashierOperationType = {
  state: () => ({
    cashier_operation_type: Object(),
  }),

  mutations: {
    GET_ALL_CASHIER_OPERATION_TYPE: (state, cashierOperationType) => {
      state.cashier_operation_type = cashierOperationType;
    },
  },

  actions: {
    GET_ALL_CASHIER_OPERATION_TYPE({ commit }, payload) {
      return new Promise((resolve) => {
        let token = localStorage.getItem("token_gestao");
        axiosautomacao.post("cashierOperationType/index", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
    
            }
        })
          .then((response) => {
            resolve({response})
            commit(GET_ALL_CASHIER_OPERATION_TYPE, response.data);
          });
      })
 
    },

  },


  // GET_BY_ID_SALES({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     let token = localStorage.getItem("token_gestao");
  //     axiosautomacao.post("sales/getbysaleidsenterprise", payload, {
  //       headers: {
  //         'Authorization': 'Bearer ' + token,

  //       }
  //     })
  //       .then((data) => {
  //         resolve({ data })
  //         commit(GET_BY_ID_SALES)
  //       }).catch((error) => {
  //         reject(error)
  //       })
  //   })

  // },

  getters: {
    getAllCashierOperationType: (state) => state.cashier_operation_type,
  },
};

export default moduleCashierOperationType;
