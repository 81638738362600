const links = [
  {
    text: "Cliente",
    icon: "mdi-account-outline",
    route: "/client",
    class: "white--text",
    active: false
  },
  {
    text: "Produto",
    icon: "mdi-cart-variant",
    route: "/produtos",
    class: "white--text",
    active: false
  },

  {
    text: "Vendedor",
    icon: "mdi-account-group-outline",
    route: "/seller",
    class: "white--text",
    active: false
  },
  // {
  //   text: "Transportador",
  //   icon: "mdi-truck-outline",
  //   route: "/transport",
  //   class: "white--text",
  //   active: false
  // },
  {
    text: "Forma de pagamento",
    icon: "mdi-credit-card-outline",
    route: "/formOfPayment",
    class: "white--text",
    active: false
  },
  {
    text: "Condição de pagamento",
    icon: "mdi-credit-card-scan-outline",
    route: "/paymentTerms",
    class: "white--text",
    active: false
  },
  {
    text: "Caixas",
    icon: "mdi-cash-register",
    route: "/cashier",
    class: "white--text",
    active: false
  },
];
export { links };
