import axios from "axios";
import axiosautomacao from "../apigestao"
const NETWORK_ERROR = "Network Error"

const {
  ADD_PAYMENT_TERM,
  UPDATE_PAYMENT_TERM,
  GET_ALL_PAYMENT_TERM,
  DESTROY_PAYMENT_TERM,
  AUTOCOMPLETE_PAYMENT_TERM,
} = require("./actions/paymentterm");
const { SPLICE_SUCESSO, SPLICE_ERRO } = require("./actions/alert_api");

const modulePaymentTerm = {
  state: () => ({
    paymentterms: {},
  }),

  mutations: {
    GET_ALL_PAYMENT_TERM: (state, paymentterms) => {
      state.paymentterms = paymentterms;
    },
    AUTOCOMPLETE_PAYMENT_TERM: (state, paymentterms) => {
      state.paymentterms = paymentterms;
    },
    ADD_PAYMENT_TERM: (state, newPaymentterms) => {
      let data = newPaymentterms.data
      state.paymentterms.data.push(data);
    },
    UPDATE_PAYMENT_TERM(state, updatePaymentterm) {
      let result = state.paymentterms.data.find(
        (r) => r.id === updatePaymentterm.data.id
      );
      for (let key in updatePaymentterm.data) {
        result[key] = updatePaymentterm.data[key];
      }
    },


    DESTROY_PAYMENT_TERM: (state, id) => {
      let auxState = [...state.paymentterms.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.paymentterms.data = [...auxState];
    },
  },

  actions: {
    GET_ALL_PAYMENT_TERM({ commit }, payload) {

      let token = localStorage.getItem("token_gestao");
      axiosautomacao.post("paymentTerm/index", {
        page: payload.page,
        limit: payload.limit,
        paginate: true
      }, {
        headers: {
          'Authorization': 'Bearer ' + token,

        }
      }).then((response) => {
        commit(GET_ALL_PAYMENT_TERM, response.data[0]);
      }).catch((error) => {
        commit(SPLICE_ERRO, {
          alert: "Erro ao carregar as condições de pagamento!",
          type: "E",
        });
        if (NETWORK_ERROR == error.message) {
          axios
            .post("paymentterm/index", {
              page: payload.page,
              limit: payload.limit,
              paginate: true
            })
            .then((response) => {
              commit(GET_ALL_PAYMENT_TERM, response.data.data);
            });
        }

      })

    },
    AUTOCOMPLETE_PAYMENT_TERM({ commit }, payload) {

      let token = localStorage.getItem("token_gestao");
      axiosautomacao.post("paymentTerm/autocomplete", {
        search: payload.search,
        page: payload.page,
        limit: payload.limit,
        paginate: true
      }, {
        headers: {
          'Authorization': 'Bearer ' + token,

        }
      }).then((response) => {
        commit(AUTOCOMPLETE_PAYMENT_TERM, response.data[0]);
      })
    },


    ADD_PAYMENT_TERM({ commit }, payload) {
      axios
        .post("paymentterm/create", payload)
        .then((response) => {

          commit(ADD_PAYMENT_TERM, response.data);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    UPDATE_PAYMENT_TERM({ commit }, payload) {
      axios
        .post("paymentterm/update", payload)
        .then((response) => {
          commit(UPDATE_PAYMENT_TERM, response.data);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    DESTROY_PAYMENT_TERM({ commit }, payload) {
      axios
        .post("paymentterm/delete", payload)
        .then((response) => {
          commit(DESTROY_PAYMENT_TERM, payload.id);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },
  },

  getters: {
    getPaymentTerms: (state) => ({ ...state.paymentterms }),
  },
};

export default modulePaymentTerm;
