<template>
  <v-row justify="center">
    <v-dialog persistent v-model="showDialog" max-width="600">
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>{{ title }}</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid" @submit.prevent="submit" class="mt-6">
            <v-col>
              <v-text-field
                :label="labelFieldFirst"
                type="number"
                v-model="code"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="4"
                :rules="[() => !!code || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
              <v-textarea
                :label="labelFieldSecond"
                v-model="description"
                :rules="[() => !!description || 'Campo Obrigatorio']"
                outlined
              />
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    closePisCofins();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon>Cancelar</v-btn
              >
              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DialogRegister",
  props: {
    labelFieldFirst: String(),
    labelFieldSecond: String(),
    add_value: String,
    update_value: String,
    title: String,
    addDialogPisCofins: Boolean,
    component: Object,
    lengthCode: Number(),
   // rules: Array(),
  },

  data() {
    return {
      showDialog: Boolean(),
      attrs: false,
      on: false,
      valid: true,
      id: "",
      code: Number(),
      description: "",
      search: "",
    };
  },

  destroyed() {
    this.$destroy;
  },

  methods: {
    closePisCofins() {
      this.showDialog = false;
    },
    getComponent() {
      return this._props.component;
    },
    async create_pisCofins() {
      console.log("chamou func" + this.add_value);
      const pisCofins = {
        code: this.code,
        description: this.description,
      };

      await this.$store.dispatch(this.add_value, pisCofins).then(() => {
        this.closePisCofins();
      });
    },
    submit() {
      this.id == undefined ? this.create_pisCofins() : this.update_pisCofins();
    },
    async update_pisCofins() {
      const pisCofins = {
        id: this.id,
        code: this.code,
        description: this.description,
      };
      pisCofins;
      await this.$store.dispatch(this.update_value, pisCofins).then(() => {
        this.closePisCofins();
      });
    },
  },

  watch: {
    addDialogPisCofins: function () {
      if (this.addDialogPisCofins) {
        this.showDialog = true;
      } else {
        this.showDialog = true;
      }
    },
  },

  async created() {
    this.object = { ...this.getComponent().objectToUpdate };
    this.id = this.object.id;
    this.code = this.object.code;
    this.description = this.object.description;
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
