<template>
    <div class="text-center">
        <v-dialog
          v-model="component.bleedDialog"
          width="500"
        >
    
          <v-card class="text-center">
            <v-card-text class="text-center, text-h6">
              Usuário sem permissão para Realizar Sangria
            </v-card-text>
            <v-card-actions>
            <v-col cols="12" md="4" >
              <v-btn color="primary"  @click="component.bleedDialog = false">Fechar Mensagem</v-btn>
            </v-col>
            </v-card-actions>
          
          </v-card>
        </v-dialog>
      </div>
    </template>
    <script>
    
    export default {
        props: {
        component: Object(),
      },
      data: () => ({
      }),
    };
    </script>