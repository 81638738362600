<template>
  <v-form ref="form" v-model="component.validGenerate">
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-select
            :items="items"
            item-text="text"
            item-value="value"
            label="*Status"
            :rules="[component.textRules]"
            outlined
            :readonly="component.justVisible"
            v-model="component.product.status"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            label="Codigo do Fabricante "
            v-model="component.product.manufacturer_code"
            maxlength="150"
            :readonly="component.justVisible"
            :rules="[component.textRules]"
            required
          ></v-text-field>
          <v-text-field
            outlined
            label="*Descrição completa"
            v-model="component.product.description_item"
            :rules="[component.textRules]"
            :readonly="component.justVisible"
            required
          ></v-text-field>
          <v-text-field
            outlined
            label="*Descrição Pdv"
            maxlength="15"
            v-model="component.product.description_pdv"
            :rules="[component.textRules]"
            :readonly="component.justVisible"
            required
          ></v-text-field>
          <v-text-field
            outlined
            v-model="component.product.bar_code"
            :rules="[component.textRules]"
            :readonly="component.justVisible"
            label="*Código de Barras"
            required
          ></v-text-field>
          <v-text-field
            outlined
            v-model="component.product.reference"
            :readonly="component.justVisible"
            label="Referencia"
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <div class="photo-container">
              <img
                id="Preview_image_create"
                :src="url"
                :readonly="component.justVisible"
                class="Preview_image"
                width="350"
                height="350"
              />
            </div>
        </v-col>
      </v-row>

      <v-row class="justify-space-between">
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            label="Caixa Master"
            :readonly="component.justVisible"
            v-model="component.product.master_box"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-file-input
            outlined
            rounded
            dense
            class="mt-2"
            id="myFile"
            label="Escolher imagem"
            @change="Preview_image($event)"
          >
          </v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-autocomplete
            outlined
            :readonly="component.justVisible"
            v-model="component.product.product_group_id"
            :items="product_group"
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="
              showDialog('setShowDialogRegisterProductGroup')
            "
            item-text="name"
            item-value="id"
            label="Grupo de Produto"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-autocomplete
            outlined
            :readonly="component.justVisible"
            v-model="component.product.sub_product_group_id"
            :items="subGroup"
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="
              showDialog('setShowDialogRegisterSubProductGroup')
            "
            item-text="name"
            item-value="id"
            label="Sub Grupo"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-autocomplete
            outlined
            :readonly="component.justVisible"
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="
              showDialog('setShowDialogRegisterUnitMeasurement')
            "
            v-model="component.product.unit_id"
            :items="unit"
            item-text="description"
            item-value="id"
            label="Unidade de Medida"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <ProductGroup />
      <SubProductGroup />
      <UnitMeasurement />
    </v-container>
  </v-form>
</template>

<script>
import { GET_ALL_PRODUCT_GROUP } from "../../../../store/actions/productgroup";
import { GET_ALL_SUB_PRODUCT_GROUP } from "../../../../store/actions/sub_product_group";
import { GET_ALL_UNIT } from "../../../../store/actions/unit";
import ProductGroup from "../../../productgroup/AddDialog";
import SubProductGroup from "../../../Sub_group_product/AddDialog/";
import UnitMeasurement from "../../../unit/AddDialog/";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object(),
  },
  components: {
    ProductGroup,
    SubProductGroup,
    UnitMeasurement,
  },
  data: () => ({
    items: [
      { text: "Ativo ", value: "Ativo" },
      { text: "Inativo", value: "Inativo" },
      { text: "Bloqueado", value: "Bloqueado" },
    ],
    subGroup: Array(),
    product_group: Array(),
    unit: Array(),
    url: String("/img/icons/logo.png"),
  }),

  computed: {
    ...mapGetters([
      "getProductGroup",
      "getSubProductGroup",
      "getUnit",
      "getNewProductgroupsToAdd",
      "getNewUnitToAdd",
    ]),
  },

  methods: {
    showDialog(setState) {
      this.$store.dispatch(`${setState}`, true);
    },
    Preview_image(e) {
      e ? (this.url = URL.createObjectURL(e)) : (this.url = null);
      this.component.product.photo = e;
    },
    Reset_image() {
      this.url = "";
    },
    async GetAll(page = 0) {
      await this.$store.dispatch(GET_ALL_PRODUCT_GROUP, page);
      await this.$store.dispatch(GET_ALL_SUB_PRODUCT_GROUP, page);
      await this.$store.dispatch(GET_ALL_UNIT, page);
    },
    hasImage() {
      if (this.component.product.id !== undefined) {
        this.url =
          process.env.VUE_APP_URL_DEFAULT + this.component.product.photo;
      }
      console.log(this.url);
    },
  },
  watch: {
    getNewUnitToAdd: function (value) {
      this.unit.push(value.data);
      this.component.product.unit_id = value.data.id;
    },
    getNewProductgroupsToAdd: function (value) {
      this.product_group.push(value.data);
      this.component.product.product_group_id = value.data.id;
    },
    getProductGroup: function (val) {
      this.product_group = val.data.data;
    },
    getSubProductGroup: function (val) {
      this.subGroup = val.data.data;
    },
    getUnit: function (val) {
      this.unit = val.data.data;
    },
  },
  async mounted() {
    await this.GetAll();
    this.hasImage();
  },
};
</script>

<style>
.photo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
