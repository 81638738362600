let IssueNfceDomainService = require("../../../Domain/Service/IssueNfceDomainService")

async function issueNfce(sales) {
  try {
    let nfce = await IssueNfceDomainService.execute(sales);
    return nfce
  } catch (error) {
    return error
  }
}

module.exports = {
    issueNfce,
};
