<template>
  <div>
    <Edit
      v-if="addDialog"
      :component="this"
      :objectToUpdate="objectToUpdate"
      title="Editar"
    />
    <Delete :component="this" v-if="dialog_cancel" />
    <v-data-table
      :headers="header"
      :items="results"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          color="orange darken-3"
          class="mr-2"
          v-if="!component.permission.Editar"
          @click="
            () => {
              edit_item(item);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          color="red darken-3"
          v-if="!component.permission.Excluir"
          @click="
            () => {
              deleteItem(item);
            }
          "
          >mdi-delete</v-icon
        >
        <v-icon color="green darken-4" class="mr-2" v-if="component.permission.Excluir" @click="
          () => {
            visibleItem(item);
          }
        ">mdi-eye</v-icon>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <div>
      <v-pagination
        color="success"
        v-model="current"
        :length="total"
        :total-visible="10"
        :disabled="paginationDelay"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { header } from "./script";
import Edit from "../AddDialog/";
import Delete from "./Delete_Item/";
import { DESTROY_SELLER } from "../../../store/actions/seller";
import { mapGetters } from "vuex";

export default {
  props: {
    search: String,
    component: Object,
    getters: Object,
  },

  components: { Edit, Delete },

  data: () => ({
    paginationDelay: Boolean(),
    header,
    addDialog: Boolean(),
    objectToUpdate: Object(),
    dialog_cancel: false,
    ObjectToDestroy: Object(),
    element: Object(),
    dialog: Boolean(),
    results: Array(),
    total: Number(),
    current: Number(),
    destroyDialog: false,
  }),

  methods: {
    deleteItem(item) {
      this.dialog_cancel = !this.dialog_cancel;
      this.ObjectToDestroy = { ...item };
    },
    close() {
      this.dialog = !this.dialog;
    },
    visibleItem(item){
      this.addDialog = !this.addDialogs;
      this.objectToUpdate = { ...item, justVisible:true  };
    },
    edit_item(item) {
      this.addDialog = !this.addDialogs;
      this.objectToUpdate = { ...item, justVisible:false };
    },

    async confirmDelete() {
      try {
        await this.$store.dispatch(DESTROY_SELLER, this.ObjectToDestroy);
      } catch (error) {
        console.log(error);
      }
      this.dialog_cancel = !this.dialog_cancel;
    },
  },

  created() {
    this.component.GetAll();
  },

  watch: {
    getSeller: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
      this.paginationDelay = false
    },
    current: function (val) {
      this.paginationDelay = true
      this.component.GetAll && this.component.GetAll(val);
    },
  },
  computed: {
    ...mapGetters(["getSeller"]),
  },
};
</script>

<style>
</style>