import axios from "axios";
import axiosautomacao from "../apigestao"
const NETWORK_ERROR = "Network Error"
const {
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  GET_ALL_PRODUCT,
  DESTROY_PRODUCT,
  GET_BY_PRODUCT,
  GET_FILTER_PRODUCT,
  GET_SEARCH_PRODUCT,
  GET_ALL_PRODUCT_SALES,
  GET_ALL_PRODUCT_BY_STATUS,
} = require("./actions/product");
const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleProduct = {
  state: () => ({
    products: Object(),
    productsSales: Object(),
    productCode: Object(),
    justVisible: Boolean(),
    productFilter: Object(),
    productSearch: Object(),
    productsByStatus: Object(),
  }),
  mutations: {
    GET_ALL_PRODUCT: (state, products) => {
      state.products = products;
    },
    GET_ALL_PRODUCT_BY_STATUS: (state, products) => {
      state.productsByStatus = products;
    },
    GET_ALL_PRODUCT_SALES: (state, products) => {
      state.productsSales = products;
    },
    GET_FILTER_PRODUCT: (state, payload) => {
      if (payload !== undefined) {
        state.products = payload;
      }
      
    },
    ADD_PRODUCT: (state, newProduct) => {
      state.products.data.push(newProduct);
    },
    GET_SEARCH_PRODUCT: (state, payload) => {
      state.productSearch = payload;
    },

    UPDATE_PRODUCT(state, updateProduct) {
      let result = state.products.data.find(
        (r) => r.id === updateProduct.data.id
      );
      for (let key in updateProduct.data) {
        result[key] = updateProduct.data[key];
      }
    },
    DESTROY_PRODUCT: (state, id) => {
      let auxState = [...state.products.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.products.data = [...auxState];
    },
    GET_BY_PRODUCT: (state, payload) => {
      state.productCode = payload;
    }
  },

  actions: {
    async GET_ALL_PRODUCT({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axiosautomacao.post("product/index", {
        page: payload.page,
        limit: payload.limit,
        paginate: true
      }, {
        headers: {
          'Authorization': 'Bearer ' + token,

        }
      }).then((response) => {
        commit(GET_ALL_PRODUCT, response.data[0]);
      }).catch((error) => {
        commit(SPLICE_ERRO, {
          alert: "Erro ao carregar os produtos!",
          type: "E",
        });
        if (NETWORK_ERROR == error.message) {
          axios.post("product/index", {
            page: payload.page,
            limit: payload.limit,
            paginate: true
          }).then((response) => {
            commit(GET_ALL_PRODUCT, response.data.data);
          })

        }
      })


    },

    async GET_ALL_PRODUCT_BY_STATUS({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axiosautomacao.post("product/getAllProductsByStatus", {
        page: payload.page,
        limit: payload.limit,
        paginate: true,
        status: "Ativo"
      }, {
        headers: {
          'Authorization': 'Bearer ' + token,

        }
      }).then((response) => {
        commit(GET_ALL_PRODUCT_BY_STATUS, response.data[0]);
      }).catch((error) => {
        commit(SPLICE_ERRO, {
          alert: "Erro ao carregar os produtos!",
          type: "E",
        });
        if (NETWORK_ERROR == error.message) {
          axios.post("product/getAllProductsByStatus", {
            page: payload.page,
            limit: payload.limit,
            paginate: true,
            status: "Ativo"
          }).then((response) => {
            commit(GET_ALL_PRODUCT_BY_STATUS, response.data.data);
          })

        }
      })


    },


    async GET_ALL_PRODUCT_SALES({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axiosautomacao.post("product/getAllProductsByStatus", {
        page: payload.page,
        limit: payload.limit,
        paginate: true,
        status: "Ativo",
      }, {
        headers: {
          'Authorization': 'Bearer ' + token,

        }
      }).then((response) => {
        commit(GET_ALL_PRODUCT_SALES, response.data[0]);
      }).catch((error) => {
        commit(SPLICE_ERRO, {
          alert: "Erro ao carregar os produtos!",
          type: "E",
        });
        if (NETWORK_ERROR == error.message) {
          axios.post("product/index", {
            page: payload.page,
            limit: payload.limit,
            paginate: true
          }).then((response) => {
            commit(GET_ALL_PRODUCT_SALES, response.data.data);
          })

        }
      })


    },



    ADD_PRODUCT({ commit }, payload) {

      return new Promise((resolve, reject) => {
        let form = new FormData()
        for (let key in payload) {
          form.append(key, payload[key])
        }

        axios
          .post("product/create",
            form
            , { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => {
            resolve()
            commit(ADD_PRODUCT, response.data.data);
            commit(SPLICE_ERRO, {
              alert: response.data.msg,
              type: "S",
            });
          })
          .catch((e) => {
            reject(e.response.data.errors)
            e.response.data.errors.forEach((el) => {
              commit(SPLICE_ERRO, {
                alert: el,
                type: 'E'
              })
            })
          });

      })

    },

    UPDATE_PRODUCT({ commit }, payload) {
      return new Promise((resolve, reject) => {

        let form = new FormData();
        for (let key in payload) {
          form.append(key, payload[key])
        }

        axios
          .post("product/update", form
            , { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => {
            resolve()
            commit(UPDATE_PRODUCT, response.data);
            commit(SPLICE_ERRO, {
              alert: response.data.msg,
              type: "S",
            });
          })
          .catch((e) => {
            reject()
            console.log(e.response)
            e.response.data.errors.forEach((el) => {
              commit(SPLICE_ERRO, {
                alert: el,
                type: 'E'
              })
            })
          });
      })

    },

    DESTROY_PRODUCT({ commit }, payload) {
      axios
        .post("product/delete", { id: payload })
        .then((response) => {
          commit(DESTROY_PRODUCT, payload);
          commit(SPLICE_ERRO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((e) => {
          e.response.data.errors.forEach((el) => {
            commit(SPLICE_ERRO, {
              alert: el,
              type: 'E'
            })
          })
        });
    },
    GET_BY_BARCODE({ commit }, payload) {
      commit
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          let token = localStorage.getItem("token_gestao");
          axiosautomacao.post("product/barcode", {
            enterprise_id: payload.enterprise_id,
            barcode: payload.code.barcode,
          }, {
            headers: {
              'Authorization': 'Bearer ' + token,

            }
          }).then((data) => {
            resolve({ data })
          }).catch(r => {
            commit(SPLICE_ERRO, {
              alert: "Não foi possível encontrar codigo de barras",
              type: 'E'
            })
            reject({ r })
          });
        }

        else {
          axios.post('product/show/barcode', payload).then(({ data }) => {
            resolve({ data })
          })
        }


      })
    },
    GET_BY_PRODUCT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token_gestao");
        axiosautomacao.post('product/show', { id: payload }, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        }).then((response) => {
          let data = response.data
          if (data !== null) {
            commit(GET_BY_PRODUCT, data)
            resolve({ data })
          } else {
            reject(data)
          }
        }).catch(r => {
           resolve({ r })
          commit(SPLICE_ERRO, {
            alert: "Não foi possível encontrar codigo do produto",
            type: 'E'
          })
        });

      }).catch((error) => {
        if (NETWORK_ERROR == error.message) {
          return new Promise((resolve, reject) => {
            axios.post('product/show', { id: payload }).then((response) => {
              let { data } = response.data
              if (data !== null) {
                resolve(commit(GET_BY_PRODUCT, data))
              } else {
                reject(data)
              }
            })
          })
        }
        commit(SPLICE_ERRO, {
          alert: error.message,
          type: 'E'
        })
      })
    },

    async GET_SEARCH_PRODUCT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token_gestao");
        axiosautomacao.post("product/autocompleteProductByStatus", {
          search: payload.search,
          paginate: payload.paginate,
          page: payload.page,
          limit: payload.limit,
          enterprise_id: payload.enterprise_id,
          status: "Ativo"
        }, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        }).then((response) => {
          let  data  = response.data[0]
          commit(GET_SEARCH_PRODUCT, data)
          if (data !== null) {
            resolve({ data })
          } else {
            reject(data)
          }

        })
      })
    },
    async GET_FILTER_PRODUCT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token_gestao");
        axiosautomacao.post("products/autocomplete", {
          search: payload.search,
          paginate: payload.paginate,
          page: payload.page,
          limit: payload.limit
        }, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        }).then((response) => {
          let  data  = response.data[0]      
          commit(GET_FILTER_PRODUCT, data )
          if (data !== null) {
            resolve({ data })
          } else {
            reject(data)
          }

        })
      })

    }

  },
  getters: {
    getProduct: (state) => ({
      ...state.products,
    }),
    getProductByStatus: (state) => ({
      ...state.productsByStatus,
    }),
    getProductSale: (state) => ({
      ...state.productsSales,
    }),
    getProductCode: (state) => ({
      ...state.productCode,
    }),
    getSeachProduct: (state) => ({
      ...state.productSearch,
    }),
  },
};

export default moduleProduct;
