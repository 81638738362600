<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              v-if="!component.permission.Editar"
              @click="
                () => {
                  change_EditItem(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-if="!component.permission.Excluir"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
            <v-icon color="green darken-4" class="mr-2" v-if="component.permission.Excluir" @click="
          () => {
            visibleItem(item);
          }
        ">mdi-eye</v-icon>
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    destroy_object: Function,
    search: String,
    change_addDialog: Function,
  },
  data: () => ({
    destroyDialog: false,
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
    headers: [
      { text: "Codigo", value: "id" },
      { text: "Sigla", value: "code" },
      { text: "Descrição", value: "description" },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    deleteItem(item) {
      this.component.objectDestroyItem = item;
      this.component.destroyDialog = true;
    },
    visibleItem(item){
      this.component.change_addDialog({...item, justVisible: true});
    },
    change_EditItem(item) {
      this.component.change_addDialog({...item, justVisible: false});
    },

    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
  },
  async created() {
    this.component.GetAll();
  },
  watch: {
    getFormOfPayment: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      this.component.GetAll && this.component.GetAll(val);
    },
  },
  computed: {
    ...mapGetters(["getFormOfPayment"]),
  },
};
</script>
