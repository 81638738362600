import axios from "axios"

const {
    GET_ALL_PARAMETER,
} = require('./actions/parameter')

//const {SPLICE_ERRO} = require("./actions/alert_api")

const moduleParameter = {
    state: () => ({
        parameter: Object()
    }),
    mutations: {
        GET_ALL_PARAMETER: (state, payload) => {
            state.parameter = payload
        }
    },
    actions: {
        GET_ALL_PARAMETER({ commit }, payload) {
            axios.post("parameter/index", payload).then((response) => {
                console.log(response.data)
                commit(GET_ALL_PARAMETER, response.data)
            })
        }
    },
    getters: {
        getParameter: (state) => state.parameter
    }
}

export default moduleParameter;