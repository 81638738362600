const ADD_ALIQUOT = "ADD_ALIQUOT";
const UPDATE_ALIQUOT = "UPDATE_ALIQUOT";
const GET_ALL_ALIQUOT = "GET_ALL_ALIQUOT";
const DESTROY_ALIQUOT = "DESTROY_ALIQUOT";

module.exports = {
  ADD_ALIQUOT,
  UPDATE_ALIQUOT,
  GET_ALL_ALIQUOT,
  DESTROY_ALIQUOT,
}
