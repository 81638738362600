<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="getShowDialogRegisterUnitMeasurement"
      max-width="600"
    >
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Unidade de Medida</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="*Sigla"
                v-model="sigla"
                :rules="[() => !!sigla || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
              <v-text-field
                label="*Descrição"
                v-model="description"
                :rules="[() => !!description || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    close();
                  }
                "
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ADD_UNIT, UPDATE_UNIT } from "../../../store/actions/unit";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
    OpenDialog: Boolean(),
  },

  data() {
    return {
      CallDialog: Boolean(),
      attrs: false,
      on: false,
      valid: true,
      id: undefined,
      code: "",
      description: "",
      search: "",
    };
  },

  computed: {
    ...mapGetters(["getShowDialogRegisterUnitMeasurement"]),
  },
  methods: {
    getComponent() {
      return this.component;
    },
    close() {
      this.$store.dispatch("setShowDialogRegisterUnitMeasurement", false);
    },
    async create_unit() {
      const unit = {
        sigla: this.sigla,
        description: this.description,
      };

      await this.$store.dispatch(ADD_UNIT, unit).then(() => {
        this.$store.dispatch("setShowDialogRegisterUnitMeasurement", false);
        this.close();
        this.component.unitGetAll = !this.component.unitGetAll;
      });
    },
    submit() {
      this.id == undefined ? this.create_unit() : this.update_unit();
    },
    async update_unit() {
      const unit = {
        id: this.id,
        description: this.description,
        sigla: this.sigla,
      };
      await this.$store.dispatch(UPDATE_UNIT, unit).then(() => {
        this.close();
      });
    },
  },
  watch: {
    OpenDialog: function () {
      console.log(this.CallDialog);
      if (this.OpenDialog == false) {
        this.CallDialog = !this.CallDialog;
      } else if (this.OpenDialog == true) {
        this.CallDialog = !this.CallDialog;
      }
    },
  },
  async created() {
    this.object = { ...this.getComponent().objectToUpdate };
    this.id = this.object.id;
    this.sigla = this.object.sigla;
    this.description = this.object.description;
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
