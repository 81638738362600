<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this._props.addDialogAliquot" max-width="600">
      <v-card>
        <v-card-title primary-title>Aliquota</v-card-title>

        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="Descrição"
                v-model="description"
                :rules="[
                  () => !!description || 'Campo Obrigatorio',
                  () =>
                    (!!description && description.length >= 1) ||
                    'Campo tem que ser maior do que 1',
                ]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Aliquota - Valor entre 0 e 100 %."
                type="number"
                v-model="aliquot"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="5"
                :rules="[
                  () => !!aliquot || 'Campo Obrigatorio',
                  () =>
                    (!!aliquot && aliquot.length >= 1 && aliquot <= 100) ||
                    'Campo tem que ser maior do que 1 e menor que 100',
                ]"
                outlined
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    closeAliquotDialog();
                    //runAlterDialog(this, alterAddDialog);
                  }
                "
                >Cancelar</v-btn
              >
              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiAccount } from "@mdi/js";

import { ADD_ALIQUOT, UPDATE_ALIQUOT } from "../../../store/actions/aliquot";

export default {
  props: {
    addDialogAliquot: Boolean,
    component: Object,
  },

  data() {
    return {
      attrs: false,
      on: false,
      valid: true,
      id: "",
      description: "",
      aliquot: "",
      updated_at: "",
      created_at: "",
      resultAliquot: null,
      svgPath: mdiAccount,
      search: "",
      formHasErrors: false,
    };
  },
  methods: {
    closeAliquotDialog() {
      this.component.addDialogAliquot = false;
    },
    getComponent() {
      return this._props.component;
    },
    async create_aliquot() {
      const aliquot = {
        description: this.description,
        aliquot: this.aliquot,
      };
      try {
        await this.$store.dispatch(ADD_ALIQUOT, aliquot);
        this.closeAliquotDialog();
      } catch (error) {
        alert(error);
      }
    },
    submit() {
      this.id == undefined ? this.create_aliquot() : this.update_aliquot();
      this.component.addDialog = false;
    },
    async update_aliquot() {
      const aliquot = {
        id: this.id,
        description: this.description,
        aliquot: this.aliquot,
      };
      try {
        await this.$store.dispatch(UPDATE_ALIQUOT, aliquot);
      } catch (error) {
        console.log({ ...error });
      }
    },
    show_aliquot(aliquot) {
      (this.aliquot = aliquot.aliquot),
        (this.description = aliquot.description);
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

  },

  async created() {
    this.object = { ...this.getComponent().objectToUpdate };
    this.id = this.object.id;
    this.description = this.object.description;
    if (this.id >= 0) {
      this.aliquot = this.object.aliquot.toString();
    } else {
      this.aliquot = this.object.aliquot;
    }
  },
  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
