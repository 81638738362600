import axios from "axios";
import ENV from "../env";


// const {
//   SPLICE_SUCESSO, SPLICE_ERRO
// } = require('./actions/alert_api');

const modulepdv = {
  state: () => ({
    pdv: {},
  }),

  mutations: {
    GET_CURRENTLY_PDV: (state, pdv) => {
      state.pdv = pdv;
    },

  },

  actions: {
    GET_CURRENTLY_PDV({
      commit
    }) {
      let id = ENV.getInstance().PDV_COMPUTER_ID
      axios.post("pdv/show", {
        id
      }).then((response) => {
        commit('GET_CURRENTLY_PDV', response.data.data);

      });
    },

  },
  getters: {
    getCurrentPdv: (state) => ({ ...state.pdv }),
  },
};

export default modulepdv;