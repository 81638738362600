<template>
  <div class="text-center">
    <v-dialog v-model="component.cashierDialog" width="500">
      <v-card class="text-center">
        <v-card-text class="py-2">
          <div>
            <span class="text-h6 pt-6">
              Usuário sem permissão para Abrir Caixa
            </span>
            <v-img
              src="/img/icons/block.png"
              alt="Icon"
              style="width: 95px; height: 95px"
              class="mx-auto py-6 mt-6"
            ></v-img>
            <v-card-actions class="d-flex justify-end mt-4">
              <v-btn color="primary" @click="component.cashierDialog = false"
                >Fechar Mensagem</v-btn
              >
            </v-card-actions>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>




    <script>
export default {
  props: {
    component: Object(),
  },
  data: () => ({}),
};
</script>