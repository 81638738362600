import axios from "axios";
const {
  GET_ALL_UNIT,
  ADD_UNIT,
  UPDATE_UNIT,
  DESTROY_UNIT,
} = require("./actions/unit");
const { SPLICE_SUCESSO, SPLICE_ERRO } = require("./actions/alert_api");
const moduleUnit = {
  state: () => ({
    units: Object(),
    newUnitToAdd: Object(),
  }),

  mutations: {
    GET_ALL_UNIT: (state, units) => {
      state.units = units;
    },
    ADD_UNIT: (state, newUnits) => {
      state.newUnitToAdd = newUnits 
      //state.units.data.push(newUnits);
    },
    UPDATE_UNIT(state, editUnit) {
      let result = state.units.data.find((r) => r.id === editUnit.data.id);
      for (let key in editUnit.data) {
        result[key] = editUnit.data[key];
      }
    },

    DESTROY_UNIT: (state, id) => {
      let auxState = [...state.units.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.units.data = [...auxState];
    },
  },
  actions: {
    GET_ALL_UNIT({ commit }, { page }) {
      axios
        .post("unit/index", {
          page,
        })
        .then((response) => {

          commit(GET_ALL_UNIT, response.data);
        });
    },

    ADD_UNIT({ commit }, payload) {
      axios
        .post("unit/create", payload)
        .then((response) => {
          commit(ADD_UNIT, response.data);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    UPDATE_UNIT({ commit }, payload) {
      axios
        .post("unit/update", payload)
        .then((response) => {
          commit(UPDATE_UNIT, response.data[0]);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    DESTROY_UNIT({ commit }, payload) {
      axios
        .post("unit/delete", payload)
        .then((response) => {
          commit(DESTROY_UNIT, payload.id);
          commit(SPLICE_SUCESSO, {
            alert: response.data.sucess.message[0],
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },
  },

  getters: {
    getNewUnitToAdd: (state) => state.newUnitToAdd,
    getUnit: (state) => ({
      ...state.units,
    }),
  },
};

export default moduleUnit;
