<template>
  <v-row>
    <v-dialog
      max-width="400"
      v-model="this._props.component.dialog_cl2"
      persistent
    >
      <v-card>
        <v-container>
          <v-row>
            <v-card-title>Cadastro rápido</v-card-title>
          </v-row>
          <v-divider></v-divider>
          <v-form v-model="valid">
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  v-model="new_client.cpf"
                  label="CPF"
                  v-mask="'###.###.###-##'"
                  :rules="cpfRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-4 white--text"
              @click="
                () => {
                  close();
                }
              "
              >Cancelar</v-btn
            >
            <v-btn @click="add_client()" color="success" :disabled="!valid"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: { component: Object },
  data: () => ({
    valid: Boolean(),
    new_client: {
      name: "Cadastrado com CPF",
      cpf: String(),
    },
    cpfRules: [
      (v) => !!v || "CPF é obrigatório",
      (v) => v.length == 14 || "O CPF deve possuir 11 caracteres",
    ],
  }),
  methods: {
    close() {
      this.component.dialog_cl2 = !this.component.dialog_cl2;
    },
    add_client() {
      this.component.client = { ...this.new_client };
      this.close();
    },
  },
};
</script>
