var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":3}},[_c('v-card-title',[_vm._v("Carrinho")]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"fixed-header":"","items-per-page":-1,"height":"50vh","hide-default-footer":"","no-data-text":"Carrinho Vazio"},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-icon',{attrs:{"small":"","color":"orange darken-3"},on:{"click":() => {
            _vm.descontItemCar(item);
          }}},[_vm._v("mdi-calculator")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"orange darken-3"},on:{"click":() => {
            _vm.editItemCar(item);
          }}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"red darken-3"},on:{"click":() => {
            _vm.deleteItem(item);
          }}},[_vm._v("mdi-delete")])]}},{key:"item.value_descont_product",fn:function({ item }){return [_vm._v(_vm._s(parseFloat(item.value_descont_product).toFixed(2)))]}}])}),_c('v-divider'),_c('v-divider'),_c('v-card-actions',[_c('div',{staticClass:"footerTable"},[_c('p',{staticClass:"payment"},[_c('span',{staticClass:"paymentTotal"},[_vm._v("Total a Pagar")]),_vm._v(": "),_c('span',{staticClass:"numberTotal"},[_vm._v("R$ "+_vm._s(_vm.total.toFixed(2)))])]),_c('p',{staticClass:"infSeller"},[_c('span',{staticClass:"infSeller operator"},[_vm._v("Cliente")]),_vm._v(": "),_c('span',{staticClass:"infSeller Name"},[_vm._v(_vm._s(this.component.client.name))]),_vm._v(" | "),_c('span',{staticClass:"infSeller pdvDescription"},[_vm._v("Vendedor")]),_vm._v(": "),_c('span',{staticClass:"infSeller pdvId"},[_vm._v(_vm._s(this.component.seller.name))])]),_c('p',{staticClass:"infSeller"},[_c('span',{staticClass:"infSeller operator"},[_vm._v("Operador")]),_vm._v(": "),_c('span',{staticClass:"infSeller Name"},[_vm._v(_vm._s(_vm.user.name))]),_vm._v(" | "),_c('span',{staticClass:"infSeller pdvDescription"},[_vm._v("PDV")]),_vm._v(": "),_c('span',{staticClass:"infSeller pdvId"},[_vm._v(_vm._s(_vm.pdvId))])])]),_c('v-spacer'),_c('CustomButtom'),_c('v-btn',{staticClass:"red darken-4 white--text",on:{"click":() => {
          _vm.ClearCart();
        }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cart-remove")]),_vm._v("Limpar ")],1),_c('v-btn',{staticClass:"green darken-4 white--text",attrs:{"disabled":_vm.valid},on:{"click":() => {
          _vm.FinishSaleFormOfPaymentsEndCondidtionsPayments();
        }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cart-arrow-right")]),_vm._v("PAGAR ")],1)],1),_c('EditProduct',{attrs:{"component":this}}),_c('DescontProduct',{attrs:{"component":this}}),_c('DeleteProduct',{attrs:{"component":this}}),_c('ClearCar',{attrs:{"component":this}}),(_vm.dialog_finish)?_c('FinishSale',{attrs:{"component":this}}):_vm._e(),(_vm.dialog_alert)?_c('AlertError',{attrs:{"title_error":_vm.errorTitle,"listErrors":_vm.errorsList,"component":this}}):_vm._e(),(_vm.permissionDiscountDialog)?_c('PasswordDialog',{attrs:{"component":this}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }