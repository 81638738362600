<template>
  <v-form ref="form" v-model="component.valid">
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12" xl="12" >
        <v-autocomplete
          class="ma-2"
          hide-no-data
          outlined
          v-model="component.product.cst_cofins_id"
          :readonly="component.justVisible"
          label="*CST/COFINS"
          item-text="description"
          item-value="id"
          :rules="component.textRules"
          :items="cst_pis_cofins"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changePisCofinsDialog()"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-layout>
          <v-select
            class="ma-2"
            label="Forma de calculo do Cofins"
            v-model="component.product.ipi_calculation_form"
            :readonly="component.justVisible"
            outlined
            :items="formcalcofins"
          >
          </v-select>
        </v-layout>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          label="Aliquota Cofins (%)"
          v-model="component.product.framing_class"
          :readonly="component.justVisible"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-layout>
          <v-select
            class="ma-2"
            label="Forma de calculo de Subst. Trib"
            v-model="component.product.ipi_calculation_form"
            :readonly="component.justVisible"
            full-width
            outlined
            :items="formpis"
          >
          </v-select>
        </v-layout>
      </v-col>
    </v-row>

    <ModalPisCofins
      v-if="addDialogPisCofins"
      :addDialogPisCofins="addDialogPisCofins"
      :component="this"
      @close="addDialogPisCofins = $event"
    />
  </v-form>
</template>

<script>
import ModalPisCofins from "../../../pis_cofins/AddDialog/index";
import { GET_ALL_PIS_COFINS } from "../../../../store/actions/pis_cofins";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object(),
  },

  components: {
    ModalPisCofins,
  },

  data: () => ({
    page: Number(),
    last_page: Number(),
    item_listNcm: Array(),
    item_list: Array(),
    addDialogPisCofins: false,
    cst_pis_cofins: Array(),
    objectToUpdate: Object(),
    vSelect: String(),
    items: [
      "Nacional",
      "Estrangeira - Imoportação direta",
      "Estrangeira - Adquirida no mercado interno",
    ],
    formpis: [
      "Nacional",
      "Estrangeira - Imoportação direta",
      "Estrangeira - Adquirida no mercado interno",
    ],
    formcalcofins: ["Porcentagem", "Em valor"],
  }),

  methods: {
    getAllResources(page = 0) {
      this.$store.dispatch(GET_ALL_PIS_COFINS, { page });
    },
    change_addDialog(item) {
      this.objectToUpdate = item;
    },
    changePisCofinsDialog() {
      this.addDialogPisCofins = !this.addDialogPisCofins;
    },
    reset() {
      this.$refs.form.resetValidation();
    },
  },

  computed: {
    ...mapGetters(["getPisCofins", "getNewPisCofinsToAdd"]),
  },

  watch: {
    getNewPisCofinsToAdd: function (value) {
      this.cst_pis_cofins.push(value);
      this.component.product.cst_confis_id = value.id;
    },
    getPisCofins: function (val) {
      this.cst_pis_cofins = [];
      this.cst_pis_cofins = Object.keys(val).map((key) => val[key]);
    },
  },

  created() {
    this.getAllResources();
    this.cst_pis_cofins = Object.keys(this.getPisCofins).map(
      (key) => this.getPisCofins[key]
    );
  },
};
</script>
