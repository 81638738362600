let destinationNFeInitial = {
  cnpjorcpfDestination: "",
  idDestination: "",
  nameDestination: "",
  publicPlaceDestination: "",
  numberDestination: "",
  complementDestination: "",
  districtDestination: "",
  codeCountyDestination: "",
  nameCountyDestination: "",
  ufDestination: "",
  zipCodeDestination: "",
  codeCountryDestination: "",
  nameCountryDestination: "",
  numberPhoneDestination: "",
  recipientIEIndicatorDestination: "",
  stateRegistrationDestinationNumber: "",
  stateSuframaRegistration: "",
  stateMunicipalRegistrationDestination: "",
  emailDestination: "",
};
const CONSUMER_DESTINATION_FINAL = 1

async function execute(sales) {
  try {
    let clientIdentify = sales.client.id
    if (clientIdentify == CONSUMER_DESTINATION_FINAL) {
      return await mounterDestinationFinalConsumer()
    } else if (clientIdentify != CONSUMER_DESTINATION_FINAL) {
      return await mounterConsumerDestinationFinalIdentify(sales)
    }
  } catch (error) {
    return error;
  }
}

async function mounterConsumerDestinationFinalIdentify(sales) {
   let destinationNFe = {...destinationNFeInitial}
    destinationNFe.cnpjorcpfDestination = sales.client.physicalpeople === null ? sales.client.legalpeople.cnpj : sales.client.physicalpeople.cpf,
    (destinationNFe.idDestination = ""),
    destinationNFe.nameDestination = sales.client.physicalpeople === null ? sales.client.legalpeople.socialName : sales.client.physicalpeople.name + sales.client.physicalpeople.lastName,
    (destinationNFe.publicPlaceDestination = sales.client.address[0].street),
    (destinationNFe.numberDestination = sales.client.address[0].number),
    (destinationNFe.complementDestination = sales.client.address[0].complement),
    (destinationNFe.districtDestination = sales.client.address[0].district),
    (destinationNFe.codeCountyDestination = String(sales.client.address[0].city_id)),
    (destinationNFe.nameCountyDestination = sales.client.address[0].state),
    (destinationNFe.ufDestination = sales.client.address[0].state),
    (destinationNFe.zipCodeDestination = sales.client.address[0].cep),
    (destinationNFe.codeCountryDestination = "1058"),
    (destinationNFe.nameCountryDestination = "BRASIL"),
    (destinationNFe.numberPhoneDestination = sales.client.contacts[0].phone),
    (destinationNFe.recipientIEIndicatorDestination = "9"),
    (destinationNFe.stateRegistrationDestinationNumber = ""),
    (destinationNFe.stateSuframaRegistration = ""),
    (destinationNFe.stateMunicipalRegistrationDestination = ""),
    (destinationNFe.emailDestination = sales.client.contacts[0].primaryEmail);
    return destinationNFe;

}
async function mounterDestinationFinalConsumer() {
  let destinationNFe = {...destinationNFeInitial}
  destinationNFe.recipientIEIndicatorDestination = "9"
  return destinationNFe;
}

module.exports = {
  execute,
};
