const ADD_UNIT = "ADD_UNIT";
const UPDATE_UNIT = "UPDATE_UNIT";
const GET_ALL_UNIT = "GET_ALL_UNIT";
const DESTROY_UNIT = "DESTROY_UNIT";

module.exports = {
  ADD_UNIT,
  UPDATE_UNIT,
  GET_ALL_UNIT,
  DESTROY_UNIT,
};
