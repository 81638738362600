<template>
  <div>
    <div v-if="getLoad" class="wraped">
      <div class="load">
        <v-progress-circular
          size="50"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";


export default {
  computed: {
    ...mapGetters(["getLoad"]),
  },
};
</script>

<style scoped>
.wraped {
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: flex;
  top: 0;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>