<template>
  <v-app class="myContainer">
    <Navbar v-if="isValid" class="myContainer" />
    <v-main class="myContainer">
      <router-view class="myContainer"></router-view>
    </v-main>
    <!-- <v-footer class="success" app>
      <v-col class="text-center white--text">
        {{ new Date().getFullYear() }} -
        <strong>PDV TopWay System</strong>
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar/";
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

export default {
  name: "App",
  components: {
    Navbar,
  },
  data: () => ({
    isValid: Boolean(),
  }),
  updated() {
    this.isValid = this.$store.getters["auth/getIsAuth"];
  },
  mounted() {
    this.isValid = this.$store.getters["auth/getIsAuth"];
  },
  // computed: {
  //   user() {
  //   return this.$store.getters["auth/getIsAuth"];
  //    //return this.$store.getters["auth/authenticated"]
  //   },
  // },
};
</script>


<style>
.myContainer {
  border: 1px soild red;
  /* background-color: #eceff1; */
}
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");

th.text-start {
  font-size: 15px !important;
  font-family: "Mulish", sans-serif !important;
}
</style>
