<template>
  <v-container>
    <v-card elevation="4" outlined>
      <v-card-text>
        <v-row>
          <v-card-title>Parâmetros</v-card-title>
          <v-spacer></v-spacer>
          <v-btn class="mt-3 mr-4" color="success" @click="confirm()">
            <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
          >
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <ParameterList />
    </v-card>
  </v-container>
</template>

<script>
import ParameterList from "../components/Parameter/List";

export default {
  components: {
    ParameterList,
  },
};
</script>

<style>
</style>