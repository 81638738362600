const ADD_CSOSN = "ADD_CSOSN";
const UPDATE_CSOSN = "UPDATE_CSOSN";
const GET_ALL_CSOSN = "GET_ALL_CSOSN";
const DESTROY_CSOSN = "DESTROY_CSOSN";

module.exports = {
    ADD_CSOSN,
    UPDATE_CSOSN,
    GET_ALL_CSOSN,
    DESTROY_CSOSN,
}
