/* eslint-disable no-unused-vars */

import { setInterval } from "core-js";

const alert_api = {
    state: () => ({
        feeds: [],
    }),

    mutations: {
        SPLICE_SUCESSO: (state, err) => {

            state.feeds.push({
                ...err
            })
            let interval = setInterval(() => {

                if (state.feeds.length != 0) {

                    state.feeds.splice(0, 1)
                } else if (state.feeds.length === 0) {
                    clearInterval(interval);
                }
            }, 5000);
        },
        SPLICE_ERRO: (state, err) => {

            state.feeds.push({
                ...err
            })
            let interval = setInterval(() => {

                if (state.feeds.length != 0) {

                    state.feeds.splice(0, 1)
                } else if (state.feeds.length === 0) {
                    clearInterval(interval);
                }
            }, 5000);
        },
        REMOVE_ALL: state => state.feeds = [],
    },

    actions: {},

    getters: {
        getFeeds: state => state.feeds
    },
};

export default alert_api;