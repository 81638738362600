import axios from "axios";

const {
  ADD_CSOSN,
  UPDATE_CSOSN,
  GET_ALL_CSOSN,
  DESTROY_CSOSN,
} = require("./actions/csosn");
const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleCsosn = {
  state: () => ({
    csosns: Object(),
    newCsosnToAdd:Object()
  }),

  mutations: {
    GET_ALL_CSOSN: (state, csosns) => {
      state.csosns = csosns.data;
    },
    ADD_CSOSN: (state, newCSOSN) => {
      state.newCsosnToAdd = newCSOSN
      //state.csosns.data.push(newCSOSN);
    },
    UPDATE_CSOSN(state, editCSOSN) {
      let result = state.csosns.data.find((r) => r.id === editCSOSN.data.id);
      for (let key in editCSOSN.data) {
        result[key] = editCSOSN.data[key];
      }
    },

    DESTROY_CSOSN: (state, id) => {
      let auxState = [...state.csosns.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.csosns = [...auxState];
    },
  },

  actions: {
    async GET_ALL_CSOSN({ commit }, page) {
      await axios
        .post("csosn/index", {
          page: page.page,
        })
        .then((response) => {
          commit(GET_ALL_CSOSN, response.data);
        });
    },

    ADD_CSOSN({ commit }, payload) {
      axios
        .post("csosn/create", payload)
        .then((response) => {
          console.log(response)
          commit(ADD_CSOSN, response.data.data);

          commit(SPLICE_ERRO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    UPDATE_CSOSN({ commit }, payload) {
      axios
        .post("csosn/update", payload)
        .then((response) => {
          commit(UPDATE_CSOSN, response.data[0]);
          commit(SPLICE_ERRO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    DESTROY_CSOSN({ commit }, payload) {
      axios
        .post("csosn/delete", {
          ...payload,
        })
        .then((response) => {
          commit(DESTROY_CSOSN, payload);
          commit(SPLICE_ERRO, {
            alert: response.data.sucess.message[0],
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },
  },

  getters: {
    getCsosn: (state) => ({ ...state.csosns }),
    getNewCsosnToAdd: (state) => state.newCsosnToAdd
  },
};

export default moduleCsosn;
