<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this.component.addDialog" max-width="1300px">
      <v-card>
        <v-container>
          <v-card-title>{{ title }} Grupo de Acesso</v-card-title>
          <v-divider></v-divider>
          <v-form v-model="valid">
            <v-text-field
              class="mt-4"
              label="Nome do Grupo"
              v-model="roles.label"
              :rules="rulesMandatory"
              outlined
            ></v-text-field>
            <v-text-field
              label="Descrição Grupo"
              v-model="roles.description"
              :rules="rulesMandatory"
              outlined
            ></v-text-field>
          </v-form>

          <v-data-table
            :headers="header"
            no-data-text="Sem registro!"
            :disable-sort="true"
            :items="items"
            :item-class="fadein"
            :footer-props="{
              'items-per-page-options': [5, 10, 15, -1],
              'items-per-page-text': 'Items por página',
            }"
          >
            <template v-slot:item.enable="{ item }">
              <v-simple-checkbox
                color="success"
                :ripple="false"
                v-model="item.enable"
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.level="{ item }">
              <v-select
                :items="levels"
                dense
                :ripple="false"
                color="success"
                chips
                multiple
                outlined
                v-model="item.level"
              ></v-select>
            </template>
            <v-spacer></v-spacer>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row class="mt-4">
              <v-spacer></v-spacer>
              <v-btn class="error" @click="close()"
                ><v-icon class="mr-2">mdi-cancel</v-icon>Cancelar</v-btn
              >
              <v-btn
                class="ml-4 mr-4 success"
                @click="submit()"
                :disabled="!valid"
              >
                <v-icon class="mr-2">mdi-content-save</v-icon>Salvar</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  GET_ALL_PERMISSION,
  CREATE_ROLES,
  UPDATE_ROLES,
} from "../../../store/actions/permission";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
    title: String,
    objectToUpdate: Object,
  },
  computed: {
    ...mapGetters(["getPermissions"]),
  },

  data: () => ({
    header: [
      { text: "Rotinas", value: "name" },
      { text: "Habilitar?", value: "enable" },
      { text: "Nível", value: "level" },
    ],
    rulesMandatory: [(v) => !!v || "Campo obrigatório"],
    levels: ["Visualizar", "Cadastrar", "Editar", "Excluir"],
    valid: Boolean(),
    results: Array(),
    items: Array(),
    roles: {
      label: String(),
      description: String(),
      display_name: String(),
    },
  }),

  methods: {
    close() {
      this.component.addDialog = !this.component.addDialog;
    },
    async getAllPermissions() {
      await this.$store.dispatch(GET_ALL_PERMISSION, { page: 0 });
    },
    fadein: function () {
      return " fade";
    },
    submit() {
      if (this.roles.id === undefined) {
        this.create();
      } else {
        this.update();
      }
    },

    create() {
      let role = Object();
      let permissions = Array();
      this.items.forEach((el) => {
        if (el.enable === true) {
          let permission = Object();
          permission.id = el.id;
          permission.level = el.level;
          permissions.push(permission);
        }
      });
      role.label = this.roles.label;
      role.description = this.roles.description;
      role.display_name = this.roles.label;
      role.permissions = permissions;
      this.$store
        .dispatch(CREATE_ROLES, role)
        .then(() => {
          this.component.addDialog = false;
        })
        .catch((error) => {
          console.log("Deu Error", error);
        });
    },
    update() {
      let role = Object();
      let permissions = Array();
      this.items.forEach((el) => {
        if (el.enable === true) {
          let permission = Object();
          permission.id = el.id;
          permission.level = el.level;
          permissions.push(permission);
        }
      });
      role.id = this.roles.id;
      role.label = this.roles.label;
      role.description = this.roles.description;
      role.display_name = this.roles.label;
      role.permissions = permissions;
      console.log("Dale", UPDATE_ROLES);
      this.$store
        .dispatch(UPDATE_ROLES, role)
        .then(() => {
          this.component.addDialog = false;
        })
        .catch((error) => {
          console.log("Deu Error", error);
        });
    },
    refreshInfos() {
      this.roles.label = this.objectToUpdate.label;
      this.roles.description = this.objectToUpdate.description;
      this.roles.display_name = this.objectToUpdate.display_name;
      this.roles.id = this.objectToUpdate.id;
    },

    tablePermissions() {
      let alocItem = this.objectToUpdate;
      this.getPermissions.forEach((item, i) => {
        setTimeout(() => {
          let array = alocItem ? alocItem.permissions : null;
          let current = array
            ? array.find((r) => r.route_name == item.route_name)
            : null;

          this.items.push({
            id: item.id,
            name: item.display_name,
            enable: current ? true : false,
            level: current
              ? current.permissionrole.permission_col.split(",")
              : [],
          });
        }, 100 * i);
      });
    },
  },

  async mounted() {
    await this.getAllPermissions();
    this.tablePermissions();
    if (this.objectToUpdate !== undefined) {
      this.refreshInfos();
    }
  },
};
</script>

<style>
.zeroOp {
  opacity: 0;
}
.fade {
  -webkit-animation: fadein 1s ease-in; /* Safari, Chrome and Opera > 11.1 */
  -moz-animation: fadein 1s ease-in; /* Firefox < 16 */
  -ms-animation: fadein 1s ease-in; /* Internet Explorer */
  -o-animation: fadein 1s ease-in; /* Opera < 11.1 */
  animation: fadein 1s ease-in;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>