import axios from "axios";
import axiosautomacao from "../apigestao"
const NETWORK_ERROR = "Network Error"

const {
  ADD_FORM_OF_PAYMENT,
  UPDATE_FORM_OF_PAYMENT,
  GET_ALL_FORM_OF_PAYMENT,
  DESTROY_FORM_OF_PAYMENT,
  AUTOCOMPLETE_FORM_OF_PAYMENT,
} = require("./actions/formofpayment");

const { SPLICE_SUCESSO, SPLICE_ERRO } = require("./actions/alert_api");

const moduleFormOfPayments = {
  state: () => ({
    formofpayments: Object(),
  }),

  mutations: {
    GET_ALL_FORM_OF_PAYMENT: (state, formofpayments) => {
      state.formofpayments = formofpayments;
    },
    AUTOCOMPLETE_FORM_OF_PAYMENT: (state, formofpayments) => {
      state.formofpayments = formofpayments;
    },
    ADD_FORM_OF_PAYMENT: (state, newFormofpayments) => {
      state.formofpayments.data.push(newFormofpayments.data);
    },
    UPDATE_FORM_OF_PAYMENT(state, updateFormofpayment) {
      let result = state.formofpayments.data.find(
        (r) => r.id === updateFormofpayment.data.id
      );
      for (let key in updateFormofpayment.data) {
        result[key] = updateFormofpayment.data[key];
      }
    },

    DESTROY_FORM_OF_PAYMENT: (state, id) => {
      let auxState = [...state.formofpayments.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.formofpayments.data = [...auxState];
    },
  },
  actions: {
    async GET_ALL_FORM_OF_PAYMENT({ commit }, payload) {

        let token = localStorage.getItem("token_gestao");
        axiosautomacao.post("formOfPayment/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: true
        }, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        }).then((response) => {
          commit(GET_ALL_FORM_OF_PAYMENT, response.data[0]);
        }).catch((error) => {
          commit(SPLICE_ERRO, {
            alert: "Erro ao carregar as formas de pagamento!",
            type: "E",
          });
          if (NETWORK_ERROR == error.message) {
            axios
              .post("formofpayment/index", {
                page: payload.page,
                limit: payload.limit,
                paginate: true
              },)
              .then((response) => {
                commit(GET_ALL_FORM_OF_PAYMENT, response.data.data);
              });
          }

        })
      


    },

    async AUTOCOMPLETE_FORM_OF_PAYMENT({ commit }, payload) {

        let token = localStorage.getItem("token_gestao");
        axiosautomacao.post("formOfPayment/autocomplete", {
          search: payload.search,
          page: payload.page,
          limit: payload.limit,
          paginate: true
        }, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        }).then((response) => {
          commit(GET_ALL_FORM_OF_PAYMENT, response.data[0]);
        })
    },

    ADD_FORM_OF_PAYMENT({ commit }, payload) {
      axios
        .post("formofpayment/create", payload)
        .then((response) => {
          commit(ADD_FORM_OF_PAYMENT, response.data);
          commit(SPLICE_SUCESSO, { alert: response.data.msg });
        })
        .catch((e) => {
          e.response.data.errors.forEach((el) => {
            commit(SPLICE_ERRO, {
              alert: el,
              type: 'E'
            })
          })
        });
    },
    UPDATE_FORM_OF_PAYMENT({ commit }, payload) {
      axios
        .post("formofpayment/update", payload)
        .then((response) => {
          commit(UPDATE_FORM_OF_PAYMENT, response.data);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
          });
        })
        .catch((e) => {
          e.response.data.errors.forEach((el) => {
            commit(SPLICE_ERRO, {
              alert: el,
              type: 'E'
            })
          })
        });
    },
    DESTROY_FORM_OF_PAYMENT({ commit }, payload) {
      axios
        .post("formofpayment/delete", payload)
        .then((response) => {
          commit(DESTROY_FORM_OF_PAYMENT, payload.id);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
          });
        })
        .catch((e) => {
          e.response.data.errors.forEach((el) => {
            commit(SPLICE_ERRO, {
              alert: el,
              type: 'E'
            })
          })
        });
    },
  },
  getters: {
    getFormOfPayment: (state) => ({ ...state.formofpayments }),
  },
};
export default moduleFormOfPayments;
