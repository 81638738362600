<template>
  <v-form ref="form" v-model="component.valid">
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12" xl="12">
        <v-text-field
          v-model="component.physical_people.name"
          :readonly="component.justVisible"
          label="*Nome"
          :rules="[
            () => !!component.physical_people.name || 'Campo Obrigatorio',
          ]"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" xl="12">
        <v-text-field
          v-model="component.physical_people.lastName"
          :readonly="component.justVisible"
          label="*Sobrenome"
          :rules="[
            () => !!component.physical_people.lastName || 'Campo Obrigatorio',
          ]"
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.physical_people.cpf"
          :readonly="component.justVisible"
          label="*CPF"
          v-mask="'###.###.###-##'"
          :rules="[
            () => !!component.physical_people.cpf || 'Campo Obrigatorio',
            () =>
              (!!component.physical_people.cpf &&
                component.physical_people.cpf.length == 14) ||
              'Campo tem que ser igual a 11',
          ]"
          maxlength="14"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.physical_people.idCard"
          :readonly="component.justVisible"
          label="Identidade"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.physical_people.emitter"
          :readonly="component.justVisible"
          label="Orgão Emissor"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.physical_people.emitterState"
          :readonly="component.justVisible"
          label="UF Emissor"
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.physical_people.dateOfBirth"
          :readonly="component.justVisible"
          label="*Data de Nascimento"
          outlined
          type="date"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-select
          label="Gênero"
          outlined
          v-model="component.physical_people.gender"
          :readonly="component.justVisible"
          :items="items_gener"
        >
        </v-select>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-select
          v-model="component.physical_people.nacionality"
          :readonly="component.justVisible"
          label="Nacionalidade"
          :items="nacionality"
          outlined
          required
        >
        </v-select>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.physical_people.naturality"
          :readonly="component.justVisible"
          label="Naturalidade"
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { nameRules, LastNameRules, cpfRules } from "./script";

export default {
  props: {
    component: Object(),
  },
  data: () => ({
    items_gener: ["Masculino", "Feminino"],
    nacionality: ["Brasileiro", "Estrangeiro"],
  }),
  methods: {
    clear() {
      this.$refs.form.reset();
    },
  },
  nameRules,
  LastNameRules,
  cpfRules,
};
</script>

<style></style>
