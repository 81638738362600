const ADD_BARCODE = 'ADD_BARCODE'
const UPDATE_BARCODE = 'UPDATE_BARCODE'
const GET_ALL_BARCODE = 'GET_ALL_BARCODE'
const DESTROY_BARCODE = 'DESTROY_BARCODE'
const GET_BYBARCODE_BARCODE = 'GET_BYBARCODE_BARCODE'
const CLEAR_BARCODE_CODE = 'CLEAR_BARCODE_CODE'



module.exports = {
  ADD_BARCODE,
  UPDATE_BARCODE,
  GET_ALL_BARCODE,
  DESTROY_BARCODE,
  CLEAR_BARCODE_CODE,
  GET_BYBARCODE_BARCODE
}