<template>
  <v-row>
    <v-dialog
      v-model="getShowDialogRegisterSubProductGroup"
      max-width="400"
      persistent
      transition="fab-transition"
    >
      <v-card>
        <v-container>
          <v-row>
            <v-card-title>Registrar Subgrupo de Produtos</v-card-title>
          </v-row>
          <v-row class="mb-3"> </v-row>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid" class="mt-6">
            <v-autocomplete
              v-model="productgroup"
              outlined
              label="Grupo de Produtos"
              item-value="id"
              item-text="name"
              :items="getProductGroup.data.data"
              :rules="[() => !!productgroup || 'Campo Obrigatório']"
            ></v-autocomplete>
            <v-text-field
              v-model="code"
              outlined
              label="Código"
              :rules="[() => !!code || 'Campo Obrigatório']"
            ></v-text-field>
            <v-text-field
              v-model="name"
              outlined
              label="Nome"
              :rules="[() => !!code || 'Campo Obrigatório']"
            ></v-text-field>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="() => cancel()" class="error">Cancelar</v-btn>
            <v-btn class="success" :disabled="!valid" @click="() => confirm()"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ADD_SUB_PRODUCT_GROUP,
  UPDATE_SUB_PRODUCT_GROUP,
} from "../../../store/actions/sub_product_group";
import { mapGetters } from "vuex";
import { GET_ALL_PRODUCT_GROUP } from "../../../store/actions/productgroup";
import { dispatchGetAll } from "../../../components/Methods/dispatchGetAll";

export default {
  props: {
    component: Object,
  },

  data: () => ({
    valid: true,
    productgroup_items: Array(),
    id: undefined,
    product_group_id: "",
    productgroup: Object(),
    code: "",
    name: "",
    productgroupname: "",
    getter: "getProductGroup",
  }),

  methods: {
    showDialog(setState) {
      this.$store.dispatch(`${setState}`, true);
    },
    cancel() {
      this.$store.dispatch("setShowDialogRegisterSubProductGroup", false);
    },

    confirm() {
      this.id == undefined
        ? this.create_subproductgroup()
        : this.update_subproductgroup();
      this.cancel();
    },

    async create_subproductgroup() {
      const subproductgroup = {
        product_group_id: this.productgroup,
        code: this.code,
        name: this.name,
        productgroupname: this.productgroup.name,
      };
      try {
        await this.$store.dispatch(ADD_SUB_PRODUCT_GROUP, subproductgroup);
      } catch (error) {
        alert(error);
      }
    },

    async update_subproductgroup() {
      const subproductgroup = {
        id: this.id,
        product_group_id: this.productgroup,
        code: this.code,
        name: this.name,
        productgroupname: this.productgroup.name,
      };
      try {
        await this.$store.dispatch(UPDATE_SUB_PRODUCT_GROUP, subproductgroup);
      } catch (error) {
        console.log({ ...error });
      }
    },

    async GetAll(page = 1) {
      await dispatchGetAll(this, GET_ALL_PRODUCT_GROUP, page);
    },

    getComponent() {
      return this._props.component;
    },
    async generate_form() {
      this.productgroup_items = [];
      let lastPage = this.getProductGroup.last_page;
      await this.GetAll();
      for (var i = 1; i <= lastPage; i++) {
        this.GetAll(i);
        this.productgroup_items = this.productgroup_items.concat(
          this.getProductGroup.data
        );
      }
    },
  },

  async created() {
    this.object = { ...this.getComponent().objectToUpdate };
    this.id = this.object.id;
    this.productgroup = this.object.product_group_id;
    this.code = this.object.code;
    this.name = this.object.name;
    this.GetAll();
  },

  computed: {
    ...mapGetters([
      "getProductGroup",
      "getShowDialogState",
      "getShowDialogRegisterSubProductGroup",
    ]),
  },
  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },

   watch: {
     getProductGroup(val) {
       this.productgroup = val.data;
     },
   },
};
</script>
