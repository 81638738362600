<template>
  <v-container>
    <v-dialog persistent width="1080" v-model="this.component.dialog">
      <v-card>
        <v-card-title> Visualizar Notas </v-card-title>
        <v-divider class="mr-5 ml-5" />

        <v-tabs centered>
          <v-tab href="#tab-1">Dados Gerais</v-tab>
          <v-tab href="#tab-3">Itens da Nota</v-tab>
          <v-tab href="#tab-2">Informações da Nota</v-tab>
          <v-tab href="#tab-4">Pagamento</v-tab>
          <v-tab href="#tab-5">Informações Nfce</v-tab>

          <!---->

          <v-tab-item value="tab-1">
            <GeneralData :Items="this.component.sendItems" />
          </v-tab-item>
          <v-tab-item value="tab-2">
            <NoteInformation :Items="this.component.sendItems" />
          </v-tab-item>
          <v-tab-item value="tab-3">
            <ItemsNote :Items="this.component.sendItems" />
          </v-tab-item>
          <v-tab-item value="tab-4">
            <Payment :Items="this.component.sendItems" />
          </v-tab-item>
          <v-tab-item value="tab-5">
            <InfoNfce :nfce="this.component.nfce" />
          </v-tab-item>
        </v-tabs>
        <!---->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" color="error">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import GeneralData from "./GeneralData/index.vue";
import NoteInformation from "./NoteInformation";
import ItemsNote from "./ItemNote";
import Payment from "./Payment";
import InfoNfce from "./InfoNfce"

export default {
  components: {
    GeneralData,
    NoteInformation,
    ItemsNote,
    Payment,
    InfoNfce
  },
  props: {
    component: Object(),
    item: Object(),
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.component.dialog = false;
      console.log(this.component.sendItems);
    },
  },
  watch: {},
};
</script>

<style>
</style>