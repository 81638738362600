<template>
  <Container>
    <v-card elevation="5" height="100%" class="pa-5">
      <Cards />
    </v-card>
    <NotesDialog :component="this" v-if="notesDialog"/>
    <CertificateDialog :component="this" v-if="certificateDialog"/>
  </Container>
</template>

<script>
import Container from "../components/Shortcuts/Container/";
import Cards from "../components/Shortcuts/Cards/";
import NotesDialog from "../components/Shortcuts/NotesDialog/";
import CertificateDialog from "../components/Shortcuts/CertificateDialog/";
import { GET_ALL_SALES_WITH_FILTER } from "../store/actions/sales";
import { GET_ALL_ENTERPRISE, CHECK_CERTIFICATE } from "../store/actions/enterprise";
import { mapGetters } from "vuex";
export default {
  components: {
    Container,
    Cards,
    NotesDialog,
    CertificateDialog,
  },

  data: () => ({
    notesDialog: Boolean(),
    notes: Array(),
    certificateDaysLeft: Number(),
    certificateDialog: Boolean(),
  }),

  methods: {
    async getAllNotes(enterprise_id) {
      var currentDate = new Date();

      currentDate.setDate(1);
      var initialDate = currentDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format

      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(0);

      var lastDate = currentDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
      await this.$store.dispatch(GET_ALL_SALES_WITH_FILTER, {
        page: 1,
        limit: 10,
        paginate: false,
        initialDate: initialDate,
        finalDate: lastDate,
        status: "Em Digitacao",
        enterpriseId: enterprise_id,
      });
      await this.$store.dispatch(GET_ALL_SALES_WITH_FILTER, {
        page: 1,
        limit: 10,
        paginate: false,
        initialDate: initialDate,
        finalDate: lastDate,
        status: "Rejeitada",
        enterpriseId: enterprise_id,
      });
    },

    async checkCertificate(cnpj, enterprise_id) {
      const payload = {
        cnpjEmitter: cnpj,
      }

      await this.$store.dispatch(CHECK_CERTIFICATE, payload).then((response) => {
        this.certificateDaysLeft = response
        this.getAllNotes(enterprise_id)
      })
    }
  },

  watch: {
    getSales: function(val) {
      let notesObj = val
      notesObj = Object.values(notesObj);
      for(let i=0; i<notesObj.length; i++) {
        this.notes.push(notesObj[i])
      }
      if(this.notes.length > 0) {
        for(let i=0; i<this.notes.length; i++) {
          if(this.notes[i].cliente.people.physicalpeople) {
            this.notes[i].cliente.name = ""
            this.notes[i].cliente.name = this.notes[i].cliente.people.physicalpeople.name
          } else {
            this.notes[i].cliente.name = ""
            this.notes[i].cliente.name = this.notes[i].cliente.people.legalpeople.fantasyName
          }
        }
        this.notesDialog = true
      } else if (this.certificateDaysLeft <= 30) {
        this.certificateDialog = true
      }
    },

    getEnterprises: function(val) {
      this.checkCertificate(val.data[0].cnpj, val.data[0].id)
    }
  },

  computed: {
      ...mapGetters([
        "getSales",
        "getEnterprises"
      ]),
  },
};
</script>

<style>
</style>