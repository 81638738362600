<template>
  <v-container>
    <v-col>
      <v-form ref="form" v-model="formValid">
        <v-row>
          <v-col cols="7" lg="4" md="4" sm="4" xl="4">
            <v-text-field
              v-model="form.coupon_inf"
              label="Pesquisar Cupom"
              outlined
              append-icon="mdi-magnify"
              @click:append="searchCupon"
              :rules="rules"
              :readonly="justVisible"
              :disabled="couponSearched"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" md="4" sm="4" xl="4">
            <v-text-field
              readonly
              v-model="form.data_sale"
              label="Data da Venda"
              outlined
              :rules="rules"
            />
          </v-col>
          <v-col>
            <v-text-field
              readonly
              v-model="form.access_key"
              label="Chave da Nota"
              outlined
              :rules="rules"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              readonly
              v-model="form.client_name"
              label="Cliente"
              outlined
              :rules="rules"
            />
          </v-col>
          <v-col>
            <v-text-field
              readonly
              v-model="form.coupon_value"
              label="Valor do Cupom"
              outlined
              :rules="rules"
            />
          </v-col>
          <v-col>
            <v-text-field
              readonly
              v-model="form.total_credit"
              label="Valor Total de Crédito"
              outlined
            />
          </v-col>
        </v-row>
        <Table :component="this" />
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn class="mr-1 error" @click="closeDialog">Cancelar</v-btn>
            <v-btn :disabled="activateButton" class="ml-1 success" @click="submit"
              >Gerar Crédito</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-dialog
      v-model="searchErrorDialog"
      width="300"
    >

      <v-card text-center>
        <v-card-title class="text-h5 grey lighten-2">
          Cupom Inválido
        </v-card-title>

        <v-card-text style="font-size: 18px;">
          Este Cupom não existe ou já foi utilizado para troca
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="searchErrorDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { rules } from "./rules";
import {
  SEARCH_CUPOM,
  EXCHANGE_HEADER,
} from "../../../../store/actions/exchange_control";
import { mapGetters } from "vuex";
import Table from "../table/index.vue";
export default {
  components: {
    Table,
  },
  props: {},
  computed: {
    ...mapGetters(["getCupomInformation", "getCuponValue", "getTotalCredit","getSelectedEnterprise", "getViewExchange", "getErrorDialog"]),
  },
  data: () => ({
    rules,
    formValid: Boolean(),
    validateAmountChange: Boolean(),
    activateButton: Boolean(true),
    submitFlag: Boolean(),
    couponSearched: Boolean(),
    searchErrorDialog: Boolean(),
    form: {
      total_credit: Number(),
      coupon_inf: String(),
      data_sale: String(),
      access_key: String(),
      client_id: Number(),
      coupon_value: String(),
      client_name: String(),
    },
  }),
  methods: {
    viewItem(val) {
      if (val.justVisible == true) {
        this.justVisible = val.justVisible
        this.form.data_sale = val.item.data_exchange
        this.form.access_key = val.item.access_key
        this.form.coupon_value = val.item.value_exchange
        this.form.total_credit = val.item.value_credit_icms_total
        this.form.client_id = val.item.enterprise_id
        if(val.item.sale.cliente.people.physicalpeople) {
          this.form.client_name = val.item.sale.cliente.people.physicalpeople.name
        } else {
          this.form.client_name = val.item.sale.cliente.people.legalpeople.fantasyName
        }
      }
      // else {
      //   this.justVisible = val.justVisible
      //   this.form.data_sale = ''
      //   this.form.access_key = ''
      //   this.form.coupon_value = ''
      //   this.form.total_credit = ''
      //   this.form.client_id = ''
      // }
    },
    submit() {
      this.submitFlag = !this.submitFlag
    },
    closeDialog() {
      this.$store.dispatch("setShowDialogRegisterExchangeControl", false);
    },
    autoLoad(value) {
      this.form.data_sale = value.data_sale;
      this.form.access_key = value.access_key;
      this.form.client_id = value.client_id;
      this.form.coupon_value = this.getCuponValue
      if(value.cliente.people.physicalpeople) {
        this.form.client_name = value.cliente.people.physicalpeople.name
      } else {
        this.form.client_name = value.cliente.people.legalpeople.fantasyName
      }
      this.couponSearched = true
    },
    searchCupon() {
      const payload = {
        enterprise_id: this.getSelectedEnterprise.id,
        number_sale: this.form.coupon_inf
      }
      this.$store.dispatch(SEARCH_CUPOM, payload);
    },
  },
  watch: {
    validateAmountChange: function(val){
      if(val && this.formValid){
        this.activateButton = false
      }else {
        this.activateButton = true
      }
    },
    getTotalCredit: function (val) {
      this.form.total_credit = val;
    },
    getCuponValue: function (val) {
      this.form.coupon_value = val;
    },
    getCupomInformation: function (val) {
      this.autoLoad(val);
      this.$store.dispatch(EXCHANGE_HEADER, this.form);
    },
    getErrorDialog: function (val) {
      this.searchErrorDialog = true
    },
  },
  created() {
    this.form = {
      total_credit: Number(),
      coupon_inf: String(),
      data_sale: String(),
      access_key: String(),
      client_id: Number(),
      coupon_value: String(),
    }
    this.viewItem(this.getViewExchange)
  },
};
</script>

<style></style>
