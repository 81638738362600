/* eslint-disable no-unused-vars */
import axios from "axios";

const {
  ADD_COMPANY_GROUP,
  UPDATE_COMPANY_GROUP,
  GET_ALL_COMPANY_GROUP,
  DESTROY_COMPANY_GROUP,
} = require("./actions/companygroup");

const { SPLICE_SUCESSO, SPLICE_ERRO } = require("./actions/alert_api");

const moduleCompanygroup = {
  state: () => ({
    companygroups: Object(),
  }),

  mutations: {
    GET_ALL_COMPANY_GROUP: (state, companygroups) => {
      state.companygroups = companygroups;
    },

    ADD_COMPANY_GROUP: (state, newCompanygroups) => {
      state.companygroups.data.push(newCompanygroups);
    },
    UPDATE_COMPANY_GROUP(state, editCompanygroups) {
      let result = state.companygroups.data.find(
        (r) => r.id === editCompanygroups.data.id
      );
      for (let key in editCompanygroups.data) {
        result[key] = editCompanygroups.data[key];
      }
    },

    DESTROY_COMPANY_GROUP: (state, id) => {
      let auxState = [...state.companygroups.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.companygroups = [...auxState];
    },
  },

  actions: {
    GET_ALL_COMPANY_GROUP({ commit }, payload) {
      axios
        .post("companygroup/index", { page: payload.page })
        .then((response) => {
          commit(GET_ALL_COMPANY_GROUP, response.data);
        });
    },

    ADD_COMPANY_GROUP({ commit }, payload) {
      axios
        .post("companygroup/store", payload)
        .then((response) => {
          commit(ADD_COMPANY_GROUP, response);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    async UPDATE_COMPANY_GROUP({ commit }, payload) {
      try {
        let rest = await axios.post("companygroup/update", payload);
        commit(UPDATE_COMPANY_GROUP, rest.data[0]);
        commit(SPLICE_SUCESSO, {
          alert: rest.data.msg,
          type: "S",
        });
      } catch (error) {
        commit(SPLICE_ERRO, {
          alert: error.message,
          type: "E",
        });
        let erros = error.response.data.errors;
        for (let erro in erros) {
          if (erros[erro]) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        }
      }
    },

    DESTROY_COMPANY_GROUP({ commit }, payload) {
      axios
        .post("companygroup/delete", payload)
        .then((response) => {
          commit(DESTROY_COMPANY_GROUP, payload);
          commit(SPLICE_SUCESSO, {
            alert: response.data.sucess.message[0],
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data[0].errors;

          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },
  },

  getters: {
    getCompanygroup: (state) => ({
      ...state.companygroups,
    }),
  },
};
//namespaced: true,
//module.exports = moduleAliquot
export default moduleCompanygroup;
