<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="650" persistent>
      <v-card>
        <v-container>
          <v-card-title>{{ title }} Usuário</v-card-title>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid_form">
            <v-row class="ma-2">
              <v-checkbox
                v-model="component.user.status"
                label="Ativo"
              ></v-checkbox>
            </v-row>
            <v-row class="ma-2">
              <v-text-field
                v-model="component.user.name"
                :rules="[(val) => !!val || 'Campo obrigatório!']"
                class="mr-1"
                label="*Nome"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="component.user.email"
                :rules="[
                  (val) => !!val || 'Campo obrigatório!',
                  (v) => /.+@.+/.test(v) || 'Insira um e-mail válido!',
                ]"
                label="*E-mail"
                outlined
              ></v-text-field>
            </v-row>
            <div v-if="component.changePassword">
              <v-row class="ma-2">
                <v-text-field
                  class="mr-1"
                  v-model="component.user.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  hint="Minimo 8 digitos"
                  label="*Senha"
                  outlined
                  @click:append="show1 = !show1"
                />
                <v-text-field
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  :rules="[
                    (val) =>
                      val === component.user.password || 'Senhas não coicidem!',
                  ]"
                  label="*Confirmar Senha"
                  hint="Minimo 8 digitos"
                  v-model="component.confirm_password"
                  @click:append="show2 = !show2"
                  outlined
                />
              </v-row>
            </div>

            <v-row class="ma-2">
              <v-select
                :items="getRoles"
                v-model="component.user.roles"
                outlined
                return-object
                item-text="label"
                label="*Permissão"
                multiple
              ></v-select>
            </v-row>
            <v-row class="ma-2">
              <v-text-field
                outlined
                readonly
                v-model="cashier.name"
                clearable
                label="*Permissões de Caixa"
                @click:append="activeCashier()"
                append-icon="mdi-cash-register"
              >
              </v-text-field>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="actionDialog()" class="error">
              <v-icon class="mr-2">mdi-cancel</v-icon>Cancelar</v-btn
            >
            <v-btn @click="submit" :disabled="!valid_form" class="success">
              <v-icon class="mr-2">mdi-content-save</v-icon> Confirmar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <cashierSetting :component="this" v-if="dialogCashier" />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import cashierSetting from "../AddDialog/cashierSettings/";

export default {
  props: {
    component: Object,
    dialog: Boolean,
    title: String,
    actionDialog: Function,
  },
  components: {
    cashierSetting,
  },
  data: () => ({
    valid_form: true,
    show1: Boolean(),
    show2: Boolean(),
    rules: {
      required: (value) => !!value || "Obrigatório",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
    },
    dialogCashier: Boolean(),
    operator_cashier: Object(),
    namePermission: String(),
    cashierObject: {
      id: Number(),
      description: String(),
    },
    cashier: {
      name: null,
      limit_descont_value: Number(),
      limit_descont_percentual: Number(),
      cancel_vend: Boolean(),
      cancel_item: Boolean(),
      close_cashier: Boolean(),
      open_cashier: Boolean(),
      cancel_cupon: Boolean(),
      print_cupon: Boolean(),
      supply: Boolean(),
      bleed: Boolean(),
      cashier_id: Number(),
      user_id: Number(),
    },
  }),
  computed: {
    ...mapGetters(["getRoles"]),
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (this.valid_form) {
        this.component.confirm(this.cashier);
      }
    },
    activeCashier() {
      this.dialogCashier = !this.dialogCashier;
    },
  },
  created() {
    if (this.component.user.cashieroperator !== undefined) {
      this.cashier = { ...this.component.user.cashieroperator };
      delete this.cashier.id;
      this.cashierObject.description = this.cashier.name;
      this.cashierObject.id = this.cashier.cashier_id;
    }
  },
};
</script>

<style>
</style>