const ADD_USER = "ADD_USER";
const UPDATE_USER = "UPDATE_USER";
const GET_ALL_USER = "GET_ALL_USER";
const DESTROY_USER = "DESTROY_USER";
const CREATE_USER = "CREATE_USER";
const GET_BY_EMAIL_USER = "GET_BY_EMAIL_USER";
const GET_ALL_ROLE = "GET_ALL_ROLE";
const GET_ALL_PERMISSION = "GET_ALL_PERMISSION"
const CREATE_USERS = "CREATE_USERS"
const SHOW_USER = "SHOW_USER"

export {
    ADD_USER,
    UPDATE_USER,
    GET_ALL_USER,
    DESTROY_USER,
    CREATE_USER,
    GET_BY_EMAIL_USER,
    GET_ALL_ROLE,
    GET_ALL_PERMISSION,
    CREATE_USERS,
    SHOW_USER
};