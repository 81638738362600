<template>
  <v-row>
    <v-dialog
      persistent
      transition="scale-transition"
      max-width="300"
      v-model="this._props.component.dialog_add"
    >
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row justify="center">
            <v-card-title>Qual a quantidade?</v-card-title>
          </v-row>
          <v-form v-model="component.valid">
            <v-text-field
              outlined
              label="Quantidade"
              v-model="component.amount"
            >
            </v-text-field>
          </v-form>
          <v-row justify="center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                class="red darken-4 white--text"
                @click="
                  () => {
                    disableDialog();
                  }
                "
              >
                Cancelar
              </v-btn>
              <v-btn
                :disabled="!component.valid"
                depressed
                class="success"
                @click="
                  () => {
                    component.AddDialogProductCar();
                  }
                "
              >
                Adicionar
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object(),
  },

  methods: {
    disableDialog() {
      this.component.dialog_add = !this.component.dialog_add;
      this.component.amount = 1;
    },
    restartProductAmountAdd() {
      this.component.amount = 1;
    },
  },
  destroyed() {
    this.restartProductAmountAdd();
  },
};
</script>

<style>
</style>