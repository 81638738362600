<template>
  <v-container fluid>
    <DestroyItem
      v-if="destroyDialog"
      :component="this"
      :destroy_object="confirmDestroy"
    />
    <EditItem
      v-if="addDialog"
      :component="this"
      title="Editar"
      :objectToUpdate="objectToUpdate"
    />
    <v-data-table
      :headers="header"
      :items="results"
      no-data-text="Sem registro!"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, -1],
        'items-per-page-text': 'Items por página',
      }"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-icon color="yellow darken-4" class="mr-2" @click="edit_item(item)"
          >mdi-pencil</v-icon
        >
        <v-icon
          color="red darken-4"
          class="mr-2"
          @click="changeDialogDestroy(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      color="success"
      v-model="current"
      :length="total"
      :total-visible="10"
    ></v-pagination>
  </v-container>
</template>

<script>
import { GET_ALL_ROLES, DELETE_ROLES } from "../../../store/actions/permission";
import EditItem from "../AddDialog/";
import DestroyItem from "../DestroyDialog/destroyDialog";
import { mapGetters } from "vuex";

export default {
  components: {
    DestroyItem,
    EditItem,
  },
  data: () => ({
    header: [
      { text: "Código", value: "id" },
      { text: "Nome", value: "label" },
      { text: "Descrição", value: "description" },
      { text: "Ações", value: "actions" },
    ],
    results: Array(),
    current: Number(),
    total: Number(),
    previousPage: Number(),
    destroyDialog: Boolean(),
    idDestroy: Number(),
    addDialog: Boolean(),
    objectToUpdate: Object(),
  }),
  computed: {
    ...mapGetters(["getRoles"]),
  },
  methods: {
    async GetRoles(page = 1) {
      await this.$store.dispatch(GET_ALL_ROLES, { page: page, limit: 10, paginate:true });
    },
    changeDialogDestroy(item) {
      this.idDestroy = item.id;
      this.destroyDialog = !this.destroyDialog;
    },
    confirmDestroy() {
      this.$store.dispatch(DELETE_ROLES, { id: this.idDestroy }).then(() => {
        if (this.results.length === 1) {
          this.GetRoles(this.previousPage);
        } else {
          this.GetRoles(this.current);
        }
      });
    },
    edit_item(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = { ...item };
    },
  },
  watch: {
    getRoles: function (val) {
      this.results = val.data;
      this.total = val.lastPage;
      this.current = val.currentPage;
      this.previousPage = val.prevPage;
    },
    current: function (val) {
      this.GetRoles && this.GetRoles(val);
    },
  },
  async created() {
    await this.GetRoles();
  },
};
</script>

<style>
</style>