<template>
  <div>
    <v-data-table
      :headers="atual.header"
      :items="atual.getter"
      hide-default-footer
      no-data-text="Nenhum Cliente Cadastrado"
      no-results-text="Nenhum Cliente Encontrado"
    >
      <template v-slot:[`item.actions`]="{ item, index }">
        <v-icon
          color="green darken-4"
          class="mr-2"
          v-if="component.permission.Excluir"
          @click="
            () => {
              visibleItem(item);
            }
          "
          >mdi-eye</v-icon
        >
        <v-icon
          v-if="component.permission.Editar"
          color="yellow darken-4"
          class="mr-2"
          @click="
            () => {
              edit_item(item, index);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          color="red darken-4"
          class="mr-2"
          v-if="component.permission.Excluir"
          @click="
            () => {
              deleteItem(item);
            }
          "
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <Edit :component="this" ref="edit" />
    <Delete :component="this" />

    <div>
      <v-divider class="mt-6"></v-divider>
      <v-pagination
        v-model="atualCurrent"
        :length="atualTotal"
        color="success"
        :total-visible="10"
        :disabled="paginationDelay"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { headers } from "./script";
import Edit from "./Edit_Item/";
import Delete from "./Delete_Item/";
import {
  GET_ALL_CLIENT,
  DESTROY_CLIENT,
  ITEM_TO_UPDATE_CLIENT,
  GET_AUTOCOMPLETE_CLIENT,
} from "../../../store/actions/client";
import { mapGetters } from "vuex";
import { fixingPeopleCNPJ } from "../../Utils/global_functions/clean_cnpj_cpf";

export default {
  props: { component: Object(), search: String() },
  components: { Edit, Delete },
  data: () => ({
    paginationDelay: Boolean(),
    current: 1,
    dialog: false,
    dialog_cancel: false,
    ObjectToDestroy: Object(),
    Legal: Array(),
    Physical: Array(),
    getters: Array(),
    total: Number(),
    headers,

    //Pagination Pessoa Física
    pagPhysicalTotal: Number(),
    pagPhysicalCurrent: Number(),

    //Pagination Pessoa Jurídica
    pagLegalTotal: Number(),
    pagLegalCurrent: Number(),

    //Pagination
    atualTotal: Number(),
    atualCurrent: Number(),

    atual: {
      header: Array(),
      getter: Array(),
    },
  }),

  computed: {
    ...mapGetters([
      "getPhysicalClients",
      "getLegalClients",
      "getItemUpdatedClient",
      "getItemToDelete",
      "getAddedClients",
    ]),
  },
  methods: {
    edit_item(item, index) {
      let payload = {
        ...item,
        index,
        header: "Atualizar",
      };
      this.$store.dispatch(ITEM_TO_UPDATE_CLIENT, payload);
      this.$store.dispatch("setShowDialogRegisterClient", true);
      item;
    },
    visibleItem(item) {
      this.$store.dispatch(ITEM_TO_UPDATE_CLIENT, {
        item: item,
        justVisible: true,
      });
      this.$store.dispatch("setShowDialogRegisterClient", true);
    },
    deleteItem(item) {
      this.dialog_cancel = !this.dialog_cancel;
      this.ObjectToDestroy = { ...item };
    },
    async confirmDelete() {
      try {
        await this.$store.dispatch(DESTROY_CLIENT, this.ObjectToDestroy);
      } catch (e) {
        alert(e);
      } finally {
        this.dialog_cancel = !this.dialog_cancel;
      }
    },

    //Controle de trocas
    isLegal(isLegal = false) {
      const type = { isPhisical: "isPhisical", isLegal: "isLegal" };
      this.atual.header =
        this.headers[isLegal ? type.isLegal : type.isPhisical];

      // this.atual.getter = isLegal
      //   ? this.getLegalClients
      //   : this.getPhysicalClients;

      this.atual.getter = isLegal
        ? fixingPeopleCNPJ(this.getLegalClients)
        : this.getPhysicalClients;

      this.atualTotal = isLegal ? this.pagLegalTotal : this.pagPhysicalTotal;
      this.atualCurrent = isLegal
        ? this.pagLegalCurrent
        : this.pagPhysicalCurrent;
    },

    //Atualização Pagination
    PagPhysical(total, current) {
      this.atualTotal = total;
      this.atualCurrent = current;
    },

    // GetAll pessoa Física e Juŕidica
    async GetAllPhysical(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT, {
        type_people: "PhysicalPeople",
        page: page,
        limit: 10,
        paginate: true,
      });
    },
    async GetAllLegal(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT, {
        type_people: "LegalPeople",
        page: page,
        limit: 10,
        paginate: true,
      });
    },
    async AutocompleteClientPhysical(searchVal) {
        await this.$store.dispatch(GET_AUTOCOMPLETE_CLIENT, {
          type_people: "PhysicalPeople",
          page: 1,
          limit: 10,
          paginate: true,
          enterprise_id: this.component.enterpriseId,
          search: searchVal,
        });
    },
    async AutocompleteClientLegal(searchVal) {
        await this.$store.dispatch(GET_AUTOCOMPLETE_CLIENT, {
          type_people: "LegalPeople",
          enterprise_id: this.component.enterpriseId,
          search: searchVal,
          page: 1,
          limit: 10,
          paginate: true,
        });
    },
  },

  created() {
    this.GetAllPhysical();
    // this.GetAllLegal();
  },

  watch: {
    getPhysicalClients: function (val) {
      if (val[0].page.last_page === undefined) {
        this.pagPhysicalCurrent = 1;
      } else {
        this.pagPhysicalCurrent = val[0].page.current_page;
        this.pagPhysicalTotal = val[0].page.last_page;
        this.PagPhysical(this.pagPhysicalTotal, this.pagPhysicalCurrent);
        this.atual.getter = val
      }
      if(this.component.isFormalPeople) {
        this.component.isFormalPeople = false
      }
      this.paginationDelay = false
    },
    getItemToDelete: function (val) {
      //Novo algoritmo de excluir um elemento de uma lista

      let index = this.atual.getter
        .map((element) => element.id)
        .indexOf(val.id);
      this.atual.getter.splice(index, 1);
    },
    getLegalClients: function (val) {
      if (val[0].page) {
        this.pagLegalTotal = val[0].page.last_page;
        this.pagLegalCurrent = val[0].page.current_page;
        this.PagPhysical(this.pagLegalTotal, this.pagLegalCurrent);
        this.atual.getter = val
      }
      this.paginationDelay = false
    },
    getAddedClients: function (val) {
      if (val.type_people == "LegalPeople") {
        this.atual.getter.push(val);
      }
    },
    atualCurrent: function (val) {
      this.paginationDelay = true
      if (this.component.isFormalPeople) {
        this.GetAllLegal && this.GetAllLegal(val);
      } else {
        this.GetAllPhysical && this.GetAllPhysical(val);
      }
    },
    search: function(val) {
      if(val.length < 2) {
        if(this.component.isFormalPeople) {
          this.GetAllLegal && this.GetAllLegal(1);
        } else {
          this.GetAllPhysical && this.GetAllPhysical(1);
        }
      } else {
        if(this.component.isFormalPeople) {
          this.AutocompleteClientLegal(val);
        } else {
          this.AutocompleteClientPhysical(val);
        }
      }
    }
  },

  mounted() {
    this.isLegal();

    this.$watch(
      () => this.component.isFormalPeople,
      function (val) {
        this.isLegal(val);
      }
    );
  },
};
</script>
