<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="6">
        <v-text-field outlined label="CSC" :readonly="component.justVisible" v-model="component.enterprise.csc">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="!component.justVisible" cols="6">
        <v-file-input
          v-model="component.enterprise.certified_path"
          :readonly="component.justVisible"
          color="deep-purple accent-4"
          counter
          label="Importar Certificado"
          multiple
          placeholder="Selecione o certificado"
          prepend-icon="mdi-paperclip"
          outlined
          :show-size="1000"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small>
              {{ text }}
            </v-chip>

            <span
              v-else-if="index === 2"
              class="text-overline grey--text text--darken-3 mx-2"
            >
              +{{ files.length - 2 }} File(s)
            </span>
          </template>
        </v-file-input>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined
          label="Senha do certificado"
          type="password"
          v-model="component.enterprise.certificate_password"
          :readonly="component.justVisible"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          outlined
          label="Chave do Token IBPT"
          v-model="component.enterprise.token_ibpt"
          :readonly="component.justVisible"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field outlined label="Token da nota" v-model="component.enterprise.token" :readonly="component.justVisible">
          
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    valid: false,
    files: [],
  }),
  methods: {},

  watch: {},
};
</script>
