<template>
  <v-row>
    <v-dialog
      persistent
      transition="scale-transition"
      max-width="400"
      v-model="this.component.dialogInfo"
    >
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row justify="center">
            <v-card-title>Informações do Produto</v-card-title>
          </v-row>
          <v-row justify="center">
            <div>
              <v-img max-height="200" max-width="200" :src="picture"> </v-img>
            </div>
          </v-row>
          <v-form class="mt-10">
            <v-row>
              <v-col>
                <v-text-field
                v-model="product.id"
                label="Codigo do produto"
                outlined
                class="ml-2 mr-2"
                readonly
              >
              </v-text-field>
              </v-col>
    
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                v-model="product.description_item"
                label="Descrição"
                outlined
                class="ml-2 mr-2"
                readonly
              >
              </v-text-field>
              </v-col>
       
            </v-row>

            <v-row>
             <v-col>
              <v-text-field
                v-model="product.unit.sigla"
                label="Unidade de medida"
                outlined
                class="ml-2 mr-2"
                readonly
              >
              </v-text-field>
             </v-col>
             <v-col>
              <v-text-field
                v-model="product.reference"
                label="Referência"
                outlined
                class="ml-2 mr-2"
                readonly
              >
              </v-text-field>
             </v-col>
       
    
            </v-row>
            <v-row>
              <v-text-field
                v-model="product.bar_code"
                label="Codigo de Barras"
                outlined
                class="ml-2 mr-2"
                readonly
              >
              </v-text-field>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  label="Preço de venda"
                  v-model="product.sale_price"
                  outlined
                  class="ml-2 mr-2"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Preço de venda promoção"
                  v-model="product.sale_price"
                  outlined
                  class="ml-2 mr-2"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="error mr-1"
              @click="
                () => {
                  disableDialog();
                }
              "
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    component: Object,
    itemProduct: Object,
    readonly: Boolean,
  },

  data: () => ({
    product: Object(),
  }),
  methods: {
    disableDialog() {
      this.component.dialogInfo = !this.component.dialogInfo;
    },

    mountedData(item) {
      if (item.id === undefined) {
        alert("Código de barra não encontrado!");
      } else {
        this.picture = `${this.component.url}${item.photo}`;
        this.product = { ...item };
      }
    },

  },
  created() {
    if (this.itemProduct.bar_code === undefined) {
      this.product = { ...this.empty };
    } else {
      this.mountedData(this.itemProduct);
    }
  },


};
</script>
