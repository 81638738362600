<template>
  <div>
    <v-divider></v-divider>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field
            v-model="component.contact.primaryEmail"
            hint="exemplo@mail.com"
            :rules="textRules"
            outlined
            label="E-mail"
            :readonly="component.justVisible"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field
            v-model="component.contact.secundaryEmail"
            :readonly="component.justVisible"
            hint="exemplo@mail.com"
            outlined
            label="E-mail Secundário"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="component.contact.cellPhone"
            :readonly="component.justVisible"
            label="Telefone Celular"
            :rules="textRules"
            v-mask="'(##) # ####-####'"
            hint="Exemplo: (12) 3 4567-8901"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            v-model="component.contact.phone"
            :readonly="component.justVisible"
            label="Telefone Residencial"
            v-mask="'(##) ####-####'"
            hint="Exemplo: (12) 3456-7890"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            v-model="component.contact.phoneReference"
            :readonly="component.justVisible"
            label="Telefone Referência"
            v-mask="'(##) # ####-####'"
            hint="Exemplo: (12) 9 9567-8901"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    component: Object(),
  },

  data: () => ({
    rules: {
      email: [
        (v) => !!v || "Campo Obrigatório",
        (v) => /\S+@\S+\.\S+/.test(v) || "Email inválido",
      ],
      phone: [(val) => (val || "").length > 0 || "Campo Obrigatório"],
    },
    formValidDataContact: Boolean(),
  }),
  methods: {},
};
</script>
