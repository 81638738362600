<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          outlined
          v-model="search"
          label="Pesquisar"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-text>
      <Table
        :component="this"
        :getters="getEnterprises"
        :destroy_object="destroy_object"
        :search="search"
        v-if="permission.Visualizar"
      />
      <AddDialog v-if="addDialog" title="Nova" :component="this" />
    </v-card>
    <AlertAPI />
  </Container>
</template>
<script>
import Container from "../components/Enterprise/Container";
import CardTitle from "../components/Enterprise/CardTitle";
import Table from "../components/Enterprise/Table";
import AlertAPI from "../components/alert_api";
import AddDialog from "../components/Enterprise/AddDialog";
import {
  DESTROY_ENTERPRISE,
  GET_ALL_ENTERPRISE,
} from "../store/actions/enterprise";

import { mapGetters } from "vuex";

export default {
  components: { Container, CardTitle, Table, AddDialog, AlertAPI },

  data: () => ({
    search: "",
    code: "",
    getter: "getEnterprises",
    addDialog: false,
    destroyDialog: false,
    idToDestroy: 0,
    objectToUpdate: {},
    permission: {},
    title: "Empresa",
  }),

  methods: {
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_ENTERPRISE, { page: page, limit: 10, paginate:true });
    },

    async change_addDialog() {
      this.addDialog = !this.addDialog;
    },

    async destroy_object(item) {
      const enterprise = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_ENTERPRISE, enterprise);
      } catch (error) {
        console.log(error.message, { ...error });
      }
    },
  },
  mounted() {
    let name = document.URL.split("/").pop()
    this.permission = this.getConfiguracoesLinks.getPermissions(name)
  },
  created() {
    this.GetAll();
  },
  computed: {
    ...mapGetters(["getEnterprises", "getConfiguracoesLinks"]),
  },
};
</script>

<style scoped></style>
