<template>
  <v-row>
    <v-dialog max-width="550" v-model="component.dialog" persistent>
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row>
            <v-card-title> Pesquisar Vendedores </v-card-title>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2 mb-6"
              @click="
                () => {
                  close();
                }
              "
              >mdi-close</v-icon
            >
          </v-row>
          <v-text-field
            outlined
            label="Pesquisar"
            v-model="search"
            append-icon="mdi-magnify"
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="results"
            hide-default-footer
            no-data-text="Sem registros"
            no-results-text="Nenhum vendedor encontrado"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, -1],
              'items-per-page-text': 'Items por página',
            }"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                color="green darken-3"
                class="ml-3"
                @click="
                  () => {
                    add_seller(item);
                  }
                "
                >mdi-plus-circle</v-icon
              >
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-pagination
            color="success"
            v-model="current"
            :length="total"
            :total-visible="10"
          ></v-pagination>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { headers } from "./script";
import { GET_ALL_SELLER } from "../../../../store/actions/seller";
import { mapGetters } from "vuex";
export default {
  props: { component: Object },
  data: () => ({
    search: String(),
    headers,
    results: Array(),
    total: Number(),
    current: Number(),
  }),
  methods: {
    close() {
      this.component.dialog = !this.component.dialog;
    },
    add_seller(item) {
      this.component.refreshSeller(item);
      this.close();
    },
    async getAllSeller(page = 1) {
      await this.$store.dispatch(GET_ALL_SELLER, { page: page, limit: 10 });
    },
  },
  watch: {
    getSeller: function (val) {
      this.results = val.data;
      this.current =val.current_page;
      this.total = val.total;
    },

    
  },
  computed: {
    ...mapGetters(["getSeller"]),
  },
  async created() {
    await this.getAllSeller();
  },
};
</script>

<style>
</style>