<template>
  <v-container>
   <v-divider></v-divider>
   <v-form ref="form" v-model="valid">
     <v-row>
       <v-col>
         <v-autocomplete
           :items="listBanks"
           item-text="label"
           label="Banco"
           v-model="component.banks.bank"
           outlined
         >
         </v-autocomplete>
       </v-col>
     </v-row>
     <v-row>
       <v-col cols="6">
         <v-text-field
           v-model="component.banks.count"
           label="Conta"
           outlined
         >
         </v-text-field>
       </v-col>
       <v-col cols="6">
         <v-text-field
           v-model="component.banks.agency"
           label="Agência"
           outlined
         >
         </v-text-field>
       </v-col>
     </v-row>
     <v-row>
      <v-col cols="6">
        <v-autocomplete
           v-model="component.banks.type_key_pix"
           label="Tipo de chave pix"
           :items="pixKey"
           outlined
         >
      </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-text-field
           v-model="component.banks.value_key_pix"
           label="Valor da chave pix"
           :items="pixKey"
           outlined
         >
      </v-text-field>
      </v-col>
     </v-row>
     <v-row>
       <v-col>
         <v-text-field
           v-model="component.banks.manager"
           label="Gerente"
           outlined
         >
         </v-text-field>
       </v-col>
     </v-row>
   </v-form>
  </v-container>
 </template>
 
 <script>
 import { listBanks } from "./listBank";
 export default {
   props: {
     component: Object(),
   },
   data: () => ({
     valid: Boolean(),
     listBanks,
     textRules: [(v) => !!v || "Campo obrigatório!"],
     pixKey: ["CPF ou CNPJ", "E-mail", "Número de telefone celular"],
   }),
   methods: {
     reset() {
       this.$refs.form.resetValidation();
     },
   },
 };
 </script>
 
 <style>
 </style>