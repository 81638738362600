import axios from "axios";
import {

  SPLICE_SUCESSO, SPLICE_ERRO
} from "./actions/alert_api";

const {
  GET_ALL_NCM,
  ADD_NCM,
  UPDATE_NCM,
  DESTROY_NCM,

} = require("./actions/ncm");

const moduleNcm = {
  state: () => ({
    ncms: Array(),
    newNcmToAdd: Object(),
  }),

  mutations: {
    GET_ALL_NCM: (state, ncms) => {
      state.ncms = ncms.data;
    },
    ADD_NCM: (state, newNcms) => {
      state.newNcmToAdd = newNcms
      // state.ncms.push(newNcms);
    },
    UPDATE_NCM(state, editNCM) {
      let result = state.ncms.data.find(
        (r) => r.id === editNCM.data.id
      );
      for (let key in editNCM.data) {
        result[key] = editNCM.data[key];
      }
    },

    DESTROY_NCM: (state, id) => {
      let auxState = [...state.ncms.data]
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      )
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.ncms.data = [...auxState];
    },
  },

  actions: {
    GET_ALL_NCM({
      commit
    }, {
      page
    }) {
      axios.post("ncm/index", {
        page
      }).then((response) => {
        commit(GET_ALL_NCM, response.data);
      });
    },

    ADD_NCM({
      commit
    }, payload) {
      axios.post("ncm/create", payload).then((response) => {
        commit(ADD_NCM, response.data.data);
        commit(SPLICE_SUCESSO, {
          alert: response.data.msg,
          type: 'S'
        })
      }).catch(r => {
        console.log(r)
        let erros = r.response.data.errors
        for (let erro in erros) {
          erros[erro].forEach(err => commit(SPLICE_ERRO, {
            alert: err,
            type: 'E'
          }))
        }
      });
    },

    UPDATE_NCM({
      commit
    }, payload) {
      axios.post("ncm/update", payload).then((response) => {
        commit(UPDATE_NCM, response.data);
        commit(SPLICE_ERRO, {
          alert: response.data.msg,
          type: 'S'
        })
      }).catch(r => {
        let erros = r.response.data.errors
        for (let erro in erros) {
          erros[erro].forEach(err => commit(SPLICE_ERRO, {
            alert: err,
            type: 'E'
          }))
        }
      });
    },

    DESTROY_NCM({
      commit
    }, payload) {
      axios
        .post("ncm/delete", payload.id,
      )
        .then((response) => {
          commit(DESTROY_NCM, payload.id);
          commit(SPLICE_ERRO, {
            alert: response.data.msg,
            type: 'S'
          })
        }).catch(r => {
          let erros = r.response.data.errors
          for (let erro in erros) {
            erros[erro].forEach(err => commit(SPLICE_ERRO, {
              alert: erro + ' : ' + err,
              type: 'E'
            }))
          }
        });
    },
  },

  getters: {
    getNewNcmToAdd: (state) => state.newNcmToAdd,
    getNcm: (state) => ({
      ...state.ncms
    }),
  },
};

export default moduleNcm;