<template>
  <div>
    <v-dialog persistent max-width="600px" v-model="getShowDialogRegisterGeneralDataSales">
      <v-card>
        <v-col>
          <v-row class="d-flex justify-center">
            <v-card-title>
              <v-icon class="mr-2">mdi-card-account-details</v-icon>Dados Gerais
            </v-card-title>
          </v-row>
          <v-row>
            <v-text-field append-icon="mdi-magnify" label="Vendedor" outlined v-model="component.seller.name"
              @blur="getByIdSeller(component.seller.name)" class="ma-2 mt-2" @click:append="() => {
                search_seller();
              }
                "></v-text-field>
          </v-row>
          <v-row>
            <v-text-field append-icon="mdi-magnify" append-outer-icon="mdi-account-plus" label="Cliente"
              @blur="getByIdClient(component.client.name)" v-model="component.client.name" outlined class="ma-2 mt-2"
              @click:append="() => {
                search_client();
              }
                " @click:append-outer="() => {
    add_client();
  }
    "></v-text-field>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-btn @click="closeGeneralDataSalesDialog" class="mr-5 mb-5 success">Ok</v-btn>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <TableSearchSeller :component="this" v-if="dialog" />
    <TableSearchClient :component="this" v-if="dialog_cl1" />
    <AddClient :component="this" />
    <RegisterClient :component="this" v-if="clientDialog" />
  </div>
</template>

<script>
import { GET_BY_ID } from "../../../../store/actions/client";
import { GET_BY_ID_SELLER } from "../../../../store/actions/seller";
import TableSearchSeller from "../../Tables/TableSearchSeller";
import TableSearchClient from "../../Tables/TableSearchClient";
import AddClient from "../../AddDialog/Add_Client";
import RegisterClient from "../../AddDialog/Register_Client";

import { GET_ALL_SELLER } from "../../../../store/actions/seller";
import { GET_ALL_CLIENT } from "../../../../store/actions/client";

import { mapGetters } from "vuex";

export default {
  props: {
    component: Object(),
  },
  components: { TableSearchSeller, TableSearchClient, AddClient, RegisterClient },

  data: () => ({
    dialog: false,
    dialog_cl1: false,
    dialog_cl2: false,
    client: Object(),
    seller: Object(),
    clientDialog: Boolean(),
  }),
  methods: {
    async getAllClient(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT, {
        page: page,
        limit: 10,
        type_people: "PhysicalPeople",
        paginate: true
      });
    },
    async getAllSeller(page = 1) {
      await this.$store.dispatch(GET_ALL_SELLER, { page: page, limit: 10 });
    },
    closeGeneralDataSalesDialog() {
      this.$store.dispatch("setShowDialogRegisterGeneralDataSales", false);
    },
    search_client() {
      this.dialog_cl1 = !this.dialog_cl1;
    },

    add_client() {
      // this.dialog_cl2 = !this.dialog_cl2;
      this.clientDialog = true
    },
    search_seller() {
      this.dialog = !this.dialog;
    },
    getByIdClient(client_id) {
      if(client_id.length > 0) {
        try {
          this.$store.dispatch(GET_BY_ID,
          {
            id: client_id,
            enterprise_id: this.getEnterprises.data[0].id
          });
        } catch (e) {
          console.error(e);
        }
      }
      },
    getByIdSeller(seller_id) {
    if(seller_id.length > 0) {
      try {
        this.$store.dispatch(GET_BY_ID_SELLER, {
          id: seller_id,
        });
      } catch (e) {
      }
    }
    },

    setClientSelect(val) {
      if (val.type_people === "PhysicalPeople") {
        this.component.client = { ...val.physicalpeople };

      } else {
        this.component.client = { ...val.legalpeople };
        this.component.client.name = val.legalpeople.socialName

      }
      this.component.client.id = val.id;
      this.component.clientNfceObject = val;
      this.component.client.enterprise_id = this.getEnterprises.data[0].id
      this.component.enterpriseNfceObject = this.getEnterprises.data[0];
    },

    refreshClient(val) {
      if (val.type_people === "PhysicalPeople") {
        this.component.client = { ...val.physicalpeople };
      } else {

        this.component.client = { ...val.legalpeople };
        this.component.client.name = val.legalpeople.socialName
      }
      this.component.client.id = val.id;
      this.component.clientNfceObject = val;
      this.component.enterpriseNfceObject = this.getEnterprises.data[0];
    },
    refreshSeller(val) {
        this.component.sellerNfceObject =  val
        this.component.seller = { ...val.physicalpeople };
        this.component.seller.id = val.id;
    },
    refreshSellerById(val) {
      if (Object.keys(val).length == 0) {
        this.component.seller.id = undefined;
      } else {
        this.component.sellerNfceObject =  val
        this.component.seller = { ...val.physicalpeople };
        this.component.seller.id = val.id;
      }

    },

    reFreshInMemory() {
      // Client check if was setted
      if (this.component.client.type_people) {
        if (this.component.client.type_people === "PhysicalPeople") {
          this.component.client = this.component.client.physicalpeople;
        } else {
          this.component.client = this.component.client.legalpeople;
          this.component.client.name = this.component.client.legalpeople.socialName
        }
      }
      // Seller check if was setted
      if (this.component.seller.type_people) {
        if (this.component.seller.type_people === "PhysicalPeople") {
          this.component.seller = { ...this.component.seller.physicalpeople };
        }
      }
    },
    selectAddedClient() {
      this.clientDialog = false
      this.refreshClient(this.getAddedClients)
    }
  },

  async created() {
    await this.getAllClient()
    await this.getAllSeller()
    // await this.reFreshInMemory();
  },
  computed: {
    ...mapGetters([
      "getResult",
      "getResultSeller",
      "getEnterprises",
      "getSeller",
      "getShowDialogRegisterGeneralDataSales",
      "getPhysicalPeople",
      "getAddedClients",
    ])
  },
  watch: {
    // getPhysicalPeople: function(val){
    //  this.refreshClient(val.data[0])
    // },
    // getSeller: function(val){
    //  this.refreshSeller(val.data[0])
    // },
    getResult: function (val) {
      this.refreshClient(val);
    },
    getResultSeller: function (val) {
      this.refreshSellerById(val);
    }
  }
};
</script>
