


//trantando cpf - cnpj

function cleanString(value){
    
    return  value.replace(/[^\d]+/g,'');
  }
  
  function cleanCNPJ(cnpj) {
      const newCNPJ = cnpj.replace(/[^0-9]/g, '')
      return newCNPJ
  }
  
  function cleanCPF(cnpj) {
      const newCNPJ = cnpj.replace(/[^0-9]/g, '')
      return newCNPJ
  }
  
  function setMaskCNPJ(cnpj) {
      if (cnpj.substr(2, 1) == ".") {
          return cnpj
      }
      const cnpjWithMask = cnpj.substr(0, 2) + "." + cnpj.substr(2, 3) + "." + cnpj.substr(5, 3) + "/" + cnpj.substr(8, 4) + "-" + cnpj.substr(12, 2)
      return cnpjWithMask
  }
  
  function fixingPeopleCNPJ(arrayPeople) {
      const newArray = Array()
      for (const element of arrayPeople) {
          element.legalpeople.cnpj = setMaskCNPJ(element.legalpeople.cnpj)
          newArray.push(element)
      }
      return newArray
  }
  
  function fixingCNPJ(arrayEnterprise) {
      const newArray = Array()
      for (const element of arrayEnterprise) {
          element.cnpj = setMaskCNPJ(element.cnpj)
          newArray.push(element)
      }
      return newArray
  }
  
  //cpf
  function setMaskCPF(cpf) {
  
      if (cpf.substr(3, 1) == ".") {
          return cpf
      }
      const cpfWithMask = cpf.substr(0, 3) + "." + cpf.substr(3, 3) + "." + cpf.substr(6, 3) + "-" + cpf.substr(9, 2)
      return cpfWithMask
  }
  function fixingPeopleCPF(arrayPeople) {
      const newArray = Array()
      if (arrayPeople) {
  
          for (const element of arrayPeople) {
  
              if (element.people) {
                  element.people.physicalpeople.cpf = setMaskCPF(element.people.physicalpeople.cpf)
                  newArray.push(element)
              } else {
                  element.legalpeople.cnpj = setMaskCNPJ(element.legalpeople.cnpj)
                  newArray.push(element)
              }
          }
          return newArray
      }
  
  }
  
  
  
  export {
      setMaskCPF,
      fixingPeopleCPF,
      fixingPeopleCNPJ,
      cleanCNPJ,
      setMaskCNPJ,
      fixingCNPJ,
      cleanCPF,
      cleanString
  }