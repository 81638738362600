<template>
  <div>
    <TabProduct :component="this"/>
    <TabGeneral :component="component" />
  </div>
</template>

<script>
import TabProduct from "../../Sales/TabDate/TabProduct/index.vue";
import TabGeneral from "../../Sales/TabDate/TabGeneral/index.vue";
import { GET_ALL_PRODUCT_BY_STATUS } from "../../../store/actions/product";

export default {
  props: {
    component: Object,
    disabled: Boolean,
  },

  components: {
    TabProduct,
    TabGeneral,
  },
  data: () => ({
    statusOpenCashier: Boolean(),
    product: Object(),
    tab: String(),
  }),
  async created() {
    // await this.$store.dispatch(GET_ALL_PRODUCT_BY_STATUS, { page: 0, limit: 10 });
  },
};
</script>
