<template>
  <v-row justify="center">
    <v-dialog
      transition="fab-transition"
      persistent
      v-model="component.dialog_bleed"
      max-width="400"
    >
      <v-card>
        <v-card-title> Sangria de Caixa </v-card-title>
        <v-divider></v-divider>
        <v-container>
          <v-data-table
            dense
            :headers="header"
            :items="items"
            hide-default-footer
            no-data-text="Sem registro"
          >
            <template v-slot:item.price>
              <v-text-field
                class="mt-3"
                dense
                filled
                outlined
                prefix="R$"
                placeholder="0,00"
              >
              </v-text-field>
            </template>
          </v-data-table>
          <v-divider></v-divider>

          <v-card-actions>
            <v-row justify="center">
              <v-btn
                @click="component.dialog_bleed = !component.dialog_bleed"
                class="error mr-1"
                width="150"
                >Cancelar</v-btn
              >
              <v-btn class="success" width="150">Confirmar</v-btn>
            </v-row>
          </v-card-actions>
          <v-card-subtitle>Pressione ESC para sair</v-card-subtitle>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    items: [{ FormOfPayment: "Credito" }, { FormOfPayment: "Débito" }],
    header: [
      { text: "Forma de Pagamento", value: "FormOfPayment" },
      { text: "Valor (R$)", value: "price" },
    ],
  }),
};
</script>

<style>
.title {
  font-family: "Courier New", Courier, monospace;
  color: #424242;
}
</style>