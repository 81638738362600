<template>
  <v-row>
    <v-card-title primary-title>{{ getTitle() }}</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        outlined
        dense
        return-object
        flat
        height="10px"
        label="Empresa"
        item-value="id"
        item-text="fantasyName"
        loader-height
        v-model="selectValue"
        :items="enterprises"
        >Adicionar</v-select
      >
    </v-col>
    <v-btn
      color="success mr-2 mt-3"
      v-if="component.permission.Cadastrar"
      @click="open_Dialog"
      >Adicionar</v-btn
    >
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";

export default {
  props: {
    title: String,
    component: Object,
  },

  data: () => ({
    selectValue: Object(),
    enterprises: [],
  }),

  created() {
    this.GetAllNoPageEnterprise();
  },

  methods: {
    async GetAllNoPageEnterprise() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
    getComponent() {
      return this._props.component;
    },
    getTitle() {
      return this.getComponent().title;
    },
    open_Dialog() {
      this.$store.dispatch("setShowDialogState", true);
    },
    alterAddDialog(component) {
      component.addDialog = !component.addDialog;
    },
  },

  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  watch: {
    getEnterprises(val) {
      this.enterprises = Object.values(val);
      this.selectValue = this.enterprises[0];
    },
    selectValue(val) {
      this.component.enterpriseId = val.id;
    },
  },
};
</script>

<style></style>
