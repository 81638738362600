<template>
  <v-row justify="center">
    <v-dialog v-model="component.dialog_supply" persistent max-width="350" transition="fab-transition">
      <v-card>
        <v-card-title>Suprimento</v-card-title>
        <v-card-subtitle>Informe o valor a ser adicionado ao caixa.</v-card-subtitle>
        <v-divider></v-divider>
        <v-container>
          <v-form>
            <v-col cols="12">
              <v-text-field outlined label="Valor de Adição" prefix="R$" placeholder="0.00"></v-text-field>
            </v-col>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row justify="center">
              <v-btn @click="closeDialog" class="error mr-1">Cancelar</v-btn>
              <v-btn class="success">Confirmar</v-btn>
            </v-row>
          </v-card-actions>
          <v-card-subtitle>Pressione ESC para sair</v-card-subtitle>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  methods: {
    closeDialog() {
      this.component.dialog_supply = !this.component.dialog_supply
    }
  }
};
</script>
