<template>
  <div>
    <DestroyDialog :component="this" :destroy_object="destroy_object" />
    <Edit
      v-if="addDialog"
      :component="this"
      title="Editar"
      :objectToUpdate="objectToUpdate"
    />
    <v-data-table
      :headers="headers"
      :items="results"
      no-data-text="Nenhum produto cadastrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              v-bind="attrs"
              color="yellow darken-4"
              v-if="!component.permission.Editar"
              v-on="on"
              @click="
                () => {
                  editItem(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              v-bind="attrs"
              color="red darken-4"
              v-on="on"
              v-if="!component.permission.Excluir"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
            <v-icon
              color="green darken-4"
              class="mr-2"
              v-if="component.permission.Excluir"
              @click="
                () => {
                  visibleItem(item);
                }
              "
              >mdi-eye</v-icon
            >
          </template>

          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <div>
      <v-pagination
        color="success"
        v-model="current"
        :length="total"
        :total-visible="10"
        :disabled="paginationDelay"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import DestroyDialog from "../DestroyDialog/destroyDialog";
import Edit from "../AddDialog/";

export default {
  components: { DestroyDialog, Edit },
  props: {
    search: String,
    getters: Object,
    component: Object,
    change_addDialog: Function,
  },
  data: () => ({
    paginationDelay: Boolean(),
    destroyDialog: Boolean(),
    destroy_object: Object(),
    addDialog: Boolean(),
    itemSelectedOnClick: Object(),
    objectToUpdate: Object(),
    results: Array(),
    total: Number(),
    current: Number(),
    currentItem: {},
    headers: [
      { text: "Codigo", value: "id" },
      { text: "Descrição", value: "description_item" },
      { text: "Referencia", value: "reference" },
      { text: "Unidade", value: "unit.description" },
      { text: "Ncm", value: "ncm.code" },
      { text: "Preço de Venda", value: "sale_price" },
      { text: "Ações", value: "actions" },
    ],
    page: 1,
  }),
  methods: {
    visibleItem(item) {
      this.objectToUpdate = { ...item, justVisible: true };
      this.addDialog = !this.addDialog;
    },
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
    created() {
      this.component.GetAll();
    },

    editItem(item) {
      this.objectToUpdate = { ...item, justVisible: false };
      this.addDialog = !this.addDialog;
    },

    deleteItem(item) {
      this.destroy_object = { ...item };
      this.destroyDialog = !this.destroyDialog;
    },
  },
  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
      this.paginationDelay = false
    },
    current: function (val) {
      this.paginationDelay = true
      this.component.GetAll && this.component.GetAll(val);
    },
  },
};
</script>
