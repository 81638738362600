const ADD_CST = "ADD_CST";
const UPDATE_CST = "UPDATE_CST";
const GET_ALL_CST = "GET_ALL_CST";
const DESTROY_CST = "DESTROY_CST";

module.exports = {
    ADD_CST,
    UPDATE_CST,
    GET_ALL_CST,
    DESTROY_CST,
}
