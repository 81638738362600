<template>
  <div class="errors">
    <div>
      <v-alert
        :color="err.type === 'E' ? 'red' : 'green'"
        :type="err.type === 'E' ? 'error' : 'success'"
        v-for="(err, i) in getFeeds"
        :key="i"
      >
        {{ err.alert }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getFeeds"]),
  },
};
</script>

<style>
.errors {
  margin: 10px;
  left: 0;
  position: absolute;
  z-index: 5;
  top: 0;
  width: 100%;
}
</style>