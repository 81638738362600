<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="filter"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-text>
      <Table
        :component="this"
        v-if="permission.Visualizar"
        :getters="getProductGroup"
        :destroy_productGroup="destroy_productGroup"
        :search="search"
        :headers="headers"
        :open_editDialog="open_editDialog"
      />
      <ModalProductGroup  :component="this" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/productgroup/CardTitle";
import Table from "../components/productgroup/Table";
import ModalProductGroup from "../components/productgroup/AddDialog/";
import AlertApi from "../components/alert_api/";
import loadScreen from "../components/load/";

import { mapGetters } from "vuex";
import { CHANGE_LOAD } from "../store/actions/load";
import { GET_FILTER_PRODUCT_GROUP } from "../store/actions/productgroup";
import {
  GET_ALL_PRODUCT_GROUP,
  DESTROY_PRODUCT_GROUP,
} from "../store/actions/productgroup";

export default {
  data: () => ({
    search: "",
    code: "",
    getter: "getProductGroup",
    addDialog: false,
    destroyDialog: false,
    enterpriseId: "",
    enterprises: [],
    filter: "",
    selectValue: Object(),
    idToDestroy: 0,
    objectToUpdate: {},
    title: "Grupo de Produto",
    permission: Object(),
    headers: [
      { text: "Codigo", value: "code" },
      { text: "Descrição", value: "name" },
      { text: "Ações", value: "actions" },
    ],
  }),

  methods: {
    async destroy_productGroup(item) {
      const cst = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_PRODUCT_GROUP, cst);
      } catch (error) {
        alert(error);
      }
    },
    open_editDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
    },
    async GetAll(page = 1) {
      this.$store.commit(CHANGE_LOAD, true);
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_PRODUCT_GROUP, value);
    },

    async productgroup_filter(page = 1) {
      if (!this.filter === true) {
        this.GetAll();
      } else {
        let value = {
          search: this.filter,
          page: page,
          limit: 10,
          paginate: true,
        };
        await this.$store.dispatch(GET_FILTER_PRODUCT_GROUP, value);
      }
    },
  },

  async created() {
    await this.GetAll();
  },

  components: {
    Container,
    Table,
    ModalProductGroup,
    AlertApi,
    loadScreen,
    CardTitle,
  },

  computed: {
    ...mapGetters(["getProductGroup"]),
    ...mapGetters(["getCadastrosLinks"]),
  },

  mounted() {
    // usando js para pegar o nome da rota, vai funcionar para todas rotas menos a rota /
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
    // usar getter especifico para comparar as rotas, getPermission deve receber ultimo parametro da rota
    // exemplo se a rota é http://localhost:8080/Aliquot voce deve passar para ele somente o Aliquot
  },

  watch: {
    filter: function () {
      this.productgroup_filter();
    },
    
  },
};
</script>

<style scoped></style>
