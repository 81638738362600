/* eslint-disable no-unused-vars */
module.exports = () => {
    window.debug = e => console.log(JSON.parse(JSON.stringify(e)))
    window.debug = (e, ...i) => console.log(JSON.parse(JSON.stringify(e)), [...i])
    Array.prototype.Update = function (editNoteModel) {
        let result = this.find(r => r.id === editNoteModel.id);

        for (let key in editNoteModel) {
            result[key] = editNoteModel[key]
        }
    }

    Array.prototype.getPermissions = function (name) {
        let currentRoute = this.find(
            (r) => r.route.split("/").pop() === name
        );

        let permission = {
            "Editar": Boolean(),
            "Visualizar": Boolean(),
            "Excluir": Boolean(),
            "Cadastrar": Boolean(),
        }
        for (const key of currentRoute.permission_col) {
            permission[key] = true;
        }
        return permission
    }
    Date.prototype.dateDDMMYYYH = function () {
        let hour = this.getHours()
        let minutes = this.getMinutes()
        let seconds = this.getSeconds() < 10 ? `0${(this.getSeconds())}` : (this.getSeconds())
        let day = this.getDate() < 10 ? `0${(this.getDate())}` : (this.getDate())
        let month = (this.getMonth() + 1) < 10 ? `0${(this.getMonth() + 1)}` : (this.getMonth() + 1)
        let year = this.getFullYear()



        return `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`
    }
    String.prototype.dateDDMMYYY = function () {
        var data = new Date(this);
        let dia = (data.getDate() + 1).toString();
        let diaF = dia.length == 1 ? "0" + dia : dia;
        let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
        let mesF = mes.length == 1 ? "0" + mes : mes;
        let anoF = data.getFullYear();
        return diaF + "/" + mesF + "/" + anoF;
    }
    String.prototype.dateYYMMDD = function () {
        if (this.includes('/')) {
            let dateParts = this.split("/");
            let date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            let day = date.getDate() < 9 ? '0' + date.getDate() : date.getDate()
            let month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
            let fullYear = date.getFullYear()

            return `${fullYear}-${month}-${day}`
        } else {
            let date = new Date(parseInt(this));
            let day = date.getDate() < 9 ? '0' + date.getDate() + 1 : date.getDate() + 1
            let month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
            let fullYear = date.getFullYear()

            return `${fullYear}-${month}-${day}`
        }
    }


    Number.prototype.wait = function (v) {
        let t = this
        return new Promise(function (resolve) {
            setTimeout(resolve.bind(null, v), t)
        });
    }

    Number.prototype.numberToMoney = function () {
        var formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', });

        return formatter.format(this)
    }
}