<template>
  <div>
    <div>
      <Edit
        v-if="addDialog"
        :dialog="addDialog"
        title="Editar"
        :component="this"
        :actionDialog="close"
        isUpdate="true"
      />
      <DestryDialog
        :component="this"
        :destroy_object="destroy_object"
        title="Usuário"
      />
      <ChangePass v-if="dialogPass" :component="this" />
    </div>

    <v-data-table
      :headers="headers"
      :items="results"
      :search="search"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              v-if="component.permission.Editar"
              @click="edit_item(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar Usuário</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="blue"
              @click="change_password(item)"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              >mdi-lock</v-icon
            >
          </template>
          <span>Alterar Senha</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-if="component.permission.Excluir"
              v-bind="attrs"
              v-on="on"
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar Usuário</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
  </div>
</template>

<script>
import DestryDialog from "./../DestroyDialog/";
import { headers } from "./script";
import { DESTROY_USER, UPDATE_USER } from "../../../store/actions/user";
import Edit from "../AddDialog";
import ChangePass from "./ChangePassword/";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    search: String,
    change_addDialog: Function,
  },
  components: { DestryDialog, Edit, ChangePass },
  data: () => ({
    headers,
    changePassword: false,
    destroyDialog: false,
    user: Object(),
    addDialog: Boolean(),
    currentItem: Object(),
    confirm_password: String(),
    results: Array(),
    total: Number(),
    current: Number(),
    dialogPass: Boolean(),

    itemSelectedOnClick: Object(),
  }),
  computed: {
    ...mapGetters(["getUsers"]),
  },
  methods: {
    deleteItem(item) {
      this.destroyDialog = true;
      this.currentItem = item;
    },
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
    async confirm(cashier) {
      delete cashier.cashier;

      if (cashier.name === null || cashier.name === undefined) {
        this.user.operator_cashier = null;
      } else {
        this.user.operator_cashier = { ...cashier };
      }

      await this.$store.dispatch(UPDATE_USER, this.user).then(() => {
        this.addDialog = false;
        this.dialogPass = false;
      });
    },
    close() {
      this.user = {};
      this.user.roles = [];
      this.addDialog = !this.addDialog;
    },
    edit_item(item) {
      this.changePassword = false;
      this.user = { ...item };
      this.addDialog = !this.addDialog;
    },
    async destroy_object(params) {
      await this.$store.dispatch(DESTROY_USER, params);
    },
    change_password(item) {
      this.user = { ...item };
      this.user.password = "";
      this.user_confirm_pass = "";
      this.changePassword = true;
      this.confirm_password = "";
      this.applyUser(item);
      this.dialogPass = !this.dialogPass;
    },
    applyUser(user) {
      for (const key in user) {
        this.user[key] = user[key];
      }
    },
  },
  watch: {
    getUsers: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      this.component.getAll(val);
    },
  },
};
</script>
