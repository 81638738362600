
async function calculeTributeCstPisCofins(product, aliquot_pis, aliquot_cofins) {
   try {
    
        product = await classificationCstPis(product, aliquot_pis)
        product = await classificationCstCofins(product,aliquot_cofins)
        return product
    } catch (error) {
        return error
    }
}
async function classificationCstPis(product, aliquot_pis) {

    let cst_pis_code_found = parseInt(product.codeSituationTaxablePis) // parseInt(product['cstpis']['code'])

    if (cst_pis_code_found >= 1 & cst_pis_code_found <= 9) {
        return _normal_Pis_Calculation(product, aliquot_pis)
    }
    else if (cst_pis_code_found == 49) {
        return _Calculation_Pis_Base49(product, aliquot_pis)

    } else if (cst_pis_code_found >= 50 & cst_pis_code_found <= 75){
        return _normal_Pis_Calculation(product, aliquot_pis)

    } else if (cst_pis_code_found >= 98 & cst_pis_code_found <= 99){
        return _others_Calculation_Pis(product, aliquot_pis)
    }
    

    
}

async function classificationCstCofins(product, aliquot_cofins) {
    let cst_cofins_code_found =parseInt(product.codeSituationTaxableCofins)

    if (cst_cofins_code_found >= 1 & cst_cofins_code_found <= 9) {
        return _normal_Cofins_Calculation(product, aliquot_cofins)
    }else if (cst_cofins_code_found == 49) {
        return _Calculation_Base49Cofins(product, aliquot_cofins)
    }else if (cst_cofins_code_found >= 50 & cst_cofins_code_found <= 75) {
        return _normal_Cofins_Calculation(product, aliquot_cofins)
    }
    else if (cst_cofins_code_found >= 98 || cst_cofins_code_found <= 99) {
        return _others_Calculation_Cofins(product, aliquot_cofins)
    }

}


async function _normal_Pis_Calculation(product, aliquot_pis) {

    product.aliquot_pis = aliquot_pis
    if(product.aliquot_pis == undefined || product.aliquot_pis  == null ){
        product.aliquot_pis = 0 
    } 
    product.value_base_pis_product = product.amount * product.value_sale
    let valuePis = product.value_base_pis_product * (aliquot_pis / 100)
    product.value_pis_product = valuePis.toFixed(2)
    product.value_base_pis_st_product = 0
    product.value_pis_st_product = 0
    product.value_pis_st_product = 0
    product.value_other_pis_product = 0
    product.value_free_pis_product = 0

    return product
}

async function _others_Calculation_Pis(product, aliquot_pis) {

  
    product.aliquot_pis = aliquot_pis
    if(product.aliquot_pis == undefined || product.aliquot_pis  == null){
        product.aliquot_pis = 0 
    } 
    product.value_base_pis_product = 0
    product.value_pis_product = 0
    product.value_base_pis_st_product = 0
    product.value_pis_st_product = 0
    product.value_pis_st_product = 0
    product.value_other_pis_product = product.amount * product.value_sale
    product.value_free_pis_product = 0
    return product
}




async function _normal_Cofins_Calculation(product, aliquot_cofins) {

    product.aliquot_cofins  = aliquot_cofins
    if(product.aliquot_cofins == undefined || product.aliquot_cofins == null){
        product.aliquot_pis = 0 
    } 
    product.value_base_cofins_product =  product.amount * product.value_sale
    let valueCofins =  product.value_base_cofins_product * (aliquot_cofins / 100)
    product.value_cofins_product = valueCofins.toFixed(2)
    product.value_base_cofins_st_product = 0
    product.value_cofins_st_product = 0
    product.value_other_cofins_product = 0
    product.value_free_cofins_product = 0

    return product


}
async function _others_Calculation_Cofins(product, aliquot_cofins) {

    if(product.aliquot_cofins == undefined || product.aliquot_cofins == null ){
        product.aliquot_pis = 0 
    } 
    product.aliquot_cofins  = aliquot_cofins
    product.value_base_cofins_product = 0
    product.value_cofins_product = 0
    product.value_base_cofins_st_product = 0
    product.value_cofins_st_product = 0
    product.value_other_cofins_product = product.amount * product.value_sale
    product.value_free_cofins_product  = 0

    return product
}

async function _Calculation_Pis_Base49(product, aliquot_pis) {
  
    product.aliquot_pis = aliquot_pis
    if(product.aliquot_pis == undefined || product.aliquot_pis  == null){
        product.aliquot_pis = 0 
    } 
    product.value_base_pis_product = product.amount * product.value_sale
    product.value_pis_product = 0
    product.value_base_pis_st_product = 0
    product.value_pis_st_product = 0
    product.value_pis_st_product = 0
    product.value_other_pis_product = 0
    product.value_free_pis_product = 0
    return product
}


async function _Calculation_Base49Cofins(product, aliquot_cofins) {
    if(product.aliquot_cofins == undefined || product.aliquot_cofins == null ){
        product.aliquot_pis = 0 
    } 
    product.aliquot_cofins  = aliquot_cofins
    product.value_base_cofins_product = product.amount * product.value_sale
    product.value_cofins_product = 0
    product.value_base_cofins_st_product = 0
    product.value_cofins_st_product = 0
    product.value_other_cofins_product = 0
    product.value_free_cofins_product  = 0
    return product
}



module.exports = {
    calculeTributeCstPisCofins
}
