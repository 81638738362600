var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DestroyDialog',{attrs:{"component":this,"destroy_object":_vm.destroy_object}}),(_vm.addDialog)?_c('Edit',{attrs:{"component":this,"title":"Editar","objectToUpdate":_vm.objectToUpdate}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results,"no-data-text":"Nenhum produto cadastrado","no-results-text":"Nenhum resultado encontrado","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(!_vm.component.permission.Editar)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"yellow darken-4"},on:{"click":() => {
                _vm.editItem(item);
              }}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar esse Item")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(!_vm.component.permission.Excluir)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red darken-4"},on:{"click":() => {
                _vm.deleteItem(item);
              }}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")]):_vm._e(),(_vm.component.permission.Excluir)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green darken-4"},on:{"click":() => {
                _vm.visibleItem(item);
              }}},[_vm._v("mdi-eye")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Deletar esse Item")])])]}}])}),_c('v-divider'),_c('div',[_c('v-pagination',{attrs:{"color":"success","length":_vm.total,"total-visible":10,"disabled":_vm.paginationDelay},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }