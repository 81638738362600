import axios from "axios";
const {
  GET_ALL_ALIQUOT,
  ADD_ALIQUOT,
  UPDATE_ALIQUOT,
  DESTROY_ALIQUOT,
} = require("./actions/aliquot");
const { SPLICE_ERRO, SPLICE_SUCESSO } = require("./actions/alert_api");

const moduleAliquot = {
  state: () => ({
    aliquots: Object(),
    newAliquotToAdd: Object()
  }),

  mutations: {
    GET_ALL_ALIQUOT: (state, aliquots) => {
      state.aliquots = aliquots.data;
    },
    ADD_ALIQUOT: (state, newAliquots) => {
      state.newAliquotToAdd = newAliquots
      // state.aliquots.data.push(newAliquots.data);
    },
    UPDATE_ALIQUOT(state, editAliquot) {
      let result = state.aliquots.data.find(
        (r) => r.id === editAliquot.data.id
      );
      for (let key in editAliquot.data) {
        result[key] = editAliquot.data[key];
      }
    },

    DESTROY_ALIQUOT: (state, id) => {
      let auxState = [...state.aliquots.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.aliquots.data = [...auxState];
    },
  },
  actions: {
    GET_ALL_ALIQUOT({ commit }, payload) {
      axios
        .post("aliquot/index", {
          page: payload.page,
        })
        .then((response) => {
          commit(GET_ALL_ALIQUOT, response.data);
        });
    },

    ADD_ALIQUOT({ commit }, payload) {
      axios
        .post("aliquot/create", payload)
        .then((response) => {
          commit(ADD_ALIQUOT, response.data.data);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    async UPDATE_ALIQUOT({ commit }, payload) {
      try {
        let response = await axios.post("aliquot/update", payload);
        commit(UPDATE_ALIQUOT, response.data[0]);
        commit(SPLICE_ERRO, {
          alert: "Cadastrado com Sucesso!",
        });
      } catch (r) {
        let erros = r.response.data.errors;
        for (let erro in erros) {
          erros[erro].forEach((err) =>
            commit(SPLICE_ERRO, {
              alert: err,
              type: "E",
            })
          );
        }
      }
    },

    async DESTROY_ALIQUOT({ commit }, payload) {
      try {
        const response = await axios.post("aliquot/delete", {
          ...payload,
        });
        commit(DESTROY_ALIQUOT, payload.id);
        commit(SPLICE_ERRO, {
          alert: response.data.sucess.message[0],
        });
      } catch (r) {
        let erros = r.response.data.errors;
        for (let erro in erros) {
          erros[erro].forEach((err) =>
            commit(SPLICE_ERRO, {
              alert: err,
              type: "E",
            })
          );
        }
      }
    },
  },

  getters: {
    getNewAliquotToAdd: (state) => state.newAliquotToAdd,
    getAliquot: (state) => ({
      ...state.aliquots,
    }),
  },
};

export default moduleAliquot;
