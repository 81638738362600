<template>
  <v-row>
    <v-dialog
      persistent
      transition="scale-transition"
      max-width="350"
      v-model="component.dialogPermissionPrint"
    >
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row justify="center">
            <v-card-title class="title">Deseja Visualizar Nfce?</v-card-title>
          </v-row>
          <v-row justify="center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                style="min-width: 100px !important;"
                class="red darken-4 white--text"
                @click="
                  () => {
                    close()
                  }
                "
              >
                Não
              </v-btn>
              <v-btn
                style="min-width: 100px !important;"
                class="success"
                @click="
                  () => {
                    printNfce()
                  }
                "
              >
                Sim
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object,
  },
  components: {},

  methods: {
    printNfce() {
        window.open(this.component.nfcePrint);
        this.component.saleSuccess = false
        this.component.close()
    },

    close() {
      this.component.saleSuccess = false
      this.component.close()
    }
  },
 
};
</script>
<style>
.title {
  font-size: 25px;
  font-weight: bold;
  color: gray;
}
</style>