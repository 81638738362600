<template>
  <div>
    <NavListItem />
    <NavListCadastro />
    <NavListSales/>
    <NavListReports/>
    <NavListSecurity/>
  </div>
</template>

<script>
import NavListCadastro from "./ListItemCadastros"
import NavListSecurity from "./ListItemConfig"
import NavListSales from "./ListItemSales"
import NavListReports from "./ListItemReports"
import NavListItem from "./NavListItem/"
export default {
  components: {
    NavListCadastro,
    NavListItem,
    NavListSales,
    NavListReports,
    NavListSecurity,
  }
};
</script>

<style>
.navDrop {
  padding-left: 25px !important;
}
</style>