const ADD_SELLER = "ADD_SELLER";
const GET_ALL_SELLER_OFFLINE = "GET_ALL_SELLER_OFFLINE";
const GET_ALL_SELLER_ONLINE = "GET_ALL_SELLER_ONLINE";
const UPDATE_SELLER = "UPDATE_SELLER";
const GET_ALL_SELLER = "GET_ALL_SELLER";
const DESTROY_SELLER = "DESTROY_SELLER";
const GET_BY_ID_SELLER = "GET_BY_ID_SELLER"
const GET_BY_ID_NOT_IDENTIFY_SELLER  = "GET_BY_ID_NOT_IDENTIFY_SELLER";
const AUTOCOMPLETE_SELLER  = "AUTOCOMPLETE_SELLER";

module.exports = {
    ADD_SELLER,
    GET_ALL_SELLER_OFFLINE,
    GET_ALL_SELLER_ONLINE, 
    UPDATE_SELLER,
    GET_ALL_SELLER,
    DESTROY_SELLER,
    GET_BY_ID_SELLER,
    GET_BY_ID_NOT_IDENTIFY_SELLER,
    AUTOCOMPLETE_SELLER
}
