<template>
  <v-row>
    <v-dialog
      v-model="component.addDialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn
            icon
            dark
            @click="
              () => {
                closeAddDialog();
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }} Produto</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container>
          <v-divider></v-divider>

          <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
                <v-tab
                  v-for="(item, index) in newArrayItems"
                  :key="index"
                  :href="'#tab-' + item.href"
                >
                  {{ item.tabName }}
                </v-tab>

                <v-menu v-if="$vuetify.breakpoint.xsOnly" bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      class="align-self-center mr-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      more
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>

                  <v-list class="grey lighten-3">
                    <v-list-item
                      v-for="(item, index) in more"
                      :key="index"
                      @click="addItem(item)"
                    >
                      {{ item.tabName }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-tabs>

              <v-tabs-items v-model="currentItem">
                <v-tab-item value="tab-1">
                  <v-col>
                    <GenetalDate :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <v-col>
                    <Cost :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-3">
                  <v-col>
                    <TaxIcms :component="this"  />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-4">
                  <v-col>
                    <TaxPis :component="this"/>
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-5">
                 <v-col>
                  <TaxCofins :component="this" />
                 </v-col>
                </v-tab-item>
                <v-tab-item value="tab-6">
                 <v-col>
                  <TaxIpi :component="this" />
                 </v-col>
                </v-tab-item>
              </v-tabs-items>
              
          <!-- <v-tabs>
            <v-tab href="#tab1">
              <v-icon class="mr-1">mdi-account-details</v-icon> Dados Gerais
            </v-tab>
            <v-tab href="#tab2">
              <v-icon class="mr-1">mdi-cash</v-icon> Valores
            </v-tab>
            <v-tab href="#tab3">
              <v-icon class="mr-1">mdi-calculator</v-icon>ICMS</v-tab
            >
            <v-tab href="#tab4">
              <v-icon class="mr-1">mdi-alpha-p-box</v-icon>Pis</v-tab
            >
            <v-tab href="#tab5">
              <v-icon class="mr-1">mdi-alpha-p-box</v-icon>Cofins</v-tab
            >
            <v-tab href="#tab6">
              <v-icon class="mr-1">mdi-domain</v-icon>IPI</v-tab
            >

            <v-tab-item value="tab1">
              <v-card flat>
                <GenetalDate :component="this" />
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab2" :eager="render">
              <v-card flat>
                <Cost :component="this" ref="cost" />
              </v-card>
            </v-tab-item>

            <v-tab-item :eager="true" value="tab3">
              <v-card flat><TaxIcms :component="this" ref="taxIcms" /></v-card>
            </v-tab-item>

            <v-tab-item :eager="true" value="tab4">
              <v-card flat><TaxPis :component="this" ref="taxPis" /></v-card>
            </v-tab-item>
            <v-tab-item :eager="true" value="tab5">
              <v-card flat
                ><TaxCofins :component="this" ref="taxCofins"
              /></v-card>
            </v-tab-item>
            <v-tab-item :eager="true" value="tab6">
              <v-card flat><TaxIpi :component="this" ref="taxIpi" /></v-card>
            </v-tab-item>
          </v-tabs> -->
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error" @click="clear()">
              <v-icon class="mr-2">mdi-cancel</v-icon> Limpar Formulário
            </v-btn>
            <v-btn
            v-if="!justVisible"
              class="success"
              :disabled="!valid"
              @click="
                () => {
                  submit();
                }
              "
            >
              <v-icon class="mr-2">mdi-content-save</v-icon>Salvar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
      <AlertAPI />
    </v-dialog>
  </v-row>
</template>

<script>
import GenetalDate from "../Tabs/genatedate";
import Cost from "../Tabs/cost";
import TaxIcms from "../Tabs/TaxIcms";
import TaxPis from "../Tabs/TaxPis";
import TaxCofins from "../Tabs/TaxCofins";
import TaxIpi from "../Tabs/TaxIpi/";
import { ADD_PRODUCT, UPDATE_PRODUCT } from "../../../store/actions/product";
import AlertAPI from "../../alert_api/";

export default {
  components: {
    GenetalDate,
    Cost,
    AlertAPI,
    TaxIcms,
    TaxPis,
    TaxCofins,
    TaxIpi,
  },

  props: {
    component: Object,
    title: String,
    objectToUpdate: Object,
  },

  data: () => ({
            
    newArrayItems: Array(),
  currentItem: "tab-1",
      items: [
        {
          tabName: "Dados Gerais",
          href: "1",
        },
      ],
      more: [
        {
          tabName: "Valores",
          href: "2",
        },
        {
          tabName: "ICMS",
          href: "3",
        },
        {
          tabName: "Pis",
          href: "4",
        },
        {
          tabName: "Cofins",
          href: "5",
        },
        {
          tabName: "IPI",
          href: "5",
        },
      ],

    textRules: (v) => !!v || "Campo obrigatório!",
    max: (v, p = 20) => (v && v >= p) || `Não pode ser maior que ${p}`,
    min: (v, p = 20) => (v && v <= p) || `Não pode ser menor que ${p}`,
    valid: Boolean(),
    justVisible: Boolean(),
    validGenerate: Boolean(),
    validCost: Boolean(),
    validTaxation: Boolean(),

    render: Boolean(),
    product: {
      status: String(),
      description_item: String(),
      description_pdv: String(),
      manufacturer_code: String(),
      bar_code: String(),
      master_box: String(),
      reference: String(),
      use_type: null,
      date_last_buy: String(),
      date_last_register: String(),
      user_change: String(),
      photo: null,
      management_cost: Number(),
      mechandise_cost: Number(),
      avarenge_cost: Number(),
      note_price: Number(),
      cost_price: Number(),
      accounting_price: Number(),
      sale_price: Number(),
      promotion_price: Number(),
      initial_promotion_date: String(),
      final_promotion_date: String(),
      minimum_stock: Number(),
      max_stock: Number(),
      stock_turning: Number(),
      ipi: Number(),
      product_group_id: Number(),
      sub_group_product_id: Number(),
      cfop_id: Number(),
      cst_pis_id: Number(),
      cst_cofins_id: Number(),
      ncm_id: Number(),
      cst_id: Number(),
      csosn_id: Number(),
      aliquot_id: Number(),
      product_type: String(),
      unit_id: Number(),
      cst_ipi_id: Number(),
      cnpj_producer: String(),
      aliquot_fcp: Number(),
      ipi_calculation_form: "Porcentagem",
      code_stamp: String(),
      amount_stamp: String(),
      enterprise_id: Number(),
      framing_class_cest: String(),
      tax_bemefot_code_uf: Number(),
      tax_replacement_base_modality: "0",
      nfci: Number(),
      percentage_calc_base_reduction: "0",
      percentage_supported_final_consumer: "0",
      source_product: Number(),
      calculation_base_mode: String(),
      reason_exemption_icms: "3",
      percentage_deferral: Number(),
      additional_value_margin: Number(),
      percentagem_calc_st_reduction: Number(),
      additional_aliquot_tax_st: Number(),
      percentage_fcp_tax: Number(),
      percentage_aliquot_tax_st: Number(),
    },
  }),

  methods: {
    setArrayItens() {
      this.newArrayItems = Array();
      if (this.$vuetify.breakpoint.xsOnly) {
        this.newArrayItems = this.items;
      } else {
        this.newArrayItems = this.items.concat(this.more);
      }
    },
    addItem(item) {
      const removed = this.items.splice(0, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item.href;
      });
    },
    closeAddDialog() {
      this.component.addDialog = !this.component.addDialog;
    },

    clear() {
      for (let i in this.product) {
        this.product[i] = String();
      }
    },

    submit() {
      this.product.id == undefined
        ? this.create_products()
        : this.update_products();
    },

    dataFormat() {
      var data = new Date(),
        dia = data.getDate().toString(),
        diaF = dia.length == 1 ? "0" + dia : dia,
        mes = (data.getMonth() + 1).toString(),
        mesF = mes.length == 1 ? "0" + mes : mes,
        anoF = data.getFullYear();
      // return diaF+"/"+mesF+"/"+anoF;
      return anoF + "/" + mesF + "/" + diaF;
    },

    async create_products() {
      let product = { ...this.product };
      product.date_last_buy = this.dataFormat();
      product.date_last_register = this.dataFormat();
      product.final_promotion_date = this.dataFormat();
      product.initial_promotion_date = this.dataFormat();
      product.enterprise_id = this.component.enterpriseId;
      await this.$store.dispatch(ADD_PRODUCT, product).then(() => {
        this.clear();
        this.component.addDialog = false;
      });
    },
    async update_products() {
      let product = { ...this.product };
      await this.$store.dispatch(UPDATE_PRODUCT, product).then(() => {
        this.component.addDialog = false;
      });
    },
    validator() {
      if (this.validGenerate === true) {
        if (this.validCost === true) {
          if (this.validTaxation === true) {
            this.valid = !this.valid;
          }
        }
      }
    },
  },
  watch: {
    "$vuetify.breakpoint.xsOnly": function(){
      this.setArrayItens()
    },
    validGenerate: function () {
      this.validator();
    },
    validCost: function () {
      this.validator();
    },
    validTaxation: function () {
      this.validator();
    },
  },

  created() {
    this.setArrayItens()
    if (this.objectToUpdate !== undefined) {
      console.log("ObjectToUpdate",this.objectToUpdate.justVisible)
      this.justVisible = this.objectToUpdate.justVisible
      this.product = { ...this.objectToUpdate };
    }
  },
  mounted() {
    this.render = !this.render;
  },
};
</script>
