import { links as cadastros } from "../components/Navbar/NavList/ListItemCadastros/script.js";
import { links as configuracoes } from "../components/Navbar/NavList/ListItemConfig/script.js";
import { links as sales } from "../components/Navbar/NavList/ListItemSales/script.js"
const module_links = {
    state: () => ({
        cadastros,
        cadastrosSize: Number(),
        sales,
        salesSize: Number(),
        configuracoes,
        configuracoesSize: Number(),

    }),

    mutations: {
        SET_CADASTROS(state, payload) {
            let id = payload.index;

            state.cadastros[id].active = true;
            state.cadastros[id].permission_col = payload.perm.split(",");
        },
        SET_MOVIMENTACAO(state, payload) {
            let id = payload.index;

            state.sales[id].active = true;
            state.sales[id].permission_col = payload.perm.split(",");
        },
        SET_SALES(state, payload) {
            let id = payload.index;
            console.log(id, 'id');
            state.sales[id].active = true;
            state.sales[id].permission_col = payload.perm.split(",");
        },
        CHECK_CADASTROS(state) {
            state.cadastrosSize = state.cadastros.filter(
                (val) => val.active == true
            ).length;
        },
        SET_CONFIGURACOES(state, payload) {
            let id = payload.index;
            state.configuracoes[id].active = true;
            state.configuracoes[id].permission_col = payload.perm.split(",");
        },
        CHECK_CONFIGURACOES(state) {
            state.configuracoesSize = state.configuracoes.filter(
                (val) => val.active == true
            ).length;
        },
        CLEAR_STATE_ALL(state) {
            state
        }
    },

    actions: {
        CLEAR_STATE_ALL({ commit }) {
            commit("CLEAR_STATE_ALL", null)
        }
    },

    getters: {
        getCadastrosLinks: (state) => ([...state.cadastros]),
        getCadastrosLinksSize: (state) => state.cadastrosSize,
        getSalesLinks: (state) => ([...state.sales]),
        getSalesLinksSize: (state) => state.salesSize,
        getConfiguracoesLinks: (state) => ([...state.configuracoes]),
        getConfiguracoesLinksSize: (state) => state.configuracoesSize
    },
};

export default module_links;