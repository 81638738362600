const ADD_COMPANY_GROUP = "ADD_COMPANY_GROUP";
const UPDATE_COMPANY_GROUP = "UPDATE_COMPANY_GROUP";
const GET_ALL_COMPANY_GROUP = "GET_ALL_COMPANY_GROUP";
const DESTROY_COMPANY_GROUP = "DESTROY_COMPANY_GROUP";

module.exports = {
    ADD_COMPANY_GROUP,
    UPDATE_COMPANY_GROUP,
    GET_ALL_COMPANY_GROUP,
    DESTROY_COMPANY_GROUP,
}
