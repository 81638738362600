<template>
  <v-layout row justify-center>
    <v-dialog v-model="component.destroyDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline"
          >Deseja Excluir este item do {{ getTitle() || "" }} ?</v-card-title
        >
        <v-card-text
          >Essa ação irá excluir o item do {{ getTitle() || "" }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="error"
            @click="
              () => {
                executeAlterDestroyDialog();
              }
            "
            >Cancelar</v-btn
          >
          <v-btn
            color="success ml-4"
            @click="
              () => {
                submit();
              }
            "
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { DESTROY_CASHIER } from "../../../store/actions/cashier";
export default {
  props: {
    component: Object,
    destroy_object: Object,
  },
  methods: {
    executeAlterDestroyDialog() {
      this.component.destroyDialog = false;
    },
    async submit() {
      let destroyCashier = { ...this.destroy_object };
      try {
        await this.$store.dispatch(DESTROY_CASHIER, destroyCashier);
      } catch (error) {
        console.log(error);
      } finally {
        this.executeAlterDestroyDialog();
      }
    },
    getTitle() {
      return (
        (this.component.component && this.component.component.title) || "teste"
      );
    },
  },
};
</script>

<style>
</style>