<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="searchInput"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
          @keyup.enter="autocompleteCashier()"
        >
        </v-text-field>
      </v-card-text>
      <Table
        :component="this"
        :destroy_object="destroy_object"
        :search="search"
        :change_addDialog="change_addDialog"
        v-if="permission.Visualizar"
      />
      <AddDialog
        v-if="addDialog"
        :component="this"
        :objectToUpdate="objectToUpdate"
      />
    </v-card>
    <AlertApi />
    <loadScreen/>
  </Container>
</template>

<script>
import Container from "../components/Cashier/Container";
import CardTitle from "../components/Cashier/CardTitle";
import Table from "../components/Cashier/Table";
import loadScreen from "../components/load"
import AddDialog from "../components/Cashier/AddDialog";
import { GET_ALL_CASHIER, DESTROY_CASHIER, GET_AUTOCOMPLETE_CASHIER } from "../store/actions/cashier";
import AlertApi from "./../components/alert_api/";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    search: "",
    searchInput: "",
    code: "",
    getter: "getCashier",
    addDialog: false,
    objectToUpdate: {},
    title: "Caixa",
    permission: []
  }),
  methods: {
    AlterAddDialog() {
      this.addDialog = !this.addDialog;
    },
    async destroy_object(item) {
      const car_adm = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_CASHIER, car_adm);
      } catch (error) {
        alert(error);
      }
    },

    change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
    },
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_CASHIER, {
        page: page,
        limit: 10,
      });
    },
    async GetAutocompleteCashier(page = 1) {
      await this.$store.dispatch(GET_AUTOCOMPLETE_CASHIER, {
        search: this.search,
        page: page,
        limit: 10,
      });
    },
    autocompleteCashier() {
        this.search = this.searchInput
    }
  },
  watch: {
    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
      if(val.length == 0) {
        this.GetAll();
      } else if(val.length >= 2){
        this.GetAutocompleteCashier()
      }
    }
  },
  async created() {
    this.GetAll();
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name)
  },
  components: {
    Container,
    CardTitle,
    Table,
    AddDialog,
    AlertApi,
    loadScreen
  },
  computed: {
    ...mapGetters(["getCashier"]),
    ...mapGetters(["getCadastrosLinks"])
  },
};
</script>

<style scoped></style>
