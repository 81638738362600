var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dialog_cancel)?_c('Cancel',{attrs:{"component":this}}):_vm._e(),(_vm.cancelErrorDialog)?_c('CancelError',{attrs:{"component":this}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results,"hide-default-footer":"","no-data-text":"Nenhuma nota cadastrada","no-results-text":"Nenhum resultado encontrado","footer-props":{
      'items-per-page-options': [10, 20, 30, -1],
      'items-per-page-text': 'Items por página',
    }},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":() => {
            _vm.activator_info(item);
          }}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar Nota")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red darken-4"},on:{"click":() => {
            _vm.activator_cancel(item);
          }}},'v-icon',attrs,false),on),[_vm._v("mdi-cancel")])]}}],null,true)},[_c('span',[_vm._v("Cancelar Nota")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":() => {
            _vm.activator_reprint(item);
          }}},'v-icon',attrs,false),on),[_vm._v("mdi-printer")])]}}],null,true)},[_c('span',[_vm._v("Imprimir Nota")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":() => {
            _vm.activator_reprocess(item);
          }}},'v-icon',attrs,false),on),[_vm._v("mdi-note-edit")])]}}],null,true)},[_c('span',[_vm._v("Reprocessar nota")])])]}}])}),_c('v-pagination',{attrs:{"length":_vm.total,"color":"success","total-visible":10,"disabled":_vm.paginationDelay},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}}),(_vm.dialog)?_c('Info',{attrs:{"component":this}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }