<template>
  <Container>
    <v-container>
      <v-col  lg="12" xl="12">
        <v-card elevation="5" class="ma-2">
          <v-col>
            <v-row>
              <DashInfo />
            </v-row>
          </v-col>
          <v-row>
            <v-col>
              <DashGraphic />
            </v-col>
            <v-col>
              <DashOperations />
            </v-col>
          </v-row>
          
        </v-card>
      </v-col>
    </v-container>
  </Container>
</template>

<script>

import Container from "../components/Dashboard/Container";
import DashInfo from "../components/Dashboard/Info/";
import DashGraphic from "../components/Dashboard/Graphics/";
import DashOperations from "../components/Dashboard/Operations";
export default {
  methods:{},
  components: { DashInfo, DashGraphic, DashOperations, Container },
};
</script>
<style scoped>
.titleDashboard {
  font-family: "Quicksand", sans-serif;
  font-size: 2rem;
  margin: auto;
}
</style>
