<template>
  <div>
    <v-divider></v-divider>
    <v-form v-model="formValidDataAddress">
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            @click:append="searchMyAddress(component.address.cep)"
            :append-icon="icons[0]"
            v-model="component.address.cep"
            :readonly="component.justVisible"
            label="*Cep"
            placeholder="Placeholder"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            v-model="component.address.street"
            :readonly="component.justVisible"
            label="*Rua"
            placeholder="Placeholder"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            v-model="component.address.number"
            :readonly="component.justVisible"
            label="*Número"
            placeholder="Placeholder"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field
            v-model="component.address.district"
            :readonly="component.justVisible"
            label="*Bairro"
            placeholder="Placeholder"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field
            v-model="component.address.complement"
            :readonly="component.justVisible"
            label="*Complemento"
            placeholder="Placeholder"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-select
            v-model="component.address.state"
            :readonly="component.justVisible"
            :items="stateItems"
            label="*Estado"
            placeholder="Placeholder"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-select
            v-model="component.address.city_id"
            :readonly="component.justVisible"
            :items="citiesItems"
            item-text="name"
            item-value="id"
            label="*Cidade"
            placeholder="Placeholder"
            outlined
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import statesCities from "./helpers/states_cities.js";
import axios from "axios";
export default {
  props: { component: Object(), isUpdateAdd: Boolean() },

  components: {},

  data: () => ({
    textRules: [(v) => !!v || "Campo obrigatório!"],
    icons: [mdiMagnify],
    stateItems: statesCities.states,
    citiesItems: Array(),
    formValidDataAddress: Boolean(),
  }),

  methods: {
    async searchMyAddress(cep) {
      const address = await axios.get(`https://ws.apicep.com/cep/${cep}.json`);
      this.component.address.street = address.data.address;
      this.component.address.district = address.data.district;
      this.component.address.state = address.data.state;
    },
    loadCities(state_id) {
      statesCities.cities.forEach((elementStateAndCities) => {
        if (elementStateAndCities.state_id == state_id) {
          this.citiesItems.push(elementStateAndCities);
        }
      });
    },

    deleteItem(item) {
      let indice = this.component.ListAddress.indexOf(item);
      this.component.ListAddress.splice(indice, 1);
    },
  },

  watch: {
    formValidDataAddress: function () {},
    "component.address.state": function () {
      this.citiesItems = [];
      this.loadCities(this.component.address.state);
    },
    // "component.cityObject": function () {
    //   this.component.address.city_id = this.component.cityObject.id;
    // },
  },

  async created() {
    if (this.isUpdateAdd) {
      this.citiesItems = statesCities.cities;
    }
  },
};
</script>
