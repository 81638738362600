import axios from "axios";
const {
  ADD_CST,
  UPDATE_CST,
  GET_ALL_CST,
  DESTROY_CST,
} = require("./actions/cst");
const { SPLICE_ERRO, SPLICE_SUCESSO } = require("./actions/alert_api");

const moduleCst = {
  state: () => ({
    csts: Object(),
    newCstToAdd: Object()
  }),

  mutations: {
    GET_ALL_CST: (state, csts) => {
      state.csts = csts.data;
    },
    ADD_CST: (state, newCST) => {
      state.newCstToAdd = newCST
      //state.csts.data.push(newCST);
    },
    UPDATE_CST(state, updateCST) {
      let result = state.csts.data.find((r) => r.id === updateCST.data.id);
      for (let key in updateCST.data) {
        result[key] = updateCST.data[key];
      }
    },

    DESTROY_CST: (state, id) => {
      let auxState = [...state.csts.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.csts = [...auxState];
    },
  },

  actions: {
    GET_ALL_CST({ commit }, payload) {
      axios
        .post("cst/index", payload)
        .then((response) => {
          console.log("reresponseponse.data", response.data)
          commit(GET_ALL_CST, response.data);
        });
    },

    ADD_CST({ commit }, payload) {
      axios
        .post("cst/create", payload)
        .then((response) => {
          console.log("response", response)
          commit(ADD_CST, response.data.data);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    UPDATE_CST({ commit }, payload) {
      axios
        .post("cst/update", payload)
        .then((response) => {
          commit(UPDATE_CST, response.data[0]);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    DESTROY_CST({ commit }, payload) {
      axios
        .post("cst/delete", payload)
        .then((response) => {
          commit(DESTROY_CST, response.id);
          commit(SPLICE_SUCESSO, {
            alert: response.data.sucess.message[0],
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },
  },

  getters: {
    getNewCstToAdd: (state) => state.newCstToAdd,
    getCst: (state) => ({ ...state.csts }),
  },
};

export default moduleCst;
