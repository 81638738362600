const ADD_PIS_COFINS = "ADD_PIS_COFINS";
const UPDATE_PIS_COFINS = "UPDATE_PIS_COFINS";
const GET_ALL_PIS_COFINS = "GET_ALL_PIS_COFINS";
const DESTROY_PIS_COFINS = "DESTROY_PIS_COFINS";
const GET_FILTER_PIS_COFINS = "GET_FILTER_PIS_COFINS";

module.exports = {
    ADD_PIS_COFINS,
    UPDATE_PIS_COFINS,
    GET_ALL_PIS_COFINS,
    DESTROY_PIS_COFINS,
    GET_FILTER_PIS_COFINS
}
