import Vue from "vue";
import VueRouter from "vue-router";
import FormOfPayment from "../views/Formofpayments.vue";
import PaymentTerms from "../views/Paymentterm.vue";
import Cashier from "../views/Cashier.vue";
import Client from "../views/Client.vue";
import User from "../views/User.vue";
import Product from "../views/Product.vue";
import Seller from "../views/Seller.vue";
import Transporter from "../views/Transporter.vue";
import Sales from "../views/Sales.vue";
import Notes from "../views/Notes.vue";
import Enterprise from "../views/Enterprise.vue";
import Permission from "../views/Permission.vue";
import Login from "../views/login.vue";
import CheckCashier from "../views/Checkcashier.vue";
import Parameter from "../views/Parameter.vue";
import ProductGroup from "../views/ProductGroup.vue"
import Contingency from "../views/Contingency.vue"

import middleware from './middleware'
import store from "../store"
import jwtDecode from '../utils/jwt_decode'


import Shortcuts from "../views/Shortcuts"
import Dashboard from "../views/Dashboard.vue";

import ExchangeControl from "../views/ExchangeControl.vue"
import CheckCashierSlip from "../views/Reports/close_cashier"
Vue.use(VueRouter);

const routes = [
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/exchangeControl",
    name: "ExchangeControl",
    component: ExchangeControl,
  },
  {
    path: "/formOfPayment",
    name: "formOfPayment",
    component: FormOfPayment,
  },
  {
    path: "/paymentTerms",
    name: "PaymentTerms",
    component: PaymentTerms,
  },
  {
    path: "/cashier",
    name: "Cashier",
    component: Cashier,
  },
  {
    path: "/ProductGroup",
    name: "ProductGroup",
    component: ProductGroup,
  },
  {
    path: "/users",
    name: "User",
    component: User,
  },
  {
    path: "/client",
    name: "Client",
    component: Client,
  },
  {
    path: "/produtos",
    name: "Product",
    component: Product,
  },
  {
    path: "/seller",
    name: "Seller",
    component: Seller,
  },
  {
    path: "/transport",
    name: "Transport",
    component: Transporter,
  },
  {
    path: "/Sales",
    name: "Sales",
    component: Sales,
  },
  {
    path: "/notes",
    name: "Notes",
    component: Notes,
  },
  {
    path: "/parameter",
    name: "Parameter",
    component: Parameter,
  },
  {
    path: "/checkcashier",
    name: "ChechCashier",
    component: CheckCashier,
  },
  {
    path: "/enterprise",
    name: "Enterprise",
    component: Enterprise,
  },
  {
    path: "/permission",
    name: "Permission",
    component: Permission,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/shortcuts",
    name: "Shortcuts",
    component: Shortcuts,
  },
  {
    path: "/contingency",
    name: "Contingency",
    component: Contingency,
  },
  {
    path: "/checkcashierslip",
    name: "CheckCashierSlip",
    component: CheckCashierSlip,
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const storagedToken = localStorage.getItem('token');
  const storagedFiscal = localStorage.getItem('token_fiscal');
  const storagedTokenGestao = localStorage.getItem('token_gestao');

  if (!jwtDecode(storagedToken) || !jwtDecode(storagedFiscal) ||!jwtDecode(storagedTokenGestao
    )) {
      localStorage.removeItem("authenticated");
      localStorage.removeItem('token')
      localStorage.removeItem('token_fiscal')
      localStorage.removeItem('token_gestao')
  }
  if (to.name !== "Login") {
    let auth = JSON.parse(localStorage.getItem("authenticated"));
    
    if (storagedToken == null ||storagedFiscal ==null ||storagedTokenGestao ==null ){
      store.commit("auth/SET_USER");
      store.commit("auth/SET_ISVALID", false);
      return next({
        name: "Login",
      })
    }
    if (!auth) {
      store.commit("auth/SET_USER");
      store.commit("auth/SET_ISVALID", false);
      return next({
        name: "Login",
      });
    } else {
      store.commit("auth/SET_USER", auth);
      store.commit("auth/SET_ISVALID", true);

    }
  } else if (to.name === "Login") {
    let auth = JSON.parse(localStorage.getItem("authenticated"));
    if (auth) {
      store.commit("auth/SET_USER", auth);
      return next({
        name: 'Shortcuts'
      });
    }
  }
  next();
});
middleware(router)


export default router;
