<template>
  <v-row>
    <v-card-title>Consultar Caixa</v-card-title>
    <v-spacer></v-spacer>
  </v-row>
</template>

<script>

export default {
  props: {
    component: Object,
  },
  methods: {
    getComponent() {
      return this._props.component;
    },
    getTitle() {
      return this.getComponent().title;
    },

  },
};
</script>

<style>
</style>