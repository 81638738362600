<template>
  <v-container>
    <div>
      <v-data-table
      :headers="headers"
      :items="component.nfceData.itensexchange"
      no-data-text="Sem Itens cadastrados!"
      class="elevation-1"
      hide-default-footer
    >
    </v-data-table>


    </div>
  </v-container>
</template>

<script>

  export default {
    props: {
      component: Object,
    },

    data: () => ({
      headers: [
        { text: "Código", value: "product.id" },
        { text: "Descrição", value: "product.description_item" },
        { text: "Quantidade", value: "amount" },
        { text: "Valor de Venda", value: "value_sale" },
        { text: "Sub Total", value: "value_subtotal_product" },
        { text: "CFOP", value: "product.cfop_id" },
        { text: "Cst/Csosn", value: "product.cst_id" },
      ],
    })

};

</script>

<style></style>


