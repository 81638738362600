let EmitterNFce = require("../Service/MounterEmitterNfceService");
let IdentifyNFce = require("../Service/MounterIdentifyNfceService");
let DestinationNFce = require("../Service/MounterDestinationNfceDomainService");
let ProductNFce = require("../Service/MounterProductNFceDomainService");
let TransportInfoNFce = require("../Service/MounterTransportInfoNFce");

async function execute(sales) {
  try {
    let emitterNFce = await EmitterNFce.execute(sales.enterprise.cnpj);
    let identifyNFce = await IdentifyNFce.execute(sales);
    let destinationNFce = await DestinationNFce.execute(sales);
    let productNFce = await ProductNFce.execute(sales, sales.enterprise);
    let transportInfo = await TransportInfoNFce.execute(sales);

    let nfce = await mounterNfce(
      emitterNFce,
      identifyNFce,
      destinationNFce,
      productNFce,
      transportInfo
    );
    
    return nfce;
  } catch (error) {
    return error;
  }
}
async function mounterNfce(
  emiterNfe,
  identifyNfce,
  destinationNFce,
  ProductNFce,
  transportInfo
) {
  let nfceMounter = {};
  nfceMounter.emitterNFe = { ...emiterNfe };
  nfceMounter.identifyNFe = { ...identifyNfce };
  nfceMounter.destinationNFe = { ...destinationNFce };
  nfceMounter.productsNFe = { ...ProductNFce };
  nfceMounter.transportInfo = { ...transportInfo };

  return nfceMounter;
}

module.exports = {
  execute,
};
