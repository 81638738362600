const headers = [
    { text: "Codigo", value: "id" },
    { text: "Razão Social", value: "name" },
    { text: "Nome Fantasia", value: "fantasyName" },
    { text: "CNPJ", value: "cnpj" },
    { text: "Municipio", value: "address.city"},
    { text: "Estado", value: "address.state" },
    { text: "Ações", value: "actions" },
];

export { headers }