var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"height":_vm.height}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-basket")]),_vm._v("Produtos ")],1),_c('v-switch',{staticClass:"ml-2",attrs:{"color":"primary","label":"Visualizar imagens"},model:{value:(_vm.view_image),callback:function ($$v) {_vm.view_image=$$v},expression:"view_image"}}),_c('v-text-field',{staticClass:"ma-2",attrs:{"dense":"","label":_vm.labelCode,"outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enterCode()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":_vm.color_barcode},on:{"click":function($event){return _vm.activeBarcode()}}},on),[_vm._v(" mdi-barcode-scan ")])]}}])},[_vm._v(" "+_vm._s(_vm.text_barcode)+" ")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":_vm.color_numeric},on:{"click":function($event){return _vm.active_idProduct()}}},on),[_vm._v(" mdi-numeric ")])]}}])},[_vm._v(" "+_vm._s(_vm.text_numeric)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":_vm.color_numeric},on:{"click":function($event){return _vm.searchProduct()}}},on),[_vm._v(" mdi-magnify ")])]}}])},[_vm._v(" "+_vm._s(_vm.text_search)+" ")])]},proxy:true}]),model:{value:(_vm.code_bar),callback:function ($$v) {_vm.code_bar=$$v},expression:"code_bar"}}),(!_vm.view_image)?_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"height":"44vh","items":_vm.productList,"no-data-text":_vm.data_text,"hide-default-footer":"","footer-props":{
        'items-per-page-options': [5, 10],
        'items-per-page-text': 'Items por página',
      }},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-icon',{attrs:{"small":"","color":"blue darken-3"},on:{"click":() => {
              _vm.activatorView(item);
            }}},[_vm._v("mdi-magnify")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"green darken-3"},on:{"click":() => {
              _vm.activatorAdd(item);
            }}},[_vm._v("mdi-plus-circle")])]}}],null,false,2871218353)})],1):_c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{attrs:{"outlined":"","height":"40vh"}},[_c('v-container',{staticClass:"scroll"},[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.productList),function(item){return _c('v-col',{key:item.description_item,attrs:{"cols":"12","md":"6","sm":"12","lg":"4"}},[_c('div',{staticClass:"cardDiv",on:{"click":function($event){return _vm.activatorAdd(item)}}},[_c('img',{staticClass:"imagem",attrs:{"src":`${_vm.url}${item.photo}`}}),_c('img')]),_c('div',{staticClass:"descriptCard"},[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(item.description_pdv)+" ")])])])}),1)],1)],1)],1),_c('v-divider'),_c('v-pagination',{attrs:{"color":"success","length":_vm.lastPage,"total-visible":7,"disabled":_vm.paginationDelay},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}}),(_vm.dialog)?_c('SearchProduct',{attrs:{"component":this}}):_vm._e(),(_vm.dialogInfo)?_c('InfoProduct',{attrs:{"component":this,"itemProduct":_vm.element,"readonly":_vm.readonly}}):_vm._e(),(_vm.dialog_add)?_c('AddCard',{attrs:{"component":this}}):_vm._e(),(_vm.dialog_alert)?_c('AlertError',{attrs:{"component":this,"title_error":_vm.title_error,"listErrors":_vm.subtitle_error}}):_vm._e(),(_vm.dialog_date)?_c('LoadDate',{attrs:{"title":_vm.title,"component":this}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }