const links = [
  {
    text: "Realizar Vendas",
    icon: "mdi-cart-arrow-down",
    route: "/Sales",
    class: "white--text",
    active: false
  },
  {
    text: "Notas Emitidas",
    icon: "mdi-note-multiple-outline",
    route: "/notes",
    class: "white--text",
    active: false
  },
  // {
  //   text: "Consultar Caixa",
  //   icon: "mdi-cash-register",
  //   route: "/checkcashier",
  //   class: "white--text",
  //   active: false
  // },
  {
    text: "Trocas Realizadas",
    icon: "mdi-cash-register",
    route: "/exchangeControl",
    class: "white--text",
    active: true
  },

];
export { links };
