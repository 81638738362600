var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.component.addDialog),callback:function ($$v) {_vm.$set(_vm.component, "addDialog", $$v)},expression:"component.addDialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Caixa ")]),_c('v-container',[_c('v-divider'),_c('v-form',{ref:"form",staticClass:"mt-6",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Código","readonly":_vm.justVisible,"maxlength":"5","rules":[
                () => !!_vm.cashier.code || 'Campo Obrigatorio',
                () =>
                  (!!_vm.cashier.code && _vm.cashier.code.length >= 1) ||
                  'Campo tem que ser maior do que 1',
              ],"outlined":""},model:{value:(_vm.cashier.code),callback:function ($$v) {_vm.$set(_vm.cashier, "code", $$v)},expression:"cashier.code"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Descrição","readonly":_vm.justVisible,"rules":[
                () => !!_vm.cashier.description || 'Campo Obrigatório',
                () =>
                  (!!_vm.cashier.description &&
                    _vm.cashier.description.length >= 2) ||
                  'Campo tem que ser maior do que 2',
              ],"outlined":""},model:{value:(_vm.cashier.description),callback:function ($$v) {_vm.$set(_vm.cashier, "description", $$v)},expression:"cashier.description"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Saldo Inicial","readonly":_vm.justVisible,"prefix":"R$","type":"number","outlined":""},model:{value:(_vm.cashier.initial_balance),callback:function ($$v) {_vm.$set(_vm.cashier, "initial_balance", $$v)},expression:"cashier.initial_balance"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Data Inicial","type":"date","readonly":_vm.justVisible,"rules":[() => !!_vm.cashier.initial_date || 'Campo Obrigatorio'],"outlined":""},model:{value:(_vm.cashier.initial_date),callback:function ($$v) {_vm.$set(_vm.cashier, "initial_date", $$v)},expression:"cashier.initial_date"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.itensSelect,"label":"Tipo de Caixa","readonly":_vm.justVisible,"rules":[() => !!_vm.cashier.cashierType || 'Campo Obrigatorio'],"outlined":"","solo":""},model:{value:(_vm.cashier.cashierType),callback:function ($$v) {_vm.$set(_vm.cashier, "cashierType", $$v)},expression:"cashier.cashierType"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":() => {
                  _vm.executeCancelAddDialog();
                }}},[_vm._v("Cancelar")]),(!_vm.justVisible)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","disabled":!_vm.valid},on:{"click":() => {
                  _vm.submit();
                }}},[_vm._v("Salvar")]):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }