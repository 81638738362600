<template>

    <v-dialog
      persistent
      v-model="this.component.clientDialog"
      width="70%"
      hide-overlay
      transition="dialog-bottom-transition"
    >

    <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn icon dark @click="
            () => {
              close_dialog();
            }
          ">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{header}} Cadastro Rápido de Cliente</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <v-tabs>
            <v-tab href="#tab1">
              <v-icon class="mr-1">mdi-account-details</v-icon>
              Gerais
            </v-tab>
            <v-tab href="#tab2">
              <v-icon class="mr-1">mdi-home-account</v-icon>
              Endereço
            </v-tab>
            <v-tab-item value="tab1">
              <v-switch v-model="register" label="Pessoa Jurídica"></v-switch>
              <v-card flat v-if="!register">
                <TabPhysical :component="this" ref="phys" />
              </v-card>
              <v-card flat v-else>
                <TabLegal :component="this" ref="legal" />
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab2">
              <v-card flat>
                <v-card flat>
                  <TabAddress :component="this" />
                </v-card>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!justView" @click="
              () => {
                clear();
              }
            " class="error">
              <v-icon class="mr-2">mdi-cancel</v-icon> Limpar Formulário
            </v-btn>
            <v-btn v-if="!justView" :disabled="!valid" color="success" @click="
              () => {
                submit();
              }
            ">
              <v-icon class="mr-2">mdi-content-save</v-icon>
              Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </template>

  

  <script>
    import { mapGetters } from "vuex";
    import TabPhysical from "./Tabs/TabPhysical/";
    import TabLegal from "./Tabs/TabLegal/";
    import TabAddress from "./Tabs/TabAddress/index.vue";
    import { ADD_PF_CLIENT, ADD_PJ_CLIENT } from "../../../../store/actions/client";
    import {cleanCNPJ, cleanString } from "@/components/Utils/global_functions/clean_cnpj_cpf"

    // import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise"

  export default {
    props: {
        component: Object,
    },

    components: {
      TabPhysical,
      TabLegal,
      TabAddress,
    },

    data: () => ({
    header: String(),
    id: undefined,
    enterprise_id: Number(),
    register: false,
    valid: Boolean(),
    validPhysical: Boolean(),
    validLegal: Boolean(),
    validAddress: Boolean(),
    physicalClient: {
      name: String(),
      lastName: String(),
      cpf: String(),
      gender: String(),
      emitter: String(),
      emitterState: String(),
      idCard: String(),
      dateOfBirth: String(),
      fatherName: String(),
      motherName: String(),
      nacionality: String(),
      naturality: String(),
      monthlyIncome: String(),
      civilStatus: String(),
      spouse: String(),
      icomeSpouse: Number(),
      // people_id: Number(),
      people_id: undefined,

    },

    LegalPeople: {
      cnpj: String(),
      fantasyName: String(),
      socialName: String(),
      stateRegistration: String(),
      municipalRegistration: String(),
      homePage: String(),
      // people_id: Number(),
      people_id: undefined,

    },

    client: {
      type_people: String(),
      people_id: Number(),
    },

    address: {
      id: undefined,
      street: String(),
      number: String(),
      district: String(),
      complement: String(),
      city: null,
      state: null,
      cep: String(),
      city_id: Number(),
      people_id: Number(),
    },

    contact: {
      primaryEmail: "",
      secundaryEmail: "",
      phone: "",
      cellPhone: "",
      phoneReference: "",
      people_id: Number(),
    },

    banks: {
      id: undefined,
      bank: String(),
      agency: String(),
      count: String(),
      manager: String(),
      people_id: Number(),
      type_key_pix: String(),
      value_key_pix: String(),
    },
    cityObject: Object(),

  }),

    methods: {

        close_dialog() {
          this.cityObject = null
          this.address.state = null
          this.clear()
          this.component.clientDialog = false
        },

        submit(){
          this.physicalClient.cpf = this.physicalClient.cpf.replace(/\D/g, "");
          this.address.cep = this.address.cep.replace(/\D/g, "");
          this.contact.cellPhone = this.contact.cellPhone.replace(/\D/g, "");
          this.create()

        },

        async create() {
          if (this.register === true) {
            let newPeople = Object();
            newPeople.legal_people = { ...this.LegalPeople };
            newPeople.legal_people.cnpj = cleanCNPJ(newPeople.legal_people.cnpj)
            newPeople.people_id = "";
            newPeople.type_people = "LegalPeople";
            newPeople.address = { ...this.address };
            newPeople.contacts = { ...this.contact };
            newPeople.banks = { ...this.banks };
            newPeople.address.city_id = this.cityObject.id;
            newPeople.address.city = this.cityObject.nome;

        

            newPeople.address.cep = cleanString(this.address.cep)
            newPeople.contacts.phone = cleanString(this.contact.phone)
            newPeople.contacts.cellPhone = cleanString(this.contact.cellPhone)
            newPeople.contacts.phoneReference = cleanString(this.contact.phoneReference)
            newPeople.enterprise_id = this.enterprise_id
            await this.$store.dispatch(ADD_PJ_CLIENT, newPeople).then(() => {
              this.component.selectAddedClient(newPeople.type_people)
            });
          } else {
            let newPeople = Object();
            newPeople.physical_people = { ...this.physicalClient };
            newPeople.type_people = "PhysicalPeople";
            newPeople.people_id = "";
            newPeople.address = { ...this.address };
            newPeople.contacts = { ...this.contact };
            newPeople.banks = { ...this.banks };
            newPeople.address.city_id = this.cityObject.id;
            newPeople.address.city = this.cityObject.nome;
            newPeople.address.cep = cleanString(this.address.cep)
            newPeople.contacts.phone = cleanString(this.contact.phone)
            newPeople.contacts.cellPhone = cleanString(this.contact.cellPhone)
            newPeople.contacts.phoneReference = cleanString(this.contact.phoneReference)
            newPeople.enterprise_id = this.enterprise_id
            await this.$store.dispatch(ADD_PF_CLIENT, newPeople).then(() => {
              this.component.selectAddedClient(newPeople.type_people)
            });
          }

          // this.close_dialog()

        },

        clear() {
          if (this.register) {
            this.clear_legal();
          } else {
            this.clear_Physical();
          }
          this.address = [];
          this.contacts = [];
          this.banks = [];
          this.contact.cellPhone = ""
        },

        clear_Physical() {
          this.physicalClient.name = "";
          this.physicalClient.lastName = "";
          this.physicalClient.cpf = "";
          this.physicalClient.gender = "";
          this.physicalClient.emitter = "";
          this.physicalClient.emitterState = "";
          this.physicalClient.idCard = "";
          this.physicalClient.dateOfBirth = "";
          this.physicalClient.fatherName = "";
          this.physicalClient.motherName = "";
          this.physicalClient.nacionality = "";
          this.physicalClient.naturality = "";
          this.physicalClient.monthlyIncome = "";
          this.physicalClient.civilStatus = "";
          this.physicalClient.spouse = "";
          this.physicalClient.icomeSpouse = "";
          this.physicalClient.type = "";
          this.physicalClient.address = [];
          this.physicalClient.contacts = [];
          this.physicalClient.banks = [];
          this.$refs.phys.reset();
        },

        clear_legal() {
          this.LegalPeople.cnpj = "";
          this.LegalPeople.fantasyName = "";
          this.LegalPeople.socialName = "";
          this.LegalPeople.stateRegistration = "";
          this.LegalPeople.municipalRegistration = "";
          this.LegalPeople.homePage = "";
          this.LegalPeople.address = [];
          this.LegalPeople.banks = [];
          this.LegalPeople.contacts = [];
          this.LegalPeople.related_representative = "";
          this.LegalPeople.type = "";
          this.$refs.legal.reset();
        },

    },

    computed: {
      ...mapGetters([ "getEnterprises" ]),
    },

    watch: {
      validPhysical(val) {
        if ((val || this.validLegal)  && this.validAddress) {
          this.valid = true;
        } else {
          this.valid = false;
        }

      },
      validLegal(val) {
        if ((val || this.validPhysical)  && this.validAddress) {
          this.valid = true;
        } else {
          this.valid = false;
        }

      },

      validAddress(val) {
        if ((this.validLegal || this.validPhysical)  && val) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      },
    },

    created() {
      this.enterprise_id = this.getEnterprises.data[0].id
    }
  };
  </script>

  

  <style>
  #backgroundDialog {
    padding: 5%;
    background-color: white;
    border-radius: 1%;
  }
  </style>
