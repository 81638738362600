<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="setShowDialog" class="success" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-account-plus</v-icon>
      </v-btn>
    </template>
    <span>Cadastro de Pessoas</span>
  </v-tooltip>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  methods: {
    setShowDialog() {
      this.$store.dispatch("setShowDialogRegisterGeneralDataSales", true);
    }
  }
};
</script>

<style>
</style>