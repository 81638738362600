<template>
  <v-row>
    <v-dialog
      persistent
      transition="scale-transition"
      max-width="300"
      v-model="this._props.component.dialog"
    >
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row justify="center">
            <v-card-title>Qual a quantidade?</v-card-title>
          </v-row>

          <v-text-field outlined v-model="component.amount" label="Quantidade">
          </v-text-field>

          <v-row justify="center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                rounded
                class="red darken-4 white--text"
                @click="
                  () => {
                    disableDialog();
                  }
                "
              >
                Cancelar
              </v-btn>
              <v-btn
                rounded
                depressed
                class="success"
                @click="
                  () => {
                    component.confirmAlterQuantProduct();
                  }
                "
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object,
  },
  data: () => ({
    amount: "",
  }),
  methods: {
    disableDialog() {
      this._props.component.dialog = !this._props.component.dialog;
    },
    //refresh_Product(amount) {},
  },
};
</script>
