<template>
  <v-container>
    <!-- <v-row class="titleDashboard" justify="center">
      <v-col>
        <p>DashBoard</p>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <v-card outlined height="200" color="red lighten-1">
          <v-layout row justify-center>
            <v-flex>
              <p class="titleInfos white--text">Número de cupons emitidos</p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <p class="textInfos white--text">5000</p>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined height="200" color="green">
          <v-layout row justify-center>
            <v-flex>
              <p class="titleInfos white--text">Quantidade de Itens Vendidos</p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <p class="textInfos white--text">5000</p>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          outlined
          height="200"
          color="orange lighten-1
"
        >
          <v-layout row justify-center>
            <v-flex>
              <p class="titleInfos white--text">Valores Descontos Realizados</p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <p class="textInfos white--text">5000</p>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
.titleDashboard {
  font-family: "Quicksand", sans-serif;
  font-size: 3rem;
  color: #286a7d;
  margin: auto;
}
.titleInfos {
  font-family: "Quicksand", sans-serif;
  font-size: 2rem;
  margin: auto;
}
.textInfos {
  font-family: "Quicksand", sans-serif;
  font-size: 1.9rem;
  margin: auto;
}
</style>