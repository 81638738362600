<template>
  <div>
    <v-form v-model="validFormDataBank">
      
      
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12" xl="12">
            <v-select
              :items="listBanks"
              v-model="component.banks.bank"
              :readonly="component.justVisible"
              :rules="rules.textFields"
              label="Banco"
              item-text="label"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="6" xl="6">
            <v-text-field
              v-model="component.banks.count"
              :readonly="component.justVisible"
              label="Conta"
              :rules="rules.textFields"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" xl="6">
            <v-text-field
              v-model="component.banks.agency"
              :readonly="component.justVisible"
              :rules="rules.textFields"
              label="Agência"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12" xl="12">
            <v-text-field
              v-model="component.banks.manager"
              :readonly="component.justVisible"
              :rules="rules.textFields"
              label="Gerente"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      
    </v-form>
  </div>
</template>

<script>
import listBanks from "./helpers/listBank.js";
export default {
  props: {
    component: Object(),
  },
  components: {},
  data: () => ({
    rules: {
      textFields: [(v) => !!v || "Campo Obrigatório"],
    },
    listBanks: listBanks,
    validFormDataBank: Boolean(),
  }),
  methods: {},
  watch: {
    validFormDataBank: function () {
      console.log(this.validFormDataBank);
    },
  },
};
</script>

<style></style>
