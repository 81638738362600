import axios from "axios";

const {
  ADD_SUB_PRODUCT_GROUP,
  UPDATE_SUB_PRODUCT_GROUP,
  GET_ALL_SUB_PRODUCT_GROUP,
  DESTROY_SUB_PRODUCT_GROUP,
} = require("./actions/sub_product_group");
const {
  SPLICE_SUCESSO,SPLICE_ERRO
} = require("./actions/alert_api");

const moduleSubGroup = {
  state: () => ({
    subproductgroups: Object(),
    newSubProductToAdd: Object(),
  }),

  mutations: {
    GET_ALL_SUB_PRODUCT_GROUP: (state, subproductgroups) => {
      state.subproductgroups = subproductgroups;
    },
    ADD_SUB_PRODUCT_GROUP: (state, newSubproductgroups) => {
      state.newSubProductToAdd = newSubproductgroups
      //state.subproductgroups.data.push(newSubproductgroups);
    },
    UPDATE_SUB_PRODUCT_GROUP(state, editSubproductgroups) {
      let result =   state.subproductgroups.data.find((r) => r.id === editSubproductgroups.data.id);
      for (let key in editSubproductgroups.data) {
        result[key] = editSubproductgroups.data[key];
      }
    },

    DESTROY_SUB_PRODUCT_GROUP: (state, id) => {
      let auxState = [...state.subproductgroups.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      );
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.subproductgroups = [...auxState];
    },
  },

  actions: {
    async GET_ALL_SUB_PRODUCT_GROUP({ commit }, payload) {
      await axios.post("subproductgroup/index", { page: payload.page })
        .then((response) => {
          commit(GET_ALL_SUB_PRODUCT_GROUP, response.data);
        });
    },

    ADD_SUB_PRODUCT_GROUP({
      commit
    }, payload) {
      axios.post("subproductgroup/create", payload).then((response) => {
        commit(ADD_SUB_PRODUCT_GROUP, response.data);
        commit(SPLICE_SUCESSO, {
          alert: response.data.msg,
        });
      }).catch((r) => {
        let erros = r.response.data.errors;
        for (let erro in erros) {
          erros[erro].forEach((err) =>
            commit(SPLICE_ERRO, {
              alert: erro + ' : ' + err,
              type: "E",
            })
          );
        }
      });
    },

    UPDATE_SUB_PRODUCT_GROUP({
      commit
    }, payload) {
      axios.post("subproductgroup/update", payload).then((response) => {
        commit(UPDATE_SUB_PRODUCT_GROUP, response.data[0]);
        commit(SPLICE_SUCESSO, {
          alert: response.data.msg,
        });
      }).catch((r) => {

        let erros = r.response.data.errors;
        for (let erro in erros) {
          erros[erro].forEach((err) =>
            commit(SPLICE_ERRO, {
              alert: erro + ' : ' + err,
              type: "E",
            })
          );
        }
      });
    },

    DESTROY_SUB_PRODUCT_GROUP({
      commit
    }, payload) {
      axios
        .post("subproductgroup/delete", {
          ...payload
        })
        .then((response) => {
          commit(DESTROY_SUB_PRODUCT_GROUP, payload.id);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
          });

        }).catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: erro + ' : ' + err,
                type: "E",
              })
            );
          }
        });
    },
  },

  getters: {
    getNewSubProductToAdd: (state) => state.newSubProductToAdd,
    getSubProductGroup: (state) => ({
      ...state.subproductgroups
    }),
  },
};

export default moduleSubGroup;