

let header = String()
let id = undefined
let enterprise_id = Number()
let register = false
let valid = Boolean()
let validPhysical = Boolean()
let validLegal = Boolean()
let validTable = Boolean(false)
let physicalClient = {
    name: String(),
    lastName: String(),
    cpf: String(),
    gender: String(),
    emitter: String(),
    emitterState: String(),
    idCard: String(),
    dateOfBirth: String(),
    fatherName: String(),
    motherName: String(),
    nacionality: String(),
    naturality: String(),
    monthlyIncome: String(),
    civilStatus: String(),
    spouse: String(),
    icomeSpouse: Number(),
    people_id: undefined,
}
let LegalPeople = {
    cnpj: String(),
    fantasyName: String(),
    socialName: String(),
    stateRegistration: String(),
    municipalRegistration: String(),
    homePage: String(),
    people_id: undefined,
}
let client = {
    type_people: String(),
    people_id: Number(),
}
let address = {
    id: undefined,
    street: String(),
    number: String(),
    district: String(),
    complement: String(),
    city: String(),
    state: String(),
    cep: String(),
    city_id: Number(),
    people_id: Number(),
}
let contact = {
    primaryEmail: "",
    secundaryEmail: "",
    phone: "",
    cellPhone: "",
    phoneReference: "",
    people_id: Number(),
}
let banks = {
    id: undefined,
    bank: String(),
    agency: String(),
    count: Number(),
    manager: String(),
    people_id: Number(),
}
let cityObject = Object()


export {
    header,
    id,
    enterprise_id,
    register,
    valid,
    validPhysical,
    validLegal,
    validTable,
    physicalClient,
    LegalPeople,
    client,
    address,
    contact,
    banks,
    cityObject,
}