<template>
  <v-row>
    <v-card-title>Caixa</v-card-title>
    <v-spacer></v-spacer>
    <!-- <v-btn
      color="success mt-5 mr-2"
      v-if="component.permission.Cadastrar"
      @click="
        () => {
          executeAlterAddDialog();
        }
      "
      >Adicionar</v-btn
    > -->
  </v-row>
</template>

<script>
export default {
  props: {
    title: String,
    component: Object,
  },
  methods: {
    getComponent() {
      return this._props.component;
    },
    getTitle() {
      return this.getComponent().title;
    },
    executeAlterAddDialog() {
      this.component.AlterAddDialog();
    },
  },
};
</script>

<style></style>
