const headers = [
    { text: "Codigo", value: "id" },
    { text: "Descrição", value: "description_pdv" },
    { text: "Referencia", value: "reference" },
    { text: "Preço de Venda", value: "sale_price" },
    { text: "Ações", value: "actions" },
];

export { headers };

