
const moduleLoad = {
    state: () => ({
        load: Boolean()
    }),

    mutations: {
        CHANGE_LOAD: (state, payload) => {
            state.load = payload;
        },
    },

    getters: {
        getLoad: (state) => state.load
    }
}

export default moduleLoad;