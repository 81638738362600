var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dialog)?_c('Edit',{attrs:{"isUpdate":true,"component":this,"objectToUpdate":_vm.objectToUpdate,"title":"Editar"}}):_vm._e(),_c('Delete',{attrs:{"component":this}}),_c('v-data-table',{attrs:{"headers":_vm.atual.header,"search":_vm.search,"items":_vm.atual.getter,"hide-default-footer":"","no-data-text":"Nenhum Transportador Cadastrado","no-results-text":"Nenhum Transportador Encontrado"},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [(!_vm.component.permission.Editar)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"yellow darken-4"},on:{"click":() => {
          _vm.edit_item(item);
        }}},[_vm._v("mdi-pencil")]):_vm._e(),(!_vm.component.permission.Excluir)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red darken-4"},on:{"click":() => {
          _vm.delete_item(item);
        }}},[_vm._v("mdi-delete")]):_vm._e(),(_vm.component.permission.Excluir)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green darken-4"},on:{"click":() => {
          _vm.visibleItem(item);
        }}},[_vm._v("mdi-eye")]):_vm._e()]}}])}),_c('div',[_c('v-divider'),_c('v-pagination',{attrs:{"length":_vm.lastPage,"color":"success","total-visible":10},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }