<template>
  <div id="mainFrame">
    <Login />
    <AlertApi />
  </div>
</template>

<script>
import Login from "../components/Login/Main/index.vue";
import AlertApi from "../components/alert_api/login/index.vue";

export default {
  components: {
    Login,
    AlertApi,
  },
};
</script>
<style>
#mainFrame {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-image: url("../assets/BackGround.jpg");
  /* background-image: linear-gradient(to bottom right, #09c6f9, #045de9); */
}
</style>

<Login />
    <AlertApi />