<template>
  <v-form ref="form" v-model="component.validLegal">
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-text-field
          v-model="component.LegalPeople.socialName"
          :readonly="component.justVisible"
          :rules="textRules"
          label="*Razão Social"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-text-field
          v-model="component.LegalPeople.fantasyName"
          :readonly="component.justVisible"
          :rules="textRules"
          label="*Nome Fantasia"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.LegalPeople.cnpj"
          :readonly="component.justVisible"
          :rules="cnpjRules"
          v-mask="'##.###.###/####-##'"
          label="*CNPJ"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.LegalPeople.stateRegistration"
          :readonly="component.justVisible"
          :rules="textRules"
          label="*Inscrição Estadual"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.LegalPeople.municipalRegistration"
          :readonly="component.justVisible"
          label="Inscrição Municipal"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          label="Site"
          outlined
          v-model="component.LegalPeople.homePage"
          :readonly="component.justVisible"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-text-field
          v-model="component.LegalPeople.rntr"
          :readonly="component.justVisible"
          :rules="textRules"
          label="*RNTR"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-text-field
          v-model="component.LegalPeople.security"
          :readonly="component.justVisible"
          :rules="textRules"
          label="*Segurança"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: { component: Object() },
  data: () => ({
    valid: false,
    textRules: [(v) => !!v || "Campo obrigatório!"],
    cnpjRules: [
      (v) => !!v || "CNPJ é obrigatório",
      (v) => v.length == 18 || "O CNPJ deve possuir 14 caracteres",
    ],
  }),
  methods: {
    reset() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style>
</style>