<template>
  <v-list-group v-if="configuracoes.length" no-action :value="false">
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon right class="white--text">mdi-cog-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="white--text title"
          >Configurações<a href=""></a
        ></v-list-item-title>
      </v-list-item-content>
    </template>
    <div v-for="link in configuracoes" :key="link.text">
      <v-list-item
        color="white"
        router
        :to="link.route"
        class="navDrop"
        v-if="link.active"
      >
        <v-list-item-icon>
          <v-icon class="white--text">{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title :class="link.class">{{
            link.text
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list-group>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      configuracoes: "getConfiguracoesLinks"
    })
  }
};
</script>

<style></style>
