import axios from "axios"

const {
    GET_ALL_USER,
    UPDATE_USER,
    CREATE_USER,
    DESTROY_USER
} = require("./actions/user")

import handlingException from '../utils/handlingExceptionStore'

const { SPLICE_ERRO } = require('./actions/alert_api')

const User = {
    state: () => ({
        users: Object(),
    }),
    mutations: {
        GET_ALL_USER: (state, users) => {
            state.users = users
        },
        DESTROY_USER: (state, users) => {

            let id = users.id

            let auxState = [...state.users.data];
            let index = auxState.indexOf(
                auxState.find(value => value.id === id),
            );
            if (index >= 0) {
                auxState.splice(index, 1);
            }
            state.users = [...auxState];

        },
        UPDATE_USER: (state, data) => {
            const index = state.users.data.findIndex(user => user.id === data.id)
            Object.assign(state.users.data[index], data)
        },
        CREATE_USER: (state, user) => {
            state.users = user
        },
        CREATE_USERS: (state, users) => {
            state.users = users
        }
    },

    actions: {

        async GET_ALL_USER({ commit }, payload) {
            await axios.post("user/getAll", { ...payload }).then((response ) => {
                commit(GET_ALL_USER, response.data[0])
            }).catch(r => {
                handlingException(r, commit, SPLICE_ERRO)
            });
        },
        async UPDATE_USER({ commit }, payload) {
            await axios.post("user/update", { ...payload }).then(({ data }) => {
                commit(UPDATE_USER, data.data);
                commit(SPLICE_ERRO, {
                    alert: data.msg,
                    type: "S",
                })
            }).catch(r => {
                handlingException(r, commit, SPLICE_ERRO)
                throw 'Error'
            });
        },
        async CREATE_USER({ commit }, payload) {
            await axios.post("user/create", { ...payload }).then(({ data }) => {
                commit(CREATE_USER, data.data)
                commit(SPLICE_ERRO, {
                    alert: data.msg,
                    type: "S",
                })
            }).catch(r => {
                handlingException(r, commit, SPLICE_ERRO)
                throw 'Error'
            })
        },
        async DESTROY_USER({ commit }, payload) {
            await axios.post("user/delete", { ...payload }).then(({ data }) => {

                commit(DESTROY_USER, payload)
                commit(SPLICE_ERRO, {
                    alert: data.msg,
                    type: "S",
                });
            }).catch(r => {
                handlingException(r, commit, SPLICE_ERRO)
                throw 'Error'
            });
        },
        SHOW_USER(_, payload) {
            return new Promise((resolve) => {
                axios.post("user/show", { id: payload }).then(({ data }) => {
                    resolve({ data })
                })
            })

        }
    },
    getters: {
        getUsers: (state) => state.users
    },
}

export default User;