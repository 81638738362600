var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DestroyDialog',{attrs:{"component":this,"destroy_object":_vm.destroy_object}}),(_vm.addDialog)?_c('Edit',{attrs:{"isUpdate":true,"component":this,"title":"Editar"}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results,"search":_vm.search,"hide-default-footer":"","no-data-text":"Sem empresas registradas","no-results-text":"Nenhum resultado encontrado"},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.component.permission.Editar)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"yellow darken-4"},on:{"click":() => {
                _vm.editItem(item);
              }}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar este Item")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"yellow darken-4"},on:{"click":() => {
                _vm.visibleItem(item);
              }}},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v("Visualizar este Item")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.component.permission.Excluir)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red darken-4"},on:{"click":() => {
                _vm.deleteItem(item);
              }}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Deletar este Item")])])]}}])}),_c('v-divider'),_c('v-pagination',{attrs:{"length":_vm.total,"total-visible":10,"color":"success"},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }