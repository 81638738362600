<template>
  <div id="myContainer" >
    <slot></slot>
  </div>
</template>

<script>
export default {
};
</script>

<style>
#myContainer{
  margin:1%;
}
</style>