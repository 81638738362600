import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import intercepetor from "./interceptors";
import VueMask from 'v-mask';
import prototypesFunctions from "./utils/prototyps";
require('dotenv').config()
import apilocal from "./apilocal"


require("./store/subscribers");

Vue.config.productionTip = false;

prototypesFunctions()

axios.defaults.baseURL = apilocal.baseURL   ///process.env.VUE_APP_URL_DEFAULT

intercepetor(axios, store)

axios.interceptors.response.use((response) => response, function (err) {
  if (err.response?.data?.auth === false) {
    store.dispatch('auth/logout')
    router.push('/', () => { })
  }

  return Promise.reject(err)
})


import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

import { VueMaskFilter } from 'v-mask'
Vue.filter('VMask', VueMaskFilter)

store.dispatch("auth/verifyToken").then(() => {
  new Vue({
    router,
    store,
    vuetify,
    VueMask,
    render: (h) => h(App),
  }).$mount("#app");
})
