const ADD_PAYMENT_TERM = "ADD_PAYMENT_TERM";
const UPDATE_PAYMENT_TERM = "UPDATE_PAYMENT_TERM";
const GET_ALL_PAYMENT_TERM = "GET_ALL_PAYMENT_TERM";
const DESTROY_PAYMENT_TERM = "DESTROY_PAYMENT_TERM";
const AUTOCOMPLETE_PAYMENT_TERM = "AUTOCOMPLETE_PAYMENT_TERM";

module.exports = {
  ADD_PAYMENT_TERM,
  UPDATE_PAYMENT_TERM,
  GET_ALL_PAYMENT_TERM,
  DESTROY_PAYMENT_TERM,
  AUTOCOMPLETE_PAYMENT_TERM
};
