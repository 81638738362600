<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this.component.addDialog" max-width="600">
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Forma de Pagamento</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="Codigo"
                v-model="code"
                :readonly="justVisible"
                maxlength="4"
                outlined
                :rules="[
                  () => !!code || 'Campo Obrigatorio',
                  () =>
                    (!!code && code.length >= 1) ||
                    'Campo tem que ser maior do que 1',
                ]"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Descrição"
                v-model="description"
                :readonly="justVisible"
                :rules="[
                  () => !!description || 'Campo Obrigatorio',
                  () =>
                    (!!description && description.length >= 2) ||
                    'Campo tem que ser maior do que 2',
                ]"
                outlined
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    executeCancelAddDialog();
                  }
                "
                >Cancelar</v-btn
              >
              <v-btn
              v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ADD_FORM_OF_PAYMENT,
  UPDATE_FORM_OF_PAYMENT,
} from "../../../store/actions/formofpayment";

export default {
  props: {
    component: Object,
  },
  data() {
    return {
      justVisible: Boolean(),
      attrs: false,
      on: false,
      valid: true,
      id: "",
      code: "",
      description: "",
      cfopToUpdate: "",
      search: "",
      formHasErrors: false,
    };
  },
  methods: {
    executeCancelAddDialog() {
      this.component.AlterAddDialog();
    },
    getComponent() {
      return this._props.component;
    },
    async create_formofpayment() {
      const formofpayment = {
        code: this.code,
        description: this.description,
      };
      try {
        await this.$store.dispatch(ADD_FORM_OF_PAYMENT, formofpayment);
      } catch (error) {
        alert(error);
      }
    },
    submit() {
      this.id == undefined
        ? this.create_formofpayment()
        : this.update_formofpayment();
      this.component.addDialog = false;
    },
    async update_formofpayment() {
      const formofpayment = {
        id: this.id,
        code: this.code,
        description: this.description,
      };
      try {
        await this.$store.dispatch(UPDATE_FORM_OF_PAYMENT, formofpayment);
      } catch (error) {
        alert(error);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  async created() {
    this.object = { ...this.getComponent().objectToUpdate };
    this.justVisible = this.object.justVisible
    
    this.id = this.object.id;
    this.code = this.object.code;
    this.description = this.object.description;
  },
  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
