const globalDialogs = {
  state: {
    showDialogRegisterGeneralDataSales: false,
    showDialogRegisterProvider: false,
    showDialogRegisterGroupEnterprise: false,
    showDialogRegisterEnterprise: false,
    showDialogRegisterCST: false,
    showDialogRegisterAccountPlan: false,
    showDialogRegisterProductGroup: false,
    showDialogRegisterSubProductGroup: false,
    showDialogRegisterCFOP: false,
    showDialogRegisterUnitMeasurement: false,
    showDialogRegisterExchangeControl: false,
    fiscalType: undefined,
    showDialogRegisterClient: false,
  },
  getters: {
    getShowDialogRegisterGeneralDataSales: function (state) {
      return state.showDialogRegisterGeneralDataSales;
    },
    getShowDialogRegisterProvider: function (state) {
      return state.showDialogRegisterProvider;
    },
    getShowDialogRegisterGroupEnterprise: function (state) {
      return state.showDialogRegisterGroupEnterprise;
    },
    getShowDialogRegisterEnterprise: function (state) {
      return state.showDialogRegisterEnterprise;
    },
    getShowDialogRegisterCST: function (state) {
      return state.showDialogRegisterCST;
    },
    getShowDialogRegisterAccountPlan: function (state) {
      return state.showDialogRegisterAccountPlan;
    },
    getShowDialogRegisterProductGroup: function (state) {
      return state.showDialogRegisterProductGroup;
    },
    getShowDialogRegisterSubProductGroup: function (state) {
      return state.showDialogRegisterSubProductGroup;
    },
    getShowDialogRegisterCFOP: function (state) {
      return state.showDialogRegisterCFOP;
    },
    getShowDialogRegisterUnitMeasurement: function (state) {
      return state.showDialogRegisterUnitMeasurement;
    },
    getFiscalType: function (state) {
      return state.fiscalType;
    },
    getShowDialogRegisterExchangeControl: function (state) {
      return state.showDialogRegisterExchangeControl
    },
    getShowDialogRegisterClient: function (state) {
      return state.showDialogRegisterClient;
    },
  },
  mutations: {
    setShowDialogRegisterExchangeControl: function (state, payload) {
      state.showDialogRegisterExchangeControl = payload;
    },
    setShowDialogRegisterGeneralDataSales: function (state, payload) {
      state.showDialogRegisterGeneralDataSales = payload;
    },
    setShowDialogRegisterProvider: function (state, payload) {
      state.showDialogRegisterProvider = payload;
    },
    setShowDialogRegisterGroupEnterprise: function (state, payload) {
      state.showDialogRegisterGroupEnterprise = payload;
    },
    setShowDialogRegisterEnterprise: function (state, payload) {
      state.showDialogRegisterEnterprise = payload;
    },
    setShowDialogRegisterCST: function (state, payload) {
      state.showDialogRegisterCST = payload;
    },
    setShowDialogRegisterAccountPlan: function (state, payload) {
      state.showDialogRegisterAccountPlan = payload;
    },
    setShowDialogRegisterProductGroup: function (state, payload) {
      state.showDialogRegisterProductGroup = payload;
    },
    setShowDialogRegisterSubProductGroup: function (state, payload) {
      state.showDialogRegisterSubProductGroup = payload;
    },
    setShowDialogRegisterCFOP: function (state, payload) {
      state.showDialogRegisterCFOP = payload;
    },
    setShowDialogRegisterUnitMeasurement: function (state, payload) {
      state.showDialogRegisterUnitMeasurement = payload;
    },
    setFiscalType: function (state, payload) {
      state.fiscalType = payload;
    },
    setShowDialogRegisterClient: function (state, payload) {
      state.showDialogRegisterClient = payload;
    },
  },

  actions: {
    setShowDialogRegisterExchangeControl: function (context, payload) {
      context.commit("setShowDialogRegisterExchangeControl", payload)
    },
    setShowDialogRegisterGeneralDataSales: function (context, payload) {
      context.commit("setShowDialogRegisterGeneralDataSales", payload);
    },
    setShowDialogRegisterProvider: function (context, payload) {
      context.commit("setShowDialogRegisterProvider", payload);
    },
    setShowDialogRegisterGroupEnterprise: function (context, payload) {
      context.commit("setShowDialogRegisterGroupEnterprise", payload);
    },
    setShowDialogRegisterEnterprise: function (context, payload) {
      context.commit("setShowDialogRegisterEnterprise", payload);
    },
    setShowDialogRegisterCST: function (context, payload) {
      context.commit("setShowDialogRegisterCST", payload);
    },
    setShowDialogRegisterAccountPlan: function (context, payload) {
      context.commit("setShowDialogRegisterAccountPlan", payload);
    },
    setShowDialogRegisterProductGroup: function (context, payload) {
      context.commit("setShowDialogRegisterProductGroup", payload);
    },
    setShowDialogRegisterSubProductGroup: function (context, payload) {
      context.commit("setShowDialogRegisterSubProductGroup", payload);
    },
    setShowDialogRegisterCFOP: function (context, payload) {
      context.commit("setShowDialogRegisterCFOP", payload);
    },
    setShowDialogRegisterUnitMeasurement: function (context, payload) {
      context.commit("setShowDialogRegisterUnitMeasurement", payload);
    },
    setFiscalType: function (context, payload) {
      context.commit("setFiscalType", payload);
    },
    setShowDialogRegisterClient: function (context, payload) {
      context.commit("setShowDialogRegisterClient", payload)
    },
  },
};
export default globalDialogs;
