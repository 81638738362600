<template>
  <v-row justify="center">
    <v-dialog persistent v-model="component.addDialog" max-width="600">
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Condição Pagamento</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="Codigo"
                v-model="code"
                :readonly="justVisible"
                :rules="[
                  () => !!code || 'Campo Obrigatorio',
                  () =>
                    (!!code && code.length <= 10) ||
                    'Campo tem que menor do que 10 ',
                ]"
                outlined
              ></v-text-field>
              <v-text-field
                label="Descrição"
                v-model="description"
                :readonly="justVisible"
                :rules="[() => !!description || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
              <v-text-field
                label="Acrescimo"
                type="number"
                v-model="addition"
                :readonly="justVisible"
                :rules="[() => !!addition || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
              <v-text-field
                label="Parcela"
                type="number"
                v-model="amount"
                :readonly="justVisible"
                v-on:blur="validDeadline()"
                :rules="[() => !!amount || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
              <v-text-field
                label="Prazo"
                :rules="[() => !!deadline || 'Campo Obrigatorio, N Inteiro']"
                v-model="deadline"
                append-icon="mdi-magnify"
                @click:append="changeDeadline()"
                readonly
                :disabled="!disableDeadline"
                outlined
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    cancelAddDialog();
                  }
                "
              >
                <v-icon class="mr-2">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
              v-if="!justVisible"
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-2">mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
      <dialogDeadline v-if="dialogDeadline" :component="this" />
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ADD_PAYMENT_TERM,
  UPDATE_PAYMENT_TERM,
} from "../../../store/actions/paymentterm";
import dialogDeadline from "../AddDialog/DeadlineDialog/";
export default {
  components: {
    dialogDeadline,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      valid: true,
      dialogDeadline: Boolean(),
      disableDeadline: Boolean(),
      day: Number(),
      justVisible: Boolean(),
      id: "",
      code: "",
      description: "",
      addition: "",
      amount: "",
      deadline: "",
      cfopToUpdate: "",
      search: "",
    };
  },
  methods: {
    cancelAddDialog() {
      this.component.AlterAddDialog();
    },
    getComponent() {
      return this._props.component;
    },
    async create_paymentterm() {
      const paymentterm = {
        description: this.description,
        code: this.code,
        addition: this.addition,
        amount: this.amount,
        deadline: this.deadline,
      };
      try {
        await this.$store.dispatch(ADD_PAYMENT_TERM, paymentterm);
      } catch (error) {
        alert(error);
      }
    },
    submit() {
      this.id == undefined
        ? this.create_paymentterm()
        : this.update_paymentterm();
      this.component.addDialog = false;
    },
    async update_paymentterm() {
      const paymentterm = {
        description: this.description,
        code: this.code,
        addition: this.addition,
        amount: this.amount,
        deadline: this.deadline,
        id: this.id,
      };
      try {
        await this.$store.dispatch(UPDATE_PAYMENT_TERM, paymentterm);
      } catch (error) {
        alert(error);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validDeadline() {
      if (this.amount > 0) {
        this.disableDeadline = true;
      } else {
        this.disableDeadline = false;
      }
    },
    changeDeadline() {
      this.dialogDeadline = !this.dialogDeadline;
    },
  },

  async created() {
    this.object = { ...this.getComponent().objectToUpdate };
    this.justVisible = this.object.justVisible
    this.id = this.object.id;
    this.description = this.object.description;
    this.addition = this.object.addition;
    this.amount = this.object.amount;
    this.deadline = this.object.deadline;
    if (this.id == undefined) {
      this.code = this.object.code;
    } else {
      this.code = this.object.code.toString();
    }
  },
  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
