<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="getShowDialogRegisterProductGroup"
      max-width="600"
    >
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Grupo de Produto</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="*Código"
                v-model="code"
                maxlength="4"
                :rules="[
                  () => !!code || 'Campo Obrigatorio',
                  () =>
                    (!!code && code.length <= 4) ||
                    'Campo tem que ser menor ou igual a 4',
                ]"
                outlined
              ></v-text-field>
              <v-text-field
                label="*Nome"
                v-model="name"
                :rules="[() => !!name || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error" @click="cancel">
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                color="success"
                :disabled="!valid"
                class="mr-4"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ADD_PRODUCT_GROUP,
  UPDATE_PRODUCT_GROUP,
} from "../../../store/actions/productgroup";
import { mapGetters } from "vuex";
export default {
  props: {
    groupProductGetAll: Boolean(),
    component: Object,
    OpenDialog: Boolean(),
    EnterPriseID: Object(),
  },

  computed: {
    ...mapGetters(["getShowDialogRegisterProductGroup"]),
  },

  data() {
    return {
      CallDialog: Boolean(),
      attrs: false,
      on: false,
      valid: true,
      id: undefined,
      code: Number(),
      name: String(),
      cfopToUpdate: "",
      search: "",
      formHasErrors: false,
    };
  },

  methods: {
    getComponent() {
      return this._props.component;
    },
    cancel() {
      this.$store.dispatch("setShowDialogRegisterProductGroup", false);
    },
    async create_productgroup() {
      const product_group = {
        code: parseInt(this.code),
        name: this.name,
        enterpriseId: this.EnterPriseID,
      };
      await this.$store.dispatch(ADD_PRODUCT_GROUP, product_group).then(() => {
        //this.cancel();
        this.CallDialog = false;
        this.$refs.form.reset()
        this.$store.dispatch("setShowDialogRegisterProductGroup", false);
      });
    },
    submit() {
      this.id == undefined
        ? this.create_productgroup()
        : this.update_productgroup();
    },
    async update_productgroup() {
      const product_group = {
        id: this.id,
        code: this.code,
        name: this.name,
      };
      await this.$store
        .dispatch(UPDATE_PRODUCT_GROUP, product_group)
        .then(() => {
          this.$refs.form.reset()
          this.cancel();
        });
    },
  },

  watch: {
    OpenDialog: function () {
      console.log(this.CallDialog);
      if (this.OpenDialog == false) {
        this.CallDialog = !this.CallDialog;
      } else if (this.OpenDialog == true) {
        this.CallDialog = !this.CallDialog;
      }
    },
  },

  async created() {
    this.object = { ...this.getComponent().objectToUpdate };
    this.id = this.object.id;
    this.name = this.object.name;
    if (this.id == undefined) {
      this.code = this.object.code;
    } else {
      this.code = this.object.code.toString();
    }
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
