import axios from "axios";
const {
  ADD_TRANSPORTER,
  UPDATE_TRANSPORTER,
  GET_ALL_TRANSPORTER,
  DESTROY_TRANSPORTER,
  ITEM_TO_UPDATE
} = require("./actions/transporter");
const { SPLICE_SUCESSO, SPLICE_ERRO } = require("./actions/alert_api")

const PhysicalPeople = 'PhysicalPeople'
const LegalPeople = 'LegalPeople'

const moduleTransport = {
  state: () => ({
    PhisycalTransporter: Array(),
    LegalTransporter: Array(),
    Pagination: Object(),
    itemToUpdateTransporter: Object(),
  }),

  mutations: {
    ITEM_TO_UPDATE: function (state, payload) {
      state.itemToUpdateTransporter = payload
    },
    GET_ALL_TRANSPORTER: (state, payload) => {
      let arr = payload.data.data
      let pag = { ...payload.data }
      delete pag.data

      while (state.PhisycalTransporter.length) {
        state.PhisycalTransporter.pop()
      }
      while (state.LegalTransporter.length) {
        state.LegalTransporter.pop()
      }

      arr.forEach((e) => {
        if (e.type_people === PhysicalPeople) {
          state.PhisycalTransporter.push(e)
        } else if (e.type_people === LegalPeople) {
          state.LegalTransporter.push(e)
        }
      })
      state.Pagination = pag

    },
    ADD_TRANSPORTER: (state, payload) => {
      if (payload.data.type_people === LegalPeople) {
        state.LegalTransporter.push(payload.data)
      } else if (payload.data.type_people === PhysicalPeople) {
        state.PhisycalTransporter.push(payload.data)
      }
    },
    UPDATE_TRANSPORTER(state, payload) {
      let data = payload.data
      let index = -1
      if (data.type_people === LegalPeople) {
        let stateLegal = state.LegalTransporter
        index = stateLegal.findIndex(e => e.people_id === data.people_id)
        if (index >= 0) {
          stateLegal.splice(index, 1, data)
          state.LegalTransporter = stateLegal
        }
      } else if (data.type_people === PhysicalPeople) {
        let statePhysical = state.PhisycalTransporter
        index = statePhysical.findIndex(e => e.people_id === data.people_id)
        if (index >= 0) {
          statePhysical.splice(index, 1, data)
          state.PhisycalTransporter = statePhysical
        }
      }
    },
    DESTROY_TRANSPORTER: (state, payload) => {
      let index = -1
      if (payload.type_people === "PhysicalPeople") {
        let statePhisycal = state.PhisycalTransporter
        index = state.PhisycalTransporter.findIndex(value => value.id === payload.id)
        if (index >= 0) {
          statePhisycal.splice(index, 1)
          state.PhisycalTransporter = statePhisycal
        }
      } else if (payload.type_people === "LegalPeople") {
        let stateLegal = state.LegalTransporter
        index = state.LegalTransporter.findIndex(value => value.id === payload.id)
        if (index >= 0) {
          stateLegal.splice(index, 1)
          state.LegalTransporter = stateLegal
        }
      }
    },
  },
  actions: {
    ITEM_TO_UPDATE: function (context, payload) {

      payload.item.justVisible = payload.justVisible ? true : false
      context.commit(ITEM_TO_UPDATE, payload.item)
    },
    GET_ALL_TRANSPORTER({
      commit
    }, payload) {
      axios.post("transporter/index", {
        page: payload.page,
        type_people: payload.type_people,
        limit: payload.limit
      }).then((response) => {
        commit(GET_ALL_TRANSPORTER, response.data);
      });
    },

    ADD_TRANSPORTER({
      commit
    }, payload) {
      axios.post("transporter/create", payload).then((response) => {
        commit(ADD_TRANSPORTER, response.data);
        commit(SPLICE_SUCESSO, {
          alert: response.data.msg,
          type: 'S'
        })
      }).catch((e) => {
        console.log(e.response)
        e.response.data.errors.forEach((el) => {
          commit(SPLICE_ERRO, {
            alert: el,
            type: 'E'
          })
        })
      });
    },

    UPDATE_TRANSPORTER({
      commit
    }, payload) {

      axios.post("transporter/update", payload).then((response) => {
        commit(UPDATE_TRANSPORTER, response.data);
        commit(SPLICE_SUCESSO, {
          alert: response.data.msg,
          type: 'S'
        })
      }).catch((e) => {
        e.response.data.errors.forEach((el) => {
          commit(SPLICE_ERRO, {
            alert: el,
            type: 'E'
          })
        })
      })
    },

    DESTROY_TRANSPORTER({
      commit
    }, payload) {

      axios
        .post("transporter/delete", {
          id: payload.id,
        })
        .then((response) => {
          response
          commit(DESTROY_TRANSPORTER, payload);
          commit(SPLICE_ERRO, {
            alert: response.data.msg,
            type: 'S'
          })
        }).catch((e) => {
          e.response.data.errors.forEach((el) => {
            commit(SPLICE_ERRO, {
              alert: el,
              type: 'E'
            })
          })
        });
    },
  },

  getters: {
    getPhisycalTransporter: (state) => state.PhisycalTransporter,
    getLegalTransporter: (state) => state.LegalTransporter,
    getPagination: (state) => ({ ...state.Pagination }),
    getItemToUpdateTransporter: (state) => state.itemToUpdateTransporter,
  },
};

export default moduleTransport;