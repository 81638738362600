import axios from "axios";
import apifiscal from "../apifiscal";
import apigestao from "../apigestao";



export default {
  namespaced: true,
  state: {
    token: null,
    token_fiscal: null,
    token_gestao: null,
    isAuth: Boolean(),
    user: null,
    feeds: [],
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;

    },
    SET_TOKEN_FISCAL(state, token_fiscal) {
      state.token_fiscal = token_fiscal;
    },
    SET_TOKEN_GESTAO(state, token_gestao) {
      state.token_gestao = token_gestao;
    },
    SET_ISVALID(state, token) {
      state.isAuth = token;
    },
    SET_LOGOUT_USER(state, user) {
      state.user = user;
    },
    SET_LOGOUT_USER(state, user) {
      state.user = user
    },
    async SET_USER(state, data) {
      state.user = data || {
        user: {}
      };
      if (data) {
        let hasExpired = await Object.prototype.hasOwnProperty.call(data, 'status')
        if (hasExpired) {
          localStorage.removeItem('token')
          localStorage.removeItem('token_gestao')
          localStorage.removeItem('token_fiscal')
          localStorage.removeItem('authenticated')
        } else {
          localStorage.setItem('authenticated', JSON.stringify(data))
        }
      }

    },

    SPLICE_ERRO: (state, err) => {
      state.feeds.push({
          ...err
      })
      let interval = setInterval(() => {

          if (state.feeds.length != 0) {

              state.feeds.splice(0, 1)
          } else if (state.feeds.length === 0) {
              clearInterval(interval);
          }
      }, 5000);
  },
  },

  actions: {
    loginGestao({ commit }, credentials) {
      apigestao.post("login", credentials)
        .then((response) => {
          localStorage.setItem("token_gestao", response.data.token);
          commit("SET_TOKEN_GESTAO", response.data.token);
        })
        .catch(() => {
          // commit("SPLICE_ERRO", {
          //   alert: "Não foi possivel autenticar usuario",
          //   type: "E",
          // })
        });
    },

    loginApifiscal({
      commit }, credentials) {
      apifiscal.post("login", credentials)
        .then((response) => {
          localStorage.setItem("token_fiscal", response.data.token);
          commit("SET_TOKEN_FISCAL", response.data.token)
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit("SPLICE_ERRO", {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    async login({ dispatch,
      commit }, credentials) {
      await axios.post("login", credentials)
        .then((response) => {
          return dispatch("attempt", response.data.token);
        })
        .catch(() => {
          commit("SPLICE_ERRO", {
            alert: "Login ou Senha Incorretos",
            type: "E",
          })
        });

      apifiscal
        .post("login", credentials)
        .then((response) => {

          commit("SET_TOKEN_FISCAL", response.data.token)
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit("SPLICE_ERRO", {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    verifyToken({ commit }) {
      try {
        const token_exist = localStorage.getItem("token");

        if (token_exist) commit("SET_TOKEN", token_exist);

        return false;
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },
    async attempt({
      commit,
      state
    }, token) {

      if (token) {
        commit("SET_TOKEN", token);
      }
      if (!state.token) {
        return;
      }
      try {
        const response = await axios.get("user");
        commit("SET_USER", response.data);
        commit("SET_ISVALID", true);
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },

    async logout({ commit, dispatch }) {
      window.location.href = "/"
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
      commit("SET_LOGOUT_USER", null)
      commit("auth/SET_ISVALID", false)
      localStorage.removeItem("authenticated");
      localStorage.removeItem('token')
      localStorage.removeItem('token_fiscal')
      localStorage.removeItem('token_gestao')
      dispatch("CLEAR_STATE_ALL", null, { root: true });
     
    },
  },
  getters: {
    authenticated(state) {
      return state.token && state.user && state.token_fiscal && state.token_gestao
    },
    userLogin: (state) => state.user || {},
    getIsAuth: (state) => state.isAuth,
    user: (state) => state.user,
    getUser: (state) => state.user,
    getToken: (state) => state.token,
    getFeedsLogin: (state) => state.feeds
  },
};








    // if (token) {
      //   state.token = token;
      //   localStorage.setItem("token", token);
      // } else {
      //   localStorage.removeItem("token");
      //   state.token = null;
      // }


          // if (token_fiscal) {
      //   state.token_fiscal = token_fiscal;
      //   localStorage.setItem("token_fiscal", token_fiscal);
      // } else {
      //   localStorage.removeItem("token_fiscal");
      //   state.token_fiscal = null;
      // }


          // if (token_gestao) {
      //   state.token_gestao = token_gestao;
      //   localStorage.setItem("token_gestao", token_gestao);
      // }
      // else {
      //   localStorage.removeItem("token_gestao");
      //   state.token_gestao = null;
      // }