<template>
    <v-row justify="center">
      <v-dialog persistent v-model="component.dialogCancel" hide-overlay transition="dialog-bottom-transition" fullscreen>
        <v-card>
          <v-toolbar color="success" class="white--text" flat>
            <v-btn icon dark @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Estorno de Nfce</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
  
          <v-container>
  
  
            <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
              <v-tab v-for="(item, index) in newArrayItems" :key="index" :href="'#tab-' + item.href">
                {{ item.tabName }}
              </v-tab>
  
              <v-menu v-if="$vuetify.breakpoint.xsOnly" bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="align-self-center mr-4" v-bind="attrs" v-on="on">
                    more
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
  
                <v-list class="grey lighten-3">
                  <v-list-item v-for="(item, index) in more" :key="index" @click="addItem(item)">
                    {{ item.tabName }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-tabs>
  
            <v-tabs-items v-model="currentItem">
              <v-tab-item value="tab-1">
                <v-col>
                  <GeneralDate :component="this" />
                </v-col>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-col>
                  <RevenuesItems :component="this" />
                </v-col>
              </v-tab-item>
              <v-tab-item value="tab-3">
                <v-col>
                  <InformationRevenue :component="this" />
                </v-col>
              </v-tab-item>
            </v-tabs-items>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error" @click="closeDialog()">
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn>
              <v-btn class="success" @click="save()">
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  import GeneralDate from "./Tabs/TabGenerateDate/index.vue";
  import InformationRevenue from "./Tabs/TabInfoNote/index.vue";
  import RevenuesItems from "./Tabs/TabNoteItens/index.vue";
  import {CANCEL_EXCHANGE} from "../../../store/actions/exchange_control"
  export default {
    components: {
      GeneralDate,
      InformationRevenue,
      RevenuesItems,
    },
    props: {
      component: Object,
    },
    data: () => ({
      newArrayItems: Array(),
      currentItem: "tab-1",
      items: [
        {
          tabName: "Dados Gerais",
          href: "1",
        },
      ],
      more: [
        {
          tabName: " Itens da Nota",
          href: "2",
        },
        {
          tabName: "Informações da nota",
          href: "3",
        },
      ],
      nfceData: Object(),
    }),
    methods: {
        setArrayItens() {
            this.newArrayItems = Array();
            if (this.$vuetify.breakpoint.xsOnly) {
                this.newArrayItems = this.items;
            } else {
                this.newArrayItems = this.items.concat(this.more);
            }
        },
        addItem(item) {
            const removed = this.items.splice(0, 1);
            this.items.push(...this.more.splice(this.more.indexOf(item), 1));
            this.more.push(...removed);
            this.$nextTick(() => {
                this.currentItem = "tab-" + item.href;
            });
        },
        closeDialog() {
          this.component.dialogCancel = false
        },
        setData() {
          this.nfceData = this.component.cancelExchangeData
          this.nfceData.sale.cliente.people.name = String()
          if(this.nfceData.sale.cliente.type_people == "PhysicalPeople") {
            this.nfceData.sale.cliente.people.name = this.nfceData.sale.cliente.people.physicalpeople.name
          } else {
            this.nfceData.sale.cliente.people.name = this.nfceData.sale.cliente.people.legalpeople.fantasyName
          }
          this.nfceData.sale.info = String()
          this.nfceData.sale.taxoperationToCancel = this.component.taxOperationToCancel
        },
        async save() {
          const payload = this.nfceData
            await this.$store.dispatch(CANCEL_EXCHANGE, payload);
            this.closeDialog()
        }
    },
    created() {
        this.setArrayItens();
        this.setData()
  },
  };
  </script>
  
  