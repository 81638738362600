var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Pesquisar","outlined":"","append-icon":"mdi-magnify"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.autocomplete_exchange()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider'),_c('v-data-table',{attrs:{"hide-default-footer":"","items":_vm.itemsExchange,"headers":_vm.Header,"no-data-text":"Sem registro!"},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":() => {
              _vm.view_info(item);
            }}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v("Visualizar Troca")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green darken-4"},on:{"click":() => {
            _vm.print_item(item);
          }}},'v-icon',attrs,false),on),[_vm._v("mdi-printer")])]}}],null,true)},[_c('span',[_vm._v("Imprimir Troca")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":() => {
            _vm.cancelExchange(item);
          }}},'v-icon',attrs,false),on),[_vm._v("mdi-note-edit")])]}}],null,true)},[_c('span',[_vm._v("Estorno fora do prazo")])])]}}],null,true)}),_c('v-divider'),_c('v-pagination',{staticClass:"mb-1 mt-1",attrs:{"length":_vm.paginationInformation.last_page},model:{value:(_vm.paginationInformation.current_page),callback:function ($$v) {_vm.$set(_vm.paginationInformation, "current_page", $$v)},expression:"paginationInformation.current_page"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('svg',{attrs:{"id":"barcode"}})]),(_vm.dialogCancel)?_c('AddDialogCancel',{attrs:{"component":this}}):_vm._e(),(_vm.taxOperationTypeErrorDialog)?_c('TaxOperationTypeDialog',{attrs:{"component":this}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }