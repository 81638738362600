let tributeDomainRegimeNormal = require("../../../Domain/Service/Tribute/TributeIcmsRegimeNormalDomainServices")
let tributeDomainRegimeSimples = require("../../../Domain/Service/Tribute/TributeIcmsSimplesDomainService")
let tributeDomainCstPisCofins = require("../../../Domain/Service/Tribute/TributePisCofinsDomainService")
let tributeDomainIpi = require("../../../Domain/Service/Tribute/TributeIpiDomainServices")

const TYPE_ENTERPRISE_LUCRO_REAL = "1"
const TYPE_ENTERPRISE_LUCRO_PRESUMIDO = "2"
const TYPE_ENTERPRISE_SIMPLES_NACIONAL = "3"
const TYPE_ENTERPRISE_MEI = "4"

async function calculeTributeSales(product, enterprise) {
    try {
        let enterprise_regime = enterprise.tax_regime
        let cfop_sales = product.cfopProduct
        let csosn_sales = product.csosnProduct
        let aliquot_pis  = enterprise.aliquot_pis
        let aliquot_cofins = enterprise.aliquot_cofins
        let productTribute  = {}
        switch (enterprise_regime) {
            case TYPE_ENTERPRISE_LUCRO_REAL:
                productTribute = await tributeDomainRegimeNormal.verifyTypeOperationIcmsEnterpriseTaxRegimeNormal(cfop_sales, product)
                productTribute = await  tributeDomainCstPisCofins.calculeTributeCstPisCofins(product, aliquot_pis,aliquot_cofins )
                productTribute = await tributeDomainIpi.setValueIpidefault(product)
                return productTribute
               
                case TYPE_ENTERPRISE_LUCRO_PRESUMIDO:
                productTribute = await  tributeDomainRegimeNormal.verifyTypeOperationIcmsEnterpriseTaxRegimeNormal(cfop_sales, product)
                productTribute = await  tributeDomainCstPisCofins.calculeTributeCstPisCofins(product, aliquot_pis,aliquot_cofins )
                productTribute = await tributeDomainIpi.setValueIpidefault(product)
                return productTribute
             
                case TYPE_ENTERPRISE_SIMPLES_NACIONAL:
                productTribute = await  tributeDomainRegimeSimples.verifyTypeOperationEnterpriseTaxRegimeSimples(csosn_sales, product)
                productTribute = await  tributeDomainCstPisCofins.calculeTributeCstPisCofins(product, aliquot_pis,aliquot_cofins )             
                productTribute = await tributeDomainIpi.setValueIpidefault(product)
                return productTribute
             
                case TYPE_ENTERPRISE_MEI:
                productTribute = tributeDomainRegimeNormal.verifyTypeOperationEnterpriseTaxRegimeSimples(cfop_sales, product)
                productTribute = tributeDomainCstPisCofins.calculeTributeCstPisCofins(product, aliquot_pis,aliquot_cofins )
                productTribute = await tributeDomainIpi.setValueIpidefault(product)
                return productTribute
            default:
                throw  'Tipo de empresa não encontrada!'

        }

    } catch (error) {
        return error
    }

}
module.exports = {
    calculeTributeSales
}

