


const initials = [
    { text: "Acre", value: "ac" },
    { text: "Alagoas", value: "al" },
    { text: "Amapá", value: "ap" },
    { text: "Amazonas", value: "am" },
    { text: "Bahia", value: "ba" },
    { text: "Ceará", value: "ce" },
    { text: "Espírito Santo", value: "es" },
    { text: "Goiás", value: "go" },
    { text: "Maranhão", value: "ma" },
    { text: "Mato Grosso", value: "mt" },
    { text: "Mato Grosso do Sul", value: "ms" },
    { text: "Minas Gerais", value: "mg" },
    { text: "Pará", value: "pa" },
    { text: "Paraíba", value: "pb" },
    { text: "Paraná", value: "pr" },
    { text: "Pernambuco", value: "pe" },
    { text: "Piauí", value: "pi" },
    { text: "Rio de Janeiro", value: "rj" },
    { text: "Rio Grande do Norte", value: "rn" },
    { text: "Rio Grande do Sul", value: "rs" },
    { text: "Rondônia", value: "ro" },
    { text: "Roraima", value: "rr" },
    { text: "Santa Catarina", value: "sc" },
    { text: "São Paulo", value: "sp" },
    { text: "Sergipe", value: "se" },
    { text: "Tocatins", value: "to" },
    { text: "Distrito Federal", value: "df" },
]


export default initials