<template>
  <div>
    <v-data-table
      hide-default-footer
      no-data-text="Sem registro!"
      :items="configs"
      :headers="header"
    >
      <template v-slot:item.level="{ item }">
        <v-text-field v-model="item.value" dense outlined></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    configs: Array,
  },
  data: () => ({
    header: [
      { text: "Descrição", value: "description" },
      { text: "Valor", value: "level" },
    ],
  }),
};
</script>

<style>
</style>