const ADD_NCM = "ADD_NCM";
const UPDATE_NCM = "UPDATE_NCM";
const GET_ALL_NCM = "GET_ALL_NCM";
const DESTROY_NCM = "DESTROY_NCM";

module.exports = {
    ADD_NCM,
    UPDATE_NCM,
    GET_ALL_NCM,
    DESTROY_NCM ,
}
