<template>
  <div>
    <DestroyDialog :component="this" :destroy_object="destroy_object" />
    <Edit
      :isUpdate="true"
      v-if="addDialog"
      :component="this"
      title="Editar"
    />
    <v-data-table
      :headers="headers"
      :items="results"
      :search="search"
      hide-default-footer
      no-data-text="Sem empresas registradas"
      no-results-text="Nenhum resultado encontrado"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              v-if="component.permission.Editar"
              @click="
                () => {
                  editItem(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar este Item</span>
        </v-tooltip>
        
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  visibleItem(item);
                }
              "
              >mdi-eye</v-icon
            >
          </template>
          <span>Visualizar este Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              v-if="component.permission.Excluir"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar este Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      :total-visible="10"
      color="success"
    >
    </v-pagination>
  </div>
</template>

<script>
import { headers } from "./script";
import DestroyDialog from "../DestroyDialog/destroyDialog";
import { DESTROY_ENTERPRISE, ITEM_TO_UPDATE_ENTERPRISE } from "../../../store/actions/enterprise";
import Edit from "../AddDialog/";

export default {
  components: { DestroyDialog, Edit },

  props: {
    getters: Object,
    component: Object,
    destroy_object: Function,
    search: String,
  },

  data: () => ({
    headers,
    destroyDialog: false,
    addDialog: Boolean(),
    DialogEdit: Boolean(),
    results: Array(),
    total: Number(),
    current: Number(),
    currentItem: {},
    page: 1,
    itemToUpdade: Object(),
  }),

  methods: {
    visibleItem(item){
      
      item.justVisible = true
      this.$store.dispatch(ITEM_TO_UPDATE_ENTERPRISE, {...item})
      this.component.addDialog = true
    },
    editItem(item) {
      // this.$store.dispatch(ITEM_TO_UPDATE_ENTERPRISE, {...item})
      // this.addDialog = !this.addDialog;
      item.justVisible = false
      item.update = true
      this.$store.dispatch(ITEM_TO_UPDATE_ENTERPRISE, {...item})
      this.component.addDialog = true
    },
    deleteItem(item) {
      this.destroyDialog = true;
      this.currentItem = { ...item };
    },
    async confirmDelete() {
      try {
        await this.$store.dispatch(DESTROY_ENTERPRISE, this.currentItem);
      } catch (e) {
        alert(e);
      } finally {
        this.destroyDialog = !this.destroyDialog;
      }
    },
  },
  created() {
    this.component.GetAll();
  },

  watch: {
    addDialog: function(value){
      if(!value){
        this.component.GetAll(); 
      }
    },
    getters: function (val) {
      this.results = val.data;
      this.total = 1
      this.current = 1;
    },

    current: function (val) {
      this.component.GetAll && this.component.GetAll(val);
    },
  },
};
</script>
