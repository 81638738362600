<template>
  <div>
    <DestryDialog
      v-if="destroyDialog"
      :component="this"
      :destroy_object="destroyItem"
    />
    <v-data-table
      :headers="headers"
      :items="results"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="!component.permission.Editar"
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  change_EditItem(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="!component.permission.Excluir"
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
            <v-icon color="green darken-4" class="mr-2" v-if="component.permission.Excluir" @click="
          () => {
              visibleItem(item);
                }
               ">mdi-eye</v-icon>

          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
  </div>
</template>

<script>
//
import { mapGetters } from "vuex";
import DestryDialog from "./../DestroyDialog/";
export default {
  props: {
    component: Object,
    search: String,
  },
  components: { DestryDialog },
  data: () => ({
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
    destroyDialog: Boolean(),
    destroyItem: Object(),
    headers: [
      { text: "Código", value: "id" },
      { text: "Sigla", value: "code" },
      { text: "Descrição", value: "description" },
      { text: "Saldo inicial", value: "initial_balance" },
      { text: "Data inicial", value: "initial_date" },
      { text: "Saldo atual", value: "balance_current" },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    deleteItem(item) {
      this.destroyItem = { ...item };
      this.destroyDialog = !this.destroyDialog;
    },
    change_EditItem(item) {
      this.component.change_addDialog({...item, justVisible: false });
    },
    visibleItem(item){
      this.component.change_addDialog({...item, justVisible: true});
    },
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
  },
  mounted() {
    this.component.GetAll();
  },
  computed: {
    ...mapGetters(["getCashier"]),
  },

  watch: {
    getCashier: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      this.component.GetAll && this.component.GetAll(val);
    },
  },
};
</script>
