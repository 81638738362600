const ADD_CST_IPI = "ADD_CST_IPI";
const UPDATE_CST_IPI = "UPDATE_CST_IPI";
const GET_ALL_CST_IPI = "GET_ALL_CST_IPI";
const DESTROY_CST_IPI = "DESTROY_CST_IPI";
const GET_FILTER_CST_IPI = "GET_FILTER_CST_IPI";

module.exports = {
    ADD_CST_IPI,
    UPDATE_CST_IPI,
    GET_ALL_CST_IPI,
    DESTROY_CST_IPI,
    GET_FILTER_CST_IPI
}
