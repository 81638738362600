import axios from "axios";
import axiosautomacao from "../apigestao"
const NETWORK_ERROR = "Network Error"
const {
  ADD_PF_CLIENT,
  ADD_PJ_CLIENT,
  UPDATE_PF_CLIENT,
  UPDATE_PJ_CLIENT,
  GET_ALL_CLIENT,
  GET_ALL_CLIENT_OFFLINE,
  DESTROY_CLIENT,
  GET_AUTOCOMPLETE_CLIENT,
  ITEM_TO_UPDATE_CLIENT,
  GET_BY_ID,
  GET_BY_ID_NOT_IDENTIFY_CLIENT,
  OBJECT_TO_UPDATE
} = require("./actions/client");

const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleClient = {
  state: () => ({
    Phisycalclients: Array(),
    LegalClients: Array(),
    legalAutocomplete: Array(),
    physicalAutocomplete: Array(),
    addedClient: Object(),
    itemToUpdateClient: null,
    itemToDelete: Object(),
    Result: Object(),
    PhysicalPeopleClient: Object(),
    PaginationClient: Object(),
    objectToUpdate: Object(),
  }),

  mutations: {
    OBJECT_TO_UPDATE: function (state, payload) {
      state.objectToUpdate = payload
    },
    ITEM_TO_UPDATE_CLIENT: function (state, payload) {
      state.itemToUpdateClient = payload
    },
    GET_BY_ID_NOT_IDENTIFY_CLIENT: (state, payload) => {
      state.Result = {}
    },


    GET_BY_ID: (state, payload) => {
      let client = {}
      if (payload.type_people === "PhysicalPeople") {
        client.type_people = payload.type_people
        client.address = { ...payload.people.address }
        client.banks = { ...payload.people.bank_data }
        client.contacts = { ...payload.people.contact }
        client.id = payload.id
        client.legalpeople = null
        client.physicalpeople = { ...payload.people.physicalpeople }
        client.people_id = payload.people_id
      } else {
        client.address = { ...payload.people.address }
        client.banks = { ...payload.people.bank_data }
        client.contacts = { ...payload.people.contact }
        client.id = payload.id
        client.legalpeople = { ...payload.people.legalpeople }
        client.physicalpeople = null
        client.people_id = payload.people_id
      }
      state.Result = client

    },
    GET_ALL_CLIENT: (state, payload) => {
      let pages = {};
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let physicalpeople = {};
      let legalpeople = {};
      var indx = 0;
      let auxPayload = { ...payload };
      delete auxPayload.data;

      pages.current_page = payload.current_page 
      pages.last_page = payload.last_page
      pages.to = payload.to
      pages.total = payload.total

      state.PaginationClient = { ...payload };
      if (payload.type === "PhysicalPeople") {
        while (state.Phisycalclients.length) {
          state.Phisycalclients.pop();
        }
      } else {
        while (state.LegalClients.length) {
          state.LegalClients.pop();
        }
      }
      // Estruturar os dados
      payload.data.forEach((e) => {
        if (e.type_people === "PhysicalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};

          physicalpeople = { ...e.people.physicalpeople };
          contacts = e.people.contact;
          address = e.people.address;
          banks = e.people.bank_data;

          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { physicalpeople });

          Infos.type_people = e.type_people;

          Infos.enterprise_id = e.enterprise_id;
          Infos.page = pages

          Infos.id = e.id;
          indx = state.Phisycalclients.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.Phisycalclients.push(Infos);
          }
          while (state.PhysicalPeopleClient.length) {
            state.PhysicalPeopleClient.pop();
          }
          auxPayload.data = state.Phisycalclients
          state.PhysicalPeopleClient = auxPayload

        } else if (e.type_people === "LegalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};

          legalpeople = { ...e.people.legalpeople };
          address = e.people.address;
          contacts = e.people.contact;
          banks = e.people.bank_data;
          physicalpeople = null

          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { legalpeople });
          Object.assign(Infos, { physicalpeople });
 
          Infos.type_people = e.type_people;
          Infos.enterprise_id = e.enterprise_id;
          Infos.id = e.id;
          Infos.page = pages

          indx = state.LegalClients.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.LegalClients.push(Infos);
          }
        }
      });
    },
    GET_ALL_CLIENT_OFFLINE: (state, payload) => {
      let arr = payload.data.data
      let pag = { ...payload.data }
      delete pag.data

      while (state.PhisycalClients.length) {
        state.PhisycalClients.pop()
      }
      while (state.LegalClients.length) {
        state.LegalClients.pop()
      }

      arr.forEach((e) => {
        if (e.type_people === PhysicalPeople) {
          state.PhisycalClients.push(e)
        } else if (e.type_people === LegalPeople) {
          state.LegalClients.push(e)
        }
      })
      state.PaginationClient = pag


    },
    GET_AUTOCOMPLETE_CLIENT: (state, payload) => {
      let pages = {};
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let physicalpeople = {};
      let legalpeople = {};
      var indx = 0;
      let auxPayload = { ...payload };
      delete auxPayload.data;

      pages.current_page = payload.current_page 
      pages.last_page = payload.last_page
      pages.to = payload.to
      pages.total = payload.total

      state.PaginationClient = { ...payload };
      if (payload.type === "PhysicalPeople") {
        while (state.Phisycalclients.length) {
          state.Phisycalclients.pop();
        }
      } else {
        while (state.LegalClients.length) {
          state.LegalClients.pop();
        }
      }
      // Estruturar os dados
      payload.data.forEach((e) => {
        if (e.type_people === "PhysicalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};

          physicalpeople = { ...e.people.physicalpeople };
          contacts = e.people.contact;
          address = e.people.address;
          banks = e.people.bank_data;

          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { physicalpeople });

          Infos.type_people = e.type_people;

          Infos.enterprise_id = e.enterprise_id;
          Infos.page = pages

          Infos.id = e.id;
          indx = state.Phisycalclients.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.Phisycalclients.push(Infos);
          }
          while (state.PhysicalPeopleClient.length) {
            state.PhysicalPeopleClient.pop();
          }
          auxPayload.data = state.Phisycalclients
          state.PhysicalPeopleClient = auxPayload

        } else if (e.type_people === "LegalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};

          legalpeople = { ...e.people.legalpeople };
          address = e.people.address;
          contacts = e.people.contact;
          banks = e.people.bank_data;
          physicalpeople = null

          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { legalpeople });
          Object.assign(Infos, { physicalpeople });
 
          Infos.type_people = e.type_people;
          Infos.enterprise_id = e.enterprise_id;
          Infos.id = e.id;
          Infos.page = pages

          indx = state.LegalClients.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.LegalClients.push(Infos);
          }
        }
      });
    },

    ADD_PF_CLIENT: (state, payload) => {
      let Infos = {};
      let physicalpeople = {}
      let contacts = {};
      let address = {};
      let banks = {};

      physicalpeople = { ...payload.people.physicalpeople };
      contacts = payload.people.contact;
      address = payload.people.address;
      banks = payload.people.bank_data;

      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks });
      Object.assign(Infos, { physicalpeople })
      Infos.type_people = payload.type_people;
      Infos.enterprise_id = payload.enterprise_id;
      Infos.id = payload.id;

      state.addedClient = Infos
      state.Phisycalclients.push(Infos);
    },

    ADD_PJ_CLIENT: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let legalpeople = {};
      let physicalpeople = null

      legalpeople = { ...payload.people.legalpeople };
      address = payload.people.address;
      contacts = payload.people.contact;
      banks = payload.people.bank_data;

      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks });
      Object.assign(Infos, { legalpeople });
      Infos.type_people = payload.type_people;
      Infos.enterprise_id = payload.enterprise_id;
      Infos.id = payload.id;
      Infos.physicalpeople = physicalpeople

      state.addedClient = Infos
      state.LegalClients.push(Infos);
    },

    UPDATE_PF_CLIENT(state, payload) {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      Infos = { ...payload.people.physicalpeople };
      contacts = payload.people.contact;
      address = payload.people.address;
      banks = payload.people.bank_data;
      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks });
      Infos.type_people = payload.type_people;
      Infos.enterprise_id = payload.enterprise_id;
      Infos.id = payload.id;

      state.Phisycalclients.Update(Infos);
    },
    UPDATE_PJ_CLIENT(state, payload) {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};

      Infos = { ...payload.people.legalpeople };
      address = payload.people.address;
      contacts = payload.people.contact;
      banks = payload.people.bank_data;

      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks });
      Infos.type_people = payload.type_people;
      Infos.enterprise_id = payload.enterprise_id;
      Infos.id = payload.id;
      state.LegalClients.Update(Infos);
    },
    DESTROY_CLIENT: (state, payload) => {
      state.itemToDelete = payload
    },
  },

  actions: {
    ITEM_TO_UPDATE_CLIENT: function (context, payload) {
      context.commit(ITEM_TO_UPDATE_CLIENT, payload)
    },

    async GET_BY_ID({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      await axiosautomacao.post("client/show", { id: payload.id, enterprise_id: payload.enterprise_id }, {
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      })
        .then((response) => {
          commit(GET_BY_ID, response.data[0])
        }).catch((error) => {
          commit(GET_BY_ID_NOT_IDENTIFY_CLIENT, {})
          commit(SPLICE_ERRO, {
            alert: 'Cliente não encontrado!',
            type: 'E'
          })
        })
    },
    GET_AUTOCOMPLETE_CLIENT({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axiosautomacao
        .post("client/autocomplete", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
          search: payload.search,
          enterprise_id: payload.enterprise_id,
        },{
          headers: {
            'Authorization': 'Bearer ' + token,
  
          }
        })
        .then((response) => {
          response.data[0].type = payload.type_people;
          commit(GET_AUTOCOMPLETE_CLIENT, response.data[0]);
        });
    },
    GET_ALL_CLIENT_OFFLINE({ commit }, payload) {
      axios
        .post("client/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
        })
        .then((response) => {
          response.data[0].type = payload.type_people;
          commit(GET_ALL_CLIENT_OFFLINE, response.data[0]);
        });
    },

    GET_ALL_CLIENT({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axiosautomacao
        .post("client/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
        }, {
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        }
        )
        .then((response) => {
          response.data[0].type = payload.type_people;
          commit(GET_ALL_CLIENT, response.data[0]);
        }).catch((error) => {
          commit(SPLICE_ERRO, {
            alert: "Erro ao carregar os clientes!",
            type: "E",
          });
          if (NETWORK_ERROR == error.message) {
            axios
              .post("client/index", {
                page: data.page,
                type_people: data.type_people,
              })
              .then((response) => {
                commit(GET_ALL_CLIENT_OFFLINE, response.data);
              });
          }
        })
    },

    ADD_PF_CLIENT({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      return new Promise((resolve, reject) => {
        axiosautomacao
          .post("client/createphysicalpeople", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
          .then((response) => {
            resolve();
            response.data[0].enterprise_id = payload.enterprise_id;
            commit(ADD_PF_CLIENT, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Cliente cadastrado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },
    ADD_PJ_CLIENT({ commit }, payload) {
       let token = localStorage.getItem("token_gestao");
      return new Promise((resolve, reject) => {
        axiosautomacao
          .post("client/createlegalPeople", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
          .then((response) => {
            resolve();
            response.data[0].enterprise_id = payload.enterprise_id;
            commit(ADD_PJ_CLIENT, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Cliente cadastrado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },
    UPDATE_PF_CLIENT({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      return new Promise((resolve, reject) => {
        axiosautomacao
          .post("client/updatephysicalpeople", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
          .then((response) => {
            resolve();
            response.data[0].enterprise_id = payload.enterprise_id;
            commit(UPDATE_PF_CLIENT, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Cliente atualizado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    UPDATE_PJ_CLIENT({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      return new Promise((resolve, reject) => {
        axiosautomacao
          .post("client/updatelegalpeople", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
          .then((response) => {
            resolve();
            response.data[0].enterprise_id = payload.enterprise_id;
            commit(UPDATE_PJ_CLIENT, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Cliente atualizado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    DESTROY_CLIENT({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axiosautomacao
        .post("client/delete", {
          id: payload.id,
          enterprise_id: payload.enterprise_id,
        }, {
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        })
        .then((response) => {
          response, commit(DESTROY_CLIENT, payload);
          commit(SPLICE_ERRO, {
            alert: "Cliente deletado com sucesso!",
            type: "S",
          });
        });
    },
  },

  getters: {
    getItemToDelete: (state) => state.itemToDelete,
    getAddedClients: (state) => state.addedClient,
    getPhysicalClients: (state) => state.Phisycalclients,
    getLegalClients: (state) => state.LegalClients,
    getClientPhysicalAuto: (state) => state.physicalAutocomplete,
    getClientLegalAuto: (state) => state.legalAutocomplete,
    getItemToUpdateClient: (state) => state.itemToUpdateClient,
    getResult: (state) => ({ ...state.Result }),
    getPhysicalPeople: (state) => ({
      ...state.PhysicalPeopleClient
    }),
    getPaginationClient: (state) => ({ ...state.PaginationClient }),
    getObjectToUpdate: (state) => state.objectToUpdate
  },
};

export default moduleClient;
