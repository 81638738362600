let destinationNFeInitial = {
  cnpjorcpfDestination: "",
  idDestination: "",
  nameDestination: "",
  publicPlaceDestination: "",
  numberDestination: "",
  complementDestination: "",
  districtDestination: "",
  codeCountyDestination: "",
  nameCountyDestination: "",
  ufDestination: "",
  zipCodeDestination: "",
  codeCountryDestination: "",
  nameCountryDestination: "",
  numberPhoneDestination: "",
  recipientIEIndicatorDestination: "",
  stateRegistrationDestinationNumber: "",
  stateSuframaRegistration: "",
  stateMunicipalRegistrationDestination: "",
  emailDestination: "",
};

const CONSUMER_DESTINATION_FINAL  = 1 

async function execute(sales) {  
  try {
    let clientIdentify =  sales.cliente.id  
     if (clientIdentify == CONSUMER_DESTINATION_FINAL){
      return  await mounterDestinationFinalConsumer()
     }else {
       return  await mounterConsumerDestinationFinalIdentify(sales)
     } 
  } catch (error) {
    return error;
  }
}

async function mounterConsumerDestinationFinalIdentify (sales){
 
  let destinationNFe  = {...destinationNFeInitial}
  destinationNFe.cnpjorcpfDestination = sales.cliente.people.physicalpeople === null ? sales.cliente.people.legalpeople.cnpj : sales.cliente.people.physicalpeople.cpf 
  destinationNFe.nameDestination = sales.cliente.people.physicalpeople === null ? sales.cliente.people.legalpeople.socialName : sales.cliente.people.physicalpeople.name + sales.cliente.people.physicalpeople.lastName  
  destinationNFe.idDestination = "",
  destinationNFe.publicPlaceDestination = sales.cliente.people.address[0].street,
  destinationNFe.numberDestination = sales.cliente.people.address[0].number,
  destinationNFe.complementDestination = sales.cliente.people.address[0].complement,
  destinationNFe.districtDestination = sales.cliente.people.address[0].district,
  destinationNFe.codeCountyDestination = String(sales.cliente.people.address[0].city_id),
  destinationNFe.nameCountyDestination = sales.cliente.people.address[0].state,
  destinationNFe.ufDestination = sales.cliente.people.address[0].state,
  destinationNFe.zipCodeDestination = sales.cliente.people.address[0].cep,
  destinationNFe.codeCountryDestination = "1058",
  destinationNFe.nameCountryDestination = "BRASIL",
  destinationNFe.numberPhoneDestination = sales.cliente.people.contact[0].phone,
  destinationNFe.recipientIEIndicatorDestination = "9",
  destinationNFe.stateRegistrationDestinationNumber = "",
  destinationNFe.stateSuframaRegistration = "",
  destinationNFe.stateMunicipalRegistrationDestination = "",
  destinationNFe.emailDestination = sales.cliente.people.contact[0].primaryEmail
  return destinationNFe;

}

async function mounterDestinationFinalConsumer () {
  let destinationNFe = {...destinationNFeInitial}
  destinationNFe.recipientIEIndicatorDestination = "9"
   return destinationNFe;
}

module.exports = {
  execute,
};
