require('dotenv').config()

class ENV {
    /** @type {ENV} */
    _instance
    VUE_APP_PDV_COMPUTER_ID  = ''
    VUE_APP_URL_DEFAULT = ''
    API_FISCAL= ''

    constructor() {
        this.VUE_APP_PDV_COMPUTER_ID = process.env.VUE_APP_PDV_COMPUTER_ID
        this.VUE_APP_URL_DEFAULT = process.env.VUE_APP_URL_DEFAULT
        this.API_FISCAL = process.env.API_FISCAL
    }



    static getInstance() {
        let i = new ENV()
        i._instance = i

        return i
    }
}


module.exports = ENV