<template>
  <v-row justify="center">
    <v-dialog
      v-model="this._props.component.addDialog"
      max-width="800"
      persistent
    >
      <v-card>
        <v-container>
          <v-row>
            <v-card-title>Informações da movimentação de caixa </v-card-title>
          </v-row>
          <v-form>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="this.component.infocashier.date_movement"
                  outlined
                  label="Data do Movimento"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="this.component.infocashier.cashier_id"
                  outlined
                  label="Caixa"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="this.component.infocashier.descritpion_movement"
                  outlined
                  label="Descrição do movimento"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="this.component.infocashier.type_movement"
                  outlined
                  label="Tipo do movimento"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  v-model="this.component.infocashier.value_movement"
                  label="Valor da movimentação (R$)"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  v-model="this.component.infocashier.account_plans_id"
                  label="Plano de contas"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="
              () => {
                disable();
              }
            "
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object,
  },
  methods: {
    disable() {
      this._props.component.addDialog = !this._props.component.addDialog;
    },
  },
  created() {
    this.component.infocashier.date_movement = this.component.objectToInfo.date_movement;
    this.component.infocashier.cashier_id = this.component.objectToInfo.cashier.description;
    this.component.infocashier.descritpion_movement = this.component.objectToInfo.description_movement;
    this.component.infocashier.type_movement = this.component.objectToInfo.type_movement;
    this.component.infocashier.value_movement = this.component.objectToInfo.value_movement;
    this.component.infocashier.account_plans_id = this.component.objectToInfo.account_plans_id;
  },
};
</script>

<style>
</style>