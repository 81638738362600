
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";





export default  function generatePdf(enterprise, note_value, other_values, credit,  barcode) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    var docDefinition = {
        content: [
            {
                text: "-----------------------------------------------------------------------------------------------------------------------------------------------------------",
                style: "anotherStyle",
            },
            { text: `${enterprise.fantasyName}`, style: "anotherStyle" },
            {
                text: `${enterprise.address.street}, ${enterprise.address.number} - ${enterprise.address.city}/${enterprise.address.my_city.state.abbr}`,
                style: "anotherStyle",
            },
            {
                text: `CNPJ: ${enterprise.cnpj} - IE: ${enterprise.registrationState}`,
                style: "anotherStyle",
            },
            {
                text: "-----------------------------------------------------------------------------------------------------------------------------------------------------------",
                style: "anotherStyle",
            },
            {
                text: "---------------------------------------------------------------Ticket de Troca---------------------------------------------------------------",
                style: "anotherStyle",
            },
            {
                text: `Código....: ${note_value.access_key}                                                                                                       `,
                style: "body",
            },
            {
                text: `Cliente...: Cliente                                                                                                       `,
                style: "body",
            },
            {
                text: `Valor do Crédito....:   ${credit.exchange.total_credit}                                                                                                      `,
                style: "body",
            },
            {
                text: `Descrição....:   Credito referente ao cupom fiscal: ${credit.exchange.access_key}                                                                                                      `,
                style: "body",
            },
            // {
            //     text: `Quantidade de troca....:   14                                                                                                      `,
            //     style: "body",
            // },
            {
                text: "------------------------------------------------------------------------Histórico-------------------------------------------------------------------",
                style: "anotherStyle",
            },
            {
                text: `Data - ${new Date().getDate()} / ${new Date().getMonth() + 1
                    } / ${new Date().getFullYear()}`
            },
            { text: "Observação - Controle de trocas" },
            {
                text: "-----------------------------------------------------------------------------------------------------------------------------------------------------------",
                style: "anotherStyle",
            },
            {
                text: 'Identificador do Ticket',
                style: "titleStyle",
            },
            {
                svg: `${barcode}`,
                style: "anotherStyle",
            },
        ],
        styles: {
            body: {
                alignment: "left",
            },
            anotherStyle: {
                alignment: "center",
            },
            titleStyle:{
                alignment: "center",
                fontSize: 18,
                bold: true
            }
        },
    };
    pdfMake.createPdf(docDefinition).open();
}