const ADD_CASHIER = "ADD_CASHIER";
const UPDATE_CASHIER = "UPDATE_CASHIER";
const GET_ALL_CASHIER = "GET_ALL_CASHIER";
const DESTROY_CASHIER = "DESTROY_CASHIER";
const GET_AUTOCOMPLETE_CASHIER = "GET_AUTOCOMPLETE_CASHIER";

module.exports = {
  ADD_CASHIER,
  UPDATE_CASHIER,
  GET_ALL_CASHIER,
  DESTROY_CASHIER,
  GET_AUTOCOMPLETE_CASHIER,
};
