<template>
  <v-dialog
    persistent
    v-model="getShowDialogRegisterExchangeControl"
    width="70%"
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <div id="backgroundDialog">
      <v-col>
        <v-row>
          <Header />
        </v-row>
        <v-row>
          <Body />
        </v-row>
      </v-col>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./header/index.vue";
import Body from "./body/index.vue";
// import Table from "./table/index.vue";
export default {
  components: {
    Header,
    Body,
    // Table,
  },
  computed: {
    ...mapGetters(["getShowDialogRegisterExchangeControl"]),
  },
  watch: {},
};
</script>

<style>
#backgroundDialog {
  padding: 5%;
  background-color: white;
  border-radius: 1%;
}
</style>
