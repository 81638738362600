<template>
  <v-row justify="center">
    <v-dialog persistent v-model="component.addDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="success" dark flat>
          <v-btn icon dark @click="
            () => {
              executeAddDialog(); 
            }
          ">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> {{ title }} Vendedor</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <v-divider></v-divider>
          <v-card flat>


            <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
                <v-tab
                  v-for="(item, index) in newArrayItems"
                  :key="index"
                  :href="'#tab-' + item.href"
                >
                  {{ item.tabName }}
                </v-tab>

                <v-menu v-if="$vuetify.breakpoint.xsOnly" bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      class="align-self-center mr-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      more
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>

                  <v-list class="grey lighten-3">
                    <v-list-item
                      v-for="(item, index) in more"
                      :key="index"
                      @click="addItem(item)"
                    >
                      {{ item.tabName }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-tabs>

              <v-tabs-items v-model="currentItem">
                <v-tab-item value="tab-1">
                  <v-col>
                    <GenerateDate :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <v-col>
                    <FamillyDate :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-3">
                  <v-col>
                    <TitleAddress :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-4">
                  <v-col>
                    <Contact :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-5">
                    <v-col>
                      <Bank :component="this" />
                    </v-col>
                </v-tab-item>
              </v-tabs-items>


            <!-- <v-tabs>
              <v-tab href="#tab1">
                <v-icon class="mr-1">mdi-account-details</v-icon>
                Gerais
              </v-tab>
              <v-tab href="#tab2">
                <v-icon class="mr-1">mdi-family-tree</v-icon>
                Pessoais
              </v-tab>
              <v-tab href="#tab3">
                <v-icon class="mr-1">mdi-home-account</v-icon>
                Endereço
              </v-tab>
              <v-tab href="#tab4">
                <v-icon class="mr-1">mdi-card-account-mail</v-icon> Contato
              </v-tab>
              <v-tab href="#tab5">
                <v-icon class="mr-1">mdi-bank</v-icon> Contas Bancárias
              </v-tab>
              <v-tab-item value="tab1">
                <v-card flat>
                  <v-container>
                    <GenerateDate :component="this" />
                  </v-container>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab2">
                <v-card flat>
                  <v-container>
                    <FamillyDate :component="this" />
                  </v-container>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab3">
                <v-card flat>
                  <v-container>
                    <TitleAddress :component="this" />
                  </v-container>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab4">
                <Contact :component="this" />
              </v-tab-item>
              <v-tab-item value="tab5">
                <Bank :component="this" />
              </v-tab-item>
            </v-tabs> -->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error" v-on:click="clear()">
                <v-icon class="mr-2">mdi-cancel</v-icon> Limpar
                Formulário
              </v-btn>
              <v-btn v-if="!justVisible" class="success" :disabled="!valid" v-on:click="submit()">
                <v-icon class="mr-2">mdi-content-save</v-icon>Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import GenerateDate from "../Tabs/TabGeral";
import TitleAddress from "../Tabs/TabAddress/NewAddress/index.vue";
import FamillyDate from "../Tabs/TabFamily/";
import Contact from "../Tabs/TabContact/NewContact/index.vue";
import Bank from "../Tabs/TabBank/NewBank/index.vue";
import { ADD_SELLER, UPDATE_SELLER } from "../../../store/actions/seller";

export default {
  components: {
    GenerateDate,
    TitleAddress,
    FamillyDate,
    Contact,
    Bank,
  },

  props: {
    component: Object,
    objectToUpdate: Object,
    enterprise_id: Number,
    title: String,
  },

  data: () => ({
    newArrayItems: Array(),
  currentItem: "tab-1",
      items: [
        {
          tabName: "Dados Gerais",
          href: "1",
        },
      ],
      more: [
        {
          tabName: "Pessoais",
          href: "2",
        },
        {
          tabName: "Endereço",
          href: "3",
        },
        {
          tabName: "Contato",
          href: "4",
        },
        {
          tabName: "Contas Bancárias",
          href: "5",
        },
      ],

    justVisible: Boolean(),
    isUpdate:undefined,
    bank: {
      bank: String(),
      count: String(),
      agency: String(),
      manager: String(),
    },
    address: {
      cep: String(),
      street: String(),
      number: String(),
      district: String(),
      complement: String(),
      state: Object(),
      city_id: Number(),
      city: Object()
      //city: String(),
    },
    contact: {
      primaryEmail: String(),
      secundaryEmail: String(),
      cellPhone: String(),
      phone: String(),
      phoneReference: String(),
    },
    physical_people: {
      name: "",
      lastName: "",
      cpf: "",
      gender: "",
      emitter: "",
      emitterState: "",
      idCard: "",
      dateOfBirth: "",
      fatherName: "",
      motherName: "",
      nacionality: "",
      naturality: "",
      icomeSpouse: 0,
      monthlyIncome: 0,
      civilStatus: "",
      spouse: "",
      enterprise_id: "",
    },
    ListContact: Array(),
    ListAddress: Array(),
    ListBank: Array(),
    valid: Boolean(),
  }),
  methods: {
    setArrayItens() {
      this.newArrayItems = Array();
      if (this.$vuetify.breakpoint.xsOnly) {
        this.newArrayItems = this.items;
      } else {
        this.newArrayItems = this.items.concat(this.more);
      }
    },
    addItem(item) {
      const removed = this.items.splice(0, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item.href;
      });
    },
    executeAddDialog() {
      this.component.addDialog = !this.component.addDialog;
    },

    submit() {
      if (this.physical_people.id === undefined) {
        this.create();
      } else {
        this.update();
      }
    },

    async create() {
       
      let newSeller = Object();
      newSeller.type_people = "physicalpeople";
      newSeller.PhysicalPeople = { ...this.physical_people };
      newSeller.enterprise_id = this.enterprise_id;
      newSeller.contacts =  { ...this.contact };
      newSeller.bankdatas = { ...this.bank };
      newSeller.addresses = { ...this.address };
      newSeller.addresses.city_id = this.address.city.city_id
      newSeller.addresses.city = this.address.city.name
      newSeller.addresses.state = this.address.state.value
      try {
        this.$store.dispatch(ADD_SELLER, newSeller);
      } finally {
        this.executeAddDialog();
      }
    },
    loadFieldsToUpdate(item){       
      this.isUpdate = true
      this.physical_people = {...item.physicalpeople}
      this.address = {...item.address[0]}
      this.contact = {...item.contacts[0]}
      this.bank = {...item.bankdatas[0]}
    
    },
    async update() {
       
      let updateSeller = Object();
      updateSeller = { ...this.objectToUpdate };
      let peopleId = this.physical_people.people_id;

      updateSeller.type_people = "physicalpeople";
      updateSeller.physicalpeople = { ...this.physical_people };
      updateSeller.addresses = {...this.address, peopleId}
      updateSeller.addresses.state  = this.address.state.value ? this.address.state.value  : this.address.state
      updateSeller.addresses.city = this.address.city.name ? this.address.city.name : this.address.city.name
      updateSeller.addresses.city_id = this.address.city.city_id ? this.address.city.city_id : this.address.city_id
       
      updateSeller.contacts = {...this.contact, peopleId}
      updateSeller.bankdatas = {...this.bank, peopleId}
      // updateSeller.addresses = this.addPeopleId(this.ListAddress, peopleId);
      // updateSeller.contacts = this.addPeopleId(this.ListContact, peopleId);
      // updateSeller.bankdatas = this.addPeopleId(this.ListBank, peopleId);
      try {
        await this.$store.dispatch(UPDATE_SELLER, updateSeller);
      } finally {
        this.executeAddDialog();
      }
    },
    addPeopleId(arr, peopleId) {
      arr.forEach((element) => (element.people_id = peopleId));
      return arr;
    },
    clear() {
      for (let i in this.physical_people) {
        this.physical_people[i] = String();
        this.ListContact = [];
        this.ListAddress = [];
        this.ListBank = [];
      }
    },
  },
  created() {
    this.setArrayItens()
    if (this.objectToUpdate !== undefined) {
      this.justVisible = this.objectToUpdate.justVisible
      this.loadFieldsToUpdate(this.objectToUpdate)
      // this.physical_people = { ...this.objectToUpdate.physicalpeople };
      // this.ListContact = this.objectToUpdate.contacts;
      // this.ListAddress = this.objectToUpdate.addresses;
      // this.ListBank = this.objectToUpdate.bankdatas;
    }
  },
  watch: {
    "$vuetify.breakpoint.xsOnly": function(){
      this.setArrayItens()
    },
  }
};
</script>

<style>
</style>