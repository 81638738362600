<template>
  <v-container>
    <v-data-table 
    :headers="header"
    :items="this.Items.sale_form_of_payment"
    />
  </v-container>
</template>

<script>
export default {
  props:{
    Items:Object()
  },
  data() {
    return {
      header: [
        { text: "Id", value: "id" },
        { text: "Código", value: "formofpayment.code" },
        { text: "Descrição Pagamento", value: "formofpayment.description" },
        { text: "Valor Pago", value: "value_form_payment" },
      ],
    };
  },
};
</script>

<style>
</style>