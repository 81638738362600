const ADD_TRANSPORTER = "ADD_TRANSPORTER";
const UPDATE_TRANSPORTER = "UPDATE_TRANSPORTER";
const GET_ALL_TRANSPORTER = "GET_ALL_TRANSPORTER";
const DESTROY_TRANSPORTER = "DESTROY_TRANSPORTER";
const ITEM_TO_UPDATE = "ITEM_TO_UPDATE"

module.exports = {
  ADD_TRANSPORTER,
  UPDATE_TRANSPORTER,
  GET_ALL_TRANSPORTER,
  DESTROY_TRANSPORTER,
  ITEM_TO_UPDATE,
};
