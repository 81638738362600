





export default [

    {
        text: "Id",
        value: "id"
    },
    {
        text: "Númbero de Troca",
        value: "number_exchange"
    },
    {
        text: "Data da Troca",
        value: "data_exchange"
    },
    {
        text: "Valor da Troca",
        value: "value_exchange"
    },
    {
        text: "Valor Crédito de ICMS",
        value: "value_credit_icms_total"
    },
    {
        text: "Ações",
        value: "actions",
        align: "center"
    },
]

