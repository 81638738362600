<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="component.dialog_nfce"
      :retain-focus="false"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline"
          >Deseja Imprimir o Cupom Fiscal ?</v-card-title
        >
        <v-card-text>Essa ação irá imprimr o cupom fiscal</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="error"
            @click="
              () => {
                close_PrintDialog(this);
              }
            "
            >Cancelar</v-btn
          >

          <v-btn
            color="success ml-4"
            @click="
              () => {
                viewNfce(this);
              }
            "
            >Visualizar</v-btn
          >
          <v-btn
            color="success ml-4"
            @click="
              () => {
                printNFce(this);
              }
            "
            >Imprimir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
  
  <script>
export default {
  props: {
    component: Object,
  },

  methods: {
    close_PrintDialog() {
      this.component.dialogPrint = false;
    },
    viewNfce() {
      window.open(this.component.nfcePrint);
      this.component.nfcePrint = null;
      this.component.dialogPrint = ! this.component.dialogPrint
    },
    printNFce() {
      var iframe = document.createElement("iframenfce");
      iframe.style.display = "none";
      iframe.src = this.component.nfcePrint;
      document.body.appendChild(iframe);
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
      this.component.nfcePrint = null;
      this.component.dialogPrint = ! this.component.dialogPrint
    },
  },
};
</script>
  
  <style>
</style>