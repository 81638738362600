const headers = [
    { text: 'ID', value: 'id' },
    { text: 'Data', value: 'data_sale' },
    { text: 'Status', value: 'status' },
    { text: 'Chave de Acesso', value: 'access_key' },
    { text: 'Numero da nota', value: 'number_sale' },
    { text: 'Cliente', value: 'cliente.name' },
    { text: 'Vendedor', value: 'seller.people.physicalpeople.name' },
    { text: 'PDV', value: 'pdv.code' },
    { text: 'Valor da nota(R$)', value: 'value_total_note' },
    { text: 'Ações', value: 'actions' }
];

export { headers };