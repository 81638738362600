<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
        <AddDialog v-if="addDialog" :component="this" />
        <DestroyDialog v-if="destroyDialog" :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="searchInput"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
          @keyup.enter="autocompletePaymentTerm()"
        >
        </v-text-field>
      </v-card-text>
      <Table v-if="permission.Visualizar" :component="this" :search="search" />
    </v-card>
    <AlertApi />
    <loadScreen/>
  </Container>
</template>

<script>
import Container from "../components/PaymentTerms/Container";
import CardTitle from "../components/PaymentTerms/CardTitle";
import Table from "../components/PaymentTerms/Table";
import loadScreen from "../components/load"
import AddDialog from "../components/PaymentTerms/AddDialog";
import DestroyDialog from "../components/PaymentTerms/DestroyDialog";

import {
  GET_ALL_PAYMENT_TERM,
  DESTROY_PAYMENT_TERM,
  AUTOCOMPLETE_PAYMENT_TERM,
} from "../store/actions/paymentterm";

import AlertApi from "../components/alert_api/";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    search: "",
    searchInput: "",
    code: "",
    addDialog: false,
    destroyDialog: false,
    objectDestroyItem: {},
    objectToUpdate: {},
    permission: []
  }),
  methods: {
    AlterAddDialog() {
      this.addDialog = !this.addDialog;
    },

    async destroy_object() {
      const paymentterm = {
        id: this.objectDestroyItem.id,
      };
      try {
        await this.$store.dispatch(DESTROY_PAYMENT_TERM, paymentterm);
      } catch (error) {
        alert(error);
      } finally {
        this.destroyDialog = false;
      }
    },

    change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
    },
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_PAYMENT_TERM, {
        page: page,
        limit: 10,
      });
    },
    async GetAutocompletePaymentTerm(page = 1) {
      await this.$store.dispatch(AUTOCOMPLETE_PAYMENT_TERM, {
        search: this.search,
        page: page,
        limit: 10,
      });
    },
    autocompletePaymentTerm() {
      this.search = this.searchInput
    }
  },
  watch: {
    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
      if(val.length == 0) {
        this.GetAll();
      } else if(val.length >= 2){
        this.GetAutocompletePaymentTerm()
      }
    }
  },
  mounted() {
    let name = document.URL.split("/").pop()
    this.permission = this.getCadastrosLinks.getPermissions(name)
  },
  async created() {
    this.GetAll();
  },

  components: {
    Container,
    CardTitle,
    Table,
    AddDialog,
    DestroyDialog,
    AlertApi,
    loadScreen
  },
  computed: { ...mapGetters(["getPaymentTerms", "getCadastrosLinks"]) },
};
</script>

<style scoped></style>
