<template>
  <v-dialog max-width="500" v-model="component.exchangeDialog ">
    <div class="main-frame">
      <div class="title-div">
        <p class="title-cupom-fiscal">Pesquisar Identificador de troca</p>
      </div>
      <v-divider />
      <v-form>
        <div class="body-cupom-fiscal">
          <div style="width: 400px">
            <v-text-field
              v-model="component.codeCupomSearch"
              outlined
              label="Digitar código do identificador de troca "
            />
          </div>
          <div style="width: 400px">
            <v-btn block class="success" @click="component.searchCupom">Pesquisar Cupom</v-btn>
          </div>
        </div>
      </v-form>
    </div>
  </v-dialog>
</template>



<script>
import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise";
export default {
  props: {
    component: Object,
  },
  components: {},
  data: () => ({
    listEnterprise: Array(),
  }),
  methods: {
    getAll(page = 1) {
      const payload = {
        page,
        limit: 10,
      };
      this.$store.dispatch(GET_ALL_ENTERPRISE, payload).then((response) => {
        this.listEnterprise = [...response.data];
      });
    },
  },
  computed: {},
  created() {
    this.getAll();
  },
};
</script>
<style>
.main-frame {
  background-color: white;
  text-align: flex-start;
  padding: 5px;
}
.body-cupom-fiscal {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 5px;
}
.title-cupom-fiscal {
  font-size: 20px;
  font-weight: bold;
  color: gray;
}
</style>