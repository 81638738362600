<template>
  <v-row>
    <v-dialog max-width="500" v-model="component.dialogPass">
      <v-card>
        <v-card-title> Alterar Senha </v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                v-model="component.user.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                label="Nova Senha"
                hint="Minimo 8 digitos"
                @click:append="show1 = !show1"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                v-model="component.confirm_password"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                label="Confirmar Senha"
                :rules="[
                  (val) =>
                    val === component.user.password || 'Senhas não coicidem!',
                ]"
                hint="Minimo 8 digitos"
                @click:append="show2 = !show2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="error"
            @click="component.dialogPass = !component.dialogPass"
          >
            <v-icon class="mr-2">mdi-cancel</v-icon>Cancelar</v-btn
          >
          <v-btn @click="component.confirm" class="success">
            <v-icon class="mr-2">mdi-content-save</v-icon>Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    show1: Boolean(),
    show2: Boolean(),
    rules: {
      required: (value) => !!value || "Obrigatório",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
    },
  }),

  props: {
    component: Object,
  },
  created() {
    if (this.component.user.cashieroperator !== undefined) {
      this.cashier = { ...this.component.user.cashieroperator };
      delete this.cashier.id;
    }
  },
};
</script>

<style>
</style>