<template>
  <v-form ref="form" v-model="component.validPhysical">
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-text-field
          v-model="component.PhysicalPeople.name"
          :readonly="component.justVisible"
          label="*Nome"
          :rules="nameRules"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-text-field
          v-model="component.PhysicalPeople.lastName"
          :readonly="component.justVisible"
          label="*Sobrenome"
          :rules="LastNameRules"
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.PhysicalPeople.cpf"
          :readonly="component.justVisible"
          label="*CPF"
          v-mask="'###.###.###-##'"
          :rules="cpfRules"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.PhysicalPeople.idCard"
          :readonly="component.justVisible"
          label="Identidade"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.PhysicalPeople.emitter"
          :readonly="component.justVisible"
          label="Orgão Emissor"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          v-model="component.PhysicalPeople.emitterState"
          :readonly="component.justVisible"
          label="UF Emissor"
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4"> 
        <v-text-field
          v-model="component.PhysicalPeople.dateOfBirth"
          :readonly="component.justVisible"
          label="Data de Nascimento"
          placeholder="null"
          :rules="TransportRules"
          outlined
          type="date"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4"> 
        <v-select
          v-model="component.PhysicalPeople.nacionality"
          :readonly="component.justVisible"
          label="Nacionalidade"
          :items="nacionality"
          outlined
          required
        >
        </v-select>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4"> 
        <v-text-field
          v-model="component.PhysicalPeople.naturality"
          :readonly="component.justVisible"
          label="Naturalidade"
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-text-field
          v-model="component.PhysicalPeople.rntr"
          :readonly="component.justVisible"
          label="*RNTR"
          :rules="TransportRules"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-text-field
          v-model="component.PhysicalPeople.security"
          :readonly="component.justVisible"
          label="*Segurança"
          :rules="TransportRules"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { nameRules, LastNameRules, cpfRules, TransportRules } from "./script";

export default {
  props: {
    component: Object(),
  },
  data: () => ({
    nameRules,
    LastNameRules,
    cpfRules,
    TransportRules,
    nacionality: ["Brasileiro", "Estrangeiro"],
  }),
  methods: {
    reset() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style>
</style>