<template>
  <v-row>
    <v-card-title>Transportador</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        class="mt-3"
        label="Empresa"
        :items="getEnterprises.data"
        item-value="id"
        item-text="fantasyName"
        v-model="component.enterpriseId"
        dense
        flat
        outlined
        height="10px"
      ></v-select>
    </v-col>
    <!-- <v-btn
      class="mr-2 mt-6"
      color="success"
      v-if="component.permission.Cadastrar"
      @click="
        () => {
          activate_add();
        }
      "
      >Adicionar</v-btn
    > -->
  </v-row>
</template>

<script>

import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
  },
  components: {
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    activate_add() {
      this.component.dialog = !this.component.dialog;
    },
    async GetAllEnterprise() {
      await this.$store.dispatch(GET_ALL_ENTERPRISE, 0);
    },
  },
  computed: {
    ...mapGetters(["getEnterprises"]),
  },
  created() {
    this.GetAllEnterprise();

  },
};
</script>
