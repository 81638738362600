<template>
  <div class="header">
    <div class="title">
      <p>Controle de Trocas</p>
    </div>
    <div>
      <v-divider />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "./index.css";
</style>
