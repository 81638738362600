const ADD_FORM_OF_PAYMENT = "ADD_FORM_OF_PAYMENT";
const UPDATE_FORM_OF_PAYMENT  = "UPDATE_FORM_OF_PAYMENT";
const GET_ALL_FORM_OF_PAYMENT  = "GET_ALL_FORM_OF_PAYMENT";
const DESTROY_FORM_OF_PAYMENT  = "DESTROY_FORM_OF_PAYMENT";
const AUTOCOMPLETE_FORM_OF_PAYMENT  = "AUTOCOMPLETE_FORM_OF_PAYMENT";

module.exports = {
    ADD_FORM_OF_PAYMENT,
    UPDATE_FORM_OF_PAYMENT,
    GET_ALL_FORM_OF_PAYMENT,
    DESTROY_FORM_OF_PAYMENT,
    AUTOCOMPLETE_FORM_OF_PAYMENT,
};
