var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.component.valid),callback:function ($$v) {_vm.$set(_vm.component, "valid", $$v)},expression:"component.valid"}},[_c('v-row',{staticClass:"mr-1 ml-1"},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","xl":"4","lg":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":"*Data Inicial de Fechamento de Caixa","type":"date","rules":[
          () => !!_vm.component.report.date_initial_close_cashier || 'Campo Obrigatorio',
        ]},model:{value:(_vm.component.report.date_initial_close_cashier),callback:function ($$v) {_vm.$set(_vm.component.report, "date_initial_close_cashier", $$v)},expression:"component.report.date_initial_close_cashier"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","xl":"4","lg":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":"*Data Final de Fechamento de Caixa","rules":[
          () => !!_vm.component.report.date_finish_close_cashier || 'Campo Obrigatorio',
        ],"type":"date"},model:{value:(_vm.component.report.date_finish_close_cashier),callback:function ($$v) {_vm.$set(_vm.component.report, "date_finish_close_cashier", $$v)},expression:"component.report.date_finish_close_cashier"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","xl":"4","lg":"4"}},[_c('v-select',{attrs:{"label":"*Caixa","outlined":"","items":_vm.component.cashier,"rules":[
          () => !!_vm.component.report.selectCashier || 'Campo Obrigatorio',
        ],"item-text":"description","item-value":"id"},model:{value:(_vm.component.report.selectCashier),callback:function ($$v) {_vm.$set(_vm.component.report, "selectCashier", $$v)},expression:"component.report.selectCashier"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }