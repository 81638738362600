<template>
    <v-row>
        <v-dialog v-model="dialog" max-width="500">
            <v-card>
                <v-card-title>
                    <v-img src="./error_icon.png" max-width="40" class="mr-5"></v-img>
                    {{ title }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle class="mt-8 text-center">{{ menssage }}</v-card-subtitle>
            </v-card>
            <v-btn class="error" @click="() => {
                closeError();
            }
                ">Fechar</v-btn>
        </v-dialog>

    </v-row>
</template>
  
<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["getInfoError"]),
    },
    data() {
        return {
            dialog: Boolean(),
            title: String(),
            menssage: String()
        };
    },
    methods: {
        closeError() {
            this.dialog = false;
        }
    },
    watch: {
        getInfoError: function (val) {
            this.dialog = val.dialog
            this.title = val.title
            this.menssage = val.menssage
        },
    },
    destroyed: {
        
    }

};
</script>
  
<style>
.body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
}

.spinner {
    margin-top: 15px;
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #22a6b3;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>