<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="component.dialog_cashier"
      max-width="300"
      transition="fab-transition"
    >
      <v-card>
        <v-card-title class="title">Abrir Caixa</v-card-title>
        <v-card-subtitle>Informe o valor de abertura do caixa.</v-card-subtitle>
        <v-divider></v-divider>
        <v-container>
          <v-form>
            <v-text-field
              outlined
              dense
              tile
              label="Valor de Abertura"
              placeholder="0.00"
              prefix="R$"
            ></v-text-field
          ></v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row justify="center">
              <v-btn class="error mr-1" @click="cancel()"> Cancelar</v-btn>
              <v-btn class="success"> Confirmar</v-btn>
            </v-row>
          </v-card-actions>
          <v-card-subtitle>Pressione ESC para sair</v-card-subtitle>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  methods: {
    cancel() {
      this.component.dialog_cashier = !this.component.dialog_cashier;
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Courier New", Courier, monospace;
}
</style>
