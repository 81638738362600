var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","max-width":"500"},model:{value:(_vm.component.dialog_nfce),callback:function ($$v) {_vm.$set(_vm.component, "dialog_nfce", $$v)},expression:"component.dialog_nfce"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Deseja Imprimir o Cupom Fiscal ?")]),_c('v-card-text',[_vm._v("Essa ação irá imprimr o cupom fiscal")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":() => {
              _vm.close_PrintDialog(this);
            }}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"success ml-4"},on:{"click":() => {
              _vm.viewNfce(this);
            }}},[_vm._v("Visualizar")]),_c('v-btn',{attrs:{"color":"success ml-4"},on:{"click":() => {
              _vm.printNFce(this);
            }}},[_vm._v("Imprimir")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }