const ADD_ENTERPRISE = "ADD_ENTERPRISE";
const UPDATE_ENTERPRISE = "UPDATE_ENTERPRISE";
const GET_ALL_ENTERPRISE = "GET_ALL_ENTERPRISE";
const DESTROY_ENTERPRISE = "DESTROY_ENTERPRISE";
const ITEM_TO_UPDATE_ENTERPRISE = "ITEM_TO_UPDATE_ENTERPRISE"
const ENTERPRISE_SELECTED = "ENTERPRISE_SELECTED"
const CHECK_CERTIFICATE = "CHECK_CERTIFICATE"

module.exports = {
  ADD_ENTERPRISE,
  UPDATE_ENTERPRISE,
  GET_ALL_ENTERPRISE,
  DESTROY_ENTERPRISE,
  ITEM_TO_UPDATE_ENTERPRISE,
  ENTERPRISE_SELECTED,
  CHECK_CERTIFICATE,

};
