<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <v-row>
          <v-card-title class="ml-2">Permissões</v-card-title>
          <v-spacer></v-spacer>
          <AddDialog v-if="addDialog" :title="title" :component="this" />
          <v-btn color="success" class="mr-5 mt-4" @click="changeAddDialog()"
            >Adicionar</v-btn
          >
        </v-row>
        <v-row>
          <v-text-field
            outlined
            append-icon="mdi-magnify"
            class="ma-2"
            no-data-text="Sem registro!"
            label="Usuário"
          ></v-text-field>
        </v-row>
      </v-card-text>
      <Table :component="this" v-if="permission.Visualizar" />
    </v-card>
    <AlertAPI />
  </Container>
</template>

<script>
import Table from "../components/Permission/Table";
import Container from "../components/Permission/Container";
import AddDialog from "../components/Permission/AddDialog";
import AlertAPI from "../components/alert_api/";
import { mapGetters } from "vuex";

export default {
  components: {
    Table,
    Container,
    AddDialog,
    AlertAPI,
  },
  computed: {
    ...mapGetters(["getConfiguracoesLinks"]),
  },
  data: () => ({
    addDialog: false,
    permission: Array(),
    title: String(),
  }),
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getConfiguracoesLinks.getPermissions(name);
  },
  methods: {
    changeAddDialog() {
      this.addDialog = !this.addDialog;
      this.title = "Adicionar";
    },
  },
};
</script>

<style></style>
