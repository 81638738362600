<template>
  <v-row justify="center">
    <v-dialog
      v-model="component.dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn
            icon
            dark
            @click="
              () => {
                close_dialog();
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }} Transportadora</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>

          <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
                <v-tab
                  v-for="(item, index) in newArrayItems"
                  :key="index"
                  :href="'#tab-' + item.href"
                >
                  {{ item.tabName }}
                </v-tab>

                <v-menu v-if="$vuetify.breakpoint.xsOnly" bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      class="align-self-center mr-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      more
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>

                  <v-list class="grey lighten-3">
                    <v-list-item
                      v-for="(item, index) in more"
                      :key="index"
                      @click="addItem(item)"
                    >
                      {{ item.tabName }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-tabs>

              <v-tabs-items v-model="currentItem">
                <v-tab-item value="tab-1">
                  <v-col>
                    <v-switch
                v-model="register"
                label="Pessoa Jurídica"
                :disabled="StatusRegister"
              ></v-switch>
              <v-card flat v-if="!register">
                <TabPhisical :component="this" ref="phys" />
              </v-card>
              <v-card flat v-else>
                <TabLegal :component="this" ref="legal" />
              </v-card>
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <v-col>
                    <TabPessoal :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-3">
                  <v-col>
                    <TabContact :component="this" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-4">
                  <v-col>
                   <TabAddress :component="this" :isUpdateAdd="isUpdate" />
                  </v-col>
                </v-tab-item>
                <v-tab-item value="tab-5">
                 <v-col>
                  <TabBank :component="this" />
                 </v-col>
                </v-tab-item>
              </v-tabs-items>


          <!-- <v-tabs>
            <v-tab href="#tab1">
              <v-icon class="mr-1">mdi-account-details</v-icon>
              Gerais
            </v-tab>
            <v-tab href="#tab2" v-if="!register">
              <v-icon class="mr-1">mdi-family-tree</v-icon>
              Pessoal
            </v-tab>
            <v-tab href="#tab3">
              <v-icon class="mr-1">mdi-card-account-mail</v-icon> Contato
            </v-tab>
            <v-tab href="#tab4">
              <v-icon class="mr-1">mdi-home-account</v-icon>
              Endereço
            </v-tab>
            <v-tab href="#tab5">
              <v-icon class="mr-1">mdi-bank</v-icon> Contas Bancárias
            </v-tab>

            <v-tab-item value="tab1">
              <v-switch
                v-model="register"
                label="Pessoa Jurídica"
                :disabled="StatusRegister"
              ></v-switch>
              <v-card flat v-if="!register">
                <TabPhisical :component="this" ref="phys" />
              </v-card>
              <v-card flat v-else>
                <TabLegal :component="this" ref="legal" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab2" :eager="true">
              <v-container>
                <v-card flat>
                  <TabPessoal :component="this" />
                </v-card>
              </v-container>
            </v-tab-item>

            <v-tab-item value="tab3">
              <v-card flat>
                <TabContact :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab4">
              <v-card flat> <TabAddress :component="this" :isUpdateAdd="isUpdate" /> </v-card>
            </v-tab-item>
            <v-tab-item value="tab5">
              <v-card flat> <TabBank :component="this" /></v-card>
            </v-tab-item>
          </v-tabs> -->
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="
                () => {
                  clear();
                }
              "
              class="error"
            >
              <v-icon class="mr-2">mdi-cancel</v-icon>Limpar Formulário</v-btn
            >
            <v-btn
            v-if="!justVisible"
              color="success"
              :disabled="!valid"
              @click="
                () => {
                  submit();
                }
              "
              ><v-icon class="mr-2">mdi-content-save</v-icon>
              Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TabPessoal from "../Tabs/TabPersonal/";
import TabContact from "../Tabs/TabContact/";
import TabAddress from "../Tabs/TabAddress/";
import TabPhisical from "../Tabs/TabPhisical/";
import TabLegal from "../Tabs/TabLegal/";
import TabBank from "../Tabs/TabBank/";
import {
  ADD_TRANSPORTER,
  UPDATE_TRANSPORTER,
} from "../../../store/actions/transporter";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object(),
    isUpdate:Boolean(),
    id: Number(),
    title: String(),
    enterprise_id: Number(),
    objectToUpdate:Object(),
  },

  components: {
    TabContact,
    TabAddress,
    TabPhisical,
    TabLegal,
    TabBank,
    TabPessoal,
  },
  data: () => ({
    newArrayItems: Array(),
  currentItem: "tab-1",
      items: [
        {
          tabName: "Dados Gerais",
          href: "1",
        },
      ],
      more: [
        {
          tabName: "Pessoais",
          href: "2",
        },
        {
          tabName: "Endereço",
          href: "3",
        },
        {
          tabName: "Contato",
          href: "4",
        },
        {
          tabName: "Contas Bancárias",
          href: "5",
        },
      ],

    register: false,
    justVisible: Boolean(),
    StatusRegister: Boolean(),
    valid: Boolean(),
    validPhysical: Boolean(),
    validPessoal: Boolean(),
    validLegal: Boolean(),

    PhysicalPeople: {
      type_people: String(),
      enterprise_id: Number(),
      client_id: String(),
      name: "",
      lastName: "",
      cpf: "",
      gender: "",
      emitter: "",
      emitterState: "",
      idCard: "",
      dateOfBirth: "",
      fatherName: "",
      motherName: "",
      nacionality: "",
      naturality: "",
      monthlyIncome: "",
      civilStatus: "",
      spouse: "",
      rntr: Number(),
      security: String(),
      icomeSpouse: Number(),
      people_id:Number()
    },

    LegalPeople: {
      enterprise_id: Number(),
      cnpj: String(),
      fantasyName: String(),
      socialName: String(),
      stateRegistration: String(),
      municipalRegistration: String(),
      homePage: String(),
      rntr: Number(),
      security: String(),
      people_id:Number()
    },
    address: {
      street: String(),
      number: String(),
      district: String(),
      complement: String(),
      state: String(),
      cep: String(),
      city_id: Number(),
      people_id:Number()
    },
    banks: {
      bank: String(),
      count: Number(),
      agency: Number(),
      manager: String(),
      people_id:Number()
    },
    contact: {
      primaryEmail: String(),
      secundaryEmail: String(),
      phone: String(),
      phoneReference: String(),
      cellPhone: String(),
      people_id:Number()
    },

  }),
  computed: {
    ...mapGetters(["getItemToUpdateTransporter"]),
  },
  methods: {
    setArrayItens() {
      this.newArrayItems = Array();
      if (this.$vuetify.breakpoint.xsOnly) {
        this.newArrayItems = this.items;
      } else {
        this.newArrayItems = this.items.concat(this.more);
      }
    },
    addItem(item) {
      const removed = this.items.splice(0, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item.href;
      });
    },
    close_dialog() {
      this.component.dialog = !this.component.dialog;
    },
    submit() {
      if (this.register) {
        if (this.LegalPeople.id === undefined) {
          this.create();
        } else {
          this.update();
        }
      } else {
        if (this.PhysicalPeople.id === undefined) {
          this.create();
        } else {
          this.update();
        }
      }
    },
    async create() {
      let newPeople = Object();
      newPeople.enterprise_id = this.enterprise_id;
      if (this.register === true) {
        newPeople.LegalPeople = { ...this.LegalPeople };
        newPeople.type_people = "LegalPeople";
        newPeople.addresses = { ...this.address };
        newPeople.contacts = { ...this.contact };
        newPeople.bankdatas = { ...this.banks };
        newPeople.rntr = this.LegalPeople.rntr;
        newPeople.security = this.LegalPeople.security;
      } else {
        newPeople.PhysicalPeople = { ...this.PhysicalPeople };
        newPeople.type_people = "PhysicalPeople";
        newPeople.addresses = { ...this.address };
        newPeople.contacts = { ...this.contact };
        newPeople.bankdatas = { ...this.banks };
        newPeople.rntr = this.PhysicalPeople.rntr;
        newPeople.security = this.PhysicalPeople.security;
      }
      try {
    
        await this.$store.dispatch(ADD_TRANSPORTER, newPeople);
        this.close_dialog();
      } catch (error) {
        return "error:" + error
      }
    },
    setItemtoUpdate(item) {
      this.justVisible = item.justVisible
      if (item.PhysicalPeople != null) {
        this.PhysicalPeople = { ...item.PhysicalPeople };
        this.PhysicalPeople.rntr = item.rntr;
        this.PhysicalPeople.security = item.security;
      } else if (item.LegalPeople != null) {
        this.register = true
        this.LegalPeople = { ...item.LegalPeople };
        this.LegalPeople.rntr = item.rntr;
        this.LegalPeople.security = item.security;
      }
      this.address = {... item.addresses[0]};
      this.address.city_id = item.addresses[0].city.id
      this.contact = {...item.contacts[0]};
      this.banks = {...item.bankdatas[0]};
    },
    async update() {
      let newPeople = Object();
      newPeople = { ...this.objectToUpdate };
      if (this.register === true) {
        newPeople.LegalPeople = { ...this.LegalPeople };
        newPeople.addresses = { ...this.address };
        newPeople.contacts = { ...this.contact };
        newPeople.bankdatas = { ...this.banks };
        newPeople.rntr = this.LegalPeople.rntr;
        newPeople.security = this.LegalPeople.security;
      } else {
        newPeople.PhysicalPeople = { ...this.PhysicalPeople };
        newPeople.addresses = { ...this.address };
        newPeople.contacts = { ...this.contact };
        newPeople.bankdatas = { ...this.banks };
        newPeople.rntr = this.PhysicalPeople.rntr;
        newPeople.security = this.PhysicalPeople.security;
      }
      try {
        await this.$store.dispatch(UPDATE_TRANSPORTER, newPeople);
        this.close_dialog();
      } catch (error) {
        return "error:" + error
      }
    },

    addPeopleId(arr, peopleId) {
      arr.forEach((element) => (element.people_id = peopleId));
      return arr;
    },
    clear() {
      if (this.register === true) {
        this.clear_legal();
      } else {
        this.clear_Physical();
      }
      this.ListAddress = [];
      this.ListContact = [];
      this.ListBank = [];
    },
    clear_Physical() {
      for (let i in this.PhysicalPeople) {
        this.PhysicalPeople[i] = String();
      }
    },
    clear_legal() {
      for (let i in this.LegalPeople) {
        this.LegalPeople[i] = String();
      }
    },

    validator(val, form) {
      if (val) {
        if (form) {
          this.valid = true;
        }
      } else {
        this.valid = false;
      }
    },
  },
  created() {  
    this.setArrayItens()
     if (this.isUpdate) {
      this.setItemtoUpdate(this.getItemToUpdateTransporter);
    }
  },

  watch: {
    "$vuetify.breakpoint.xsOnly": function(){
      this.setArrayItens()
    },
    validPhysical(val) {
      this.validator(val, this.validPessoal);
    },
    validPessoal(val) {
      this.validator(val, this.validPhysical);
    },
    validLegal(val) {
      this.valid = val ? true : false;
    },
  },
};
</script>

