<template>
  <v-row>
    <v-col
      v-for="(item, index) in informations"
      :key="index"
      cols="16"
      sm="4"
      md="3"
      xl="2"
    >
      <v-hover v-slot="{ hover }" open-delay="200">
        <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
          :color="listColors[index]"
          id="card"
          class="d-flex align-center pa-5"
          :to="item.route"
        >
          <v-col>
            <v-row class="d-flex justify-center">
              <div class="image">
                <v-img :src="item.image" aspect-ratio="1"></v-img>
              </div>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="label">{{ item.label }}</p>
            </v-row>
          </v-col>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import Client from "../assets/clientes.png";
import Product from "../assets/Box.png";
import Sellers from "../assets/Vendedor.png";
import Sales from "../assets/Vendas.png";
import Notes from "../assets/NotaFiscal.png";
import Users from "../assets/Usuario.png";
import Transporter from "../assets/delivery-truck.png";
import PaymentForm from "../assets/debit-card.png";
import ConditionPayment from "../assets/terms-and-conditions.png";
import Cashiers from "../assets/caixa-registradora.png";
import Permissions from "../assets/permission.png";
import Enterprise from "../assets/companhia.png";

export default {
  data: function () {
    return {
      paleteColors: [
        "#ffadad",
        "#ffd6a5",
        "#fdffb6",
        "#caffbf",
        "#9bf6ff",
        "#a0c4ff",
        "#bdb2ff",
        "#ffc6ff",
        //entrada de uma paleta de cor
        "#f94144",
        "#f3722c",
        "#f8961e",
        "#f9844a",
        "#f9c74f",
        "#90be6d",
        "#43aa8b",
        "#4d908e",
        "#577590",
        "#277da1",
      ],
      listColors: Array(),
      informations: [
        { image: Client, route: "/client", label: "Cadastro de Clientes" },
        { image: Product, route: "/produtos", label: "Cadastro de Produtos" },
        { image: Sellers, route: "/seller", label: "Cadastro de Vendedores" },
        {
          image: Transporter,
          route: "/transport",
          label: "Cadastro Transportador",
        },
        {
          image: PaymentForm,
          route: "/formOfPayment",
          label: "Cadastro Forma de Pagamento",
        },
        {
          image: ConditionPayment,
          route: "/paymentTerms",
          label: "Cadastro Condições de Pagamento",
        },
        { image: Cashiers, route: "/cashier", label: "Cadastro de Caixas" },
        { image: Sales, route: "/Sales", label: "Vendas" },
        { image: Notes, route: "/notes", label: "Notas Fiscais Emitidas" },
        { image: Users, route: "/users", label: "Usuários" },
        { image: Enterprise, route: "/enterprise", label: "Empresa" },
        { image: Permissions, route: "/permission", label: "Permissões" },
      ],
    };
  },
  methods: {
    setColors() {
      for (let index = 0; index < this.informations.length; index++) {
        this.listColors.push(
          this.paleteColors[
            Math.floor(Math.random() * this.paleteColors.length)
          ]
        );
      }
    },
    onKey(e) {
      let keycode = e.key;
      switch (keycode) {
        case "F1":
          this.$router.push("Client").catch(() => {});
          break;
        case "F2":
          this.$router.push("produtos").catch(() => {});
          break;
        case "F3":
          this.$router.push("Seller").catch(() => {});
          break;
        case "F4":
          this.$router.push("Sales").catch(() => {});
          break;
        case "F8":
          this.$router.push("Notes").catch(() => {});
          break;
        case "F9":
          this.$router.push("users").catch(() => {});
          break;
      }
    },
  },

  mounted() {
    document.addEventListener("keyup", this.onKey);
    this.setColors();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.onKey);
  },
};
</script>

<style>
.label {
  font-weight: bold;
  color: black;
}
#card {
  width: 500px;
  height: 220px;
}
.image {
  width: 120px;
  height: 120px;
}
.titleMain {
  font-family: "Courier New", Courier, monospace;
  padding-top: 20px;
  color: white;
  font-size: 1.8rem;
}
.subtitleMain {
  margin-top: 20px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 1.7rem;
  color: white;
}
</style>