import axios from "axios";
import tributeSales from "../application/Service/Tribute/CalculeTributeSalesApplication"
import axiosautomacao from "../apigestao"
const NETWORK_ERROR = "Network Error"
const {
  ADD_SALES,
  UPDATE_SALES,
  GET_ALL_SALES,
  DESTROY_SALES,
  CANCEL_SALES,
  GET_MOVEMENT_OPEN_CASHIER,
  CONFIRM_CLOSE_CASHIER,
  GET_ALL_SALES_WITH_FILTER,
  AUTOCOMPLETE_SALES,
  GET_CASHIER_CLOSE_REPORT,

} = require("./actions/sales");

const { SPLICE_ERRO } = require("./actions/alert_api");
const { CLEAN_CART } = require("./actions/cart")
const { GET_CLOSE_SALE, GET_BY_ID_SALES } = require('./actions/sales')


const moduleSales = {
  state: () => ({
    sales: Object(),
    close: Object(),
    client: Object(),
    seller: Object(),
    saleOpen: Object(),
    salesCancel: Object(),
    salesReprocess: Object(),
    salesCloseCashier: Object(),
    salesGetCashierCloseReport: Object(),
  }),

  mutations: {
    GET_CLOSE_SALE: (state, payload) => {
      state.close = payload
    },
    GET_ALL_SALES: (state, payload) => {
      state.sales = payload;
    },
    AUTOCOMPLETE_SALES: (state, payload) => {
      state.sales = payload;
    },
    GET_ALL_SALES_WITH_FILTER: (state, payload) => {
      state.sales = payload;
    },
    GET_BY_ID_SALES: (state, payload) => {
      state.salesReprocess = payload
    },
    ADD_SALES: (state, newSales) => {
      state.sales = newSales;
    },
    UPDATE_SALES(state, updateSales) {
      state.sales.data.Update(updateSales);
    },
    GET_MOVEMENT_OPEN_CASHIER(state, payload) {
      state.saleOpen = payload
    },
    CONFIRM_CLOSE_CASHIER(state, payload) {
      state.salesCloseCashier = payload
    },

    DESTROY_SALES: (state, id) => {
      let auxState = [...state.productgroups.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.productgroups = [...auxState];
    },

    SET_CLIENT_IN_MEMORY(state, data) {
      state.client = data
    },
    SET_SELLER_IN_MEMORY(state, data) {
      state.seller = data
    },
    RESET_MEMORY(state) {

      state.seller = Object()
      state.client = Object()
    },
    CANCEL_SALES(state, payload) {
      state.salesCancel = payload
    },
    GET_CASHIER_CLOSE_REPORT (state, payload){
      state.salesGetCashierCloseReport = payload
    },
  },

  actions: {
    GET_ALL_SALES({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axiosautomacao
        .post("sales/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: true
        }, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        })
        .then((response) => {
          commit(GET_ALL_SALES, response.data[0]);
        }).catch((error) => {
          commit(SPLICE_ERRO, {
            alert: "Erro ao carregar as vendas realizadas!",
            type: "E",
          });
          if (NETWORK_ERROR == error.message) {
            axios
              .post("sale/index", {
                page: payload.page,
                limit: payload.limit,
                paginate: true
              }).then((response) => {
                commit(GET_ALL_SALES, response.data);
              });
          }

        })
    },
    AUTOCOMPLETE_SALES({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axiosautomacao
        .post("sales/autocomplete", {
          search: payload.search,
          page: payload.page,
          limit: payload.limit,
          paginate: true
        }, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        })
        .then((response) => {
          console.log('response', response);
          commit(AUTOCOMPLETE_SALES, response.data);
        })
    },

    GET_ALL_SALES_WITH_FILTER({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axiosautomacao
        .post("sales/getAllNotEmitterNFe", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          data_initial: payload.initialDate,
          date_final: payload.finalDate,
          status: payload.status,
          enterprise_id: payload.enterpriseId,
          
        }, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        })
        .then((response) => {
          commit(GET_ALL_SALES_WITH_FILTER, response.data);
        }).catch((error) => {
          commit(SPLICE_ERRO, {
            alert: "Erro ao carregar as vendas realizadas!",
            type: "E",
          });
          if (NETWORK_ERROR == error.message) {
            axios
              .post("sales/getAllNotEmitterNFe", {
                page: payload.page,
                limit: payload.limit,
                paginate: true,
                data_initial: payload.initialDate,
                date_final: payload.finalDate,
                status: payload.status,
                enterprise_id: 1,
              }).then((response) => {
                commit(GET_ALL_SALES_WITH_FILTER, response.data);
              });
          }

        })
    },



    async GET_CALC_TRIBUTE({ commit }, payload) {
      commit
      let productTribute = await tributeSales.calculeTributeSales(payload.product, payload.enterprise)
      return productTribute

    },
    
    GET_BY_ID_SALES({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token_gestao");
        axiosautomacao.post("sales/getbysaleidsenterprise", payload, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        })
          .then((data) => {
            resolve({ data })
            commit(GET_BY_ID_SALES)
          }).catch((error) => {
            reject(error)
          })
      })

    },
    SALES_PROCESSING_MESSAGE({ commit }) {
      commit(SPLICE_ERRO, {
        alert: "Venda gravada com sucesso",
        type: "S",
      });
    },

    ADD_SALES({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token_gestao");
        axiosautomacao.post("sales/createsale", payload, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        })
          .then(({ data }) => {
            resolve({
              data
            })
            commit(CLEAN_CART)
            commit(ADD_SALES, data);
          })
          .catch((error) => {
            reject({error})
            commit(SPLICE_ERRO, {
              alert: "Não foi possivel gravar dados de vendas- Verificar em Notas emitidas",
              type: 'E'
            })
          });
      })

    },
    UPDATE_SALES({ commit }, payload) {
      return new Promise((resolve) => {
        let token = localStorage.getItem("token_gestao");
        axiosautomacao.post("sales/updatesale", payload, {
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        })
          .then((response) => {
            resolve({
              response
            })
            commit(UPDATE_SALES, response.data);

          })
          .catch((error) => {
            console.log(error)

          });
      })



    },



    DESTROY_SALES({ commit }, payload) {
      axios
        .post("productGroup/delete", payload)
        .then((response) => {
          commit(DESTROY_SALES, payload.id);
          commit(SPLICE_ERRO, {
            alert: response.data.sucess.message[0],
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },
    OPEN_CASHIER({ commit }, payload) {
      if (navigator.onLine) {
        return new Promise((resolve, reject) => {
          let token = localStorage.getItem("token_gestao");
          axiosautomacao.post("sales/opencashier", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          }).then((response) => {
            commit(GET_MOVEMENT_OPEN_CASHIER, response.data)
            resolve()

            commit(SPLICE_ERRO, {
              alert: response.data.msg,
              type: 'S'
            })
          }).catch((error) => {

            commit(SPLICE_ERRO, {
              alert: error.response.data.errors,
              type: 'E'
            })
            reject(error.response.data.errors)
          })
        })

      } else {
        return new Promise((resolve, reject) => {
          axios.post("sale/opencashier", payload).then((response) => {
            resolve()

            commit(SPLICE_ERRO, {
              alert: response.data.msg,
              type: 'S'
            })
          }).catch((error) => {

            commit(SPLICE_ERRO, {
              alert: error.response.data.errors,
              type: 'E'
            })
            reject(error.response.data.errors)
          })
        })
      }
    },

    BLEED({ commit }, payload) {
      if (navigator.onLine) {
        return new Promise((resolve, reject) => {
          let token = localStorage.getItem("token_gestao");
          axiosautomacao.post("sales/bleed", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          }).then((response) => {
            resolve()
            commit(SPLICE_ERRO, {
              alert: response.data.msg,
              type: 'S'
            })
          }).catch((error) => {
            reject()

            commit(SPLICE_ERRO, {
              alert: error.response.data.errors,
              type: 'E'
            })

          })
        })
      } else {
        return new Promise((resolve, reject) => {
          axios.post("sale/bleed", payload).then((response) => {
            resolve()

            commit(SPLICE_ERRO, {
              alert: response.data.msg,
              type: 'S'
            })
          }).catch((error) => {
            reject()

            commit(SPLICE_ERRO, {
              alert: error.response.data.errors,
              type: 'E'
            })

          })
        })

      }

    },
    SUPPLY({ commit }, payload) {
      if (navigator.onLine) {
        let token = localStorage.getItem("token_gestao");
        return new Promise((resolve, reject) => {
          axiosautomacao.post("sales/supply", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          }).then((response) => {
            resolve()

            commit(SPLICE_ERRO, {
              alert: response.data.msg,
              type: 'S'
            })

          }).catch((error) => {
            reject()

            commit(SPLICE_ERRO, {
              alert: error.response.data.errors,
              type: 'E'
            })

          })
        })

      } else {
        return new Promise((resolve, reject) => {
          axios.post("sale/supply", payload).then((response) => {
            resolve()

            commit(SPLICE_ERRO, {
              alert: response.data.msg,
              type: 'S'
            })

          }).catch((error) => {
            reject()

            commit(SPLICE_ERRO, {
              alert: error.response.data.errors,
              type: 'E'
            })

          })
        })
      }

    },
    async GET_MOVEMENT_OPEN_CASHIER({ commit }, payload) {
      return new Promise((resolve) => {
        let token = localStorage.getItem("token_gestao");
        axiosautomacao.post("/cashmovement/checkOperationcashierpdv", payload, {
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        }).then((response) => {
          resolve({ response })
          commit(GET_MOVEMENT_OPEN_CASHIER, response.data)
        })

      })
    },
    // return new Promise((resolve)=>{
    //   let token = localStorage.getItem("token_gestao");
    //   axiosautomacao.post("sales/getclosecashier", payload, {
    //     headers: {
    //       'Authorization': 'Bearer ' + token,
    //     }
    // }).then((response)=>{
    //   resolve({response})
    //   commit(GET_MOVEMENT_OPEN_CASHIER, response.data)
    // }),



    async GET_CLOSE_SALE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token_gestao");
        axiosautomacao.post("sales/getclosecashier", payload, {
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        }).then((response) => {
          resolve({ response })
          commit(GET_CLOSE_SALE, response.data)
        }).catch((error) => {
          reject()
          commit(SPLICE_ERRO, {
            alert: error.response.data.errors,
            type: 'E'
          })
        })

      })


      // try {
      //   let response = await axios.post("sale/closeCashier", payload)
      //   commit(SET_CLOSE_SALE, response.data)
      // } catch (error) {
      //   commit(SPLICE_ERRO, {
      //     alert: error.response.data.msg,
      //     type: 'E'
      //   })

      //   throw new Error('')
      // }
    },


    async CANCEL_SALES({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      axiosautomacao.post("sales/cancelsales", payload, {
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      }).then((response) => {
        commit(SPLICE_SUCESSO, {
          alert: "NFCE cancelado com sucesso",
          type: 'S'
        })
        commit(CANCEL_SALES, response.data)
       
      }).catch((error) => {
        commit(SPLICE_ERRO, {
          alert: error.response.data.errors,
          type: 'E'
        })
      })


    },
    async CONFIRM_CLOSE_CASHIER({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      let response = axiosautomacao.post("sales/confirmCloseCashier", payload, {
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      }).then((response) => {
        commit(CONFIRM_CLOSE_CASHIER, response.data)
        commit(SPLICE_ERRO, {
          alert: "Nfce canceladoc com sucesso",
          type: 'S'
        })
      }).catch((error) => {
        commit(SPLICE_ERRO, {
          alert: error.response.data.errors,
          type: 'E'
        })
      })


    },

    async SEARCH_COUPON({ commit }, payload) {
        let token = localStorage.getItem("token_gestao");
        const response = axiosautomacao.post("/billstopay/findByTitleExchange", payload, {
          headers: {
            'Authorization': 'Bearer ' + token,
          },
        })
        return response
        .catch ((error) => {
          commit(SPLICE_ERRO, {
            alert: "Código Inválido",
            type: 'E'
          })
        })
      },

      async GET_CASHIER_CLOSE_REPORT({ commit }, payload) {
        return new Promise((resolve, reject) => {
          let token = localStorage.getItem("token_gestao");
          axiosautomacao.post("sales/getcashclosingreport", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          }).then((response) => {
            resolve({ response })
            commit(GET_CASHIER_CLOSE_REPORT, response.data)
          }).catch((error) => {
            commit(SPLICE_ERRO, {
              alert: "Não foi possivel gerar Relatório",
              type: 'E'
            })
          })
        })
      }
  },



  getters: {
    getSales: (state) => ({ ...state.sales }),
    getSellerMemory: (state) => ({ ...state.seller }),
    getClientMemory: (state) => ({ ...state.client }),
    getCloseCashier: (state) => ({ ...state.close }),
    getSalesReprocess: (state) => ({ ...state.salesReprocess }),
    getSalesOpenCashier: (state) => ({ ...state.saleOpen })
  },
};

export default moduleSales;
