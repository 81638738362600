var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.getShowDialogRegisterClient),callback:function ($$v) {_vm.getShowDialogRegisterClient=$$v},expression:"getShowDialogRegisterClient"}},[_c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"success","flat":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":() => {
            _vm.close_dialog();
          }}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.header)+" Cliente")]),_c('v-spacer')],1),_c('v-container',[_c('v-tabs',[_c('v-tab',{attrs:{"href":"#tab1"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-account-details")]),_vm._v(" Gerais ")],1),_c('v-tab',{attrs:{"href":"#tab2"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-home-account")]),_vm._v(" Endereço ")],1),(!_vm.register)?_c('v-tab',{attrs:{"href":"#tab3"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-card-account-mail")]),_vm._v(" Pessoal ")],1):_vm._e(),_c('v-tab',{attrs:{"href":"#tab4"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-family-tree")]),_vm._v(" Contato ")],1),_c('v-tab',{attrs:{"href":"#tab5"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-bank")]),_vm._v(" Contas Bancárias ")],1),_c('v-tab-item',{attrs:{"value":"tab1"}},[_c('v-switch',{attrs:{"label":"Pessoa Jurídica"},model:{value:(_vm.register),callback:function ($$v) {_vm.register=$$v},expression:"register"}}),(!_vm.register)?_c('v-card',{attrs:{"flat":""}},[_c('TabPhisical',{ref:"phys",attrs:{"component":this}})],1):_c('v-card',{attrs:{"flat":""}},[_c('TabLegal',{ref:"legal",attrs:{"component":this}})],1)],1),_c('v-tab-item',{attrs:{"value":"tab2"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card',{attrs:{"flat":""}},[_c('TabAddress',{attrs:{"component":this}})],1)],1)],1),_c('v-tab-item',{attrs:{"value":"tab3"}},[_c('v-card',{attrs:{"flat":""}},[_c('TabPessoal',{attrs:{"component":this}})],1)],1),_c('v-tab-item',{attrs:{"value":"tab4"}},[_c('TabContact',{attrs:{"component":this}})],1),_c('v-tab-item',{attrs:{"value":"tab5"}},[_c('v-card',{attrs:{"flat":""}},[_c('TabBank',{attrs:{"component":this}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(!_vm.justView)?_c('v-btn',{staticClass:"error",on:{"click":() => {
              _vm.clear();
            }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cancel")]),_vm._v(" Limpar Formulário ")],1):_vm._e(),(!_vm.justView)?_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"success"},on:{"click":() => {
              _vm.submit();
            }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-content-save")]),_vm._v(" Salvar ")],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }