const nameRules = [(v) => !!v || "Nome é obrigatório"];

const LastNameRules = [(v) => !!v || "Sobrenome é obrigatório"];

const cpfRules = [
  (v) => !!v || "CPF é obrigatório",
  (v) => v.length == 11 || "O CPF deve possuir 11 caracteres",
];

export { nameRules, LastNameRules, cpfRules };
