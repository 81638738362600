<template>
  <v-container>
   <v-divider></v-divider>
   <v-form ref="form" v-model="component.validPersonal">
     <v-row>
       <v-col>
         <v-text-field
           v-model="component.physicalClient.motherName"
           label="Nome da Mãe"
           outlined
           required
         >
         </v-text-field>
       </v-col>
       <v-col>
         <v-text-field
           v-model="component.physicalClient.fatherName"
           label="Nome do Pai"
           outlined
           required
         >
         </v-text-field>
       </v-col>
     </v-row>
     <v-row>
       <v-col>
         <v-select
           label="*Gênero"
           outlined
           v-model="component.physicalClient.gender"
           :items="items_gender"
           :rules="[() => !!component.physicalClient.gender || 'Campo Obrigatorio']"
         >
         </v-select>
       </v-col>
       <v-col>
         <v-text-field
           v-model="component.physicalClient.monthlyIncome"
           label="Renda Mensal"
           outlined
           type="number"
           prefix="R$"
           required
         >
         </v-text-field>
       </v-col>
     </v-row>
     <v-row>
       <v-col cols="3">
         <v-select
           v-model="component.physicalClient.civilStatus"
           label="*Estado Civil"
           :items="civilStatus"
           outlined
           @blur="checkCivilStatus()"
         >
         </v-select>
       </v-col>
       <v-col cols="6">
         <v-text-field
           v-model="component.physicalClient.spouse"
           label="Cônjugue"
           outlined
           required
         >
         </v-text-field>
       </v-col>
       <v-col>
         <v-text-field
           type="number"
           v-model="component.physicalClient.icomeSpouse"
           label="Renda Conjugal"
           outlined
           prefix="R$"
         >
         </v-text-field>
       </v-col>
     </v-row>
   </v-form>
  </v-container>
 </template>
 
 <script>
 export default {
   props: {
     component: Object(),
   },
   data: () => ({
     items_gender: ["Masculino", "Feminino"],
     civilStatus: ["Casado(a)", "Divorciado(a)", "Solteiro(a)", "Viúvo(a)"],
   }),

   methods: {
    checkCivilStatus() {
      if(this.component.physicalClient.civilStatus == "") {
        this.component.physicalClient.civilStatus = "Solteiro(a)"
      }
    }
   },
 };
 </script>
 
 <style>
 </style>