<template>
  <v-row>
    <v-card-title>Vendedor</v-card-title>
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        outlined
        dense
        flat
        :items="getEnterprises.data"
        v-model="enterprise"
        height="10px"
        label="Empresa"
        item-value="id"
        item-text="fantasyName"
        loader-height
        >Adicionar</v-select
      >
    </v-col>
    <!-- <v-btn
      color="success mr-2 mt-3"
      v-if="component.permission.Cadastrar"
      v-on:click="
        () => {
          executeDrawer();
        }
      "
      >Adicionar</v-btn
    > -->
  </v-row>
</template>

<script>
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    getters: Object,
  },

  data: () => ({
    enterprise: 1,
  }),
  methods: {
    executeDrawer() {
      this.component.enterprise_id = this.enterprise;
      this.component.addDialog = !this.component.addDialog;
    },
  },

  computed: {
    ...mapGetters(["getEnterprises"]),
  },

  async created() {
    await this.$store.dispatch(GET_ALL_ENTERPRISE, {
      page: 1, limit: 10, paginate:true 
    });
  },
};
</script>

<style>
</style>