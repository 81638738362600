<template>
  <v-form ref="form" v-model="component.validPessoal">
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-text-field
          v-model="component.PhysicalPeople.motherName"
          :readonly="component.justVisible"
          label="Nome da Mãe"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-text-field
          v-model="component.PhysicalPeople.fatherName"
          :readonly="component.justVisible"
          label="Nome do Pai"
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-select
          label="*Gênero"
          :rules="rulesMandatory"
          outlined
          v-model="component.PhysicalPeople.gender"
          :readonly="component.justVisible"
          :items="items_gender"
        >
        </v-select>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-text-field
          v-model="component.PhysicalPeople.monthlyIncome"
          :readonly="component.justVisible"
          label="*Renda Mensal"
          :rules="rulesMandatory"
          outlined
          type="number"
          prefix="R$"
          required
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-select
          v-model="component.PhysicalPeople.civilStatus"
          :readonly="component.justVisible"
          label="*Estado Civil"
          :rules="rulesMandatory"
          :items="civilStatus"
          outlined
          required
        >
        </v-select>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-text-field
          v-model="component.PhysicalPeople.spouse"
          :readonly="component.justVisible"
          label="Cônjugue"
          outlined
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-text-field
          type="number"
          v-model="component.PhysicalPeople.icomeSpouse"
          :readonly="component.justVisible"
          label="Renda Conjugal"
          outlined
          prefix="R$"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    rulesMandatory: [(v) => !!v || "Campo obrigatório"],
    items_gender: ["Masculino", "Feminino"],
    civilStatus: ["Casado(a)", "Divorciado(a)", "Solteiro(a)", "Viúvo(a)"],
  }),
};
</script>

<style>
</style>