const links = [
    {
        text: "Empresa",
        icon: "mdi-home-city-outline",
        route: "/enterprise",
        class: "white--text",
        active: false
    },
    {
        text: "Usuario",
        icon: "mdi-account-settings-outline",
        route: "/users",
        class: "white--text",
        active: false
    },
    {
        text: "Permissão",
        icon: "mdi-shield-account-outline",
        route: "/permission",
        class: "white--text",
        active: false
    },
    {
        text: "Contingência",
        icon: "mdi-shield-account-outline",
        route: "/contingency",
        class: "white--text",
        active:true
    },
    {
        text: "Parâmetros",
        icon: "mdi-cog-transfer-outline",
        route: "/parameter",
        class: "white--text",
        active: false
    },

];
export { links };
