<template>
  <v-row justify="center">
    <v-dialog v-model="this._props.addDialogNcm" max-width="600" persistent>
      <v-card class="mx-auto" outlined>
        <v-card-title primary-title>Ncm</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="Codigo"
                v-model="code"
                onKeyPress="if(this.value.length==8) return false;"
                type="number"
                :rules="[
                  () => !!code || 'Campo Obrigatorio',
                  () =>
                    (!!code && code.length == 8) ||
                    'Campo tem que ser igual a 8',
                ]"
                outlined
              ></v-text-field>
              <v-textarea
                label="Descrição"
                v-model="description"
                :rules="[() => !!description || 'Campo Obrigatorio']"
                outlined
              />
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    closeNcmDialog();
                    //runAlterDialog(this, alterAddDialog);
                  }
                "
                >Cancelar</v-btn
              >
              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiAccount } from "@mdi/js";
import { ADD_NCM, UPDATE_NCM } from "../../../store/actions/ncm";
export default {
  props: {
    addDialogNcm: Boolean,
    component: Object,
  },

  data: () => ({
    attrs: false,
    on: false,
    valid: true,
    id: "",
    code: "",
    description: "",
    cfopToUpdate: "",
    svgPath: mdiAccount,
    search: "",
    formHasErrors: false,
  }),
  methods: {
    closeNcmDialog() {
      this.component.addDialogNcm = false;
    },
    getComponent() {
      return this._props.component;
    },
    async create_ncm() {
      const ncm = {
        code: this.code,
        description: this.description,
      };
      try {
        await this.$store.dispatch(ADD_NCM, ncm);
        this.closeNcmDialog();
      } catch (error) {
        alert(error);
      }
    },
    submit(item) {
      this.id == undefined ? this.create_ncm() : this.update_ncm();
      this.component.change_addDialog(item);
      this.component.addDialog = false;
    },
    async update_ncm() {
      const ncm = {
        id: this.id,
        code: this.code,
        description: this.description,
      };
      try {
        await this.$store.dispatch(UPDATE_NCM, ncm);
      } catch (error) {
        alert(error);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },

  async created() {
    this.object = { ...this.getComponent().objectToUpdate };
    this.id = this.object.id;
    this.description = this.object.description;
    if (this.id >= 0) {
      this.code = this.object.code.toString();
    } else {
      this.code = this.object.code;
    }
  },
  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
