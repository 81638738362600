import axios from "axios";
const {
  ADD_PRODUCT_GROUP,
  UPDATE_PRODUCT_GROUP,
  GET_ALL_PRODUCT_GROUP,
  DESTROY_PRODUCT_GROUP,
} = require("./actions/productgroup");

const {
  SPLICE_SUCESSO, SPLICE_ERRO
} = require('./actions/alert_api');

const moduleProductGroup = {
  state: () => ({
    productgroups: Object(),
    newProductgroupsToAdd: Object(),
  }),
  mutations: {
    GET_ALL_PRODUCT_GROUP: (state, productGroups) => {
      state.productgroups = productGroups;
    },
    ADD_PRODUCT_GROUP: (state, newProductGroup) => {
      state.newProductgroupsToAdd = newProductGroup
      //state.productgroups.data.push(newProductGroup);
    },
    UPDATE_PRODUCT_GROUP(state, updateProductGroup) {
      let result = state.productgroups.data.find((r) => r.id === updateProductGroup.data.id);
      for (let key in updateProductGroup.data) {
        result[key] = updateProductGroup.data[key];
      }
    },

    DESTROY_PRODUCT_GROUP: (state, id) => {
      let auxState = [...state.productgroups.data];
      let index = auxState.indexOf(
        auxState.find(value => value.id === id),
      );
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.productgroups.data = [...auxState];
    },
  },
  actions: {
    GET_ALL_PRODUCT_GROUP({
      commit
    }, {
      page
    }) {
      axios.post("productgroup/index", {
        page
      }).then((response) => {
        commit(GET_ALL_PRODUCT_GROUP, response.data);

      });
    },

    ADD_PRODUCT_GROUP({
      commit
    }, payload) {
      axios.post("productgroup/create", payload).then((response) => {
        commit(ADD_PRODUCT_GROUP, response.data);
        commit(SPLICE_SUCESSO, {
          alert: response.data.msg,
          type: 'S'
        })

      }).catch(r => {
        let erros = r.response.data.errors
        for (let erro in erros) {
          erros[erro].forEach(err => commit(SPLICE_ERRO, {
            alert: err,
            type: 'E'
          }))
        }
      });
    },

    UPDATE_PRODUCT_GROUP({
      commit
    }, payload) {
      axios.post("productgroup/update", payload).then((response) => {
        commit(UPDATE_PRODUCT_GROUP, response.data[0]);
        commit(SPLICE_SUCESSO, {
          alert: response.data.msg,
          type: 'S'
        })
      }).catch(r => {
        let erros = r.response.data.errors || r.response.data[0].errors
        for (let erro in erros) {
          erros[erro].forEach(err => commit(SPLICE_ERRO, {
            alert: erro + " : " + err,
            type: 'E'
          }))
        }
      });
    },

    DESTROY_PRODUCT_GROUP({
      commit
    }, payload) {
      axios
        .post("productgroup/delete", payload)
        .then((response) => {
          commit(DESTROY_PRODUCT_GROUP, payload.id);
          commit(SPLICE_SUCESSO, {
            alert: response.data.sucess.message[0],
            type: 'S'
          })
        }).catch(r => {
          let erros = r.response.data.errors
          for (let erro in erros) {
            erros[erro].forEach(err => commit(SPLICE_ERRO, {
              alert: err,
              type: 'E'
            }))
          }
        });
    },
  },

  getters: {
    getNewProductgroupsToAdd: (state) => state.newProductgroupsToAdd,
    getProductGroup: (state) => ({ ...state.productgroups }),
  },
};

export default moduleProductGroup;