



async function setValueIpidefault(product) {
    product.value_ipi_product = 0
    product.value_ipi_outher_product = 0
    product.value_ipi_free_product = 0
    product.value_base_ipi_product = 0


    return product

}


module.exports = {
    setValueIpidefault
}