const ADD_TAX_REGIME = "ADD_TAX_REGIME";
const UPDATE_TAX_REGIME = "UPDATE_TAX_REGIME";
const GET_ALL_TAX_REGIME = "GET_ALL_TAX_REGIME";
const DESTROY_TAX_REGIME = "DESTROY_TAX_REGIME";

module.exports = {
    ADD_TAX_REGIME,
    UPDATE_TAX_REGIME,
    GET_ALL_TAX_REGIME,
    DESTROY_TAX_REGIME ,
}
