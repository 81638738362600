<template>
  <div>
    <Cancel v-if="dialog_cancel" :component="this" />
    <CancelError v-if="cancelErrorDialog" :component="this" />
    <v-data-table
      :headers="headers"
      :items="results"
      hide-default-footer
      no-data-text="Nenhuma nota cadastrada"
      no-results-text="Nenhum resultado encontrado"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, -1],
        'items-per-page-text': 'Items por página',
      }"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
        <v-icon
          class="mr-2"
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="
            () => {
              activator_info(item);
            }
          "
        >
          mdi-eye
        </v-icon>
      </template>
          <span>Visualizar Nota</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="red darken-4"
          class="mr-2"
          v-bind="attrs"
          v-on="on"
          @click="
            () => {
              activator_cancel(item);
            }
          "
          >mdi-cancel</v-icon
        >
      </template>
          <span>Cancelar Nota</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="primary"
          class="mr-2"
          v-bind="attrs"
          v-on="on"
          @click="
            () => {
              activator_reprint(item);
            }
          "
          >mdi-printer</v-icon
        >
      </template>
          <span>Imprimir Nota</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="primary"
          class="mr-2"
          v-bind="attrs"
          v-on="on"
          @click="
            () => {
              activator_reprocess(item);
            }
          "
          >mdi-note-edit</v-icon
        >
      </template>
          <span>Reprocessar nota</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
      :disabled="paginationDelay"
    ></v-pagination>
    <Info v-if="dialog" :component="this" />
  </div>
</template>

  <script>
/* eslint-disable no-unused-vars */

import { headers } from "./script";
import Info from "../AddDialog/View_info/index.vue";
import Cancel from "../AddDialog/View_cancel/";
import CancelError from "../AddDialog/CancelErrorDialog/index.vue";
import { GET_ALL_SALES, GET_ALL_SALES_WITH_FILTER, AUTOCOMPLETE_SALES } from "../../../store/actions/sales";
import {
  GET_NFCE_WITH_KEY,
  REPRINT_NFCE_WITH_KEY,
  REPROCESS_NFCE_WITH_KEY,
  REPROCESS_NFCE_BEFORE_ERROR_DATA,
  GET_NFCE_WITH_KEY_RESULT,
} from "../../../store/actions/nfce";
import {
  GET_TAX_PRODUCT_IBPT
} from "../../../store/actions/ibpt"

import { UPDATE_SALES } from "../../../store/actions/sales";
import { mapGetters } from "vuex";

export default {
  components: { Info, Cancel, CancelError },
  props: {
    search: String,
    component: Object(),
    filterData: Object(),
    filter: Boolean(),
  },

  data: () => ({
    paginationDelay: Boolean(),
    headers,
    dialog: false,
    dialog_cancel: false,
    results: Array(),
    total: Number(),
    current: Number(),
    sendItems: Object(),
    objectNfceCancel: Object(),
    nfce: Object(),
    nfcePrint: Object(),
    getSaleReprocess: Object(),
    user: Object(),
    cancelErrorDialog: false,
  }),

  methods: {
    checkCancelNotePermission(item) {
      if (this.user.cashieroperator.cancel_cupon == true) {
        this.activator_cancel(item);
      } else {
        this.component.cancelDialog = true;
      }
    },
    checkPrintNotePermission(item) {
      if (this.user.cashieroperator.print_cupon == true) {
        this.activator_reprint(item);
      } else {
        this.component.printDialog = true;
      }
    },
    activator_info(items) {
      this.dialog = !this.dialog;
      this.sendItems = items;
      this.getStatusNfce(items);
    },
    async activator_reprint(nfce) {
      let payload = {
        cnpjEmitter: this.getEnterprises.data[0].cnpj,
        keyNfe: nfce.access_key,
        modelNf: "65",
      };

      if (nfce.status === "Autorizada") {
        await this.$store
          .dispatch(REPRINT_NFCE_WITH_KEY, payload)
          .then((data) => {
            this.nfcePrint = data.response.data.nfce;
            window.open(this.nfcePrint);
          });
      } else {
        alert("Essa nota não pode ser impressa");
      }
    },
    async activator_reprocess(sale) {
      if (sale.status === "Rejeitada") {
        let payload = {
          sale_id: sale.id,
          enterprise_id: sale.enterprise_id,
        };
        // await this.getNfceWithCalculeValueIbptReprocessItens(sale)
        await this.calcIbptProductsItensNfceDigitacao(sale, sale.itens_sales);
        await this.calcIbptTotalNfceDigitacao(sale,sale.itens_sales)
        this.$store.dispatch(REPROCESS_NFCE_WITH_KEY, sale).then((response) => {
          sale.access_key = response.response.data.nfce.key;
          sale.status = response.response.data.nfce.status;
          if (sale.status === "Autorizada") {
            sale.number_sale = response.response.data.nfce.numberNfe;
            sale.authorization_protocol =
              response.response.data.nfce.numberProtocol;
            sale.series = response.response.data.nfce.seriesDocumentSupervisior;
            this.$store.dispatch(UPDATE_SALES, sale);
            this.status = "Imprimindo Danfe";
            this.nfcePrint = response.response.data.nfcepdf;
            window.open(this.nfcePrint);
          } else {
            this.title = "Error na emissao nfce";
            sale.number_sale = response.response.data.nfce.numberNfe;
            sale.authorization_protocol =
              response.response.data.nfce.numberProtocol;
            sale.series = response.response.data.nfce.seriesDocumentSupervisior;
            this.status = "Erro na emissão nfce - Nfce" + sale.status;
          }
        });
      } else if (sale.status === "Em Digitacao") {
        await this.calcIbptProductsItensNfceDigitacao(sale, sale.itens_sales);
        await this.calcIbptTotalNfceDigitacao(sale,sale.itens_sales)
        this.$store
          .dispatch(REPROCESS_NFCE_BEFORE_ERROR_DATA, sale)
          .then((response) => {
            sale.access_key = response.response.data.nfce.key;
            sale.status = response.response.data.nfce.status;
            if (sale.status === "Autorizada") {
              sale.number_sale = response.response.data.nfce.numberNfe;
              sale.authorization_protocol =
                response.response.data.nfce.numberProtocol;
              sale.series =
                response.response.data.nfce.seriesDocumentSupervisior;
              this.$store.dispatch(UPDATE_SALES, sale);
              this.status = "Imprimindo Danfe";
              this.nfcePrint = response.response.data.nfcepdf;
              window.open(this.nfcePrint);
            } else {
              this.title = "Error na emissao nfce";
              sale.number_sale = response.response.data.nfce.numberNfe;
              sale.authorization_protocol = "00000000"
                //response.response.data.nfce.numberProtocol;
              sale.series =
                response.response.data.nfce.seriesDocumentSupervisior;
              this.status = "Erro na emissão nfce - Nfce" + sale.status;
              this.$store.dispatch(UPDATE_SALES, sale);
            }
          });
      } else {
        alert("Essa nota não pode ser reprocessada");
      }
    },
    async getNfceWithCalculeValueIbptReprocessItens(sales) {
      const REGIME_NORMAL_ENTERPRISE =  "3"
      let payload = {
        cnpjEmitter: this.getEnterprises.data[0].cnpj,
        keyNfe: sales.access_key,
        modelNf: "65",
      };
      await this.$store.dispatch(GET_NFCE_WITH_KEY_RESULT, payload).then((result)=> {
      let nfceNotProcess = result.response.data.nfes[0]
      sales.valueApproximateTax = nfceNotProcess.nfe_total_grand[0].valueApproximateTax
      sales.itens_sales.forEach((itemSale)=> {
        for (let index = 0; index < nfceNotProcess.nfe_products.length; index++) {
          if (itemSale.product_id  === parseInt(nfceNotProcess.nfe_products[index].codeProduct)){

            itemSale.valueOfApproximateTax = this.getEnterprises.data[0].crt == REGIME_NORMAL_ENTERPRISE
            ? nfceNotProcess.nfe_products[index].nfe_tax_normal[0].valueOfApproximateTax :
            nfceNotProcess.nfe_products[index].nfe_tax_simples_nacional[0].valueOfApproximateTax

          }  
      }
      })
      }).catch((error)=> {
        alert(error)
      })
    },
    async calcIbptProductsItensNfceDigitacao(sale, itensVenda) {
      let valueAproximeTaxNational = 0;
      let valueAproximeTaxState = 0;
      let itensProductResultApiIbpt = {};
      let valueItemProductTaxAprox = 0;
      let itensSalesIbpt = this.mounterValueCalcIbpt(itensVenda);
      await this.$store
        .dispatch(GET_TAX_PRODUCT_IBPT, {
          cnpjEmitter: this.getEnterprises.data[0].cnpj,
          listProducts: itensSalesIbpt,
        })
        .then((data) => {
          itensProductResultApiIbpt = data.response.data.ibptTaxProducts;
          itensVenda.forEach((itemProduct) => {
            itensProductResultApiIbpt.forEach((itemResultApi) => {
              if (String(itemProduct.ncmProduct) === itemResultApi.code_tax) {
                valueAproximeTaxNational = itemProduct.value_sale *(itemResultApi.tax_national / 100);
                valueAproximeTaxState =  itemProduct.value_sale * (itemResultApi.tax_state / 100);
                valueItemProductTaxAprox = parseFloat(valueAproximeTaxNational) + parseFloat(valueAproximeTaxState);
                itemProduct.valueOfApproximateTax  = valueItemProductTaxAprox.toFixed(2)
              }
            });
          });
     
        })
        .catch((error) => {
          this.calcIbptProductsIfExceptionErrorApi(sale, itensVenda);
        });
    },

    calcIbptProductsIfExceptionErrorApi(sale,itensVenda) {
      const nacionalTax = 7.85 / 100;
      const stateTax = 17 / 100;
      let valueTotalTaxAproxDefault = 0;
      let valueCalcNacional = 0;
      let valueStateTax = 0;
      let valueTaxProduct = 0;
      itensVenda.forEach((itemProduct) => {
        valueCalcNacional = itemProduct.value_sale * nacionalTax;
        valueStateTax = itemProduct.value_sale * stateTax;
        valueTaxProduct = parseFloat(valueCalcNacional) +  parseFloat(valueStateTax)
        itemProduct.valueOfApproximateTax =  valueTaxProduct.toFixed(2)
        valueTotalTaxAproxDefault = valueTotalTaxAproxDefault + valueCalcNacional + valueStateTax;
      });
      sale.valueApproximateTax = valueTotalTaxAproxDefault.toFixed(2);
    },

    mounterValueCalcIbpt(itensVenda) {
      let listProductIbpt = [];
      let productIbpt = {};

      itensVenda.forEach((item) => {
        productIbpt = {
          code_tax: String(item.ncmProduct),
          uf_operation: "ES",
          extarif: 0,
          code_product: String(item.product_id),
          description_product: item.description,
          unit_product: item.product.unit.sigla,
          valor_product: item.value_sale,
          gtin: this.checkGetin(item.product.bar_code),
        };
        listProductIbpt.push(productIbpt);
      });
      return listProductIbpt;
    },
    checkGetin(eanProduct) {
      if (eanProduct === null || eanProduct === "") {
        return "SEM GTIN";
      }
      return eanProduct;
    },

    async calcIbptTotalNfceDigitacao(sale, itensVenda) {
      let valueAproximateTributeTotal = 0;
      itensVenda.forEach((itemProduct) => {
        valueAproximateTributeTotal  = parseFloat(valueAproximateTributeTotal)  + parseFloat(itemProduct.valueOfApproximateTax)
      })
      sale.valueApproximateTax = valueAproximateTributeTotal;
    },


    async reprocess_nfce(nfce) {
      let payload = {
        cnpjEmitter: this.getEnterprises.data[0].cnpj,
        keyNfe: nfce.access_key,
        modelNf: "65",
      };
      await this.$store.dispatch(REPRINT_NFCE_WITH_KEY, payload);
      window.open(this.nfcePrint);
    },
    activator_cancel(item) {
      const nfceDate = new Date(item.created_at);
      const currentDate = new Date();
      const timeDifference = currentDate - nfceDate;
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));
      // console.log('minutesDifference', minutesDifference);
      if(minutesDifference <= 30) {
        this.objectNfceCancel = { ...item };
        this.dialog_cancel = !this.dialog_cancel;
      } else {
        this.cancelErrorDialog = true
      }
    },
    async getAll(page = 1) {
      await this.$store.dispatch(GET_ALL_SALES, {
        page: page,
        limit: 10,
        paginate: true,
      });
    },
    async getStatusNfce(nfce) {
      let payload = {
        cnpjEmitter: this.getEnterprises.data[0].cnpj,
        keyNfe: nfce.access_key,
        modelNf: "65",
      };
      await this.$store.dispatch(GET_NFCE_WITH_KEY, payload);
    },
    async getAllByFilter(page = 1) {
      if(this.component.filterData.status == "Em Digitação") {
        this.component.filterData.status = "Em Digitacao"
      }
      await this.$store.dispatch(GET_ALL_SALES_WITH_FILTER, {
        page: page,
        limit: 10,
        paginate: true,
        initialDate: this.component.filterData.initialDate,
        finalDate: this.component.filterData.finalDate,
        status: this.component.filterData.status,
        enterpriseId: this.component.filterData.enterpriseId,
      });
    },
    async getAutocompleteNfce(page = 1) {
      await this.$store.dispatch(AUTOCOMPLETE_SALES, {
        search: this.search,
        page: page,
        limit: 10,
        paginate: true,
      });
    },
  },
  computed: {
    ...mapGetters([
      "getSales",
      "getStatusNFce",
      "getEnterprises",
      "rePrintNfce",
      "getSalesReprocess",
      "auth/getUser",
    ]),
  },
  async mounted() {
    await this.getAll();
    this.user = { ...this["auth/getUser"] };
  },
  watch: {
    getSales: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
      this.results.forEach((e) => {
        if (e.cliente.people.legalpeople === null) {
          e.cliente.name = e.cliente.people.physicalpeople.name;
        } else {
          e.cliente.name = e.cliente.people.legalpeople.fantasyName;
        }
      });
      this.paginationDelay = false
    },
    getStatusNFce: function (val) {
      this.nfce = val;
    },
    rePrintNfce: function (val) {
      this.nfcePrint = val.nfce;
    },
    reReprocessNfce: function (val) {
      this.nfcePrint = val.nfce;
    },
    getSalesReprocess: function (val) {
      this.getSaleReprocess = val;
    },
    current: function (val) {
      this.paginationDelay = true
      if(this.component.filterData.status == 'Todos') {
        this.getAll && this.getAll(val);
      } else {
        this.getAllByFilter(val)
      }
    },
    "component.filter": function() {
      if(this.component.filterData.status == 'Todos') {
        this.getAll();
      } else {
        this.getAllByFilter(1)
      }
    },
    search: function (val) {
      if(val.length == 0) {
        this.getAll();
      } else if(val.length >= 2){
        this.getAutocompleteNfce()
      }
    },
    'component.searchInput': function (val) {
      if(val.length == 0) {
        this.getAll();
      }
    }
  },
};
</script>
