<template>
    <v-dialog
          v-model="component.cancelErrorDialog"
          width="450"
        >
    
          <v-card text-center>
            <v-card-title class="text-h5 grey lighten-2">
              Não é possível cancelar esta Nota
            </v-card-title>
    
            <v-card-text style="font-size: 18px;">
              O Limite de 30 minutos após a emissão da nota foi expirado
            </v-card-text>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="component.cancelErrorDialog = false"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
    <script>
    export default {
      props: {
        component: Object,
      },
    };
    </script>