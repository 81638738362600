<template>
    <v-row>
      <v-dialog max-width="600" v-model="this.component.certificateDialog" persistent>
        <v-card class="mx-auto" outlined>
          <v-container>
            <v-row style="justify-content: center;" v-if="component.certificateDaysLeft <= 30">
              <v-card-title style="font-size: 25px; text-align: center; display: block"> Atenção! <br> Certificado digital tem  <span style="color: #FF5252;"> &nbsp;{{ component.certificateDaysLeft }}&nbsp; </span> dias de validade </v-card-title>
            </v-row>
            <v-container style="height: 20px;"></v-container>
            <v-divider></v-divider>
            <v-card-actions style="justify-content: flex-end;">
              <v-btn @click="closeDialog()" dark style="background-color: #264867;">Ok</v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    props: { component: Object() },

    methods: {
     closeDialog() {
      this.component.certificateDialog = false
     }
    },
  };
  </script>
  