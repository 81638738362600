let emitterNFce = {
  cnpjEmitter: "",
};

async function execute(cnpj) {
  if (!cnpj) {
    throw "Favor informar cnpj do emitente";
  }
  emitterNFce.cnpjEmitter = cnpj;
  return emitterNFce;
}

module.exports = {
  execute,
};
