<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
        <v-switch
          v-model="isFormalPeople"
          label="Pessoa Jurídica"
          color="primary"
        ></v-switch>
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="search"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-card-text>
      <Table v-if="permission.Visualizar" :component="this" :search="search" />
    </v-card>
    <Addialog v-if="dialog" :component="this" title="Novo" :enterprise_id="enterpriseId"  />
    <AlertAPI />
  </Container>
</template>

<script>
import Container from "../components/Transport/Container/";
import CardTitle from "../components/Transport/CardTitle/";
import Addialog from "../components/Transport/AddDialog/";
import Table from "../components/Transport/Table/";
import AlertAPI from "../components/alert_api/";
import { mapGetters } from "vuex";
export default {
  components: {
    Container,
    Addialog,
    CardTitle,
    Table,
    AlertAPI,
  },
  data: () => ({
    search: "",
    dialog: false,
    isFormalPeople: false,
    permission: [],
    enterpriseId: 1,
  }),
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },
  computed: {
    ...mapGetters(["getCadastrosLinks"]),
  },
};
</script>

