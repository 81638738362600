<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-layout row justify-center>
            <v-flex>
              <apexchart
                type="bar"
                width="500"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: { apexchart: VueApexCharts },
  data: function () {
    return {
      series: [
        {
          data: [21, 22, 10, 28, 16],
        },
      ],

      chartOptions: {
        chart: {
          height: 250,
          type: "bar",
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            columnWidth: "40%",
            horizontal: true,
            distributed: true,
          },
        },

        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
        },
        title: {
          text: "Produtos Mais Vendidos",
          align: "center",
          floating: true,
          style: {
            color: "#286A7D",
          },
        },
        xaxis: {
          categories: [
            ["Capinha"],
            ["Lanterna"],
            ["Caixinha JBL"],
            ["Película"],
            ["Guarda-chuva"],
          ],

          labels: {
            style: {
              fontSize: "14px",
              color: "#286A7D",
            },
          },
        },
      },
    };
  },
};
</script>

<style>
</style>