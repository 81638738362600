<template>
  <v-container>
    <div>
      <v-form ref="form" v-model="validationForm">
        <v-row>
          <v-col>
            <v-text-field label="*Empresa Emitente" outlined  v-model="component.nfceData.enterprise.fantasyName" disabled></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-text-field label="*Data de Emissão" outlined v-model="component.nfceData.data_exchange" disabled></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-text-field label="*Tipo de Nota" outlined v-model="component.nfceData.sale.note_model.tax_document_type" disabled></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-text-field outlined label="*Finalidade da Nota" v-model="component.nfceData.sale.note" disabled>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" xl="3" lg="3">
            <v-text-field label="*Tipo de Operação" outlined v-model="component.nfceData.sale.taxoperationToCancel.description" disabled></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="4" xl="6" lg="6">
            <v-text-field label="*Cliente" outlined v-model="component.nfceData.sale.cliente.people.name" disabled></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-text-field label="Número da Nota" outlined v-model="component.nfceData.sale.number_sale" disabled></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="3" lg="3">
            <v-text-field label="Serie" outlined v-model="component.nfceData.sale.series" disabled></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="4" xl="4" lg="4">
            <v-text-field label="Chave de Acesso da NFE" outlined v-model="component.nfceData.sale.access_key" disabled></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="4" lg="4">
            <v-text-field label="Prot. Autorização de uso" outlined v-model="component.nfceData.sale.authorization_protocol" disabled></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" xl="4" lg="4">
            <v-text-field outlined label="Informações Adicionais" v-model="component.nfceData.sale.info"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>

export default {
  props: {
    component: Object,
  },

  data: () => ({
  }),

};
</script>

<style></style>
