<template>
  <v-form ref="form" v-model="component.valid">
    <v-row>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-autocomplete
          hide-no-data
          class="ma-2"
          type="number"
          outlined
          v-model="component.product.cst_id"
          :readonly="component.justVisible"
          label="*Cst de Saída"
          item-text="code"
          :rules="component.textRules"
          item-value="id"
          :items="cst"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changeCstDialog()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3"> 
        <v-autocomplete
          hide-no-data
          class="ma-2"
          type="number"
          outlined
          v-model="component.product.csosn_id"
          :readonly="component.justVisible"
          label="*Csosn de Saída"
          item-text="code"
          item-value="id"
          :rules="component.textRules"
          :items="csosn"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changeCsosnDialog()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-autocomplete
          hide-no-data
          class="ma-2"
          outlined
          v-model="component.product.aliquot_id"
          :readonly="component.justVisible"
          label="*Aliquota de Saída"
          :rules="component.textRules"
          item-text="aliquot"
          item-value="id"
          :items="aliquot"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changeAliquotDialog()"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-autocomplete
          hide-no-data
          class="ma-2"
          type="number"
          outlined
          v-model="component.product.cfop_id"
          :readonly="component.justVisible"
          label="*Cfop de Saída"
          item-text="code"
          :rules="component.textRules"
          item-value="id"
          append-outer-icon="mdi-plus-circle"
          :items="cfop"
          @click:append-outer="() => changeCfopDialog()"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-layout>
          <v-autocomplete
            hide-no-data
            class="ma-2"
            label="*Origem mercadoria"
            v-model="component.product.source_product"
            :rules="[
              () => !!component.product.source_product || 'Campo Obrigatório!',
            ]"
            full-width
            outlined
            :items="items"
          >
          </v-autocomplete>
        </v-layout>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-autocomplete
          class="ma-2"
          hide-no-data
          outlined
          v-model="component.product.ncm_id"
          label="*NCM"
          :rules="[
            () => !!this.component.product.ncm_id || 'Campo Obrigatório!',
          ]"
          item-text="code"
          item-value="id"
          :items="ncm"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="() => changeNcmDialog()"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4" xl="4">
        <v-autocomplete
          class="ma-2"
          hide-no-data
          outlined
          v-model="component.product.calculation_base_mode"
          label="Modalidade BC"
          item-text="text"
          item-value="value"
          :items="modBC"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <div v-if="value_st">
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            class="ma-2"
            type="string"
            outlined
            label="Codigo CEST"
            v-model="component.product.framing_class_cest"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            class="ma-2"
            type="number"
            outlined
            label="Codigo Beneficio Fiscal na UF "
            v-model="component.product.tax_bemefot_code_uf"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-autocomplete
            class="ma-2"
            hide-no-data
            outlined
            v-model="component.product.tax_replacement_base_modality"
            label="Modalidade BC ST"
            item-text="text"
            item-value="value"
            :items="modST"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            class="ma-2"
            type="number"
            outlined
            label="Margem de valor adicional (MVA)"
            v-model="component.product.additional_value_margin"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            class="ma-2"
            type="number"
            outlined
            label="Redução da base ST Icms(%)"
            v-model="component.product.percentagem_calc_st_reduction"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            class="ma-2"
            type="number"
            outlined
            label="Percentual Icms St (%)"
            v-model="component.product.additional_aliquot_tax_st"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            class="ma-2"
            type="number"
            outlined
            label="NFCI "
            v-model="component.product.nfci"
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            class="ma-2"
            type="number"
            outlined
            label="Percentual FCP (%)"
            v-model="component.product.aliquot_fcp"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            class="ma-2"
            type="number"
            outlined
            label="Percentual FCP retido ST (%)"
            v-model="component.product.percentage_fcp_tax"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3" md="3" sm="3" xl="3">
          <v-text-field
            class="ma-2"
            type="number"
            outlined
            label="Percentual Red. BC (%) "
            v-model="component.product.percentage_calc_base_reduction"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="3" md="3" sm="3" xl="3">
          <v-autocomplete
            class="ma-2"
            hide-no-data
            outlined
            v-model="component.product.reason_exemption_icms"
            label="Motivo desoneracao Icms"
            item-text="text"
            item-value="value"
            :items="modDesIcms"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="3" xl="3">
          <v-text-field
            class="ma-2"
            type="number"
            outlined
            label="Percentual Diferimento (%) "
            v-model="component.product.percentage_deferral"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="3" xl="3">
          <v-text-field
            class="ma-2"
            type="number"
            outlined
            label="Aliquota Sup. Cons. Final "
            v-model="component.product.percentage_supported_final_consumer"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <ModalNcm
      v-if="addDialogNcm"
      :addDialogNcm="addDialogNcm"
      :component="this"
      @close="addDialogNcm = $event"
    />

    <ModalAliquot
      v-if="addDialogAliquot"
      :addDialogAliquot="addDialogAliquot"
      :component="this"
      @close="addDialogAliquot = $event"
    />
    <ModalPisCofins
      v-if="addDialogPisCofins"
      :addDialogPisCofins="addDialogPisCofins"
      :component="this"
      @close="addDialogPisCofins = $event"
    />
    <ModalCfop
      v-if="addDialogCfop"
      :addDialogCfop="addDialogCfop"
      :component="this"
      @close="addDialogCfop = $event"
    />
    <ModalCst
      v-if="addDialogCst"
      :addDialogCst="addDialogCst"
      :component="this"
      @close="addDialogCst = $event"
    />
    <ModalCsosn
      v-if="addDialogCsosn"
      :addDialogCsosn="addDialogCsosn"
      :component="this"
      @close="addDialogCsosn = $event"
    />
  </v-form>
</template>

<script>
import ModalNcm from "../../../Ncm/AddDialog/";
import ModalAliquot from "../../../Aliquot/AddDialog/";
import ModalCfop from "../../../Cfop/AddDialog/";
import ModalCst from "../../../Cst/AddDialog/";
import ModalCsosn from "../../../Csosn/AddDialog/";
import { GET_ALL_CST } from "../../../../store/actions/cst";
import { GET_ALL_CSOSN } from "../../../../store/actions/csosn";
import { GET_ALL_ALIQUOT } from "../../../../store/actions/aliquot";
import { GET_ALL_CFOP } from "../../../../store/actions/cfop";
import { GET_ALL_NCM } from "../../../../store/actions/ncm";
// import ModalPisCofins from "../../../pis_cofins/AddDialog/index";

import { mapGetters } from "vuex";
export default {
  components: {
    ModalNcm,
    ModalAliquot,
    // ModalPisCofins,
    ModalCfop,
    ModalCst,
    ModalCsosn,
  },

  props: {
    component: Object(),
  },
  //
  data: () => ({
    ICMS: Object(),
    page: Number(),
    last_page: Number(),
    item_listNcm: Array(),
    item_list: Array(),
    addDialogNcm: false,
    addDialogAliquot: false,
    addDialogPisCofins: false,
    addDialogCfop: false,
    addDialogCst: false,
    addDialogCsosn: false,
    ncm: Array(),
    aliquot: Array(),
    cst_pis_cofins: Array(),
    cfop: Array(),
    cst: Array(),
    csosn: Array(),
    objectToUpdate: Object(),
    vSelect: String(),

    items: [
      { text: "Nacional", value: "0" },
      { text: "Estrangeira - Imoportação direta", value: "2" },
      { text: "Estrangeira - Adquirida no mercado interno", value: "3" },
    ],
    modBC: [
      { text: "Margem de valor agregado", value: "0" },
      { text: "Pauta", value: "1" },
      { text: "Preço Tabelado Max", value: "2" },
      { text: "Valor de Operação", value: "3" },
    ],
    modST: [
      { text: "Preço tabelado ou máximo sugerido", value: "0" },
      { text: "Lista Negativa (valor)", value: "1" },
      { text: "Lista Positiva (valor)", value: "2" },
      { text: "Lista Neutra (valor)", value: "3" },
      { text: "Margem Valor Agregado (%)", value: "4" },
      { text: "Pauta (valor)", value: "5" },
      { text: "Valor da Operação (%)", value: "6" },
    ],
    modDesIcms: [
      { text: "Uso na agropecuária", value: 3 },
      { text: "Utilitários e Motocicletas da Amazônia Ocidental", value: 6 },
      { text: "Suframa", value: 7 },
      { text: "Órgão de fomento e desenvolvimento agropecuário.", value: 12 },
      { text: "Outros", value: 9 },
    ],
    value_st: true,
  }),

  computed: {
    ...mapGetters([
      "getNcm",
      "getAliquot",
      "getCfop",
      "getCst",
      "getCsosn",
      "getPisCofins",
      "getNewCstToAdd",
      "getNewCsosnToAdd",
      "getNewAliquotToAdd",
      "getNewCfopToAdd",
      "getNewNcmToAdd",
    ]),
  },

  watch: {
    getNewNcmToAdd: function (value) {
      this.ncm.push(value);
      this.component.product.ncm_id = value.id;
    },
    getNewCfopToAdd: function (value) {
      if (value.code >= 5000) {
        this.cfop.push(value);
        this.component.product.cfop_id = value.id;
      }
    },
    getNewAliquotToAdd: function (value) {
      this.aliquot.push(value);
      this.component.product.aliquot_id = value.id;
    },
    getNewCsosnToAdd: function (value) {
      this.csosn.push(value);
      this.component.product.csosn_id = value.id;
    },
    getNewCstToAdd: function (value) {
      this.cst.push(value);
      this.component.product.cst_id = value.id;
    },
    getNcm: function (val) {
      this.ncm = [];
      this.ncm = Object.keys(val).map((key) => val[key]);
    },
    getAliquot: function (val) {
      this.aliquot = [];
      this.aliquot = Object.keys(val).map((key) => val[key]);
    },
    getCfop: function (val) {
      console.log(val);
      let cfopOut = Array();
      let responseApi = Object.keys(val).map((key) => val[key]);

      //Filtro CFOP de saída
      responseApi.map((element) => {
        if (element.code >= 5000) {
          cfopOut.push(element);
        }
      });
      this.cfop = cfopOut;
    },
    getCst: function (val) {
      this.cst = Object.keys(val).map((key) => val[key]);
    },
    getCsosn: function (val) {
      this.csosn = Object.keys(val).map((key) => val[key]);
    },
    getPisCofins: function (val) {
      this.cst_pis_cofins = Object.keys(val).map((key) => val[key]);
    },
    // "component.product.cst_id": function (val) {
    //   // this.enableTaxFieldIcms(val);
    // },
  },

  created() {
    this.getAllResourse();
    // this.component.product.cst_id = undefined;
    // this.component.product.csosn_id = undefined;
    // this.component.product.aliquot_id = undefined;
    // this.ncm = Object.keys(this.getNcm).map((key) => this.getNcm[key]);
    // this.aliquot = Object.keys(this.getAliquot).map((key) => this.getAliquot[key]);
    // this.cst_pis_cofins = Object.keys(this.getPisCofins).map(
    //   (key) => this.getPisCofins[key]
    // );
    // this.cst = Object.keys(this.getCst).map((key) => this.getCst[key]);
    // this.csosn = Object.keys(this.getCsosn).map((key) => this.getCsosn[key]);
    // this.cfop = Object.keys(this.getCfop).map((key) => this.getCfop[key]);
  },

  destroyed() {
    this.$destroy;
  },

  methods: {
    async getAllResourse(page = 0) {
      await this.$store.dispatch(GET_ALL_CST, { page });
      await this.$store.dispatch(GET_ALL_CSOSN, { page });
      await this.$store.dispatch(GET_ALL_ALIQUOT, { page });
      await this.$store.dispatch(GET_ALL_CFOP, { page });
      await this.$store.dispatch(GET_ALL_NCM, { page });
    },
    change_addDialog(item) {
      this.objectToUpdate = item;
    },
    changeNcmDialog() {
      this.addDialogNcm = !this.addDialogNcm;
    },
    changeAliquotDialog() {
      this.addDialogAliquot = !this.addDialogAliquot;
    },
    changeCfopDialog() {
      this.addDialogCfop = !this.addDialogCfop;
    },
    changeCstDialog() {
      this.addDialogCst = !this.addDialogCst;
    },
    changeCsosnDialog() {
      this.addDialogCsosn = !this.addDialogCsosn;
    },
    changePisCofinsDialog() {
      this.addDialogPisCofins = !this.addDialogPisCofins;
    },
    reset() {
      this.$refs.form.resetValidation();
    },
    getAll(page = 0) {
      this.$store.dispatch(GET_ALL_CST, page);
      this.$store.dispatch(GET_ALL_CSOSN, page);
      this.$store.dispatch(GET_ALL_ALIQUOT, page);
      this.$store.dispatch(GET_ALL_CFOP, page);
      this.$store.dispatch(GET_ALL_NCM, page);
    },
    enableTaxFieldIcms(val) {
      switch (val) {
        case 0:
          this.cst_tribute_integralmente();
          break;
        case 10:
          this.cst_tribute_icms_st();
          break;

        default:
          break;
      }
    },

    cst_tribute_integralmente() {
      (this.csosn_show = false),
        (this.code_cest = false),
        (this.mode_st = false),
        (this.mva = false),
        (this.nfci = true),
        (this.percentagem_st_reduction = false);
      this.aliquot_fcp_show = true;
    },

    cst_tribute_icms_st() {
      (this.aliquot_fcp = true),
        (this.csosn_show = false),
        (this.code_cest = true),
        (this.mode_st = true),
        (this.mva = true),
        (this.nfci = true),
        (this.additional_aliquot_tax_st = true),
        (this.percentagem_st_reduction = true),
        (this.percentage_fcp_tax = true);
    },
  },
};
</script>
