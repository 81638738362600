<template>
  <v-row justify="center">
    <v-dialog
      v-model="component.dialog_supply"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <v-card>
        <v-card-title>Suprimento</v-card-title>
        <v-card-subtitle
          >Informe o valor a ser adicionado ao caixa.</v-card-subtitle
        >
        <v-divider></v-divider>
        <v-container>
          <v-form>
            <v-col cols="12">
              <v-text-field
                outlined
                label="Tipo operação de caixa"
                v-model="cashier_operation_types_description"
                readonly
              ></v-text-field>
              <v-text-field
                outlined
                label="Valor de Adição"
                prefix="R$"
                v-model="supply.value_supply"
                placeholder="0.00"
              ></v-text-field>
            </v-col>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row justify="center">
              <v-btn
                class="error mr-1"
                @click="suggest_print ? submit_supply(null) : cancel()"
              >
                <v-icon>mdi-cancel</v-icon>{{ words.cancel }}</v-btn
              >
              <v-btn
                class="success"
                @click="
                  current_operation == 'CONFIRM'
                    ? suggestPrint()
                    : submit_supply(1)
                "
              >
                <v-icon>mdi-content-save</v-icon> {{ words.confirm }}</v-btn
              >
            </v-row>
          </v-card-actions>
          <v-card-subtitle>Pressione ESC para sair</v-card-subtitle>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { SUPPLY } from "../../../store/actions/sales";
import { GET_ALL_CASHIER_OPERATION_TYPE } from "../../../store/actions/cashier_operation_type";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    idOpenCashier: Number(),
    cashier_operation_types_description: Object(),
    cashier_operation_types_id: Number(),
    supply: {
      dateMovement: Date(),
      numberMovement: String(),
      descriptionMovement: String(),
      typeMovement: String(),
      enterprise_id: Number(),
      value_supply: String(),
      cashier_operation_types_id: Number(),
    },
    operation_type: {
      SUGGEST: "SUGGEST",
      CONFIRM: "CONFIRM",
    },
    current_operation: "CONFIRM",
    isPritting: Boolean(),
    suggest_print: Boolean(),
    isDisablead: Boolean(),
    words: {
      confirm: "Confirmar",
      cancel: "Cancelar",
    },
  }),
  methods: {
    checkState() {
      switch (this.current_operation) {
        case this.operation_type.SUGGEST:
          this.words.confirm = "IMPRIMIR";
          this.words.cancel = "NÃO";

          break;

        case this.operation_type.CONFIRM:
          this.words.confirm = "Confirmar";
          this.words.cancel = "Cancelar";

          break;
      }
    },
    suggestPrint(val) {
      if (val) this.isPritting = true;

      this.suggest_print = true;
    },
    cancel() {
      this.isDisablead = false;

      this.component.dialog_supply = !this.component.dialog_supply;
    },
    async submit_supply(value) {
      if (value) this.isPritting = true;
      this.component.titlePDF = "SUPRIMENTO DE CAIXA";

      let object = { ...this.supply };
      object.dateMovement = this.mounterDateOpenCashier();
      object.typeMovement = "Entrada";
      object.cashier_operation_types_id = this.cashier_operation_types_id;
      object.enterprise_id = this.getEnterprises.data[0].id;
      let max = 100000;
      let min = 1;
      object.numberMovement = String(
        Math.floor(Math.random() * (max - min) + min)
      );
      object.descriptionMovement = "Suprimento de caixa";

      this.component.needPrint = this.isPritting;
      this.component.doc_operation = object.numberMovement
      this.component.open_cashie_payload = object.value_supply;
      await this.$store.dispatch(SUPPLY, object);
      this.cancel();
    },

    mounterDateOpenCashier() {
      // Create a date object from a date string
      let date = new Date();

      // Get year, month, and day part from the date
      let year = date.toLocaleString("default", { year: "numeric" });
      let month = date.toLocaleString("default", { month: "2-digit" });
      let day = date.toLocaleString("default", { day: "2-digit" });
      let formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },
    processTypeOperationCashier(cashierOperationTypeList) {
      const SUPRIMENTO = "Suprimento";

      cashierOperationTypeList.forEach((cashierOperationType) => {
        if (
          cashierOperationType.type.toLowerCase() === SUPRIMENTO.toLowerCase()
        ) {
          this.cashier_operation_types_description =
            cashierOperationType.description;
          this.cashier_operation_types_id = cashierOperationType.id;
        }
      });
    },
  },
  async created() {
    const SUPRIMENTO = "Suprimento";
    await this.$store
      .dispatch(GET_ALL_CASHIER_OPERATION_TYPE, {})
      .then((response) => {
        let cashierTypeOperation = response.response.data[0];
        cashierTypeOperation.forEach((cashierOperationType) => {
          if (cashierOperationType.type === SUPRIMENTO)
            this.idOpenCashier = cashierOperationType.id;
        });
      });
  },
  computed: {
    ...mapGetters(["getAllCashierOperationType", "getEnterprises"]),
  },
  watch: {
    current_operation: function () {
      this.checkState();
    },
    getAllCashierOperationType: function (val) {
      this.processTypeOperationCashier(val[0]);
    },
    suggest_print: function (v) {
      if (v) {
        this.current_operation = this.operation_type.SUGGEST;
      } else {
        this.current_operation = this.operation_type.CONFIRM;
      }
    },
  },
};
</script>
