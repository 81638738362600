<template>
    <div class="errors">
      <div>
        <v-alert
          :color="err.type === 'E' ? 'red' : 'green'"
          :type="err.type === 'E' ? 'error' : 'success'"
          v-for="(err, i) in alert"
          :key="i"
        >
          {{ err.alert }}
        </v-alert>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  
  export default {
    computed: {
      ...mapGetters({
        alert: "auth/getFeedsLogin"
      }),
    },
  };
  </script>
  
  <style>
  .errors {
    display: flex;
    justify-content: center;
    margin: 10px;
    top: 0;
  }
  </style>