let productsNFeInitial  = {
  itemProduct: "",
  codeProduct: "",
  eanProduct: "",
  descriptionProduct: "",
  ncmProduct: "",
  nomenclatureValueCustomsProduct: "",
  codeCstProdut: "",
  relevantScaleIndicatorProduct: "",
  codeManufacturerProduct: "",
  recipientProduct: "",
  extIpiProduct: "",
  cfopProduct: "",
  unitBusinessProduct: "",
  amountBusinessProduct: "",
  valueUnityBusinessProduct: "",
  valueTotalGrossProduct: "",
  valueCostOfFreight: "",
  unityTaxProduct: "",
  amountTaxProduct: "",
  valueUnityTaxProduct: "",
  indicatesWhetherProduct: "",
  valueInsurancePriceProduct: "",
  sourceMerchandise: "",
  cstProduct: "",
  valueOfApproximateTax: "",
  methodDeterminingBaseIcms: "",
  valueBaseIcms: "",
  aliquotIcmsProduct: "",
  valueIcmsProduct: "",
  valueBaseRelatedCombatPoverty: "",
  percentageRelatedCombatPoverty: "",
  valueRelatedCombatPoverty: "",
  codeSituationTaxableCofins: "",
  codeSituationTaxablePis: "",
  csosnProduct: "",
  aliquotApplicableSimplesNacional: "",
  valueApplicableSimplesNacional: "",
  valueBaseIcmsST: "",
  // aliquotValueIcmsST: "",
  valueIcmsST: "",
  valueRelatedCombatPovertyFCP: "",
  percentageRelatedCombatPovertyFCP: "",
  valueRelatedCombatPovertyFCPWithheld: "",
  valueBaseIcmsSTWithheld: "",
  valueIcmsOwnSubstitute: "",
  valueIcmsStWithheld: "",
  valueBaseRelatedCombatPovertyWithhelPrevious: "",
  valueRelatedCombatPovertyWithhelST: "",
  percentageReductionBaseEffective: "",
  valueBaseEffective: "",

  valueTaxEffective: "",
  percentageReductionBaseIcms: "",
  valueCreditUsedSimplesNacional: "",
  valueBasePis: "",
  valuePis: "",
  amountPis: "",
  aliquotPisReais: "",
  valueBaseCofins: "",
  valueCofins: "",
  amountCofins: "",
  aliquotCofinsReais: "",
  cnpjProducerCommodity: "",
  codeSealcontrollerIPI: "",
  amountSealcontrollerIPI: "",
};

async function execute(sales, enterprise) {
  try {
    let product = [];
    let numericItem = 1
    sales.itens_sales.forEach((item) => {
      productsNFe = {...productsNFeInitial}
        productsNFe.aliquotApplicableSimplesNacional = 0,
        productsNFe.valueApplicableSimplesNacional = 0,
        productsNFe.aliquotIcmsProduct = item.aliquot_icms_product,
        productsNFe.aliquotPisReais = item.product.cst_confis.code != "99" ?  item.value_pis_product : 0  , 
        productsNFe.aliquotCofinsReais = item.product.cst_pis.code != "99" ?  item.value_cofins_product : 0  ,
        productsNFe.amountBusinessProduct = item.amount,
        productsNFe.amountCofins = validAmountCofins(item.product.cst_confis.code ,item.amount) //item.product.cst_confis.code != "99" ?  item.amount  : 0
        productsNFe.amountPis = validAmountPis(item.product.cst_pis.code,item.amount)  //item.product.cst_pis.code  != "99" ?  item.amount  : 0
        productsNFe.amountSealcontrollerIPI = "",
        productsNFe.amountTaxProduct = item.amount,
        productsNFe.cfopProduct = item.product.cfop.code,
        productsNFe.cnpjProducerCommodity = "",
        productsNFe.codeCstProdut = (String(item.product.cst.code) === '0' ? '00' : String(item.product.cst.code)),
        productsNFe.descriptionProduct = item.product.description_item,
        productsNFe.codeManufacturerProduct = "",
        productsNFe.codeProduct = String(item.product_id),
        productsNFe.codeSealcontrollerIPI = "",
        productsNFe.codeSituationTaxableCofins = item.product.cst_confis.code,
        productsNFe.codeSituationTaxablePis = item.product.cst_pis.code,
        productsNFe.csosnProduct = item.product.csons.code,
        productsNFe.cstProduct = item.product.cst.code,
        productsNFe.eanProduct = checkGetin(item.product.bar_code) ,//item.product.bar_code),
        productsNFe.percentageRelatedCombatPoverty = enterprise.crt == 3 ? 2 : 0
        productsNFe.valueRelatedCombatPoverty = enterprise.crt == 3 ? caculationFunValuePoverty(productsNFe.percentageRelatedCombatPoverty, item.value_subtotal_product) : 0
        productsNFe.extIpiProduct = "",
        productsNFe.indicatesWhetherProduct = 1,
        productsNFe.itemProduct = numericItem,
        productsNFe.methodDeterminingBaseIcms = String(item.product.calculation_base_mode),
        productsNFe.ncmProduct = String(item.product.ncm.code),
        productsNFe.nomenclatureValueCustomsProduct = "",
        productsNFe.percentageReductionBaseEffective = 0
      productsNFe.percentageReductionBaseIcms = "",
        productsNFe.percentageRelatedCombatPovertyFCP = 0,
        productsNFe.recipientProduct = "",
        productsNFe.relevantScaleIndicatorProduct = "",
        productsNFe.sourceMerchandise = item.product.source_product,
        productsNFe.unitBusinessProduct = item.product.unit.sigla,
        productsNFe.unityTaxProduct = item.product.unit.sigla,
        productsNFe.valueBaseCofins = item.product.cst_confis.code != "99" ?  item.value_base_cofins_product  : 0 ,
        productsNFe.valueBaseEffective = "",
        productsNFe.valueBaseIcms = item.value_base_icms_product,
        productsNFe.valueBaseIcmsST = item.value_base_icms_st_product,
        productsNFe.valueBaseIcmsSTWithheld = "",
        productsNFe.valueBasePis = item.value_base_pis_product,
        productsNFe.valueBaseRelatedCombatPoverty = 0,
        productsNFe.valueBaseRelatedCombatPovertyWithhelPrevious = 0,
        productsNFe.valueCofins = item.product.cst_pis.code != "99" ?  item.value_cofins_product  : 0 ,
        productsNFe.valueCostOfFreight = null,
        productsNFe.valueCreditUsedSimplesNacional = "",
        productsNFe.valueIcmsOwnSubstitute = "",
        productsNFe.valueIcmsProduct = item.value_icms_product,
        // productsNFe.aliquotValueIcmsST = 0
      productsNFe.valueIcmsStWithheld = "",
        productsNFe.valueInsurancePriceProduct = "",
        productsNFe.valueOfApproximateTax = item.valueOfApproximateTax,
        productsNFe.valuePis = item.value_pis_product,
        productsNFe.valueRelatedCombatPovertyFCP = 0,
        productsNFe.valueRelatedCombatPovertyFCPWithheld = 0,
        productsNFe.valueRelatedCombatPovertyWithhelST = 0,
        productsNFe.valueTaxEffective = "",
        productsNFe.valueTotalGrossProduct = item.value_subtotal_product,
        productsNFe.valueUnityBusinessProduct = item.value_sale,
        productsNFe.valueUnityTaxProduct = item.value_sale,



      numericItem = numericItem + 1
      product.push(productsNFe);

    });

    return product;
  } catch (error) {
    return error;
  }
}
function validAmountPis(cstPis,amount) {
  switch (cstPis) {
    case "99":
      return 0
      break;
    case "49":
        return 0
        break;
    default:
      return amount
  }
}
function validAmountCofins(cstCofins, amount) {
  switch (cstCofins) {
    case "99":
      return 0
      break;
    case "49":
        return 0
        break;
    default:
      return amount
  }
}


function checkGetin(eanProduct){
  if (eanProduct === null || eanProduct === '' ){
    return 'SEM GTIN'
  }
  return eanProduct
} 
function caculationFunValuePoverty(aliquot, valorProduct) {
  let valueCalcule = 0
  let taxPercentual = 0
  taxPercentual = aliquot / 100
  valueCalcule = parseFloat(valorProduct) * taxPercentual
  return valueCalcule

}

module.exports = {
  execute,
};
