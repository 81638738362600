import Vue from "vue";
import Vuex from "vuex";
import formofpayment from "./formofpayment";
import paymentterm from "./paymentterm";
import Cashier from "./cashier";
import Client from "./client";
import Aliquot from "./aliquot";
import Bank from "./bank";
import Cfop from "./cfop";
import Csosn from "./csosn";
import Cst from "./cst";
import CompanyGroup from "./companygroup";
import Enterprise from "./enterprise";
import Ncm from "./ncm";
import Unit from "./unit"
import Product from "./product";
import ProductGroup from "./productgroup";
import SubGroupProduct from "./sub_product_group";
import Seller from "./seller";
import Sales from "./sales";
import TaxRegime from "./taxregime";
import Transportador from "./transporter";
import AlertApi from './alert_api';
import auth from './auth';
import links from './links';
import Cart from "./cart"
import Barcode from "./barcode"
import User from "./user";
import Permission from "./permission";
import CstPisConfis from "./cstpisconfis";
import CheckCashier from "./checkcashier";
import Parameter from "./parameter";
import Pdv from './pdv'
import nfce from './nfce'
import cstipi from './cstipi'
import pis_cofins from './pis_cofins'
import globalDialogs from './globalDialogs'
import ExchangeControl from "./exchange_control"
import CashierOperationType from "./cashier_operation_type"
import ibpt from "./ibpt"
import load from "./load"
import infoError from "./infoError"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ExchangeControl,
    globalDialogs,
    pis_cofins,
    cstipi,
    Pdv,
    AlertApi,
    formofpayment,
    paymentterm,
    Cashier,
    Client,
    Aliquot,
    Bank,
    Cfop,
    Csosn,
    Cst,
    CompanyGroup,
    Enterprise,
    Ncm,
    Product,
    ProductGroup,
    SubGroupProduct,
    Seller,
    Sales,
    Unit,
    TaxRegime,
    Transportador,
    auth,
    links,
    Cart,
    Barcode,
    User,
    Permission,
    CstPisConfis,
    CheckCashier,
    Parameter,
    nfce,
    CashierOperationType,
    ibpt,
    load,
    infoError
  },
});

