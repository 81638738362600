const headers = [
    { text: 'Código', value: 'id' },
    { text: 'Descrição ', value: 'description_item'},
    { text: 'Ncm', value: 'ncm.code'},
    { text: 'Referência', value: 'reference'},
    { text: 'Unidade', value: 'unit.sigla'},
    { text: 'Preço de venda', value: 'sale_price'},
    { text: 'Ações', value: 'actions' }
];

export { headers };