<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-layout>
            <v-flex>
              <p class="titleInfos">Operações de Caixa</p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <p></p>

              <p>
                <v-btn
                  depressed
                  class="textInfos white--text"
                  @click="dialog_cashier = !dialog_cashier"
                  color="primary"
                >
                  Abrir Caixa - F1</v-btn
                >
              </p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <p>
                <v-btn
                  depressed
                  class="textInfos white--text"
                  color="primary"
                  @click="dialog_bleed = !dialog_bleed"
                  >Sangria - F2</v-btn
                >
              </p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <p>
                <v-btn
                  depressed
                  @click="dialog_supply = !dialog_supply"
                  class="textInfos white--text"
                  color="primary"
                  >Suprimento - F3</v-btn
                >
              </p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <p>
                <v-btn
                  depressed
                  @click="dialog_close = !dialog_close"
                  class="textInfos white--text"
                  color="primary"
                >
                  Fechar Caixa - F4</v-btn
                >
              </p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <p>
                <v-btn
                  depressed
                
                  class="textInfos white--text"
                  color="primary"
                >
                  Relatório de Venda</v-btn
                >
              </p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <p>
                <v-btn
                  depressed
                  class="textInfos white--text"
                  color="primary"
                >
                  Movimentação de Caixa</v-btn
                >
              </p>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
    <Cashier :component="this" />
    <Bleed :component="this" />
    <Supply :component="this" />
    <CloseCashier :component="this" />
  </v-container>
</template>

<script>
import Cashier from "./Open_Cashier/";
import Bleed from "./Bleed/";
import Supply from "./Supply/";
import CloseCashier from "./Close_Cashier/";
export default {
  components: {
    Cashier,
    Bleed,
    Supply,
    CloseCashier,
  },
  data: () => ({
    dialog_cashier: false,
    dialog_bleed: false,
    dialog_supply: false,
    dialog_close: false,
  }),

  created() {
    document.addEventListener("keyup", this.onKey);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.onKey);
  },
  methods: {
    onKey(e) {
      let KeyCode = e.key;
      switch (KeyCode) {
        case "F1":
          this.dialog_cashier = !this.dialog_cashier;
          break;
        case "F2":
          this.dialog_bleed = !this.dialog_bleed;
          break;

        case "F3":
          this.dialog_supply = !this.dialog_supply;
          break;
        case "F4":
          this.dialog_close = !this.dialog_close;
          break;
        case "Escape":
          this.dialog_cashier = false;
          this.dialog_bleed = false;
          this.dialog_supply = false;
          this.dialog_close = false;

          break;
      }
    },
  },
};
</script>
<style scoped>
.titleInfos {
  font-family: "Quicksand", sans-serif;
  font-size: 2rem;
  color: #286a7d;
  margin: auto;
}
.textInfos {
  font-family: "Quicksand", sans-serif;
  font-size: 1.5rem;
  margin: auto;
}
</style>

