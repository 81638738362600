

const callbackListenerKey = function (e) {
    this.pressedKey = e.key;
}

const listenerKeyPressed = function () {
    addEventListener("keydown", this.callbackListenerKey);
}

const keySelected = function (key) {
    this.permission = {...this["auth/getUser"]}
    if (key == "F2") {
        if(this.permission.user.cashier_operation.open_cashier == true){
            this.dialog_cashier = !this.dialog_cashier;
            this.pressedKey = undefined;
        } else {
            console.log('else');
            this.cashierDialog = true
            this.pressedKey = undefined;
        }
    } else if (key == "F4") {
        if(this.permission.user.cashier_operation.bleed == true) {
            this.dialog_bleed = !this.dialog_bleed;
            this.pressedKey = undefined;
        } else {
            this.bleedDialog = true
            this.pressedKey = undefined;
        }
    } else if (key == "F6") {
        if(this.permission.user.cashier_operation.supply == true) {
            this.dialog_supply = !this.dialog_supply;
            this.pressedKey = undefined;
        } else {
            this.supplyDialog = true
            this.pressedKey = undefined;
        }
    } else if (key == "F8") {
        if(this.permission.user.cashier_operation.close_cashier == true) {
            this.dialog_close = !this.dialog_close;
            this.pressedKey = undefined;
        } else {
            this.closeCashierDialog = true
            this.pressedKey = undefined;
        }
    } else if (key == "F9") {
        this.pressedKey;
    } else if (key == 'F10'){
        this.$store.dispatch('setShowDialogRegisterExchangeControl', true)
    }
}

module.exports = {
    listenerKeyPressed,
    callbackListenerKey,
    keySelected
}