
import {
    header,
    id,
    enterprise_id,
    register,
    valid,
    validPhysical,
    validLegal,
    validTable,
    physicalClient,
    LegalPeople,
    client,
    address,
    contact,
    banks,
    cityObject,
} from "./wipedata"


function wipeData() {
     
    this.header = header
    this.id = id
    this.enterprise_id = enterprise_id
    this.register = register
    this.valid = valid
    this.validPhysical = validPhysical
    this.validLegal = validLegal
    this.validTable = validTable
    this.physicalClient = { ...physicalClient }
    this.LegalPeople = { ...LegalPeople }
    this.client = { ...client }
    this.address = { ...address }
    this.contact = { ...contact }
    this.banks = { ...banks }
    this.cityObject = { ...cityObject }
}


export default {
    wipeData
}