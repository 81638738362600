<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="searchInput"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
          @keyup.enter="autocompleteProduct()"
        >
          </v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getProduct"
        :search="search"
      />
      <AddDialog
        v-if="addDialog"
        :component="this"
        title="Novo"
      />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Products/Container";
import Table from "../components/Products/Table";
import CardTitle from "../components/Products/CardTitle";
import loadScreen from "../components/load/index.vue"
import { GET_ALL_PRODUCT, GET_FILTER_PRODUCT } from "../store/actions/product";
import AlertApi from "../components/alert_api";
import { mapGetters } from "vuex";
import AddDialog from "../components/Products/AddDialog/";

export default {
  components: {
    Container,
    Table,
    CardTitle,
    AlertApi,
    AddDialog,
   loadScreen
  },
  data: () => ({
    search: "",
    searchInput: "",
    enterpriseId: Number(),
    getter: "getProduct",
    addDialog: false,
    permission: [],
    product: {},
  }),
  methods: {
    AlterAddDialog() {
      this.addDialog = !this.addDialog;
    },
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_PRODUCT, {
        page: page,
        limit: 10,
      });
    },
    async filterProduct() {
        let payload = {
          search: this.search,
          page : 1,
          limit: 10,
          paginate :true
        }
        await this.$store.dispatch(GET_FILTER_PRODUCT, payload)

    },
    autocompleteProduct() {
        this.search = this.searchInput
    }
  },
  watch: {
    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
      if(val.length == 0) {
        this.GetAll();
      } else if(val.length >= 2){
        this.filterProduct()
      }
    }
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },
  async created() {
    this.GetAll();
  },
  computed: {
    ...mapGetters(["getProduct", "getCadastrosLinks"]),
  },
};
</script>

<style scoped></style>
