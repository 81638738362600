<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="this.getCheckCashier.data"
      :search="search"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          class="mr-2"
          color="primary"
          @click="
            () => {
              activator_info(item);
            }
          "
          >mdi-information</v-icon
        >
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <div>
      <v-pagination
        color="success"
        v-model="current"
        :length="total"
        :total-visible="10"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
    getters: Object,
    destroy_object: Function,
    search: String,
    change_addDialog: Function,
  },

  data: () => ({
    destroyDialog: false,
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
    headers: [
      { text: "Código", value: "id" },
      { text: "Data Movimentação", value: "date_movement" },
      { text: "Caixa", value: "cashier.description" },
      { text: "Descrição Movimentação", value: "description_movement" },
      { text: "Tipo de movimentação", value: "type_movement" },
      { text: "Valor Movimentação", value: "value_movement" },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    deleteItem(item) {
      this.destroyDialog = true;
      this.currentItem = item;
    },
    activator_info(item) {
      this.component.AlterAddDialog();
      this.component.objectToInfo = item;
    },

    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
  },
  created() {
    this.component.GetAll();
  },
  computed: {
    ...mapGetters(["getCheckCashier"]),
  },
  watch: {
    getCheckCashier: function (val) {
      this.results = val.data;
      this.total = val.lastPage;
      this.current = val.currentPage;
    },
    current: function (val) {
      this.component.GetAll && this.component.GetAll(val);
    },
  },
};
</script>
