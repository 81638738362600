const ADD_PRODUCT = "ADD_PRODUCT";
const UPDATE_PRODUCT = "UPDATE_PRODUCT";
const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
const DESTROY_PRODUCT = "DESTROY_PRODUCT";
const GET_BY_BARCODE = "GET_BY_BARCODE";
const GET_BY_PRODUCT = "GET_BY_PRODUCT";
const GET_FILTER_PRODUCT = "GET_FILTER_PRODUCT";
const GET_SEARCH_PRODUCT =  "GET_SEARCH_PRODUCT";
const GET_ALL_PRODUCT_SALES =  "GET_ALL_PRODUCT_SALES";
const GET_ALL_PRODUCT_BY_STATUS =  "GET_ALL_PRODUCT_BY_STATUS";

module.exports = {
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  GET_ALL_PRODUCT,
  DESTROY_PRODUCT,
  GET_BY_BARCODE,
  GET_BY_PRODUCT,
  GET_FILTER_PRODUCT,
  GET_SEARCH_PRODUCT,
  GET_ALL_PRODUCT_SALES,
  GET_ALL_PRODUCT_BY_STATUS,
};
