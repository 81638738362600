let EmitterNFce = require("../../../Domain/Service/ReprocessNfce/MounterEmitterReprocessNfceDomainService")
let IdentifyNFce = require("../../../Domain/Service/ReprocessNfce/MounterIdentifyReprocessNfceDomainService");
let DestinationNFce = require("../../../Domain/Service/ReprocessNfce/MounterDestinationReprocessNfceDomainService");
let ProductNFce = require("../../../Domain/Service/ReprocessNfce/MounterProductReprocessNFceDomainService ");
let TransportInfoNFce = require("../../../Domain/Service/ReprocessNfce/MounterTransportInfoReprocessNFceDomainService");

async function execute(sales) {
    try {
      let emitterNFce = await EmitterNFce.execute(sales.enterprise.cnpj);
      let identifyNFce = await IdentifyNFce.execute(sales);
      let destinationNFce = await DestinationNFce.execute(sales);
      let productNFce = await ProductNFce.execute(sales, sales.enterprise);
      let transportInfo = await TransportInfoNFce.execute(sales);
      let nfceReprocess = await mounterNfceReprocess(
        emitterNFce,
        identifyNFce,
        destinationNFce,
        productNFce,
        transportInfo
      );
      
      return nfceReprocess;
    } catch (error) {
      return error;
    }
  }
  
  async function mounterNfceReprocess(
    emiterNfe,
    identifyNfce,
    destinationNFce,
    ProductNFce,
    transportInfo
  ) {
    let nfceMounter = {};
    nfceMounter.emitterNFe = { ...emiterNfe };
    nfceMounter.identifyNFe = { ...identifyNfce };
    nfceMounter.destinationNFe = { ...destinationNFce };
    nfceMounter.productsNFe = { ...ProductNFce };
    nfceMounter.transportInfo = { ...transportInfo };
  
    return nfceMounter;
  }
module.exports = {
    execute
}
