<template>
  <v-form ref="form" v-model="component.valid">
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12" xl="12">
        <v-autocomplete
          class="ma-2"
          hide-no-data
          outlined
          v-model="component.product.cst_ipi_id"
          :readonly="component.justVisible"
          label="*Cst IPI"
          :rules="component.textRules"
          item-text="code"
          item-value="id"
          :items="cstipi"
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="changeIpiDialog"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          label="Classe de enquadramento"
          v-model="component.product.framing_class"
          :readonly="component.justVisible"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          label="Cnpj do Produtor"
          v-model="component.product.cnpj_producer"
          :readonly="component.justVisible"
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          label="Codigo do selo controle Ipi"
          v-model="component.product.code_stamp"
          :readonly="component.justVisible"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="3" xl="3">
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          label="Quantidade do selo Ipi"
          v-model="component.product.amount_stamp"
          :readonly="component.justVisible"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-layout>
          <v-select
            class="ma-2"
            label="Forma de calculo do Ipi"
            v-model="component.product.ipi_calculation_form"
            :readonly="component.justVisible"
            full-width
            outlined
            :items="items"
          >
          </v-select>
        </v-layout>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6" xl="6">
        <v-text-field
          class="ma-2"
          type="number"
          outlined
          label="IPI"
          v-model="component.product.ipi"
          :readonly="component.justVisible"
        ></v-text-field>
      </v-col>
    </v-row>
    <DialogRegister
      :code="4"
      labelFieldFirst="Código"
      labelFieldSecond="Descrição"
      
      :component="this"
      title="Cadastro de Cst IPI"
      :addDialogPisCofins="showDialog"
      :add_value="add_ipi_cofins"
      :update_value="update_ipi_cofins"
    />
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import DialogRegister from "../../../global_components/dialog_register.vue";
import { ADD_CST_IPI, UPDATE_CST_IPI, GET_ALL_CST_IPI } from "../../../../store/actions/cstipi";

export default {
  components: {
    DialogRegister,
  },
  props: {
    component: Object(),
  },
  data: () => ({
    add_ipi_cofins: ADD_CST_IPI,
    update_ipi_cofins: UPDATE_CST_IPI,
    showDialog: false,
    items: ["Porcentagem", "Em valor"],
    cstipi: Array(),
  }),
  computed: {
    ...mapGetters(["getCstIpi"]),
  },
  methods: {
    getAll(page = 0){
      this.$store.dispatch(GET_ALL_CST_IPI,{page})
    },
    changeIpiDialog() {
      this.showDialog = !this.showDialog;
    },
    reset() {
      this.$refs.form.resetValidation();
    },
  },

  watch: {
    getCstIpi: function (val) {
        this.cstipi = [];
      this.cstipi = Object.keys(val).map(
        (key) => val[key]
      );
    },
  },
  async created() {
    await this.getAll();
    // this.cstipi = Object.keys(this.getCstIpi).map((key) => this.getCstIpi[key]);
  },
};
</script>
