<template>
  <div>
    <v-form v-model="formValidDataAddress">
      <v-row>
        <v-col cols="6" sm="2">
          <v-text-field
            @click:append="searchMyAddress(address.cep)"
            :append-icon="icons[0]"
            v-model="component.enterprise.address.cep"
            :readonly="component.justVisible"
            label="*Cep"
            placeholder="Placeholder"
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="component.enterprise.address.street"
            :readonly="component.justVisible"
            label="*Rua"
            placeholder="Placeholder"
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="component.enterprise.address.number"
            :readonly="component.justVisible"
            label="*Número"
            placeholder="Placeholder"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="component.enterprise.address.district"
            :readonly="component.justVisible"
            label="*Bairro"
            placeholder="Placeholder"
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="component.enterprise.address.complement"
            :readonly="component.justVisible"
            label="*Complemento"
            placeholder="Placeholder"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-model="component.enterprise.address.country"
            :readonly="component.justVisible"
            :items="listCountries"
            item-text="nome_pais"
            item-value="nome_pais"
            label="*País"
            placeholder="nome_pais"
            outlined
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            v-model="component.enterprise.address.state"
            :readonly="component.justVisible"
            :items="stateItems"
            label="*Estado"
            return-object
            placeholder="Placeholder"
            outlined
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            v-model="component.enterprise.address.city"
            :readonly="component.justVisible"
            :items="listCities"
            :return-object="true"
            item-text="name"
            label="*Cidade"
            placeholder="Placeholder"
            outlined
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import initials from "./helpers/new_states";
import countries from "./helpers/countries";
import axios from "axios";
import {mapGetters } from "vuex"
export default {
  props: { component: Object() },

  components: {},

  data: () => ({
    textRules: [(v) => !!v || "Campo obrigatório!"],
    icons: [mdiMagnify],
    listCountries: countries,
    stateItems: initials,
    listCities: Array(),
    formValidDataAddress: Boolean(),
    address: {
      country: String(),
      street: String(),
      number: String(),
      district: String(),
      complement: String(),
      city: String(),
      state: String(),
      cep: String(),
      city_id: Number(),
    },
  }),
  computed:{
    ...mapGetters(['getItemToUpdate'])
  },
  methods: {
    updateAddress(address, isUpdate){
       if(isUpdate){
         this.stateItems.forEach(element => {
           if(element.text == address.state.state){
               this.component.enterprise.address.state = {value:element.value, text:element.text} 
               this.component.enterprise.address.city = address.city
             }
         });
       }
     },
 
    deleteItem(item) {
      let indice = this.component.ListAddress.indexOf(item);
      this.component.ListAddress.splice(indice, 1);
    },
  },

  watch: {
    "component.enterprise.address.state":function(val){
       axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val.value}/municipios`
      ).then((response) => {
        response.data.forEach(element => {
          this.listCities.push({name:element.nome, city_id:element.id})
        });
         
      });
  }
  },
  beforeDestroy(){
    this.component.enterprise.address.state = Object()
    this.component.enterprise.address.city = Object()
  },
  async created(){
    this.updateAddress(this.component.enterprise.address , this.component.isUpdate)
  },
};
</script>
