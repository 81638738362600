/* eslint-disable no-unused-vars */
import axios from "axios";
const {
  GET_ALL_CHECK_CASHIER,
  GET_ALL_WITH_DATE_END_CASHIER
} = require("./actions/checkcashier");

const { SPLICE_SUCESSO, SPLICE_ERRO } = require("./actions/alert_api");

const moduleCheckCashier = {
  state: () => ({
    checkcashiers: {},
  }),

  mutations: {
    GET_ALL_CHECK_CASHIER: (state, checkcashiers) => {
      state.checkcashiers = checkcashiers.data;
    },
    GET_ALL_WITH_DATE_END_CASHIER: (state, checkcashiers) => {
      state.checkcashiers = checkcashiers.data;
    },

  },
  actions: {
    GET_ALL_CHECK_CASHIER({ commit }, payload) {
      axios.post("/cashmovement/index", { page: payload.page, limit: payload.limit }).then((response) => {
        commit(GET_ALL_CHECK_CASHIER, response.data);
      });
    },

    GET_ALL_WITH_DATE_END_CASHIER({ commit }, payload) {
      axios.post("/cashmovement/show", {
        dt_movement_inicial: payload.dt_movement_inicial,
        dt_movement_final: payload.dt_movement_final, 
        cashier_id: payload.cashier_id,
        page: payload.page,
        limit: payload.limit, 

      },
      ).then((response) => {
        commit(GET_ALL_WITH_DATE_END_CASHIER, response.data);
      });
    },
  },

  getters: {
    getCheckCashier: (state) => ({ ...state.checkcashiers }),
  },
};

export default moduleCheckCashier;
