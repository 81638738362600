<template>
  <v-row justify="center">
    <v-dialog v-model="component.addDialog" fullscreen transition="dialog-bottom-transition" hide-overlay>
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn icon dark @click="
            () => {
              close_addDialog();
            }
          ">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }} Empresa</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container>
          <v-tabs>
            <v-tab href="#tab1">
              <v-icon class="mr-1">mdi-account-details</v-icon>
              Gerais
            </v-tab>

            <v-tab href="#tab2">
              <v-icon class="mr-1">mdi-home-account</v-icon> Endereço
            </v-tab>

            <v-tab href="#tab3">
              <v-icon class="mr-1">mdi-receipt</v-icon> Fiscal
            </v-tab>

            <v-tab href="#tab4">
              <v-icon class="mr-1">mdi-home-account</v-icon> Chaves
            </v-tab>

            <v-tab-item value="tab1" class="mt-6">
              <v-card flat>
                <TabGeral :eager="true" :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab2" class="mt-6">
              <v-card flat>
                <TabAddress :eager="true" v-if="component.addDialog" :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab3" class="mt-6">
              <v-card flat>
                <TabFiscal :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab4" class="mt-6">
              <v-card flat>
                <TabKeyAccess :eager="true" :component="this" />
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!justVisible" color="error" @click="() => clear()">
              <v-icon class="mr-2">mdi-cancel</v-icon> Limpar formulário
            </v-btn>
            <v-btn v-if="!justVisible" color="success" :disabled="!valid" class="mr-4" @click="
              () => {
                submit();
              }
            ">
              <v-icon class="mr-2">mdi-content-save</v-icon>Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import TabGeral from "../Tab/TabGeral";
import TabAddress from "../Tab/TabAddress/";
import TabFiscal from "../Tab/TabFiscal/";
import TabKeyAccess from "../Tab/TabKeyAccess";
import { mapGetters } from "vuex"
import {
  ADD_ENTERPRISE,
  UPDATE_ENTERPRISE,
} from "../../../store/actions/enterprise";

export default {
  components: { TabGeral, TabAddress, TabFiscal, TabKeyAccess },

  props: {
    isUpdate: Boolean(),
    component: Object(),
    objectToUpdate: Object(),
    title: String(),
  },
  computed: {
    ...mapGetters(["getItemToUpdateEnterprise"])
  },
  data: () => ({
    justVisible: Boolean(),
    oldState: Object(),
    cities: Array(),
    myState:String(),
    myCity:Object(),
    valid: Boolean(),
    enterprise: {
      name: String(),
      fantasyName: String(),
      status: String(),
      cnpj: String(),
      registrationState: String(),
      registrationMunicipal: String(),
      company_group_id: Number(),
      tax_regime_id: String(),
      note_issuing_environment: String(),

      csc: String(),
      token_ibpt: String(),
      certificate_password: String(),
      token: String(),
      certified_path: String(),
      accountancy: String(),
      conter: String(),
      crc: String(),
      cnai: String(),
      cnpj_authorization: String(),
      cpf_authorization: String(),
      crt: String(),
      address: {
        state:Object(),
        city: Object(),
      },
    },

    newcity: Object(),
  }),

  methods: {
    close_addDialog() {
      this.component.addDialog = !this.component.addDialog;
    },

    submit() {
      this.enterprise.id === undefined
        ? this.created_enterprises()
        : this.update_enterprises();
    },
    clear() {
      for (let i in this.enterprise) {
        let key = typeof this.enterprise[i];
        switch (key) {
          case "string":
            this.enterprise[i] = String();
            break;
          case "number":
            this.enterprise[i] = Number();
            break;
          case "object":
            this.enterprise[i] = Object();
            break;
        }
      }
    },

    async created_enterprises() {
      const enterprise = { ...this.enterprise };
      this.enterprise.address.state = this.myState;
      this.enterprise.address.city_id = this.myCity.id;
      this.enterprise.address.city = this.myCity.name;
      try {
        await this.$store.dispatch(ADD_ENTERPRISE, enterprise);
      } catch (error) {
        alert(error);
      } finally {
        // this.close_addDialog();
      }
    },
    setItemToUpdate(item) {
      this.enterprise = { ...item }
      this.enterprise.address.state = {state: item.address.state}
      this.enterprise.address.city = {name:item.address.city.name , city_id: item.address.city.id }
      this.enterprise.status = item.status
      this.enterprise.tax_regime_id = item.tax_regime       
    },
    async update_enterprises() {
      const enterprise = { ...this.enterprise };
      this.enterprise.address.state = this.enterprise.address.state.text;
      this.enterprise.address.city_id = this.enterprise.address.city.city_id;
      this.enterprise.address.city = this.enterprise.address.city.name;

      try {
        await this.$store.dispatch(UPDATE_ENTERPRISE, enterprise);
      } catch (error) {
        alert(error);
      } finally {
        this.close_addDialog();
      }
    },
  },

  watch: {
    oldState(val) {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val.value}/municipios`
        )
        .then((response) => {
          this.cities = response.data;
        });
    },
  },
  created() {
    const visibleItem = this.getItemToUpdateEnterprise
    if(visibleItem.justVisible){
      this.enterprise = {...visibleItem}
      this.justVisible = visibleItem.justVisible
      this.enterprise.tax_regime_id = visibleItem.tax_regime
    }else if(visibleItem.update){
      this.enterprise = {...visibleItem}
      this.enterprise.tax_regime_id = visibleItem.tax_regime
    }
    if (this.isUpdate) {
      this.setItemToUpdate(this.getItemToUpdate)
    }
    // if (this.objectToUpdate !== undefined) {
    //   this.enterprise = { ...this.objectToUpdate };
    //   this.oldState.text = this.enterprise.address.state;
    //   this.newcity.nome = this.enterprise.address.city.name;
    //   this.newcity.id = this.enterprise.address.city.id;
    // }
  },
};
</script>
