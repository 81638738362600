<template>
  <v-form ref="form" v-model="component.validCost">
    <v-container>
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-select
            outlined
            :items="list"
            label="Tipo de Produto"
            v-model="component.product.product_type"
            :readonly="component.justVisible"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            outlined
            label="*Preço de Custo"
            :rules="[component.textRules]"
            type="number"
            v-model="component.product.cost_price"
            :readonly="component.justVisible"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            outlined
            type="number"
            :rules="[component.textRules]"
            label="*Preço de Venda"
            v-model="component.product.sale_price"
            :readonly="component.justVisible"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field
            outlined
            type="number"
            :rules="[component.textRules]"
            label="*Custo de Nota"
            v-model="component.product.note_price"
            :readonly="component.justVisible"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="6" xl="6">
          <v-text-field
            outlined
            type="number"
            label="Custo de Contábil"
            v-model="component.product.accounting_price"
            :readonly="component.justVisible"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            outlined
            label="Dia Inicial da Promoção"
            type="Date"
            placeholder="null"
            v-model="component.product.initial_promotion_date"
            :readonly="component.justVisible"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            outlined
            type="Date"
            placeholder="null"
            label="Dia Final Promoção"
            v-model="component.product.final_promotion_date"
            :readonly="component.justVisible"
          ></v-text-field>
        </v-col>

        <v-col cols="12" lg="4" md="4" sm="4" xl="4">
          <v-text-field
            class="mb-5"
            v-show="this.price_promo"
            type="number"
            prefix="R$"
            outlined
            label="Preço Promocional"
            v-model="component.product.promotion_price"
            :readonly="component.justVisible"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    list: ["Revenda", "Insumo", "Patrimônio", "Consumo"],
    price_promo: Boolean(),
  }),

  mounted() {
    this.$watch(
      () => this.component.product.initial_promotion_date,
      function (val) {
        if (val === "") {
          this.price_promo = !this.price_promo;
        } else {
          this.price_promo = !this.price_promo;
        }
      }
    );
  },
};
</script>
