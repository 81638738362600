<template>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="type"
              label="Tipo de Emissão de Nota"
              :items="items"
              :rules="[(v) => !!v || 'Selecione um item']"
              required
              outlined
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
            label="Empresa" :items="getEnterprises.data" item-value="id"
            item-text="fantasyName" v-model="enterprise" :rules="[(v) => !!v || 'Campo obrigatório']" outlined
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
                v-model="date"
                label= "Data e Hora da Ativação"
                type="datetime-local"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
          
            <v-textarea
              v-model="description"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              label="Justificativa da entrada em contingência"
              required
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            md="2"
            class="right"
            >
                <v-btn
                color="success"
                class="mt-4"
                block
                @click="save"
                >
                Salvar
                </v-btn>
            </v-col>
            <v-col
            cols="12"
            md="2"
            class="right"
            >
                <v-btn
                color="error"
                class="mt-4"
                block
                @click="reset"
                >
                Cancelar
                </v-btn>
            </v-col>
        </v-row>
      </v-container>
    </v-form>
  </template>
  <script>

import { mapGetters } from "vuex";
export default {
    data() {
        return{
            items: ['Normal', 'Contingência',],
            date: '',
            type: '',
            enterprise: '',
            description: '',
        };
    },
    methods: {
        reset() {
            this.$refs.form.reset()
        }, 
        save() {
          //console.log(this.enterprise);
          // const contingency = {
          //   note_issue_type: this.type,
          //   date: this.date,
          //   enterprise:this.enterprise,
          //   description: this.description
          // }
          //this.$refs.form.reset()
        }
    },

    watch: {
      getEnterprises: function(val) {
        this.enterprise = val.data[0]
      }
    },

    computed: {
    ...mapGetters(["getEnterprises"]),
    },
}


  </script>