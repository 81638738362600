<template>
  <div class="mt-8 ma-3"> 
    <v-row justify-center> 
      <v-col cols="12" md="5" lg="5" sm="6">
      <TabDate :component="this" :disabled="disabledCashier" />
  </v-col> 
  <v-col cols="12" md="7" lg="7" sm="6"> 
      <TabCar
        :component="this"
        :datas="header_data"
        :disabled="disabledCashier"
      />
    </v-col> 

</v-row> 
  <div class="footer">
    <v-footer fixed class="success">
      <h3 class="white--text ml-3, row-pointer" @click="openCashierDialog()">
        F2 Abrir Caixa 
      </h3>
      <h3 class="white--text ml-3, row-pointer" @click="openBleedDialog()">
        - F4 Sangria 
      </h3>
      <h3 class="white--text ml-3, row-pointer" @click="openSupplyDialog()">
        - F6 Suprimento
      </h3>
      <h3 class="white--text ml-3, row-pointer" @click="openCloseCashierDialog()">
        - F8 Fechar Caixa
      </h3>
      <h3 class="white--text ml-3">
        - F9 Finalizar Venda
      </h3>
      <h3 class="white--text ml-3, row-pointer" @click="openExchangeDialog()">
        - F10 Realizar Troca
      </h3>
    </v-footer>
  </div>
  <AlertAPI />
  <InfoError/>
  <CashierDialog :component="this"/>
  <CashierModal :component="this" v-if="dialog_cashier" />
  <BleedDialog :component="this"/>
  <Bleed :component="this" v-if="dialog_bleed" />
  <SupplyDialog :component="this"/>
  <Supply :component="this" v-if="dialog_supply" />
  <CloseCashierDialog :component="this" />
  <CloseCashier :component="this" v-if="dialog_close" />
  <ExchangeControl />
  <ModalError
    v-if="dialog_alert"
    :component="this"
    :title_error="title_error"
    :listErrors="listErrors"
  />
</div>
</template>

<script>
import AlertAPI from "../components/alert_api/";
import InfoError from "../components/InfoError/"
import TabDate from "../components/Sales/TabDate";
import TabCar from "../components/Sales/TabCar";
import CashierModal from "../components/CashierOperations/Open_Cashier/";
import Bleed from "../components/CashierOperations/Bleed/index.vue";
import Supply from "../components/CashierOperations/Supply/";
import CloseCashier from "../components/Dashboard/Operations/Close_Cashier/";
import ModalError from "../components/Sales/AddDialog/Modal_Error/";
import listenerfunction from "../components/Sales/scripts/functions/functions";
import { SHOW_USER } from "../store/actions/user";
import ExchangeControl from '../components/exchange_control/AddDialog/index.vue'
import { mapGetters } from "vuex";
import {GET_MOVEMENT_OPEN_CASHIER} from '../store/actions/sales'
// import { createInstance } from "./../utils/PDFHandle";
import { TemplateMount } from "./../templatesPdfs/TemplateMount";
import {TemplateMountCloseCashier} from "./../templatesPdfs/TemplateMountCloseCashier";
import CashierDialog from "../components/Sales/PermissionDialog/CashierDialog"
import BleedDialog from "../components/Sales/PermissionDialog/BleedDialog"
import SupplyDialog from "../components/Sales/PermissionDialog/SupplyDialog"
import CloseCashierDialog from "../components/Sales/PermissionDialog/CloseCashierDialog"
import ENV from "../env"
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  components: {
    AlertAPI,
    CashierModal,
    Bleed,
    Supply,
    CloseCashier,
    TabDate,
    TabCar,
    ModalError,
    ExchangeControl,
    CashierDialog,
    BleedDialog,
    SupplyDialog,
    CloseCashierDialog,
    InfoError
  },
  data: () => ({
    titlePDF: "_",
    // pdf: createInstance(),
    doc_operation:String(),
    open_cashie_payload: Number(),
    valuePrintCloseCashier: Object(),
    needPrint: Boolean(),
    printCloseCashier: Boolean(),
    dialog_cashier: Boolean(),
    dialog_bleed: Boolean(),
    dialog_supply: Boolean(),
    dialog_close: Boolean(),
    dialog_alert: Boolean(),
    title_error: String(),
    listErrors: Array(),
    disabledCashier: Boolean(),
    client: Object(),
    seller: Object(),
    clientNfceObject: Object(),
    sellerNfceObject:Object(),
    enterpriseNfceObject: Object(),
    pressedKey: String(),
    header_data: {
      client: Object(),
      seller: Object(),
    },
    permissionCashier: Object(),
    hasItems: false,
    dataToPrint: [],
    pdv_id: ENV.getInstance().VUE_APP_PDV_COMPUTER_ID,
    permission: Object(),
    cashierDialog: Boolean(),
    bleedDialog: Boolean(),
    supplyDialog: Boolean(),
    closeCashierDialog: Boolean(),
  }),

  async created() {
    this.listenerKeyPressed();
    await this.permission_cashier();
    this.permission = {...this["auth/getUser"]}
  },
  mounted() {
    this.client = this.getClientMemory.data ? this.getClientMemory.data : {};
    this.seller = this.getSellerMemory.data ? this.getSellerMemory.data : {};
    this.$store.dispatch("GET_CURRENTLY_PDV");
    this.checkPermssionCashierInitialOperation()
    this.checkOpenCashierInitialRouterCashier()

  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.onKey);
  },
  computed: {
    ...mapGetters([
      "getIsAuth",
      "getCurrentPdv",
      "getEnterprises",
      "auth/getUser",
      "getClientMemory",
      "getSellerMemory",
      "getEnterprises"
    ]),
  },

  methods: {
    ...listenerfunction,

    mountePDF(value) {
        let user = {...this["auth/getUser"].user}
        let data = new Date();
        let enterprise = this.getEnterprises.data[0];
        let money_value = parseFloat(value).numberToMoney()
        var doc = new jsPDF({
          unit: 'mm',
          format: [80, 40],  
        });
        doc.setLineWidth(0.1);

        doc.setFontSize(2);
        doc.text(`Usuário : ${user.name}`, 2, 1);
        doc.text(`${data.toLocaleString()}`, 2, 2);

        doc.setFontSize(3);
        doc.line(2, 3, 38, 3);
        doc.text(enterprise.name, 8, 5);
        doc.setFontSize(2);
        doc.text(`Nome Fantasia : ${enterprise.fantasyName}`, 2, 7);
        doc.text(`Rua : ${enterprise.address.street}`, 2, 8);       
        doc.text(`Numero : ${enterprise.address.number}`, 15, 8);
        doc.text(`Cidade : ${enterprise.address.city}`, 25, 8);
        doc.text(`Estado : ${enterprise.address.state}`, 2, 9);
        doc.text(`CEP : ${enterprise.address.cep}`, 15, 9);
        doc.setFontSize(2);
        doc.line(2, 10, 38, 10);
        doc.text(`Doc: ${this.doc_operation}`, 2, 11);
        doc.text(`PDV: ${this.pdv_id}`, 32, 11);
        doc.setFontSize(3);
        doc.text(`${this.titlePDF}`, 14, 14);
        doc.setFontSize(3);
        doc.text(`MOVIMENTO..........: ${data.toLocaleString()}`, 2, 17);
        doc.text(`TIPO DE CAIXA......: ${user.cashier_operation.cashier_id} - ${user.cashier_operation.name}`, 2, 19);
        doc.setFontSize(3);
        doc.text(`VALOR : ${money_value}`, 16, 22);
        doc.line(7, 33, 33, 33);
        doc.text(`ASSINATURA DO RESPONSÁVEL`, 12, 35);    
        doc.output("dataurlnewwindow");

    },
    mounterPdfCloseCashier() {
      let user = {...this["auth/getUser"].user}
      const reportData = Object.values(this.dataToPrint);
      let totalValueFormOfPayment = {
        form_of_description: "TOTAL",
        value_form_of_payment: Number(),
      }
      for (let i = 0; i < reportData.length; i++) {
        reportData[i].value_form_of_payment = parseFloat(reportData[i].value_form_of_payment.replace(/,/g, ''))
        totalValueFormOfPayment.value_form_of_payment += reportData[i].value_form_of_payment
      }
      totalValueFormOfPayment.value_form_of_payment.toFixed(2)

      reportData.push(totalValueFormOfPayment)
        let data = new Date();
        let enterprise = this.getEnterprises.data[0];
      
        let salesColumns = [
          { title: "Forma de pagamento", dataKey: "form_of_description" },
          { title: "Valor", dataKey: "value_form_of_payment" },
          
        ];
        var doc = new jsPDF({
          unit: 'mm',
          format: [80, 40],  
        });

        doc.setLineWidth(0.1);
        doc.setFontSize(2);
        doc.text(`Usuário : ${user.name}`, 2, 1);
        doc.text(`${data.toLocaleString()}`, 2, 2);

        doc.setFontSize(3);
        doc.line(2, 3, 38, 3);
        doc.text(enterprise.name, 8, 5);
        doc.setFontSize(2);
        doc.text(`Nome Fantasia : ${enterprise.fantasyName}`, 2, 7);
        doc.text(`Rua : ${enterprise.address.street}`, 2, 8);       
        doc.text(`Numero : ${enterprise.address.number}`, 15, 8);
        doc.text(`Cidade : ${enterprise.address.city}`, 25, 8);
        doc.text(`Estado : ${enterprise.address.state}`, 2, 9);
        doc.text(`CEP : ${enterprise.address.cep}`, 15, 9);
        doc.setFontSize(2);
        doc.line(2, 10, 38, 10);
        doc.text(`Doc: ${this.doc_operation}`, 2, 11);
        doc.text(`PDV: ${this.pdv_id}`, 32, 11);
        doc.setFontSize(3);
        doc.text("RELATÓRIO DE FECHAMENTO DE CAIXA", 10, 14);
        doc.setFontSize(3);    
    
        doc.text(`MOVIMENTO................................: ${data.toLocaleString()}`, 2, 16);
        doc.text(`TIPO DE CAIXA............................: ${user.cashier_operation.cashier_id} - ${user.cashier_operation.name}`, 2, 18);
        doc.text(`Abertura de caixa..........................: R$${parseFloat(this.valuePrintCloseCashier.value_open_cashier)}`, 2, 20);
        doc.text(`Valor de Sangria...........................: R$${parseFloat(this.valuePrintCloseCashier.value_bleed_cashier)}`, 2, 22);
        doc.text(`Valor do fechamento (ENTRADAS - SAIDAS)..: R$${this.valuePrintCloseCashier.value_close_cashier}`, 2, 24);
        doc.text(`Valor Transferência de caixa........: R$${parseFloat(this.valuePrintCloseCashier.value_close_cashier_input)}`, 2, 26);
        doc.setFontSize(2);
        doc.line(2, 28, 38, 28);
        doc.setFontSize(3);
        doc.text("VENDAS - FORMA DE PAGAMENTO", 11, 30);
        doc.autoTable(salesColumns, reportData, {
          columnStyles: {
            //
          },
          headStyles: {
            fillColor: [230, 230, 230],
            textColor: [54, 54, 54],
          },
          margin: { top: 2, right: 2, left: 2},
          startY: 31 ,
          theme: "grid",
          styles: {
          cellWidth: "wrap",
          cellPadding: 1,
          fontSize: 3,
          // halign: "center",
          // valign: "middle",
          // overflow: "linebreak",
        },
        });
        const endYsales_by_payment_method = doc.lastAutoTable.finalY;

        doc.line(7, endYsales_by_payment_method + 4, 33, endYsales_by_payment_method + 4);
        doc.setFontSize(3);
        doc.text("ASSINATURA DO RESPONSÁVEL", 12, endYsales_by_payment_method + 5.5);
        const pages = doc.internal.getNumberOfPages();

      doc.setFontSize(1); //Optional
      for (let j = 1; j < pages + 1; j++) {
        doc.setPage(j);
        doc.text(`${j} / ${pages}`, endYsales_by_payment_method + 30, 40);
      }
        doc.output("dataurlnewwindow");




    
      },

    checkOpenCashierInitialRouterCashier () {
    const CASHIER_IS_NOT_OPEN = false
    let user =  { ...this["auth/getUser"].user };
    let payload = {
      dateOperation : this.mounterDateCloseCashier(),
      enterprise_id:this.getEnterprises.data[0].id,
      typeOperation:"Abertura de caixa",
      cashier_id:user.cashier_operation.cashier_id
    }
    this.$store.dispatch(GET_MOVEMENT_OPEN_CASHIER, payload).then((response)=>{
      let getMovementOpenCashier = response.response.data[0]
      if (getMovementOpenCashier === CASHIER_IS_NOT_OPEN){
         this.dialog_cashier =  !this.dialog_cashier
      }
    })
    },

    
    checkPermssionCashierInitialOperation() {
      let user = {...this["auth/getUser"].user}
      let key = 'cashieroperator'
      if(user[key] === null){
        alert("Usuario sem permissão para Operador de caixa")
        this.$router.replace({ name: "Shortcuts" });
      } 
    },

    permission_cashier() {
      let id = this.$store.state.auth.user.id;
      this.$store.dispatch(SHOW_USER, id).then((response) => {
        let { data } = response.data;
        this.permissionCashier = { ...data.cashieroperator };
      });
      this.disabledCashier = this.permissionCashier == null ? true : false;
    },
    mounterDateCloseCashier() {
      // Create a date object from a date string
      let date = new Date();
      // Get year, month, and day part from the date
      let year = date.toLocaleString("default", { year: "numeric" });
      let month = date.toLocaleString("default", { month: "2-digit" });
      let day = date.toLocaleString("default", { day: "2-digit" });
      let formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },

    clear() {
      let clean = { name: "", id: "" };
      this.header_data.client = { ...clean };
      this.header_data.seller = { ...clean };
      this.client = { ...clean };
      this.seller = { ...clean };
    },

    openCashierDialog() {
      if(this.permission.user.cashier_operation.open_cashier == true){
          this.dialog_cashier = !this.dialog_cashier;
          this.pressedKey = undefined;
      } else {
          this.cashierDialog = true
          this.pressedKey = undefined;
        }
    },

    openBleedDialog() {
      if(this.permission.user.cashier_operation.bleed == true) {
          this.dialog_bleed = !this.dialog_bleed;
          this.pressedKey = undefined;
      } else {
          this.bleedDialog = true
          this.pressedKey = undefined;
        }
    },

    openSupplyDialog() {
      if(this.permission.user.cashier_operation.supply == true) {
          this.dialog_supply = !this.dialog_supply;
          this.pressedKey = undefined;
      } else {
          this.supplyDialog = true
          this.pressedKey = undefined;
        }
    },

    openCloseCashierDialog() {
      if(this.permission.user.cashier_operation.close_cashier == true) {
          this.dialog_close = !this.dialog_close;
          this.pressedKey = undefined;
      } else {
          this.closeCashierDialog = true
          this.pressedKey = undefined;
        }
    },

    openExchangeDialog() {
      this.$store.dispatch('setShowDialogRegisterExchangeControl', true)
    }
  },

  watch: {
    pressedKey: function () {
      this.keySelected(this.pressedKey);
    },
    client: function () {
      this.header_data.client = { ...this.client };
    },

    // "client.name": {
    //   handler: function () {
 
    //     this.header_data.client = { ...this.client };
    //   },
    //   deep: true,
    // },
    seller: function () {
     this.header_data.seller = { ...this.seller };
    },

    printCloseCashier: async function (bool){
      if (bool) {
        await (50).wait();
        this.mounterPdfCloseCashier();
        this.printCloseCashier = false;
        this.hasItems = true;
        this.dataToPrint = [];
      }
    },

    needPrint: async function (bool) {
      if (bool) {
        await (50).wait();
        this.mountePDF(this.open_cashie_payload);
        this.needPrint = false;
        this.hasItems = false;
        this.dataToPrint = [];
      }
    },
  },
};
</script>

<style scoped>
.footer {
  height: 50px;
}
/* celular */
@media (max-width: 1265px) {
  .container {
    display: flex;
    flex-direction: column;
  }
  .products {
    margin-bottom: 50px;
  }
}
/* computador */
@media (min-width: 1266px) {
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .root-page {
    /* height: 100%; */
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    /* background-color: antiquewhite; */
  }
  .cart {
    background-color: antiquewhite;
    width: 80%;
  }
  .products {
    margin: 50px;
    background-color: aqua;
    width: 50%;
  }
}
</style>
