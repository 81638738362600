/* eslint-disable no-unused-vars */
import VueRouter from "vue-router";
import store from "../store";

import {
    SET_CADASTROS,
    SET_CONFIGURACOES,
    SET_MOVIMENTACAO,
    SET_FINANCEIRO,
    SET_FISCAL,
    SET_RELATORIOL,
    SET_SEGURANCA,
} from "../store/actions/links";


export default (router = new VueRouter()) => {
    router.beforeEach((_, __, next) => {
        let user = {}
        if(store.getters["auth/user"]){
            user = store.getters["auth/user"].user
        }
        // A sequência deve ser a mesma entre os arrays
        const listLinks = [store.getters.getCadastrosLinks, store.getters.getConfiguracoesLinks, store.getters.getSalesLinks]
        const listActionsStore = [
            SET_CADASTROS,
            SET_CONFIGURACOES,
            SET_MOVIMENTACAO,
            SET_FINANCEIRO,
            SET_FISCAL,
            SET_RELATORIOL,
            SET_SEGURANCA,
        ]
        user?.roles?.length > 0 && user.roles.forEach((role) => {
            role.permissions.forEach(permission => {
                for (let i = 0; i < listLinks.length; i++) {
                    listLinks[i].forEach((link, id) => {
                        if (link.text === permission.display_name) {
                            if (!!permission?.pivot.permission_col && !!permission?.pivot.permission_col.includes("Visualizar")) {
                                // window.debug(listActionsStore[i]);
                                store.commit(listActionsStore[i], {
                                    index: id,
                                    perm: permission?.pivot.permission_col
                                })
                            }
                        }
                    })
                }
            })
        })

        next()
    })
}