<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <v-row>
          <v-card-title class="ml-2">Usuários</v-card-title>
          <v-spacer></v-spacer>
          <v-btn class="success mr-2 mt-4" @click="activeDialog()"
            >Adicionar</v-btn
          >
        </v-row>
      </v-card-text>
      <v-spacer></v-spacer>

      <v-text-field
        outlined
        class="ma-2"
        label="Pesquisar"
        append-icon="mdi-magnify"
      ></v-text-field>

      <Table :component="this" v-if="permission.Visualizar" />
    </v-card>
    <AddDialog
      v-if="dialog"
      :component="this"
      :title="title"
      :dialog="dialog"
      :actionDialog="close"
    />

    <AlertAPI />
  </Container>
</template>

<script>
import Container from "../components/User/Container";
import AddDialog from "../components/User/AddDialog";
import Table from "../components/User/Table";
import AlertAPI from "../components/alert_api/index";
import { mapGetters } from "vuex";
import { GET_ALL_ENTERPRISE } from "../store/actions/enterprise";
import { GET_ALL_USER, CREATE_USER } from "../store/actions/user";
import { GET_ALL_ROLES } from "../store/actions/permission";

export default {
  components: {
    AddDialog,
    Container,
    Table,
    AlertAPI,
  },
  data: () => ({
    dialog: Boolean(),
    user: {
      status:Boolean(),
      name: String(),
      password: String(),
      email: String(),
      enterprise_id: Number(),
      roles: Array(),
      operator_cashier: Object(),
    },
    title: String(),
    user_confirm_pass: String(),
    dialogPass: Boolean(),
    user_finish: Object(),
    confirm_password: String(),
    permission: Array(),
    changePassword: Boolean(),
  }),
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getConfiguracoesLinks.getPermissions(name);
  },
  computed: {
    ...mapGetters(["getEnterprises", "getConfiguracoesLinks"]),
  },
  async created() {
    this.$store.dispatch(GET_ALL_ENTERPRISE, { limit: 1 });
    this.$store.dispatch(GET_ALL_ROLES, { page: 0 });
    this.getAll();
  },
  methods: {

    getAll(page = 1) {
      this.$store.dispatch(GET_ALL_USER, { page : page ,limit: 10, paginate:true  });
    },
    alterAddDialog() {
      this.dialog = !this.dialog;
    },
    activeDialog() {
      this.title = "Cadastrar";
      this.changePassword = !this.changePassword;
      this.dialog = !this.dialog;
    },

    close() {
      this.user.status = "";
      this.user.name = "";
      this.user.password = "";
      this.user.email = "";
      this.user.roles = [];
      this.confirm_password = "";

      this.changePassword = !this.changePassword;

      if (this.dialog) {
        this.dialog = !this.dialog;
      } else if (this.dialogPass) {
        this.dialogPass = !this.dialogPass;
      }
    },
    confirm(cashier) {
      if (cashier.name === null) {
        this.user.operator_cashier = null;
      } else {
        this.user.operator_cashier = { ...cashier };
      }

      this.user.enterprise_id = this.getEnterprises?.data[0].id;
  
      this.$store.dispatch(CREATE_USER, this.user).then(() => {
        this.close();
      });
    },
  },
};
</script>

<style></style>
