let identifyNFe = {
    keyAccess: "",
    status: "",
    codeUfEmmiter: "",
    descriptionNatureOperation: "",
    codModelSupervisior: "",
    identifyOperationDestination: "",
    seriesDocumentSupervisior: "",
    numberNfe: "",
    issuanceDateNfe: "",
    issuanceDepartureDate: "",
    operationTypeNfe: "",
    codeCountyOperation: "",
    paperFormart: "",
    typeIssueNfe: "",
    typeKeyAcessVerifier: "",
    environmentalIdentification: "",
    issuePurpose: "",
    endConsumerOperation: "",
    buyerPresenceEstablishment: "",
    indicatorIntermediaryEstablishment: "",
    noteIssuingProcess: "",
    versionOfIssueProcess: "",
    dateAndTimeEntryContingency: "",
    contingencyEntryJustification: "",
    referencedAccessKey: "",
    qrCodeNf: "",
    urlChaveNf: "",
    sinatureNf: "",
    valueBaseTotalCalculeIcms: "",
    valueTotalCalculeIcms: "",
    valueTotalProduct: "",
    valueTotalNF: "",
    valueApproximateTax: "",
    paymentMethodIndicator: "",
    meansOfPayment: "",
    valuePaymentAmount: "",
    typeOfIntegration: "",
    cnpjInstitutionPayment: "",
    valueThing: "",
}

const AVISTA = 0


async function execute(sales) {
    try {

        identifyNFe.keyAccess = sales.access_key
        identifyNFe.status = sales.status
        identifyNFe.codeUfEmmiter = String(sales.enterprise.address.my_city.state.id) //sales.cliente.people.physicalpeople === null ? String(sales.cliente.people.address[0].city.state.id) : String(sales.cliente.people.address[0].city.state.id)
        identifyNFe.descriptionNatureOperation = "VENDA CONSUMIDOR",
            identifyNFe.codModelSupervisior = 65,
            identifyNFe.identifyOperationDestination = 1,
            identifyNFe.seriesDocumentSupervisior = sales.series,
            identifyNFe.numberNfe = String(sales.number_sale)
             
        let DateFormart = await formaterDateSales(new Date()) 
        identifyNFe.issuanceDateNfe = DateFormart,
            identifyNFe.issuanceDepartureDate = DateFormart,
            identifyNFe.operationTypeNfe = 1,
            identifyNFe.codeCountyOperation = String(sales.enterprise.address.city_id) //"3205200",
            identifyNFe.paperFormart = "4",
            identifyNFe.typeIssueNfe = "1",
            identifyNFe.typeKeyAcessVerifier = String(1),
            identifyNFe.environmentalIdentification =String(sales.enterprise.note_issuing_environment),
            identifyNFe.issuePurpose = 1,
            identifyNFe.endConsumerOperation = 1,
            identifyNFe.buyerPresenceEstablishment = 1,
            identifyNFe.indicatorIntermediaryEstablishment = "0",
            identifyNFe.noteIssuingProcess = "0",
            identifyNFe.versionOfIssueProcess = "4.00",
            identifyNFe.dateAndTimeEntryContingency = "",
            identifyNFe.contingencyEntryJustification = "",
            identifyNFe.referencedAccessKey = "",
            identifyNFe.qrCodeNf = "",
            identifyNFe.urlChaveNf = "",
            identifyNFe.sinatureNf = "",
            identifyNFe.valueBaseTotalCalculeIcms = parseFloat(sales.base_cal_icms_total)
            identifyNFe.valueTotalCalculeIcms = parseFloat(sales.value_icms_total)
            identifyNFe.valueTotalProduct = parseFloat(sales.value_total_products)
            identifyNFe.valueTotalNF = parseFloat(sales.value_total_note)
            identifyNFe.valueApproximateTax = sales.valueApproximateTax
            let paymentValueMethod = await paymentMethodIndicator(sales.sale_form_of_payment[0].payment_term.deadline)
            identifyNFe.paymentMethodIndicator = paymentValueMethod,
            identifyNFe.meansOfPayment = sales.sale_form_of_payment[0].formofpayment.code
            identifyNFe.valuePaymentAmount = parseFloat(sales.value_total_note)
            identifyNFe.typeOfIntegration = 2,
            identifyNFe.cnpjInstitutionPayment = "",
            identifyNFe.valueThing = 0
            identifyNFe.infoAdditionalTax = "informacoes para o fisco",
            identifyNFe.infoSupplementaryTax = "    Vendedor : " + sales.seller.people.physicalpeople.name
        return identifyNFe


    } catch (error) {
        return error
    }


}



async function paymentMethodIndicator(payment) {
    let paymentFormPayment = payment;
    if (paymentFormPayment == AVISTA)
        return "0"

    else {
        return "1"
    }

}


async function formaterDateSales(dateSale) {
  
    let year = dateSale.getFullYear();
    let day = dateSale.getDate();
    let month = dateSale.getMonth() + 1;
    let hours = dateSale.getHours();
    let minutes = dateSale.getMinutes();
    let seconds = dateSale.getSeconds();

    if (day < 10) {
        day = `0${day}`;
    } else {
        day = day.toString();
    }
    if (month < 10) {
        month = `0${month}`;
    } else {
        month = month.toString();
    }


    if (hours < 10) {
        hours = `0${hours}`;
    } else {
        hours = hours.toString();
    }

    if (minutes < 10) {
        minutes = `0${minutes}`;
    } else {
        minutes = minutes.toString();
    }


    if (seconds < 10) {
        seconds = `0${seconds}`;
    } else {
        seconds = seconds.toString();
    }


    let dateTimeFormat = (year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds)


    return dateTimeFormat
}





module.exports = {
    execute
}


