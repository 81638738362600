<template>
  <v-row justify="center">
    <v-dialog
      transition="fab-transition"
      persistent
      v-model="component.dialog_bleed"
      max-width="350"
    >
      <v-card>
        <v-card-title> {{ words.title }} </v-card-title>
        <v-divider></v-divider>
        <v-container>
          <v-form v-model="valid">
            <v-text-field
              outlined
              label="Tipo operação de caixa"
              v-model="cashier_operation_types_description"
              readonly
            ></v-text-field>
            <v-text-field
              outlined
              dense
              tile
              :label="words.input_label"
              v-model="bleed.value_sangria"
              placeholder="0.00"
              prefix="R$"
            ></v-text-field>
          </v-form>
          <v-divider></v-divider>

          <v-card-actions>
            <v-row justify="center">
              <v-btn
                @click="
                  () => (suggest_print ? submit_bleed(null) : cancel_dialog())
                "
                class="error mr-1"
                width="150"
              >
                <v-icon class="mr-2">mdi-cancel</v-icon>
                {{ words.cancel }}</v-btn
              >
              <v-btn
                class="success"
                width="150"
                @click="
                  () =>
                    current_operation == 'CONFIRM'
                      ? suggestPrint()
                      : submit_bleed(1)
                "
              >
                <v-icon class="mr-2">mdi-content-save</v-icon>
                {{ words.confirm }}</v-btn
              >
            </v-row>
          </v-card-actions>
          <v-card-subtitle>{{ words.btn }}</v-card-subtitle>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { BLEED } from "../../../store/actions/sales";
import { GET_ALL_CASHIER_OPERATION_TYPE } from "../../../store/actions/cashier_operation_type";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    idOpenCashier: Number(),
    words: {
      btn: "Pressione ESC para sair",
      confirm: "Confirmar",
      cancel: "Cancelar",
      title: "Sangria de Caixa ",
      input_label: "Valor de Sangria",
    },
    operation_type: {
      SUGGEST: "SUGGEST",
      CONFIRM: "CONFIRM",
    },
    current_operation: "CONFIRM",
    cashier_operation_types_description: Object(),
    cashier_operation_types_id: Number(),
    isPritting: Boolean(),
    suggest_print: Boolean(),
    valid: Boolean(),
    bleed: {
      dateMovement: Date(),
      numberMovement: String(),
      descriptionMovement: String(),
      typeMovement: String(),
      enterprise_id: Number(),
      value_sangria: Number(),
      cashier_operation_types_id: Number(),
    },
  }),
  methods: {
    suggestPrint(val) {
      if (val) this.isPritting = true;

      this.suggest_print = true;
    },
    async submit_bleed(value) {
      if (value) this.isPritting = true;

      try {
        let object = { ...this.bleed };

        object.dateMovement = this.mounterDateBleedCashier();
        let max = 100000;
        let min = 1;
        object.numberMovement = String(
          Math.floor(Math.random() * (max - min) + min)
        );
        object.descriptionMovement = "Sangria de caixa";
        object.typeMovement = "Saída";
        (object.enterprise_id = this.getEnterprises.data[0].id),
          (object.cashier_operation_types_id = this.cashier_operation_types_id ),
          (this.component.titlePDF = "SANGRIA DE CAIXA");
        await this.$store.dispatch(BLEED, object).then(() => {
          this.component.doc_operation = object.numberMovement
          this.component.open_cashie_payload = object.value_sangria;
          this.cancel_dialog();
        });
        this.component.needPrint = this.isPritting;
      } catch (error) {
        console.log(error);
      }
    },
    cancel_dialog() {
      this.component.dialog_bleed = !this.component.dialog_bleed;
    },
    checkState() {
      switch (this.current_operation) {
        case this.operation_type.SUGGEST:
          this.words.confirm = "IMPRIMIR";
          this.words.cancel = "NÃO";

          break;

        case this.operation_type.CONFIRM:
          this.words.confirm = "Confirmar";
          this.words.cancel = "Cancelar";

          break;
      }
    },
    mounterDateBleedCashier() {
      // Create a date object from a date string
      let date = new Date();

      // Get year, month, and day part from the date
      let year = date.toLocaleString("default", { year: "numeric" });
      let month = date.toLocaleString("default", { month: "2-digit" });
      let day = date.toLocaleString("default", { day: "2-digit" });
      let formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },
    processTypeOperationCashier(cashierOperationTypeList) {
      const SANGRIA = "Sangria";
      cashierOperationTypeList.forEach((cashierOperationType) => {
        if (cashierOperationType.type.toLowerCase() === SANGRIA.toLowerCase()) {
          this.cashier_operation_types_description = cashierOperationType.description;
          this.cashier_operation_types_id = cashierOperationType.id;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["getAllCashierOperationType", "getEnterprises"]),
  },
  async created() {
    await this.$store.dispatch(GET_ALL_CASHIER_OPERATION_TYPE, {});
  },
  watch: {
    current_operation: function () {
      this.checkState();
    },
    getAllCashierOperationType: function (val) {
      this.processTypeOperationCashier(val[0]);
    },
    suggest_print: function (v) {
      if (v) {
        this.current_operation = this.operation_type.SUGGEST;
      } else {
        this.current_operation = this.operation_type.CONFIRM;
      }
    },
  },
};
</script>

<style>
.title {
  font-family: "Courier New", Courier, monospace;
  color: #424242;
}
</style>