<template>
  <v-list>
    <v-list-item
      color="white"
      ml-5
      v-for="link in links"
      :key="link.text"
      router
      :to="link.route"
    >
      <v-list-item-icon>
        <v-icon right class="white--text">{{ link.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          class="white--text title"
          v-text="link.text"
        ></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
import { links } from "./script";
export default {
  data: () => ({
    links,
  }),
};
</script>

<style>
</style>