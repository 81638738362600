const infoError = {
    state: () => ({
        error : {
            dialog:false,
            title:String(),
            menssage:String()
        }
    }),

    mutations: {
        INFO_ERROR: (state, payload ) => {
            state.error = {...payload}
        },
 },


    actions: {},

    getters: {
        getInfoError: state => state.error
    },
};

export default infoError;