<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          disabled
          outlined
          dense
          label="Chave da nota "
          :value="Items.access_key"
        />
      </v-col>


    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          disabled
          outlined
          dense
          label="Protocolo"
          :value="Items.authorization_protocol"
        />
      </v-col>
      <v-col>
        <v-text-field
          disabled
          outlined
          dense
          label="Status da Venda"
          :value="Items.status"
        />
      </v-col>
      <v-col>
        <v-text-field
          disabled
          outlined
          dense
          label="Documento do Cliente"
          :value="Items.number_doc_client"
        />
      </v-col>
    </v-row>
    <v-row>


      <v-col>
        <v-text-field
          disabled
          outlined
          dense
          label="Identificação da Venda"
          :value="Items.number_sale"
        />
      </v-col>
      <v-col>
        <v-text-field
          disabled
          outlined
          dense
          label="Data da venda"
          :value="Items.data_sale"
        />
      </v-col>
      <v-col>
        <v-text-field
          disabled
          outlined
          dense
          label="Valor Total dos Produtos"
          :value="Items.value_total_products"
        />
      </v-col>
    </v-row>
    <v-row>

      <v-col>
        <v-text-field
          disabled
          outlined
          dense
          label="Valor Total da Nota"
          :value="Items.value_total_note"
        />
      </v-col>
      <v-col>
        <v-text-field
          disabled
          outlined
          dense
          label="Valor Contábil"
          :value="Items.value_account_total"
        />
      </v-col>
      <v-col>
        <v-text-field
          disabled
          outlined
          dense
          label="Anotações de Vendas"
          :value="Items.note"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          disabled
          outlined
          dense
          label="Tipo de Operação Fiscal"
          :value="Items.tax_operation_types_id"
        />
      </v-col>

      <v-col>
        <v-text-field
          disabled
          outlined
          dense
          label="Empresa"
          :value="Items.enterprise_id"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
  props: {
    Items: Object(),
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
</style>