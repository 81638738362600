async function verifyTypeOperationIcmsEnterpriseTaxRegimeNormal(cfop_entry, product) {
    if (cfop_entry >= 5100 && cfop_entry <= 5359)
        return calculeIcmsNormalInternoOut(product)
    else if (cfop_entry >= 5401 && cfop_entry <= 5415)
      return calculeIcmsSubstituicaoTributariaInternoOut(product)
    else if (cfop_entry >= 6100 && cfop_entry <= 6359)
     return calculeIcmsNormalOutState(product)
    else if (cfop_entry >= 6400 && cfop_entry <= 6415)
    return calculeIcmsSubstituicaoTributariaOutSate(product)
}



async function calculeIcmsNormalInternoOut(product) {
    product.value_subtotal_product = product.amount * product.value_sale
    product.value_base_icms_product = product.value_subtotal_product 
    product.value_accounting_product = product.value_subtotal_product
    let calcIcms  =  product.value_base_icms_product * (product.aliquot_icms_product / 100)
    product.value_icms_product = calcIcms.toFixed(2)
    product.value_free_icms_product = 0
    product.value_icms_outher_product = 0
    product.value_base_icms_st_product = 0
    product.value_icms_st_product = 0
    return product   
}

async function calculeIcmsSubstituicaoTributariaInternoOut(product) {

   
    //ICMS
    product.value_subtotal_product = product.amount * product.value_sale
    product.value_base_icms_product = 0
    product.aliquot_icms_product = 0
    product.value_accounting_product = product.value_subtotal_product
    product.value_icms_product  = 0
    product.value_free_icms_product = 0
    product.value_icms_outher_product  = product.amount * product.value_sale
    product.value_base_icms_st_product = 0
    product.value_icms_st_product = 0

    return product
}


async function calculeIcmsNormalOutState(product) {


    product.value_subtotal_product = product.amount * product.value_sale
    product.value_base_icms_product = product.value_subtotal_product 
    product.value_accounting_product = product.value_subtotal_product
    let calcIcms  =  product.value_base_icms_product * (product.aliquot_icms_product / 100)
    product.value_icms_product = calcIcms.toFixed(2)
    product.value_free_icms_product = 0
    product.value_icms_outher_product = 0
    product.value_base_icms_st_product = 0
    product.value_icms_st_product = 0
    return product   

}


async function calculeIcmsSubstituicaoTributariaOutSate(product) {
    product.value_subtotal_product = product.amount * product.value_sale
    product.value_base_icms_product = 0
    product.aliquot_icms_product = 0
    product.value_accounting_product = product.value_subtotal_product
    product.value_icms_product  = 0
    product.value_free_icms_product = 0
    product.value_icms_outher_product  = product.amount * product.value_sale
    product.value_base_icms_st_product = 0
    product.value_icms_st_product = 0

    return product
}


module.exports = {
    verifyTypeOperationIcmsEnterpriseTaxRegimeNormal
}



















