<template>
  <v-list-group
    no-action
    :value="false"
    v-if="links.length"
  >
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon
          right
          class="white--text"
        >mdi-cart-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="white--text title">Vendas</v-list-item-title>
      </v-list-item-content>
    </template>
    <div
      v-for="link in links"
      :key="link.text"
    >
      <v-list-item
        v-if="link.active"
        color="white"
        router
        :to="link.route"
        class="navDrop"
      >
        <v-list-item-icon>
          <v-icon class="white--text">{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title :class="link.class">{{ link.text }}
          </v-list-item-title>
        </v-list-item-content>
        </v-list-item>
        </div>
        </v-list-group>
</template>

<script>
import { links } from "./script";
// import { mapGetters } from "vuex";
export default {
  data: () => ({
    links,
  }),
  // computed: {
  //   ...mapGetters(""),
  // },
};
</script>

<style></style>
