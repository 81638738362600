<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="component.addDialog"
      max-width="800"
    >
      <v-card>
        <v-card-title primary-title> Caixa </v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="Código"
                v-model="cashier.code"
                :readonly="justVisible"
                maxlength="5"
                :rules="[
                  () => !!cashier.code || 'Campo Obrigatorio',
                  () =>
                    (!!cashier.code && cashier.code.length >= 1) ||
                    'Campo tem que ser maior do que 1',
                ]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Descrição"
                v-model="cashier.description"
                :readonly="justVisible"
                :rules="[
                  () => !!cashier.description || 'Campo Obrigatório',
                  () =>
                    (!!cashier.description &&
                      cashier.description.length >= 2) ||
                    'Campo tem que ser maior do que 2',
                ]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Saldo Inicial"
                v-model="cashier.initial_balance"
                :readonly="justVisible"
                prefix="R$"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Data Inicial"
                type="date"
                v-model="cashier.initial_date"
                :readonly="justVisible"
                :rules="[() => !!cashier.initial_date || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                :items="itensSelect"
                label="Tipo de Caixa"
                v-model="cashier.cashierType"
                :readonly="justVisible"
                :rules="[() => !!cashier.cashierType || 'Campo Obrigatorio']"
                outlined
                solo
              ></v-select>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="
                  () => {
                    executeCancelAddDialog();
                  }
                "
              >Cancelar</v-btn>
                <v-btn
                v-if="!justVisible"
                  color="success"
                  class="mr-4"
                  :disabled="!valid"
                  @click="
                  () => {
                    submit();
                  }
                "
                >Salvar</v-btn>
            </v-card-actions>
            </v-form>
        </v-container>
      </v-card>
      </v-dialog>
  </v-row>
</template>

<script>
import { ADD_CASHIER, UPDATE_CASHIER } from "../../../store/actions/cashier";

export default {
  props: {
    component: Object,
    objectToUpdate: Object,
  },

  data() {
    return {
      justVisible: Boolean(),
      dialog: false,
      valid: true,
      itensSelect: ["Caixa Loja", "Caixa Tesouraria"],
      cashier: {
        code: String(),
        description: String(),
        initial_balance: Number(),
        balance_current: Number(),
        initial_date: String(),
        cashierType: String(),
      },
    };
  },
  methods: {
    getComponent() {
      return this.component;
    },
    executeCancelAddDialog() {
      this.component.AlterAddDialog();
    },
    async create_cashier() {
      this.cashier.balance_current = 0;
      let cashier = { ...this.cashier };
      try {
        await this.$store.dispatch(ADD_CASHIER, cashier);
      } catch (error) {
        alert(error);
      }
    },
    submit() {
      this.cashier.id == undefined
        ? this.create_cashier()
        : this.update_cashier();
      this.component.addDialog = false;
    },

    async update_cashier() {
      let cashier = { ...this.cashier };
      try {
        await this.$store.dispatch(UPDATE_CASHIER, cashier);
      } catch (error) {
        alert(error);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  created() {
    if (this.objectToUpdate.id) {
      this.justVisible = this.objectToUpdate.justVisible
      this.cashier = { ...this.objectToUpdate };
    }
  },
  beforeDestroy() {
    this.component.objectToUpdate = {};
  },
};
</script>
