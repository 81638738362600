<template>

    <v-form ref="form" v-model="component.validLegal">
  
      <!-- <v-row v-if="component.out_of_register">
  
        <v-col cols="6">
  
          <v-select label="Empresa" :items="listEnterprise" v-model="selectedEnterprise" outlined item-text="fantasyName"
  
            item-value="id"></v-select>
  
        </v-col>
  
      </v-row> -->
  
      <v-row>
  
        <v-col cols="6">
  
          <v-text-field v-model="component.LegalPeople.socialName" label="*Razão Social" :rules="textRules" outlined>
  
          </v-text-field>
  
        </v-col>
  
        <v-col cols="6">
  
          <v-text-field v-model="component.LegalPeople.fantasyName" label="*Nome Fantasia" :rules="textRules" outlined>
  
          </v-text-field>
  
        </v-col>
  
      </v-row>
  
      <v-row>
  
        <v-col cols="3">
  
          <v-text-field v-model="component.LegalPeople.cnpj" label="*CNPJ" v-mask="'##.###.###/####-##'"
  
            :rules="[cnpjRule]" outlined></v-text-field>
  
        </v-col>
  
        <v-col cols="3">
  
          <v-text-field v-model="component.LegalPeople.stateRegistration" label="*Inscrição Estadual" :rules="textRules"
  
            outlined @blur="checkStateRegistration()"></v-text-field>
  
        </v-col>
  
      </v-row>
  
      <v-row>
  
         <v-col cols="4">
  
           <v-text-field
  
             v-model="component.contact.cellPhone"
  
             label="*Telefone Celular"
  
             :rules="textRules"
  
             hint="Exemplo: (12) 3 4567-8901"
  
             v-mask="'(##) # ####-####'"
  
             outlined
  
           ></v-text-field>
  
         </v-col>
  
       </v-row>
  
    </v-form>
  
  </template>
  
  <script>
  
  import { GET_ALL_ENTERPRISE, SELECTED_ENTERPRISE } from "../../../../../../store/actions/enterprise"
  
  import { mapGetters } from "vuex";
  
  export default {
  
    computed: {
  
      ...mapGetters(["getShowDialogRegisterClient", "getSelectedEnterprise", "getEnterprises"]),
  
    },
  
    props: { component: Object() },
  
    data: () => ({
  
      selectedEnterprise: Object(),
  
      listEnterprise: Array(),
  
      textRules: [(v) => !!v || "Campo obrigatório!"],
  
    }),
  
    methods: {
  
      getAllInformations(page = null) {
  
        var payload = {
  
          page: page, limit: null, paginate: false
  
        }
  
        this.$store.dispatch(GET_ALL_ENTERPRISE, payload)
  
      },
  
      reset() {
  
        this.$refs.form.resetValidation();
  
      },
  
      checkCnpj(cnpj) {
  
        // Remove caracteres não numéricos
  
        cnpj = cnpj.replace(/\D/g, '');
  
        // Verifica se o CNPJ tem 14 dígitos
  
        if (cnpj.length !== 14) {
  
          return false;
  
        }
  
        // Verifica se todos os dígitos são iguais (CNPJ inválido se for verdadeiro)
  
        if (/^(\d)\1+$/.test(cnpj)) {
  
          return false;
  
        }
  
        // Calcula o primeiro dígito verificador
  
        let soma = 0;
  
        let peso = 2;
  
        for (let i = 11; i >= 0; i--) {
  
          soma += parseInt(cnpj.charAt(i)) * peso;
  
          peso = peso === 9 ? 2 : peso + 1;
  
        }
  
        const digito1 = (soma % 11 < 2) ? 0 : 11 - (soma % 11);
  
        // Verifica se o primeiro dígito verificador está correto
  
        if (digito1 !== parseInt(cnpj.charAt(12))) {
  
          return false;
  
        }
  
        // Calcula o segundo dígito verificador
  
        soma = 0;
  
        peso = 2;
  
        for (let i = 12; i >= 0; i--) {
  
          soma += parseInt(cnpj.charAt(i)) * peso;
  
          peso = peso === 9 ? 2 : peso + 1;
  
        }
  
        const digito2 = (soma % 11 < 2) ? 0 : 11 - (soma % 11);
  
        // Verifica se o segundo dígito verificador está correto
  
        if (digito2 !== parseInt(cnpj.charAt(13))) {
  
          return false;
  
        }
  
        // Se todas as verificações passaram, o CNPJ é válido
  
        return true;
  
      },
  
      cnpjRule(v) {
  
        if (!this.checkCnpj(v)) {
  
          return 'CNPJ Inválido';
  
        }
  
        return true;
  
      },
  
      checkStateRegistration() {
  
        if(this.component.LegalPeople.stateRegistration == "") {
  
          this.component.LegalPeople.stateRegistration = "ISENTO"
  
        }
  
      }
  
    },
  
    watch: {
  
      // selectedEnterprise: function () {
  
      //   this.$store.dispatch(SELECTED_ENTERPRISE, this.selectedEnterprise)
  
      // },
  
      // getEnterprises: function (value) {
  
      //   if(value){
  
      //     //console.log(value);
  
      //     this.listEnterprise = Object.values(value)
  
      //     this.selectedEnterprise = this.listEnterprise[0]
  
      //   }
  
      // },
  
    },
  
    created() {
  
      // this.getAllInformations()
  
    },
  
  };
  
  </script>
  
  <style>
  
  </style>
  