<template>
  <v-card :elevation="3">
    <v-card-title>Carrinho</v-card-title>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      fixed-header
      :items-per-page="-1"
      height="50vh"
      hide-default-footer
      no-data-text="Carrinho Vazio"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          color="orange darken-3"
          @click="
            () => {
              descontItemCar(item);
            }
          "
        >mdi-calculator</v-icon>
        <v-icon
          small
          color="orange darken-3"
          class="ml-2"
          @click="
            () => {
              editItemCar(item);
            }
          "
        >mdi-pencil</v-icon>
        <v-icon
          small
          color="red darken-3"
          class="ml-2"
          @click="
            () => {
              deleteItem(item);
            }
          "
        >mdi-delete</v-icon>
      </template>
      <template
        v-slot:item.value_descont_product="{ item }"
      >{{ parseFloat(item.value_descont_product).toFixed(2) }}</template>
    </v-data-table>
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-card-actions >
      <div class="footerTable" >
        <!-- <v-card-text class="text-h6"></v-card-text> -->
        <p class="payment"> <span class="paymentTotal">Total a Pagar</span>: <span class="numberTotal">R$ {{ total.toFixed(2) }}</span></p>
        <p class="infSeller">         
         <span class="infSeller operator" >Cliente</span>: <span class="infSeller Name">{{this.component.client.name}}</span>  | <span class="infSeller pdvDescription" >Vendedor</span>: <span class="infSeller pdvId" >{{this.component.seller.name}}</span> 
        </p>
        <p class="infSeller">         
         <span class="infSeller operator" >Operador</span>: <span class="infSeller Name">{{user.name}}</span>  | <span class="infSeller pdvDescription" >PDV</span>: <span class="infSeller pdvId" >{{pdvId}}</span> 
        </p>
   
      </div>
      
      <v-spacer></v-spacer>
      <CustomButtom  />
      <v-btn
        class="red darken-4 white--text"
        @click="
          () => {
            ClearCart();
          }
        "
      >
        <v-icon left>mdi-cart-remove</v-icon>Limpar
      </v-btn>
      <v-btn
        class="green darken-4 white--text"
        :disabled="valid"
        @click="
          () => {
            FinishSaleFormOfPaymentsEndCondidtionsPayments();
          }
        "
      >
        <v-icon left>mdi-cart-arrow-right</v-icon>PAGAR
      </v-btn>
    </v-card-actions>
    <EditProduct :component="this" />
    <DescontProduct :component="this" />
    <DeleteProduct :component="this" />
    <ClearCar :component="this" />
    <FinishSale v-if="dialog_finish" :component="this" />
    <AlertError
      v-if="dialog_alert"
      :title_error="errorTitle"
      :listErrors="errorsList"
      :component="this"
    />
    <PasswordDialog v-if="permissionDiscountDialog" :component="this" />
  </v-card>
</template>

<script>
import CustomButtom from "./component/buttom.vue";
import { headers } from "./script";
import EditProduct from "../AddDialog/Edit_Product";
import DescontProduct from "../AddDialog/Descont_Product";
import DeleteProduct from "../AddDialog/Delete_Product";
import ClearCar from "../AddDialog/Delete_All_Cart/";
import FinishSale from "../AddDialog/Finish_Sale/";
import AlertError from "../AddDialog/Modal_Error/";
import PasswordDialog from "./Password_Dialog/index.vue";
import { GET_CALC_TRIBUTE } from "../../../store/actions/sales";
import ENV from "../../../env"
import {
  DESTROY_CART,
  UPDATE_CART,
  CLEAN_CART,
  DISCOUNT_PERMISSION,
  SET_DISCOUNT_PERMISSION,
} from "../../../store/actions/cart";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { mapGetters } from "vuex";

export default {

  
  components: {
    DescontProduct,
    EditProduct,
    DeleteProduct,
    ClearCar,
    FinishSale,
    AlertError,
    CustomButtom,
    PasswordDialog,
  },


  props: {
    component: Object,
    datas: Object,
    disabled: Boolean
  },

  data: () => ({
    pdvId: ENV.getInstance().VUE_APP_PDV_COMPUTER_ID, //modelo de leitura do arquivo dotenv
    user: Object(),
    headers,
    dialog: false,
    dialog_delete: false,
    dialog_clear: false,
    dialog_finish: false,
    dialog_descont: false,
    dialog_alert: Boolean(),
    errorsList: Array(),
    errorTitle: String(),
    element: Object(),
    getEnterprise: Object(),
    items: Array(),
    valid: Boolean(),
    indexOf: Number(),
    amount: "",
    valueDescont: Number(),
    type_descont: "",
    total: 0,
    enterprise_id: Number(),
    permissionDiscountDialog: Boolean(),
    isLoading: false,
    email: String(),
    password: String(),
    totalDescount: Number(),
    sale: {
      status: "Em Digitacao",
      number_doc_client: "",
      data_sale: new Date,
      access_key: String(),
      number_sale: String(),
      authorization_protocol: String(),
      series: String(),
      wasProcessed: false,

      value_total_products: Number(),
      value_total_note: Number(),
      value_descount_total: Number(),
      value_account_total: Number(),

      value_icms_substituicao_total: Number(),
      base_cal_icms_total: Number(), //alterado  back ok
      base_calc_susbt_total: Number(), //alterado back ok
      value_icms_free_total: Number(),
      value_outher_tax_total: Number(),
      value_icms_total: Number(),

      note: "Venda de mercadoria", // Valor Mocado

      value_ipi_total: Number(),
      value_ipi_outher_total: Number(),
      value_ipi_free_total: Number(),
      base_calc_ipi_total: Number(),
    
      value_base_pis_total: Number(),
      value_base_pis_st_total: Number(),
      value_other_pis_total: Number(),
      value_pis_st_total: Number(),
      value_pis_total: Number(),

      value_base_cofins_st_total: Number(),
      value_base_cofins_total: Number(),
      value_other_cofins_total: Number(),
      value_cofins_st_total: Number(),
      value_cofins_total: Number(),
      valueApproximateTax:Number(),
      client_id: Number(),
      tax_operation_types_id: 2,
      enterprise_id: 1,
      account_plans_id: 1,
      pdv_id: ENV.getInstance().VUE_APP_PDV_COMPUTER_ID,
      seller_id: Number(),
      note_models_id: 2,
      form_of_payments: Array(),
      itens_sales: Array(),
      enterprise: Object(),
      client: Object(),
      seller:Object(),
      user_id: 1
    }
  }),

  methods: {
    async GetEnterprise() {
      await this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: 1, limit: 10, paginate:true 
      });
    },
    editItemCar(item) {
      this.dialog = !this.dialog;
      this.element = { ...item };
      // this.indexOf = this.getCart.indexOf(item);
      this.amount = this.element.amount;
    },
    onKey(e) {
      let KeyCode = e.key;
      switch (KeyCode) {
        case "F9":
          if (this.items.length > 0) {
       
            this.FinishSaleFormOfPaymentsEndCondidtionsPayments();
          } else {
            this.valid = true;
          }
          break;

        case "Escape":
          this.dialog_finish = false;

          break;
      }
    },
    async recalculetributeEditQuantidadeItem(item) {
     let productTribute =  await this.$store .dispatch(GET_CALC_TRIBUTE, {
          product: item,
          enterprise: this.getEnterprise
        })

          if (productTribute) {
            this.element.value_base_icms_product = productTribute.value_base_icms_product;
            this.element.value_accounting_product =
              productTribute.value_accounting_product;
            this.element.value_icms_product = productTribute.value_icms_product;
            this.element.aliquot_ipi_product =
              productTribute.aliquot_ipi_product;
            this.element.value_cofins_product =
              productTribute.value_cofins_product;
            this.element.aliquot_icms_product =
              productTribute.aliquot_icms_product;
            this.element.value_base_icms_st_product =
              productTribute.value_base_icms_st_product;
            this.element.value_icms_st_product =
              productTribute.value_icms_st_product;
            this.element.value_ipi_product = productTribute.value_ipi_product;
            this.element.value_free_cofins_product =
              productTribute.value_free_cofins_product;
            this.element.value_other_pis_product =
              productTribute.value_other_pis_product;
            this.element.value_pis_st_product =
              productTribute.value_pis_st_product;
            this.element.value_free_icms_product =
              productTribute.value_free_icms_product;
            this.element.value_icms_outher_product =
              productTribute.value_icms_outher_product;
            this.element.value_base_ipi_product =
              productTribute.value_base_ipi_product;
            this.element.aliquota_pis = productTribute.aliquota_pis;
            this.element.value_base_pis_product =
              productTribute.value_base_pis_product;
            this.element.value_pis_product = productTribute.value_pis_product;
            this.element.value_base_cofins_product =
              productTribute.value_base_cofins_product;
            this.element.value_base_pis_st_product =
              productTribute.value_base_pis_st_product;
            this.element.value_base_cofins_st_product =
              productTribute.value_base_cofins_st_product;
            this.element.value_cofins_st_product =
              productTribute.value_cofins_st_product;
            this.element.value_other_pis_st_product =
              productTribute.value_other_pis_st_product;
            this.element.value_other_cofins_product =
              productTribute.value_other_cofins_product;
            this.element.value_free_pis_product =
              productTribute.value_free_pis_product;
            this.element.aliquota_cofins = productTribute.aliquota_cofins;
            this.element.value_ipi_free_product =
              productTribute.value_ipi_free_product;
            this.element.value_ipi_outher_product =
              productTribute.value_ipi_outher_product;
            this.element.cfop_id = productTribute.cfop_id;
            this.element.cst_id = productTribute.cst_id;
            this.element.csosn_id = productTribute.csosn_id;
            this.element.cst_pis_id = productTribute.cst_pis_id;
            this.element.cst_cofins_id = productTribute.cst_cofins_id;
          }
   
    },

    deleteItem(item) {
      this.dialog_delete = !this.dialog_delete;
      this.element = item;
      this.indexOf = this.getCart.indexOf(item);
    },
    clearClientSeller() {
      this.component.clear();
    },
    descontItemCar(item) {
      this.dialog_descont = !this.dialog_descont;
      this.element = { ...item };

      this.indexOf = this.getCart.indexOf(item);
    },
    ClearCart() {
      this.dialog_clear = !this.dialog_clear;
    },

    FinishSaleFormOfPaymentsEndCondidtionsPayments() {
      let validClient = undefined
      let validSeller = undefined
      validClient = this.component.client.id;
      validSeller = this.component.seller.id;
      this.sale.client = this.component.clientNfceObject;
      this.sale.seller = this.component.sellerNfceObject;
      this.sale.enterprise = this.component.enterpriseNfceObject;
      if (validClient == undefined || validSeller == undefined) {
        this.dialog_alert = !this.dialog_alert;
        this.errorTitle = "Campos Obrigatórios";
        this.errorsList = [
          { text: "Campo de Cliente" },
          { text: "Campo de Vendedor" }
        ];
      } else {
        this.sale.client_id = validClient;
        this.sale.seller_id = validSeller;
        this.dialog_finish = !this.dialog_finish;
      }
    },

    calculate(items) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      let item = items || [...this.items];
      this.items = [];
      let variable = item.map(e => e.value_subtotal_product);
      this.total = variable.reduce(reducer, 0);
      this.items = [...this.$store.getters.getCart];
    },

    confirm_clean_car() {
      this.$store.dispatch(CLEAN_CART);
      this.calculate(this.$store.getters.getCart);
    },
    permissionDiscountLogin() {
      this.isLoading = true
      const payload = {
        email: this.email,
        password: this.password,
        discount: this.totalDescount,
        enterprise_id: 1,
      }
      this.$store.dispatch(DISCOUNT_PERMISSION, payload)
    },
    giveDiscount() {
      if(this.type_descont == "PERCENTUAL") {

        var desconto =
              (parseFloat(this.element.value_subtotal_product) *
                parseFloat(this.valueDescont))/ 
              100;
  
            var DescontoPriceValueSales =    (parseFloat(this.element.value_sale) *
                parseFloat(this.valueDescont))/ 
              100;
            this.element.value_subtotal_product = parseFloat(this.element.value_subtotal_product).toFixed(2) - desconto.toFixed(2);
            this.element.value_sale  = this.element.value_sale -  DescontoPriceValueSales.toFixed(2)
            this.element.value_sale = this.element.value_sale.toFixed(2)
            this.element.value_descont_product += desconto;
            this.element.valueUnityBusinessProduct = this.element.value_sale
            this.recalculetributeEditQuantidadeItem(this.element)
            this.$store.dispatch(UPDATE_CART, this.element);
            this.dialog_descont = !this.dialog_descont;
            this.valueDescont = Number();

      } else if(this.type_descont == "DINHEIRO") {

        let convertionPercentualValue = (this.valueDescont / this.element.value_subtotal_product)
              var desconto =
                (parseFloat(this.element.value_subtotal_product) *
                  parseFloat(convertionPercentualValue))

              var DescontoPriceValueSales =    (parseFloat(this.element.value_sale) * parseFloat(convertionPercentualValue))
              this.element.value_subtotal_product = parseFloat(this.element.value_subtotal_product).toFixed(2) - desconto.toFixed(2);
              this.element.value_sale  = this.element.value_sale -  DescontoPriceValueSales.toFixed(2)
              this.element.value_sale = this.element.value_sale.toFixed(2)
              this.element.value_descont_product += desconto;
              this.element.valueUnityBusinessProduct = this.element.value_sale
              this.recalculetributeEditQuantidadeItem(this.element)
              this.$store.dispatch(UPDATE_CART, this.element);
              this.dialog_descont = !this.dialog_descont;
              this.valueDescont = Number();

      }
    },
    confirm_descont_item() {
      let productPrice = this.element.product.sale_price * this.element.amount
      let maxDescountValue = (productPrice / 100) * this["auth/getUser"].user.cashier_operation.limit_descont_percentual
      let maxDescountPercentualValue = Number()
      if(this.element.value_descont_product != 0) {
        maxDescountPercentualValue = (this.element.value_descont_product * 100) / productPrice 
      }
      switch (this.type_descont) {
        case "PERCENTUAL":
          if(this.valueDescont <= (this["auth/getUser"].user.cashier_operation.limit_descont_percentual - maxDescountPercentualValue)) {
            // if(this.valueDescont <= 50) {
              this.giveDiscount()
            } else {
            this.totalDescount = parseFloat(this.valueDescont) + maxDescountPercentualValue
            this.permissionDiscountDialog = true
          }

          break;
          case "DINHEIRO":
            if((parseFloat(this.valueDescont) + parseFloat(this.element.value_descont_product)) <= maxDescountValue) {
              // if(this.valueDescont <= 20) {
              this.giveDiscount()
            } else {
              this.totalDescount = parseFloat(this.valueDescont) + parseFloat(this.element.value_descont_product)
              this.totalDescount = (this.totalDescount * 100) / productPrice
              this.totalDescount = this.totalDescount.toFixed(2)
              this.permissionDiscountDialog = true
            }
          break;
      }

       this.calculate(this.$store.getters.getCart);


        // case "DINHEIRO":
        //   this.element.value_subtotal_product = parseFloat(this.element.value_subtotal_product).toFixed(2)  - parseFloat(this.valueDescont).toFixed(2);
        //   this.element.value_descont_product = this.valueDescont.toFixed(2);
        //   this.element.value_sale  = parseFloat(this.element.value_sale).toFixed(2) - parseFloat(this.valueDescont).toFixed(2);
        //   this.element.valueUnityBusinessProduct = this.element.value_sale
        //   this.recalculetributeEditQuantidadeItem(this.element)
        //   this.$store.dispatch(UPDATE_CART, this.element);
        //   this.dialog_descont = !this.dialog_descont;
        //   this.valueDescont = 0;
      
        //   break;

    },
    async confirmAlterQuantProduct() {
      this.element.amount = this.amount;
      this.element.value_subtotal_product = parseFloat(this.element.amount) * parseFloat(this.element.value_sale);
      this.recalculetributeEditQuantidadeItem(this.element);
      await this.$store.dispatch(UPDATE_CART, this.element);
      this.dialog = !this.dialog;
      this.amount = 0;
      this.calculate(this.$store.getters.getCart);
    },

    confirm_delete() {
      this.$store.dispatch(DESTROY_CART, this.getCart.indexOf(this.element));
      this.dialog_delete = !this.dialog_delete;
      this.calculate(this.$store.getters.getCart);
    },
    clearCart() {
      this.$store.dispatch(CLEAN_CART);
      this.dialog_clear = false;
      this.calculate(this.$store.getters.getCart);
    }
  },

  watch: {
    getCart: function() {
      this.calculate(this.$store.getters.getCart);
    },
    getEnterprises: function (val) {
      this.enterprise_id = val.data[0].id;
      this.getEnterprise = val.data[0]
    },
    items: function() {
      if (this.items.length > 0) {
        this.valid = false;
      } else {
        this.valid = true;
      }
    },
    getDiscountPermission: function(val) {
      if(val == false) {
        this.permissionDiscountDialog = false
      } else if(val == true) {
        this.permissionDiscountDialog = false
        this.giveDiscount()
      }
      this.$store.dispatch( SET_DISCOUNT_PERMISSION )
    }
  },
  computed: {
    ...mapGetters(["getCart","getEnterprises", "auth/getUser", "getDiscountPermission"])
  },
  mounted() {
    this.calculate(this.$store.getters.getCart);
  },
  async created() {
    this.user = { ...this.$store.state.auth.user.user };
    document.addEventListener("keyup", this.onKey);
    await this.GetEnterprise();
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.onKey);
  }
};
</script>
<style>
  .footerTable{
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15px;
  }
  .payment{
    font-size: 20px;
    font-weight: bold;
  }
  .infSeller{
    font-size: 20px;
    font-weight: bold;
  }
  .infSeller .operator{
    color: #304c64;
  }
  .infSeller .Name{
    color: grey;
  }
  .infSeller .pdvDescription{
    color: #304c64;
  }
  .infSeller .pdvId{
    color: grey;
  }
  .paymentTotal{
    color: #304c64;
  }
.numberTotal{
  color: grey;
}
  
.carTable{
  
  min-height: 100%;
  background-color: aqua;
}
</style>