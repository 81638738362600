<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>

      <v-card-text>
        <v-text-field
          v-model="searchInput"
          label="Pesquisar"
          outlined
          append-icon="mdi-magnify"
          @keyup.enter="autocompleteSeller()"
        >
        </v-text-field>
      </v-card-text>
      <Table v-if="permission.Visualizar" :search="search" :component="this" />
      <AddDialog
        v-if="addDialog"
        :component="this"
        title="Novo"
        :enterprise_id="enterprise_id"
      />
    </v-card>
    <AlertAPI />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Sellers/Container/";
import CardTitle from "../components/Sellers/CardTitle/";
import AddDialog from "../components/Sellers/AddDialog/";
import Table from "../components/Sellers/Table/";
import loadScreen from "../components/load"
import AlertAPI from "../components/alert_api/";
import { GET_ALL_SELLER, AUTOCOMPLETE_SELLER } from "../store/actions/seller";
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    search: "",
    searchInput: "",
    addDialog: false,
    destroyDialog: false,
    updateDialog: Boolean(),
    dialog_delete: Boolean(),
    idToDestroy: 0,
    objectToUpdate: {},
    title: "Vendedor",
    enterprise_id: Number(),
    permission: []
  }),
  mounted() {
    let name = document.URL.split("/").pop()
    this.permission = this.getCadastrosLinks.getPermissions(name)
  },
  methods: {
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_SELLER, {
        page: page,
        limit: 10,
      });
    },
    async GetAutocompleteSeller() {
      let payload = {
          search: this.search,
          page : 1,
          limit: 10,
          paginate: true,
          enterprise_id: this.getEnterprises.data[0].id,
        }
        await this.$store.dispatch(AUTOCOMPLETE_SELLER, payload)
    },
    autocompleteSeller() {
      this.search = this.searchInput
    }
  },
  watch: {
    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
      if(val.length == 0) {
        this.GetAll();
      } else if(val.length >= 2){
        this.GetAutocompleteSeller()
      }
    }
  },
  computed: {
    ...mapGetters(["getCadastrosLinks", "getEnterprises"]),
  },

  components: {
    Container,
    CardTitle,
    Table,
    AddDialog,
    AlertAPI,
    loadScreen
  },

  created() {
    this.GetAll();
  },
};
</script>

<style scoped></style>
